import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { BIQPackage } from '../../models/biq/biqPackage.model';
import { BiqPackageRequest } from '../../models/biq/biqPackageRequest.model';
import { CacheInfo } from '../../models/biq/cacheInfo.model';
import { map, shareReplay } from 'rxjs/operators';
import moment from "moment";
import { SagraTranslationService } from '@sagra/sagra-translation';
import { CacheInfoDetails } from '../../models/biq/cacheInfoDetails.model';
import { CacheVersion } from '../../models/biq/cacheVersion.model';
import { StaviatorUserContext } from '../../models/staviatorUserContext.model';
import { CacheLicenseType } from '../../models/biq/cacheLicenseType.model';

@Injectable()
export class BIQService {
    private apiUrl: string;
    private cachedStaviatorUserContext$?: Observable<StaviatorUserContext>;
    private cachedCacheLicenseTypes$?: Observable<any>;

    constructor(private http: HttpClient,
        private translationService: SagraTranslationService,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    getPackagesList(): Observable<Array<BIQPackage>> {
        const observable = this.http.get<Array<BIQPackage>>(this.apiUrl + "api/biq/GetPackagesList");
        return observable;

        //return new Observable<Array<BIQPackage>>(subscriber => {
        //    const lista: Array<BIQPackage> = [{ name: 'Essential', value: 'Essential' },
        //    { name: 'Standard', value: 'Standard' },
        //    { name: 'Professional', value: 'Professional' },
        //    { name: 'Business', value: 'Business' },
        //    { name: 'Enterprise', value: 'Enterprise' },
        //    { name: 'Demo', value: 'Demo', isDemo: true }];
        //    subscriber.next(lista);
        //})
    }

    switchOnPackage(biqRequest: BiqPackageRequest) {
        const observable = this.http.post<Array<BIQPackage>>(this.apiUrl + "api/biq/switchOnPackage", biqRequest);
        return observable;
    }

    removeCache(biqRequest: BiqPackageRequest) {
        const observable = this.http.post<Array<BIQPackage>>(this.apiUrl + "api/biq/removeCache", biqRequest);
        return observable;
    }

    changePackage(biqRequest: BiqPackageRequest) {
        const observable = this.http.post<Array<BIQPackage>>(this.apiUrl + "api/biq/ChangePackage", biqRequest);
        return observable;
    }

    changePackageParameters(biqRequest: BiqPackageRequest) {
        const observable = this.http.post<Array<BIQPackage>>(this.apiUrl + "api/biq/changePackageParameters", biqRequest);
        return observable;
    }

    getCachesList(): Observable<Array<CacheInfo>> {
        const observable = this.http.get<Array<CacheInfo>>(this.apiUrl + "api/biq/GetCachesList").pipe(map(items => {
            for (let item of items) {
                if (item.timeStamp != null) {
                    item.timeStamp = moment(item.timeStamp);
                    item.timeStampText = item.timeStamp.format("DD.MM.YYYY, HH:mm");
                }
                if (item.versions != null && item.versions.length > 0) {
                    item.versionsText = '';
                    for (let ver of item.versions) {
                        if (item.versionsText != null && item.versionsText.length > 0) {
                            item.versionsText += ", ";
                        }
                        item.versionsText += ver.cacheSeverVersionNameShort;
                    }
                }
                if (item.inProgressInfo) {
                    item.tooltipModel = { tooltipItems: [] };
                    item.tooltipModel.tooltipItems.push({ title: this.translationService.translate('Status'), content: (item.inProgressInfo.taskCompleted + 1) + " " + this.translationService.translate('of') + " " + item.inProgressInfo.tasksCount });
                    item.tooltipModel.tooltipItems.push({ title: this.translationService.translate('VM_version'), content: item.inProgressInfo.version.cacheSeverVersionNameShort });
                    if (item.inProgressInfo.executor != null && item.inProgressInfo.executor.length > 0) {
                        const text = item.inProgressInfo.executor.join(",\r\n");
                        item.tooltipModel.tooltipItems.push({ title: this.translationService.translate('Executor'), content: text });
                    }
                }
            }
            return items;
        }));
        return observable;
    }

    getCacheDetails(dbName: string): Observable<Array<CacheInfoDetails>> {
        let httpParams = new HttpParams().set("dbName", dbName);
        const observable = this.http.get<Array<CacheInfoDetails>>(this.apiUrl + "api/biq/GetCacheDetails", { params: httpParams }).pipe(map(items => {
            for (let item of items) {
                if (item.timestamp != null) {
                    item.timestamp = moment(item.timestamp);
                    item.timestampText = item.timestamp.format("DD.MM.YYYY");
                }
            }
            return items;
        }));
        return observable;
    }

    getCacheVersions(): Observable<Array<CacheVersion>> {
        const observable = this.http.get<Array<CacheVersion>>(this.apiUrl + "api/biq/GetCacheVersions");
        return observable;
    }

    getStaviatorUserContext(): Observable<StaviatorUserContext> {
        if (!this.cachedStaviatorUserContext$) {
            this.cachedStaviatorUserContext$ = this.http.get<StaviatorUserContext>(this.apiUrl + 'api/biq/GetStaviatorUserContext').pipe(map(data => {
                return data;
            }), shareReplay(1));
        }
        return this.cachedStaviatorUserContext$;

    }

    getCacheLicenseTypes(): Observable<Array<CacheLicenseType>> {
        if (!this.cachedCacheLicenseTypes$) {
            this.cachedCacheLicenseTypes$ = this.http.get<Array<CacheLicenseType>>(this.apiUrl + 'api/biq/GetCacheLicenseTypes').pipe(map(data => {
                return data;
            }), shareReplay(1));
        }
        return this.cachedCacheLicenseTypes$;
    }
}
