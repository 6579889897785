<sagra-modal [(showModal)]="showModal" [options]="modalOptions">
    <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
    <div class="sgf-modal-body">
        <div *ngIf="manualTask.manualTaskParameters?.fields">
            <div *ngFor="let field of manualTask.manualTaskParameters?.fields">
                <div [ngSwitch]="field.htmlTag">
                    <div class="tag" *ngSwitchCase="'A'">
                        <a href="{{field.content}}" class="sgf-link-primary" target="_blank">{{field.friendlyName}}</a>
                    </div>
                    <div class="tag" *ngSwitchCase="'Insert'">
                        <div class="label">{{field.friendlyName}} <span style="color: red" *ngIf="field.required">*</span></div>
                        <sagra-input [(model)]="field.content" [options]="inputOptions" (onBlur)="setInputToTransferParameters($event, field)" (onKeyupEnter)="setInputToTransferParameters($event, field)"></sagra-input>
                    </div>
                    <div class="tag" *ngSwitchCase="'Regions'">
                        <div class="label">{{field.friendlyName}} <span style="color: red" *ngIf="field.required">*</span></div>
                        <sagra-combobox-multiselect (selectedValues)="setInputToTransferParameters($event, field)" [options]="regionOptions" [list]="regionComboboxList" [multiselect]="false"></sagra-combobox-multiselect>
                    </div>
                    <div class="tag" *ngSwitchDefault>
                        <div class="label">{{field.friendlyName}}</div>
                        <span class="text">{{field.content}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</sagra-modal>
