<div class="header-top">
    <div>
        <div class="header-layout-app-logo" (click)="navigateHome()">
            <img class="app-img" src="/assets/images/ic_nawik.png" />
            <div class="app-name">{{appName}}</div>
        </div>
    </div>
    <div class="header-layout-tabs">
        <div class="header-layout-tab" *ngFor="let tab of tabs" [ngClass]="{'selected': activeTab==tab.id}" (click)="updateTab(tab.id)">{{tab.name}}</div>
    </div>
    <div class="header-notification-icon sgf-link-pumpkin" *ngIf="showNotificationBell" (click)="onNotificationClick()">
        <span class="icon icon-notifications_active"></span>
        <span class="text">{{L('Notifications')}}</span>
    </div>
    <sagra-profile-dropdown [model]="userProfile" *ngIf="userProfile"></sagra-profile-dropdown>
</div>
