import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth-config';
import { DatabaseListComponent } from './components/database-list/database-list.component';
import { DatabaseDetailsComponent } from './components/database-details/database-details.component';
import { FunctionalitiesComponent } from './components/functionalities/functionalities.component';
import { OperationsHistoryComponent } from './components/operations-history/operations-history.component';
import { CacheManagingComponent } from './components/cache-managing/cache-managing.component';
import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { JobViewComponent } from './components/monitoring/jobView/jobView.component';
import { CheckCacheGuard } from './guards/checkCache.guard';

const routes: Routes = [
    { path: '', component: DatabaseListComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'dbinfo', component: DatabaseDetailsComponent, canActivate: [MsalGuard] },
    { path: 'functions', component: FunctionalitiesComponent, canActivate: [MsalGuard] },
    { path: 'history', component: OperationsHistoryComponent, canActivate: [MsalGuard] },
    { path: 'cache', component: CacheManagingComponent, canActivate: [MsalGuard, CheckCacheGuard] },
    { path: 'monitoring', component: MonitoringComponent, canActivate: [MsalGuard] },
    { path: 'jobView', component: JobViewComponent, canActivate: [MsalGuard] }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        MsalModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
    bootstrap: [MsalRedirectComponent],

    exports: [
        RouterModule,
        MsalModule
    ]
})
export class AppRoutingModule { }

