import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { SagraCheckboxComponentCilckableArea, SagraCheckboxComponentLabelPosition, SagraCheckboxComponentOptions } from "@sagra/sagra-checkbox";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { SagraPlaceholderOptionsInterface } from "@sagra/sagra-placeholder";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Subject, Subscription, zip } from "rxjs";
import { ShowPanel } from "../../animations/animations";
import { BIQPackage } from "../../models/biq/biqPackage.model";
import { CacheDetails } from "../../models/biq/cacheDetails.model";
import { CacheInfo } from "../../models/biq/cacheInfo.model";
import { CacheLicenseType } from "../../models/biq/cacheLicenseType.model";
import { CacheVersion } from "../../models/biq/cacheVersion.model";
import { FilterGroup } from "../../models/filters/filterGroup.model";
import { FilterItem } from "../../models/filters/filterItem.model";
import { SelectedFilter } from "../../models/filters/selectedFilter";
import { BIQService } from "../../services/biqService/biq.service";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { Sorting } from "../../utils/sorting";

@Component({
    selector: 'cache-managing',
    templateUrl: './cache-managing.component.html',
    styleUrls: ['./cache-managing.component.scss'],
    animations: [ShowPanel(),
    trigger('blinkList', [
        state('show', style({ opacity: '1' })),
        state('hide', style({ opacity: '0.3' })),
        transition('hide => show', [animate('0.25s ease-in-out')])
    ]),
    ]
})

export class CacheManagingComponent extends SagraTranslatable implements OnInit {
    private subscriptions = new Subscription();

    public selectedFilters: Array<SelectedFilter>;
    public filters: Array<FilterGroup>;

    public searchText: string;
    public sortingReverse: boolean;
    public sortingColumn: string = 'sortStatus';

    public showLoader: boolean = false;
    public showDetailsLoader: boolean = false;
    public listItems: Array<CacheInfo>;
    public filteredListItems: Array<CacheInfo>;
    public blinkList: boolean; // używana do odpalenia animacji mrygania listy
    public showCheckColumn: boolean;
    public allItemsSelected: boolean;
    public firstDownloadingData: boolean = true; // pierwsze pobranie listy, aby nie pokazywał się placeholder
    public detailsPanelVisible: boolean = false;
    public selectedItems: Array<SelectedItem> = [];
    public selectedCacheDetails: CacheDetails;
    public cacheVersions: Array<CacheVersion>;
    public packages: Array<BIQPackage>;
    public allowDeleteSelectedCache: boolean;
    public cacheLicenseTypes: Array<CacheLicenseType>;

    private selectVersionSubject: Subject<any> = new Subject<any>();

    public checkboxListOptions: SagraCheckboxComponentOptions;
    public placeholderOptions: SagraPlaceholderOptionsInterface;
    public placeholderDetailsListOptions: SagraPlaceholderOptionsInterface;
    public sagraLoaderOptions: SagraLoaderOptionsInterface;

    constructor(public translationService: SagraTranslationService,
        public messageService: MessageBusService,
        public biqService: BIQService) {

        super(translationService);

        this.checkboxListOptions = {
            labelPosition: SagraCheckboxComponentLabelPosition.right,
            clickable: SagraCheckboxComponentCilckableArea.checkboxOnly
        }

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }

        this.subscriptions.add(this.selectVersionSubject.asObservable().subscribe({
            next: data => {
                alert(data);
            }
        }));

        this.prepareFilters();
    }

    ngOnInit() {
        this.getData();
    }

    onFilterChanged(event) {
        this.selectedFilters = event;

        this.applyFilters();
    }

    onSearchTextChanged(event) {
        this.searchText = event;
        this.applyFilters();
    }

    onActionButtonClicked(event) {
        this.messageService.showDialogSelectCacheVersion({ show: true, cacheVersions: this.cacheVersions, cancelHandler: null, acceptHandler: this.selectVersionSubject });
    }

    onEndBlinkAnimation() {
        this.blinkList = false;
    }

    onItemClick(item: CacheInfo) {
        if (this.showCheckColumn) return;

        if (this.selectedCacheDetails != null && this.selectedCacheDetails.dbName == item.dbName) {
            this.closePanel();
        }
        else {
            this.selectedCacheDetails = {
                dbName: item.dbName,
                portalName: item.clientName
            };
            this.getCacheDetails(item.dbName);
            this.openPanel();
        }
    }

    onClosePanelClick(event) {
        this.closePanel();
    }

    onCachePerformActionChange(event) {
        this.showDetailsLoader = event;
        if (!event && this.selectedCacheDetails != null) {
            this.getCacheDetails(this.selectedCacheDetails.dbName);
        }
    }

    getData() {
        this.showLoader = true;
        const tasks = zip(this.biqService.getCachesList(), this.biqService.getCacheVersions(), this.biqService.getPackagesList(), this.biqService.getCacheLicenseTypes());
        this.subscriptions.add(tasks.subscribe({
            next: data => {
                this.listItems = data[0];
                this.cacheVersions = data[1];
                this.packages = data[2];
                this.cacheLicenseTypes = data[3];

                this.applyFilters();
                this.showLoader = false;
            },
            error: error => {
                this.showLoader = false;
            }
        }));
    }

    applyFilters() {
        this.blinkList = true;
        let tmpListItems: Array<CacheInfo> = this.listItems;

        if (this.listItems != null) {
            if (this.searchText) {
                tmpListItems = tmpListItems.filter(item => {
                    return item.clientName.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
                });
            }

            if (this.selectedFilters != null) {
                tmpListItems = tmpListItems.filter(item => {
                    let addItem: boolean = true;
                    for (let group of this.selectedFilters) {
                        let add = false;
                        for (let filter of group.items) {
                            if (group.idGroup == 'status') {
                                if (filter.id == item.status.toString()) {
                                    add = true;
                                }
                            }
                            else if (group.idGroup == 'version') {
                                if (filter.id == 'update') {
                                    if (item.inProgressInfo != null) {
                                        add = true;
                                    }
                                }
                                else {
                                    for (let version of item.versions) {
                                        if (filter.id == (item.inProgressInfo == null && version.cacheSeverVersionId)) {
                                            add = true;
                                        }
                                    }
                                }
                            }
                        }
                        addItem = addItem && add;
                    }
                    return addItem;
                });
            }
        }
        this.filteredListItems = tmpListItems;

        this.sortColumn(this.sortingColumn, false);
    }

    prepareFilters() {
        this.filters = [];
        //let fGroup = new FilterGroup('status', this.L('Order_status'));
        //fGroup.items.push(new FilterItem('0', this.L('Draft')));
        //fGroup.items.push(new FilterItem('3', this.L('Error')));
        //fGroup.items.push(new FilterItem('1', this.L('In_progress')));
        //fGroup.items.push(new FilterItem('2', this.L('Active')));
        //this.filters.push(fGroup);
        let fGroup = new FilterGroup('version', this.L('VM_version'));
        fGroup.items.push(new FilterItem('1', this.L('v18')));
        fGroup.items.push(new FilterItem('2', this.L('v22')));
        fGroup.items.push(new FilterItem('update', this.L('Update')));
        this.filters.push(fGroup);
    }

    sortColumn(column: string, changeSort: boolean = true) {
        this.blinkList = true;
        if (changeSort) {
            if (column != this.sortingColumn) {
                this.sortingColumn = column;
                this.sortingReverse = false;
            }
            else {
                this.sortingReverse = !this.sortingReverse;
            }
        }
        let secondColumn = null;
        if (this.sortingColumn != 'name') {
            secondColumn = 'name';
        }
        let sortColumnType = 0;
        if (this.sortingColumn == 'sortStatus') {
            sortColumnType = 1;
        }

        Sorting.sortByTwoProperties(this.filteredListItems, this.sortingColumn, secondColumn, !this.sortingReverse, sortColumnType);
        //potrzebne dla virtual scrolla
        this.filteredListItems = [...this.filteredListItems];
    }

    selectItem(state: boolean, item: CacheInfo) {

        this.setItemSelection(item, state, true);

        this.checkAllItemsSelected();

    //    this.closePanel();
    }

    setItemSelection(item: CacheInfo, state: boolean, selection: boolean) {
        const selArr = this.selectedItems.filter((elem) => { return item.dbName == elem.dbName; });
        if (selArr.length > 0) {
            const selItem = selArr[0];
            for (let i = 0; i < this.selectedItems.length; i++) {
                if (this.selectedItems[i].dbName == selArr[0].dbName) {
                    this.selectedItems.splice(i, 1);
                    break;
                }
            }
        }
        else {
            const newItem: SelectedItem = {
                dbName: item.dbName
            }
            this.selectedItems.push(newItem);
        }

        if (this.selectedItems.length > 0) {
            this.showCheckColumn = true;
        }
        else {
            this.showCheckColumn = false;
        }
    }

    selectAllItems(event) {
        this.allItemsSelected = event;

        if (this.allItemsSelected) {
            let itemsToSelect = this.filteredListItems.filter(item => item.status == 2 || item.status == 13);

            for (let item of itemsToSelect) {
                const selArr = this.selectedItems.filter((elem) => { return item.dbName == elem.dbName; });
                if (selArr.length == 0) {
                    const newItem: SelectedItem = { dbName: item.dbName };
                    this.selectedItems.push(newItem);
                }
            }
        }
        else {
            this.selectedItems = [];
            this.showCheckColumn = false;
        }
    }

    checkAllItemsSelected() {
        let itemsToSelect = this.filteredListItems.filter(item => item.status == 2);
        this.allItemsSelected = itemsToSelect.length == this.selectedItems.length;
    }

    getCacheDetails(dbName: string) {
        this.showDetailsLoader = true;
        this.subscriptions.add(this.biqService.getCacheDetails(dbName).subscribe({
            next: data => {
                this.selectedCacheDetails.cacheDetailsItems = data;
                let forDeleteCount = 0;
                for (let details of this.selectedCacheDetails.cacheDetailsItems) {
                    if (details.inProgressInfo == null) {
                        forDeleteCount++;
                    }
                }
                this.allowDeleteSelectedCache = forDeleteCount > 1;
                this.showDetailsLoader = false;
            },
            error: error => {
                this.showDetailsLoader = false;
            }
        }));
    }

    isItemSelected(item: CacheInfo) {
        const selArr = this.selectedItems.filter(elem => { return item.dbName == elem.dbName});
        if (selArr.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    openPanel() {
        this.detailsPanelVisible = true;
    }

    closePanel() {
        this.selectedCacheDetails = null;
        this.detailsPanelVisible = false;
    }

    statusTextForPills(item: CacheInfo) {
        switch (item.status) {
            case 1:
                if (item.inProgressInfo != null) {
                    return this.L('Updating') + ' (' + (item.inProgressInfo.taskCompleted + 1) + ' ' + this.L('of') + ' ' + item.inProgressInfo.tasksCount + ')'
                }
                return this.L('Upadating');
            case 2:
                return this.L('Active');
        }
        return '';
    }

    statusClass(item) {
        switch (item.status) {
            case 1:
                return 'in-progress';
            case 2:
                return 'mint';
        }
        return '';
    }
}

interface SelectedItem {
    dbName: string;

}
