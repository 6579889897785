<sagra-modal [showModal]="show" [options]="options" [title]="options.title">
    <div class="sgf-modal-body">
        <ng-scrollbar class="sagra-scrollbar">
            <div class="dlg-content">
                <div class="dlga-list">
                    <div class="dlga-list-row" *ngFor="let item of agreements" [sagraTooltip]="tooltipOptions" [sagraTooltipModel]="item.tooltipModel">
                        <div class="dlga-list-name-col">
                            <sagra-checkbox [state]="isItemSelected(item)" [disabled]="item.status==1" [checkboxsize]="checkboxSize" [labelPosition]="checkboxLabelPosition" (update)="onItemCheck($event, item)"></sagra-checkbox>
                            <span [ngClass]="{'disabled': item.status==1}" (click)="onItemCheck($event, item)">{{item.name}}</span>
                        </div>
                        <div class="dlga-list-links-col">
                            <div class="dlga-list-links-col-text" *ngIf="item.status==2" (click)="onLinkClick(item)">{{item.link}}</div>
                            <div class="element-inprogress-status" *ngIf="item.status==1">
                                <div class="loader"><sagra-loader [options]="sagraLoaderOptions"></sagra-loader></div>
                                <div class="text">{{L('DURINGSHARING')}}</div>
                            </div>
                            <div class="element-error-status" *ngIf="item.status==3">{{L('ERROR')}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-scrollbar>
    </div>
    <div class="dlg-footer">
        <button class="sgf-btn-secondary dlg-btn-copy" [ngClass]="{'disabled': noLinksToCopy}" (click)="onCopyLinksClick()"><span class="icon icon-content_paste"></span><span>{{L('Copy_links_clipboard')}}</span></button>
        <button class="sgf-btn-primary dlg-btn-publish" (click)="onPublishClick()" [disabled]="agreementsToPublish == null || agreementsToPublish.length == 0">{{L('Publish')}}</button>
        <button class="sgf-btn-secondary dlg-btn-cancel" (click)="onCancelClick()">{{L('Cancel')}}</button>
    </div>
    <sagra-loader *ngIf="showLoader"></sagra-loader>
</sagra-modal>
