import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SagraModalOptionsInterface } from "@sagra/sagra-modal";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { DialogSelectCacheVersionOptionsInterface } from "../../logic/dialogSelectCacheVersionOptions.interface";
import { CacheVersion } from "../../models/biq/cacheVersion.model";
import { MessageBusService } from "../../services/messageBusService/messageBusService";

@Component({
    selector: 'dialog-select-cache-version',
    templateUrl: './dialog-select-cache-version.component.html',
    styleUrls: ['./dialog-select-cache-version.component.scss']
})

export class DialogSelectCacheVersionComponent extends SagraTranslatable implements OnChanges {
    @Input() dialogData: DialogSelectCacheVersionOptionsInterface;

    public showModal: boolean = true;
    public versions: Array<CacheVersion>;
    public selectedVersion: CacheVersion;

    public dialogOptions: SagraModalOptionsInterface;

    constructor(public translationService: SagraTranslationService,
        private messageService: MessageBusService) {
        super(translationService);

        this.dialogOptions = {
            zIndex: 2700,
            minWidth: '450px',
            maxWidth: '450px',
            minHeight: '250px',
            maxHeight: '450px',
            ariaLabel: ''
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.dialogData) {
            this.versions = this.dialogData.cacheVersions;
        }
    }

    public onBtnCloseClick() {
        this.closeDialog();
    }

    public onAcceptClick() {
        if (this.dialogData.acceptHandler) {
            this.dialogData.acceptHandler.next(this.selectedVersion);
        }
        this.closeDialog();
    }

    public onItemClick(version: CacheVersion) {
        this.selectedVersion = version;
    }

    private closeDialog() {
        this.showModal = false;
        setTimeout(() => {
            this.messageService.showDialogSelectCacheVersion({ show: false});
        }, 200);
    }

}
