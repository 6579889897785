import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { NavigationGroup, NavigationItemEnum } from "../../models/navigationItem.model";

@Component({
    selector: 'functionalities',
    templateUrl: './functionalities.component.html',
    styleUrls: ['./functionalities.component.scss']
})

export class FunctionalitiesComponent extends SagraTranslatable implements OnInit {
    @Input() tenant: string;

    public navigationItems: NavigationGroup[];
    public selectedItem: NavigationItemEnum = NavigationItemEnum.Unset;

    constructor(public translationService: SagraTranslationService,
        private router: Router
        ) {
        super(translationService);
    }

    ngOnInit() {
        this.router.routerState.root.queryParams.subscribe(params => {
            if (params.nav && (params.id != 'undefined' && params.id != "null")) {
                this.selectedItem = parseInt(params.nav);
            }
        })

        this.prepareNavigationItems();
    }

    navigationItemChanged(selectedItem: NavigationItemEnum) {
        this.router.navigate(['/functions'], { queryParams: { id: this.tenant, tab: 1, nav: selectedItem } });
        this.selectedItem = selectedItem;
    }

    prepareNavigationItems() {
        this.navigationItems = [
        {
            id: 1,
            name: this.L('Repositories'),
            items: [{
                id: NavigationItemEnum.ActivitiesOperations,
                name: this.L('Acts_Opers'),
                iconClass: "icon-activities-catalog",
                tooltip: { tooltipItems: [{ content: this.L('Acts_Opers') }] }
                //disabled: !this.permission.canRead
            }, {
                id: NavigationItemEnum.Processes,
                name: this.L('Processes'),
                iconClass: "icon-loop-alt3"
                //disabled: !this.permission.canRead
            }, {
                id: NavigationItemEnum.Sheets,
                name: this.L('Sheets'),
                iconClass: "icon-zestawienia"
            }, {
                id: NavigationItemEnum.Extracts,
                name: this.L('Extracts'),
                iconClass: "icon-table"
            }, {
                id: NavigationItemEnum.TWR,
                name: this.L('TWR'),
                iconClass: "icon-addin-TWR"
                //disabled: !this.permission.canRead
            }]
        },
        {
            id: 2,
            name: this.L('Configuration'),
            items: [{
                id: NavigationItemEnum.H5Tables,
                name: this.L('Remove_tables'),
                iconClass: "icon-trash-o",
                hidden: false
            }, {
                id: NavigationItemEnum.Applications,
                name: this.L('Applications'),
                iconClass: "icon-grid_view"
            },
            ]
        }];
    }
}
