import { Component, OnInit, Input, OnDestroy, SimpleChanges, SimpleChange, OnChanges } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { RegisterService } from "../../services/registerService/register.service";
import { RegisterData } from "../../models/register/registerData.model";
import { SagraCheckboxComponentCilckableArea, SagraCheckboxComponentLabelPosition } from "@sagra/sagra-checkbox";
import { RegisterActivityType } from "../../models/register/registerActivityType.model";
import { RegisterAssignRequest } from "../../models/register/registerAssignRequest.model";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { Subscription } from "rxjs";
import { SelectedItem, SelectedSubItem } from "../../models/selectedItem.model";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";

@Component({
    selector: "register-list",
    templateUrl: "./register-list.component.html",
    styleUrls: ["./register-list.component.scss"],
    animations: [trigger('hidegroup', [
        state('open', style({})),
        state('closed', style({height: '0px'})),
        transition('open => closed', [animate('0.2s ease-in-out')]),
        transition('closed => open', [animate('0.2s ease-in-out')])
    ])]
})

export class RegisterListComponent extends SagraTranslatable implements OnInit, OnDestroy, OnChanges {
    @Input() tenant: string;

    private subscriptions = new Subscription();
    public searchText: string;
    public registers: RegisterData[];
    public headerTitle: string = this.L('Register');
    public headerSubTitle: string = this.L('TurnedOn');
    public actionButtonText: string = this.L('Save');
    public actionButtonVisible: boolean;
    public actionButtonDisabled: boolean;
    public showLoader: boolean;
    public checkboxLabelPosition: SagraCheckboxComponentLabelPosition = SagraCheckboxComponentLabelPosition.right;
    public checkboxClickable: SagraCheckboxComponentCilckableArea = SagraCheckboxComponentCilckableArea.checkboxOnly;
    public sagraLoaderOptions: SagraLoaderOptionsInterface;

    public selectedItems: Array<SelectedItem> = [];


    constructor(public translationService: SagraTranslationService,
        public registerService: RegisterService,
        public messageBusService: MessageBusService) {
        super(translationService);

        this.actionButtonVisible = true;
    }

    ngOnInit(): void {
        this.showLoader = true;
        this.messageBusService.getRefreshRegisterList().subscribe((next) => {
            if (next.tenant == this.tenant.toLowerCase()) {
                //console.log("SignalR: call RefreshRegisterList next: " + JSON.stringify(next));
                this.getRegisterList();
            }
        });

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }

        this.getRegisterList();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        let currentItem: SimpleChange = changes.tenant;
        if (currentItem.currentValue) {
            this.tenant = changes.tenant.currentValue;
            if (currentItem.previousValue) {
                this.getRegisterList();
            }
        }
    }

    onSearchTextChanged(event) {
      this.searchText = event;
    }

    onActionButtonClicked($event) {
        let registerToSend: RegisterAssignRequest[] = [];

        for (let register of this.selectedItems) {
            let registerAssign = new RegisterAssignRequest();
            registerAssign.idRegister = register.id;
            registerAssign.activityTypes = [];

            for (let actType of register.items) {
                if (actType.selected != actType.originalSelected) {
                    registerAssign.activityTypes.push({ idActivityType: actType.id, isActive: actType.selected });
                }
            }
            // jeśli nowo zaznaczona ewidencja lub zmiana stanu na typie czynnosci
            if (register.selected || registerAssign.activityTypes.length > 0) {
                registerToSend.push(registerAssign);
            }
        }

        if (registerToSend.length > 0) {
            this.showLoader = true;
            this.subscriptions.add(this.registerService.saveRegisters(this.tenant, registerToSend).subscribe(data => {
                this.selectedItems = [];
                this.getRegisterList();
            }));
        }
    }

    onRegisterSelected($event: boolean, register: RegisterData) {
        for (let item of this.registers) {
            if (item.id == register.id) {
                item.selected = $event;
                item.collapsed = !item.selected;
                break;
            }
        }

        this.setItemSelection(register, null, $event);
    }

    onActivitySelected($event: boolean, register: RegisterData, activityType: RegisterActivityType) {
        for (let item of this.registers) {
            if (item.id == activityType.idRegister) {
                for (let actType of item.activityTypes) {
                    if (actType.activityType == activityType.activityType) {
                        actType.selected = $event;
                        break;
                    }
                }
                break;
            }
        }

        this.setItemSelection(register, activityType, $event);
    }

    setItemSelection(register: RegisterData, activityType: RegisterActivityType, state: boolean) {

        var idRegister = register.id;

        var selArr = this.selectedItems.filter((elem) => { return elem.id == idRegister; });
        if (selArr.length > 0) {
            var item = selArr[0];
            if (activityType != null) {
                var subItems = item.items.filter(elem => { return elem.id == activityType.activityType; });
                if (subItems.length > 0) {
                    subItems[0].selected = state;
                    for (var i = 0; i < item.items.length; i++) {
                        if (item.items[i].id == subItems[0].id) {
                            if (item.items[i].originalSelected == subItems[0].selected) {
                                item.items.splice(i, 1);
                            }
                            break;
                        }
                    }
                }
                else {
                    var newSubItem = new SelectedSubItem();
                    newSubItem.originalSelected = activityType.active;
                    newSubItem.id = activityType.activityType;
                    newSubItem.selected = state;
                    item.items.push(newSubItem);
                }
            }
            else {
                newItem.selected = state;
            }
        }
        else {
            if (activityType != null) {
                var newItem = new SelectedItem(register.selected, false);
                newItem.id = register.id;
                newItem.status = register.status;
                newItem.selected = state;
                newItem.items = [];
                var newSubItem = new SelectedSubItem();
                newSubItem.originalSelected = activityType.active;
                newSubItem.id = activityType.activityType;
                newSubItem.selected = state;
                newItem.items.push(newSubItem);
                this.selectedItems.push(newItem);
            }
            else {
                var newItem = new SelectedItem(register.selected, false);
                newItem.id = register.id;
                newItem.status = register.status;
                newItem.selected = state;
                this.selectedItems.push(newItem);
            }
        }
    }

    isRegisterSelected(register: RegisterData) {
        var selArr = this.selectedItems.filter(elem => { return elem.id == register.id && elem.selected; });
        if (selArr.length > 0 || register.status == 2) {
            return true;
        }
        return false;
    }

    isActivityTypeSelected(activityType: RegisterActivityType) {
        var result = false;
        var selRegisterArr = this.selectedItems.filter(elem => { return elem.id == activityType.idRegister; });
        if (selRegisterArr.length > 0) {
            var selRegister = selRegisterArr[0];
            var selActivitiesArr = selRegister.items.filter(elem => { return elem.id == activityType.activityType; });
            if (selActivitiesArr.length > 0) {
                return selActivitiesArr[0].selected;
            }
            else {
                return activityType.active;
            }
        }
        else {
            return activityType.active;
        }
        //return false;
    }

    onRegisterCollapse($event, register) {
        register.collapsed = !register.collapsed;
    }

    getRegisterList() {
        this.subscriptions.add(this.registerService.getRegisterList(this.tenant).subscribe(items => {
            this.registers = items;

            let itemsSelected = items.filter(item => { return item.status == 2; }).length;

            this.headerSubTitle = this.L('TurnedOn') + "  " + itemsSelected + " / " + this.registers.length;
            this.checkSelectedItems();
            this.showLoader = false;
        },
            (error) => {
                this.showLoader = false;
            }));
    }

    /**
     * Sprawdza poprawość listy zaznaczonych elementów. Czy jeszcze istnieją i czy nie należy ich usunąć.
     */
    checkSelectedItems() {
        let tmpSelectedItems: SelectedItem[] = [];

        for (let item of this.selectedItems) {
            let registerArr = this.registers.filter(elem => { return elem.id == item.id; });
            if (registerArr.length > 0) {
                var register = registerArr[0];
                item.originalSelected = register.status == 2;
                if (register.activityTypes != null && register.activityTypes.length > 0) {
                    var tmpItems = [];
                    for (let selActivity of item.items) {
                        let activityArr = register.activityTypes.filter(elem => { return elem.activityType == selActivity.id });
                        if (activityArr.length > 0) {
                            if (activityArr[0].active != selActivity.selected) {
                                tmpItems.push(selActivity);
                            }
                        }
                    }
                    item.items = tmpItems;
                }
                else {
                    item.items = [];
                }
                if (item.selected != (register.status == 2) || (item.items != null && item.items.length > 0) ) {
                    tmpSelectedItems.push(item);
                }
            }

        }

        this.selectedItems = tmpSelectedItems;
    }

    statusClass(item) {
        let classes = [];
        if (item.status <= 0)
            classes.push('hidden');
        else {
            classes.push('register-item-label');
            if (item.status == 2) {
                classes.push('green');
            }
            else if (item.status == 1) {
                classes.push('empty');
            }
            else if (item.status >= 3) {
                classes.push('red');
            }
        }
        return classes;
    }

    statusText(item) {
        switch (item.status) {
            case 0:
                return "";
            case 1:
                return this.L("DURINGSHARING");
            case 2:
                return this.L("SHARED");
            default:
                return this.L("ERRORSHARING");
        }
    }
}
