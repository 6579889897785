import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Component, Input, OnInit, OnChanges, SimpleChanges, SimpleChange, ViewChild, ElementRef } from "@angular/core";
import { SagraSwitchComponentModelInterface, SagraSwitchComponentLabelPosition } from "@sagra/sagra-switch";
import { WinPointConfiguration } from "../../models/tenant/winPointConfiguration.model";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { TenantService } from "../../services/tenantService/tenant.service";
import { SagraLoaderType, SagraLoaderOptionsInterface } from "@sagra/sagra-loader";
import { MessageBusService } from "../../services/messageBusService/messageBusService";

@Component({
    selector: 'winpoint-config',
    templateUrl: './winpoint.component.html',
    styleUrls: ['./winpoint.component.scss'],
    animations: [trigger('hiddenParamsExpanded', [
        state('open', style({ })),
        state('close', style({ height: '0px' })),
        transition('open <=> close', [animate('0.2s ease-in-out')])
    ]),
    ]
})

export class WinPointComponent extends SagraTranslatable implements OnInit, OnChanges {
    @Input() tenant: string;
    @Input() dbAddress: string;
    @Input('winPointConfiguration') winPointData: WinPointConfiguration;
    @ViewChild('hiddenParams') hiddenParamsContainer: ElementRef;


    public headerTitle = this.L('Winpoint_configuration_2');
    public actionButtonTitle = this.L('Save');
    public actionButtonEnabled = false;
    public hiddenParamsExpanded = false;

    public medicalStaffSwitchOptions: SagraSwitchComponentModelInterface;
    public localizationsSwitchOptions: SagraSwitchComponentModelInterface;
    public controlSwitchOptions: SagraSwitchComponentModelInterface;
    public sagraLoaderTextOptions: SagraLoaderOptionsInterface;
    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    public readOnly: boolean;
    public showLoader: boolean;

    constructor(public translationService: SagraTranslationService,
        public tenantService: TenantService,
        public messageBusService: MessageBusService) {
        super(translationService);
    }

    ngOnInit() {
        this.prepareControlsOptions();

        this.messageBusService.getRefreshWinpoint().subscribe((next) => {
            if (next.tenant == this.tenant.toLowerCase()) {
                //console.log("SignalR: call RefreshWinpointData next: " + JSON.stringify(next));
                this.refreshWinpointData();
            }
        });

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 40,
                borderWidth: 5
            }
        };
        this.sagraLoaderTextOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 40,
                borderWidth: 5
            },
            text: this.L('Updating_configuration'),
            textSetings: {
                color: 'black',
                size: 20
            }
        };

        this.readOnly = this.winPointData.status === 1;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const currentItem: SimpleChange = changes.winPointData;
        if (currentItem) {
            if (!currentItem.currentValue) {
                this.winPointData = new WinPointConfiguration();
                this.readOnly = this.winPointData.status === 1;
            }
        }
    }

    onActionButtonClicked(event) {
        this.showLoader = true;
        this.tenantService.saveWinpointConfiguration(this.tenant, this.dbAddress, this.winPointData).subscribe((data) => {
            this.actionButtonEnabled = false;
            this.refreshWinpointData();
        },
            (error) => {
                this.showLoader = false;
            });
    }

    onHiddenParamsClicked(event) {
        this.hiddenParamsExpanded = !this.hiddenParamsExpanded;

        //this.hiddenParamsContainer.
    }

    refreshWinpointData() {
        this.tenantService.getWinpointConfiguration(this.tenant).subscribe((data) => {
            this.winPointData = data;
            this.readOnly = this.winPointData.status === 1;
            this.showLoader = false;
        },
            (error) => {
                this.showLoader = false;
            });
    }

    demoSwitchChange(event) {
        this.winPointData.demo = event;
        this.actionButtonEnabled = true;
    }

    getProposalsSwitchChange(event) {
        this.winPointData.getProposals = event;
        this.actionButtonEnabled = true;
    }

    sendLocalizationsSwitchChange(event) {
        this.winPointData.sendLocalizations = event;
        this.actionButtonEnabled = true;
    }

    sendCategoriesSwitchChange(event) {
        this.winPointData.sendCategories = event;
        this.actionButtonEnabled = true;
    }

    sendProposalsSwitchChange(event) {
        this.winPointData.sendProposals = event;
        this.actionButtonEnabled = true;
    }

    getClientBBSwitchChange(event) {
        this.winPointData.getClientBB = event;
        this.actionButtonEnabled = true;
    }

    sendPayersSwitchChange(event) {
        this.winPointData.sendPayers = event;
        this.actionButtonEnabled = true;
    }

    sendHistCecha1SwitchChange(event) {
        this.winPointData.sendHistCecha1 = event;
        this.actionButtonEnabled = true;
    }

    sendHistCecha2SwitchChange(event) {
        this.winPointData.sendHistCecha2 = event;
        this.actionButtonEnabled = true;
    }

    /**
     * Przygotowanie obiektów do konfiguracji kontrolek
     * */
    private prepareControlsOptions() {

        this.localizationsSwitchOptions = {
            labelPosition: SagraSwitchComponentLabelPosition.right,
            labelText: this.L('DB_localizations')
        };

        this.medicalStaffSwitchOptions = {
            labelPosition: SagraSwitchComponentLabelPosition.right,
            labelText: this.L('DB_medical_staff')
        }

        this.controlSwitchOptions = {
            labelPosition: SagraSwitchComponentLabelPosition.right
        }
    }
}
