<sagra-modal [showModal]="show" [options]="options" [title]="options.title">
    <div class="sgf-modal-body no-padding">
        <ng-scrollbar class="sagra-scrollbar">
            <div class="dlg-content" *ngIf="showContent">
                <ng-container *ngIf="!readOnly && errorsDialog != null && errorsDialog.length > 0">
                    <div class="dlg-errors">
                        <div class="dlg-error" *ngFor="let error of errorsDialog">{{error}}</div>
                    </div>
                </ng-container>
                <div class="dlg-section">
                    <div class="dlg-margin-bottom">
                        <div class="dlg-field-caption">{{L('Client_name')}}</div>
                        <sagra-input #clientNameInput
                                     [(model)]="clientNameModel"
                                     [type]="clientNameInputOptions.type"
                                     [isRequired]="clientNameInputOptions.isRequired"
                                     [characterLimit]="clientNameInputOptions.characterLimit"
                                     [isDisabled]="clientNameInputOptions.isDisabled"
                                     [errorType]="clientNameInputOptions.errorType"
                                     [returnInvalidValue]="clientNameInputOptions.returnInvalidValue"
                                     (update)="onClientModelChange($event)"></sagra-input>
                    </div>
                    <div class="dlg-field-caption">{{L('Portal_address')}}</div>
                    <div class="dlg-margin-bottom">
                        <sagra-input #validateInput class="dlg-portal-address"
                                     [model]="portalAddressModel"
                                     [type]="portalAddressInputOptions.type"
                                     [isRequired]="portalAddressInputOptions.isRequired"
                                     [characterLimit]="portalAddressInputOptions.characterLimit"
                                     [isDisabled]="portalAddressInputOptions.isDisabled"
                                     [validationRegex]="portalAddressInputOptions.validationRegex"
                                     [invalidText]="portalAddressInputOptions.invalidText"
                                     [invalidTextCustom]="portalAddressInputOptions.invalidTextCustom"
                                     [errorType]="portalAddressInputOptions.errorType"
                                     [returnInvalidValue]="portalAddressInputOptions.returnInvalidValue"
                                     (update)="onPortalModelChange($event)"></sagra-input>
                        <div class="dlg-portal-domain dlg-model-value">{{portalDomain}}</div>
                    </div>
                    <div class="dlg-margin-bottom">
                        <div class="dlg-column">
                            <div class="dlg-field-caption">{{L('TME_Prefix')}}</div>
                            <sagra-input #validateInput class="dlg-short-field"
                                         [(model)]="tmePrefixModel"
                                         [type]="tmePrefixInputOptions.type"
                                         [isRequired]="tmePrefixInputOptions.isRequired"
                                         [characterLimit]="tmePrefixInputOptions.characterLimit"
                                         [isDisabled]="tmePrefixInputOptions.isDisabled"
                                         [validationRegex]="tmePrefixInputOptions.validationRegex"
                                         [invalidText]="tmePrefixInputOptions.invalidText"
                                         [invalidTextCustom]="tmePrefixInputOptions.invalidTextCustom"
                                         [errorType]="tmePrefixInputOptions.errorType"
                                         [returnInvalidValue]="tmePrefixInputOptions.returnInvalidValue"
                                         (update)="onPrefixModelChange($event)"></sagra-input>
                        </div>
                        <div class="dlg-column center">
                            <div class="dlg-field-caption">{{L('Database_name')}}</div>
                            <div class="dlg-model-value">{{databaseNameModel}}</div>
                        </div>
                    </div>
                    <div class="dlg-margin-bottom">
                        <div class="dlg-column">
                            <div class="dlg-field-caption">{{L('TME_licenses_number')}}</div>
                            <sagra-input class="dlg-short-field"
                                         [(model)]="licensesNumberModel"
                                         [type]="licensesNumberInputOptions.type"
                                         [isDisabled]="licensesNumberInputOptions.isDisabled"
                                         [invalidText]="licensesNumberInputOptions.invalidText"
                                         [invalidTextCustom]="licensesNumberInputOptions.invalidTextCustom"
                                         [minValue]="licensesNumberInputOptions.minValue"
                                         [maxValue]="licensesNumberInputOptions.maxValue"
                                         [characterLimit]="licensesNumberInputOptions.characterLimit"
                                         [errorType]="licensesNumberInputOptions.errorType"
                                         (update)="onLicensesNumberModelChange($event)"></sagra-input>
                        </div>
                        <div class="dlg-column">
                            <div class="dlg-field-caption">{{L('Portal_users_number')}}</div>
                            <sagra-input class="dlg-short-field"
                                         [(model)]="portalUsersNumberModel"
                                         [type]="portalUsersInputOptions.type"
                                         [isDisabled]="portalUsersInputOptions.isDisabled"
                                         [invalidText]="portalUsersInputOptions.invalidText"
                                         [invalidTextCustom]="portalUsersInputOptions.invalidTextCustom"
                                         [minValue]="portalUsersInputOptions.minValue"
                                         [maxValue]="portalUsersInputOptions.maxValue"
                                         [characterLimit]="portalUsersInputOptions.characterLimit"
                                         [errorType]="portalUsersInputOptions.errorType"
                                         (modelChange)="onPortalUsersNumberModelChange($event)"></sagra-input>
                        </div>
                    </div>
                    <div class="dlg-margin-bottom">
                        <div class="dlg-column">
                            <div class="dlg-field-caption">{{L('Language')}}</div>
                            <sagra-combobox-multiselect #validateCombo class="dlg-short-field" [selectedValue]="languageModel" [list]="languageItems" [options]="languageComboOptions" (selectedValueChange)="onLanguageModelChange($event)"></sagra-combobox-multiselect>
                        </div>
                        <div class="dlg-column">
                            <div class="dlg-field-caption">{{L('Currency')}}</div>
                            <sagra-combobox-multiselect #validateCombo class="dlg-short-field" [selectedValue]="currencyModel" [list]="currencyItems" [options]="currencyComboOptions" (selectedValueChange)="onCurrencyModelChange($event)"></sagra-combobox-multiselect>
                        </div>
                    </div>
                    <div>
                        <div class="dlg-field-caption">{{L('Server_name')}}</div>
                        <sagra-combobox-multiselect #validateCombo [selectedValue]="serverNameModel" [list]="serverItems" [options]="serversComboOptions" (selectedValueChange)="onServerModelChange($event)"></sagra-combobox-multiselect>
                    </div>
                </div>
                <div class="dlg-line-separator"></div>
                <div class="dlg-section">
                    <div class="dlg-section-header">
                        <div class="dlg-section-title">{{L('Domain')}}</div>
                        <div class="dlg-section-description">{{L('Domain_info')}}</div>
                    </div>
                    <div>
                        <sagra-switch class="dlg-switch" [state]="ownDomainModel" [options]="ownDomainSwitchOptions" [disabled]="readOnly" (stateChange)="ownDomainSwitchChange($event)"></sagra-switch>
                    </div>
                    <ng-container *ngIf="ownDomainModel">
                        <div *ngFor="let domain of domainItemsModel; let idx=index; trackBy:trackByIndex;">
                            <div class="dlg-medium-field dlg-field-domain">
                                <div class="dlg-field-caption" *ngIf="idx == 0">{{L('Client_domain')}}</div>
                                <div class="dlg-field-caption" *ngIf="idx > 0">{{L('Another_client_domain')}}</div>
                                <sagra-input [(model)]="domainItemsModel[idx]"
                                             [type]="domainInputOptions.type"
                                             [characterLimit]="domainInputOptions.characterLimit"
                                             [isRequired]="domainInputOptions.isRequired"
                                             [isDisabled]="domainInputOptions.isDisabled"
                                             [invalidText]="domainInputOptions.invalidText"
                                             [invalidTextCustom]="domainInputOptions.invalidTextCustom"
                                             [validationRegex]="domainInputOptions.validationRegex"
                                             [errorType]="domainInputOptions.errorType"
                                             [returnInvalidValue]="domainInputOptions.returnInvalidValue"
                                             (update)="onDomainInputModelChange($event)"></sagra-input>
                            </div>
                            <div class="dlg-domain-icon sgf-link-primary" *ngIf="idx == 0" (click)="addClientDomain()"><span class="icon icon-add"></span>{{L('add_another')}}</div>
                            <div class="dlg-domain-icon single sgf-link-danger" *ngIf="idx > 0" (click)="removeClientDomain(domain)"><span class="icon icon-minus-circle"></span></div>
                        </div>
                    </ng-container>
                </div>
                <!--<div class="dlg-line-separator"></div>
                <div class="dlg-section">
                    <div class="dlg-section-header">
                        <div class="dlg-section-title">{{L('Emigo_templates')}}</div>
                        <div class="dlg-section-description">{{L('Template_info')}}</div>
                    </div>
                    <div>
                        <div class="dlg-field-caption">{{L('Emigo_template')}}</div>
                        <sagra-combobox class="dlg-medium-field" [(selectedValue)]="emigoTemplateModel" [list]="emigoTemplateItems" [options]="emigoTemplateComboOptions" (selectedValueChange)="onTemplateModelChange($event)"></sagra-combobox>
                    </div>
                </div>-->
                <div class="dlg-line-separator" *ngIf="!oldWorldModel"></div>
                <div class="dlg-section" *ngIf="!oldWorldModel">
                    <div>
                        <div class="dlg-column with-margin-20">
                            <div class="dlg-field-caption">{{L('BIQ_package')}}</div>
                            <sagra-combobox-multiselect [selectedValue]="packageId" [list]="packageBIQItems" [options]="packageBIQComboOptions" (selectedValueChange)="onPackageBIQModelChange($event)"></sagra-combobox-multiselect>
                        </div>
                        <!--<div class="dlg-column with-margin-20">
                            <div class="dlg-field-caption">{{L('Cache_region')}}</div>
                            <sagra-combobox [(selectedValue)]="cacheRegionModel" [list]="cacheRegionItems" [options]="cacheRegionComboOptions" (selectedValueChange)="onCacheRegionModelChange($event)"></sagra-combobox>
                        </div>-->
                    </div>
                </div>
                <div class="dlg-line-separator"></div>
                <div class="dlg-section">
                    <div class="dlg-section-header">
                        <div class="dlg-section-title">{{L('Winpoint_configuration')}}</div>
                        <div class="dlg-section-description">{{L('Winpoint_conf_info')}}</div>
                    </div>
                    <div>
                        <sagra-switch class="dlg-switch" [state]="winpointConfModel" [options]="winpointConfSwitchOptions" [disabled]="readOnly" (stateChange)="winpointConfSwitchChange($event)"></sagra-switch>
                    </div>
                    <div #winpointLink class="dlg-winpoint-link" *ngIf="winpointConfModel">
                        <span class="sgf-link-primary" (click)="openWinpointdialog($event)">{{L('Open_configurator')}}</span>
                    </div>
                </div>
            </div>
        </ng-scrollbar>
    </div>
    <div class="dlg-footer">
        <button class="sgf-btn-cherry dlg-btn-delete" *ngIf="draftId!=0 && !readOnly" (click)="onDeleteClicked($event)">{{L('Delete')}}</button>
        <button class="sgf-btn-mint dlg-btn-activate" *ngIf="!readOnly" [ngClass]="{'disabled': !canActivate}" (click)="onActivateClicked($event)">{{L('Activate')}}</button>
        <button class="sgf-btn-primary dlg-btn-save" *ngIf="!readOnly" (click)="onSaveClicked($event)">{{L('Save')}}</button>
        <button class="sgf-btn-light dlg-btn-cancel" *ngIf="!readOnly" (click)="onCancelClicked($event)">{{L('Cancel')}}</button>
        <button class="sgf-btn-primary dlg-btn-close" *ngIf="readOnly" (click)="onCancelClicked($event)">{{L('Close')}}</button>
    </div>
    <dialog-new-database-winpoint *ngIf="dialogWinpointVisible" [showDialog]="dialogWinpointVisible" [readOnly]="readOnly" [winpointData]="winpointConfiguration" (onCloseDialog)="onDialogWinpointClose($event)"></dialog-new-database-winpoint>
    <sagra-loader [options]="loaderOptions" *ngIf="showLoader"></sagra-loader>
</sagra-modal>
