import { Moment } from "moment";

export class Utils {
    public static isSolutions(tenant: string) {
        return tenant.toLowerCase() == "emigosolutions";
    }

    public static formatDate(date: Moment, format: string = "DD.MM.YYYY"): string {
        return date.format(format);
    }

    public static getAADLink(id: string): string {
        const resource = "https://graph.microsoft.com";
        const redirectUri = "https://portal.azure.com/";
        return "https://login.microsoftonline.com/common/oauth2/authorize?response_type=code&client_id=" + id + "&resource=" + resource + "&redirect_uri=" + redirectUri + "&response_mode=query&prompt=admin_consent";
    }

}
