import { SettingsParameter } from "./settingsParameter.model";
import { WinPointConfiguration } from "./winPointConfiguration.model";

export class TenantConfiguration {

    public dbAddress: string;
    public publicDBAddress: string;
    public winPointEnabled: SettingsParameter;
    public cacheLicense: SettingsParameter;
    public biqPackagePlannedEnd: SettingsParameter;
    public demoStart: SettingsParameter;
    public demoEnd: SettingsParameter;
    public oDataFeedRestrictions: SettingsParameter;
    public biqInProgress: SettingsParameter;
    public newWorld: SettingsParameter;
    public newWorldInProgress: SettingsParameter;

    public winPointConfiguration: WinPointConfiguration;
}

