<div>
    <div class="dlg-backdrop"></div>
    <div #dialogContent class="dlg-content" [@animationOption2] *ngIf="showDialog">
        <div class="dlg-title">
            <div class="dlg-title-text">{{L('WINPOINT_CONFIGURATION')}}</div>
        </div>
        <div class="dlg-content-scroll">
            <ng-scrollbar class="sagra-scrollbar">
                <div class="dlg-section">
                    <div class="dlg-section-header">
                        <sagra-switch class="dlg-switch" [state]="winpointData.medicalStaffDB" [options]="medicalStaffSwitchOptions" [disabled]="readOnly" (stateChange)="medicalStaffSwitchChange($event)"></sagra-switch>
                    </div>
                </div>
                <div class="dlg-line-separator"></div>
                <div class="dlg-section">
                    <div class="dlg-section-header">
                        <div class="dlg-section-title">{{L('Settings_config_params')}}</div>
                        <!--<div class="dlg-section-description">{{L('Settings_config_params_info')}}</div>-->
                    </div>
                    <!--<div class="dlg-tenants-list">
                        <div class="dlg-field-caption">{{L('Load_settings')}}</div>
                        <sagra-combobox class="dlg-medium-field" [(selectedValue)]="templateTenantsModel" [list]="templateTenants" [options]="templateTenantsComboOptions" (selectedValueChange)="onTemplateModelChange($event)"></sagra-combobox>
                    </div>-->
                    <div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.demo" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="demoSwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('DB_test')}}</div>
                            <div class="dlg-switch-sub-text">{{L('DB_test_info')}}</div>
                        </div>
                    </div>
                    <div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.getProposals" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="getProposalsSwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('Download_proposals')}}</div>
                            <div class="dlg-switch-sub-text">{{L('Download_proposals_info')}}</div>
                        </div>
                    </div>
                    <div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.sendLocalizations" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendLocalizationsSwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('Send_localization')}}</div>
                            <div class="dlg-switch-sub-text">{{L('Send_localization_info')}}</div>
                        </div>
                    </div>
                    <div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.sendCategories" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendCategoriesSwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('Send_categories')}}</div>
                            <div class="dlg-switch-sub-text">{{L('Send_categories_info')}}</div>
                        </div>
                    </div>
                    <div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.sendProposals" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendProposalsSwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('Send_messages')}}</div>
                            <div class="dlg-switch-sub-text">{{L('Send_messages_info')}}</div>
                        </div>
                    </div>
                </div>
                <div class="dlg-line-separator"></div>
                <div class="dlg-section">
                    <div class="dlg-section-header">
                        <div class="dlg-section-title">{{L('Services_additionally_payable')}}</div>
                    </div>
                    <div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.getClientBB" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="getClientBBSwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('Download_Client_BB')}}</div>
                            <div class="dlg-switch-sub-text">{{L('Download_Client_BB_info')}}</div>
                        </div>
                    </div>
                    <div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.sendPayers" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendPayersSwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('Send_payers')}}</div>
                            <div class="dlg-switch-sub-text">{{L('Send_payers_info')}}</div>
                        </div>
                    </div>
                    <div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.sendHistCecha1" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendHistCecha1SwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('Send_HistCecha1')}}</div>
                            <div class="dlg-switch-sub-text">{{L('Send_HistCecha1_info')}}</div>
                        </div>
                    </div>
                    <div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.sendHistCecha2" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendHistCecha2SwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('Send_HistCecha2')}}</div>
                            <div class="dlg-switch-sub-text">{{L('Send_HistCecha2_info')}}</div>
                        </div>
                    </div>
                    <!--<div class="dlg-control-sub-text">
                        <div class="dlg-control">
                            <sagra-switch class="dlg-switch" [state]="winpointData.sendDictionaries" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendDictionariesSwitchChange($event)"></sagra-switch>
                        </div>
                        <div class="dlg-control-texts">
                            <div class="dlg-switch-main-text">{{L('Send_dictionaries')}}</div>
                            <div class="dlg-switch-sub-text">{{L('Send_dictionaries_info')}}</div>
                        </div>
                    </div>-->
                </div>
            </ng-scrollbar>
        </div>
        <div class="dlg-footer">
            <button class="sgf-btn-light dlg-btn-default" *ngIf="!readOnly" (click)="onDefaultClicked($event)"><span class="icon icon-replay"></span><span>{{L('Set_default')}}</span></button>
            <button class="sgf-btn-primary dlg-btn-save" *ngIf="!readOnly" (click)="onSaveClicked($event)">{{L('Save')}}</button>
            <button class="sgf-btn-light dlg-btn-cancel" *ngIf="!readOnly" (click)="onCancelClicked($event)">{{L('Cancel')}}</button>
            <button class="sgf-btn-primary dlg-btn-close" *ngIf="readOnly" (click)="onCancelClicked($event)">{{L('Close')}}</button>
        </div>
    </div>
</div>
