export class FilterItem {
    key: string;
    caption: string;
    selected: boolean;

    constructor(key: string, caption: string) {
        this.key = key;
        this.caption = caption;
    }
}
