import { WinPointConfiguration } from "../tenant/winPointConfiguration.model";
import { BIQPackage } from "../biq/biqPackage.model";

export class DraftDefinition {
    idDraft: number;
    clientName: string;
    portalAddress: string;
    databaseName: string
    prefixTME: string;
    licenseNumber: number;
    portalUsersNumber: number;
    language: string;
    currency: string;
    server: string;
    serverFull: string;
    domains: string[];
    templateEmigo: string;
    oldWorld: boolean;
    packageBIQ: BIQPackage;
    cacheRegion: string;
    winpointConfiguration: WinPointConfiguration;
}
