<ng-scrollbar class="sagra-scrollbar" [autoHeightDisabled]="true">
    <div class="database-info-component">
        <div class="db-info-content">
            <breadcrumbs [breadcrumbsId]="1"></breadcrumbs>
            <div class="db-info-header-panel">
                <div class="db-info-header-title">
                    <div class="mainText  sgf-font-h1">{{L('Client_info')}}</div>
                    <div class="subText"></div>
                </div>
            </div>
            <div>
                <div class="db-info-section">
                    <div class="db-info-section-left">
                        <div class="db-info-section-title">{{L('Client_logo')}}</div>
                    </div>
                    <div class="db-info-section-right">
                        <div class="db-info-section-control">
                            <div class="db-info-section-info-box"><span class="icon icon-tootltip_info_outline"></span><span>{{L('Client_logo_info')}}</span></div>
                            <div #dropFileBox class="db-info-file-box" (click)="fileUpload.click()" (drop)="onDrop($event)" (dragover)="onDragover($event)" (dragleave)="onDragleave($event)">
                                <div class="db-info-file" *ngIf="uploadedFile == null && !uploadingFile && !loadingPortalLogoStart &&  (imagePreviewURL == null || imagePreviewURL.length == 0)">
                                    <span class="icon icon-cloud_upload"></span><span>{{L('Disk_upload')}}</span>
                                </div>
                                <div class="db-info-uploading-file" *ngIf="uploadingFile">
                                    <span>{{uploadingTextProgress}}</span>
                                </div>
                                <div class="db-info-loading-file" *ngIf="loadingPortalLogoStart">
                                    <span>{{L('Loading_logo')}}</span>
                                </div>
                                <img class="db-info-image-preview" #imagePreview [src]="imagePreviewURL" *ngIf="(uploadedFile != null || (imagePreviewURL != null && imagePreviewURL.length > 0)) && !uploadingFile" />
                                <input hidden #fileUpload type="file" id="fileUpload" accept="image/png" (change)="uploadFile($event)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="db-info-separator"></div>
                <div class="db-info-section">
                    <div class="db-info-section-left">
                        <div class="db-info-section-title">{{L('New_world')}}</div>
                    </div>
                    <div class="db-info-section-right">
                        <new-world [tenant]="tenant" [portal]="portalName" (showLoaderChange)="onNewWorldShowLoaderChange($event)" [tenantConfiguration]="tenantConfiguration"></new-world>
                    </div>
                    <sagra-loader [loaderType]="loaderOptions.loaderType" [backgroundSetings]="loaderOptions.backgroundSetings" *ngIf="showLoaderNewWorld"></sagra-loader>
                </div>
            </div>
        </div>
        <sagra-loader [options]="loaderOptions" *ngIf="showLoader"></sagra-loader>
    </div>
</ng-scrollbar>
