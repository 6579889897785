import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BIQService } from '../services/biqService/biq.service';
import { MsalGuard } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})
export class CheckCacheGuard{

    constructor(private biqService: BIQService, public router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.biqService.getStaviatorUserContext().pipe(
            map(data => {
                if (data.showCacheList) {
                    return true;
                }
                else {
                    this.router.navigate(['/'])
                    return false;
                }
            }));
    }
}
