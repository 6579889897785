export class SelectedItem {

    constructor(originalSelected: boolean, originalEditable: boolean) {
        this.originalSelected = originalSelected;
        this.originalEditable = originalEditable;
    }

    id: number;
    guid: string;
    status: number;
    selected: boolean;
    originalSelected: boolean;
    editable: boolean;
    originalEditable: boolean;
    items: Array<SelectedSubItem> = [];
    baseType: number = 0;
}

export class SelectedSubItem {
    id: number;
    id2: number;
    selected: boolean;
    originalSelected: boolean;

}
