import { Pipe, PipeTransform } from "@angular/core";
import { DistrictData } from "../models/districts/districtData";

@Pipe({
    name: 'districtsListSearchFilter',
    pure: false
})
export class DistrictsListSearchFilter implements PipeTransform {
    transform(items: DistrictData[], search: string): DistrictData[] {
        if (!items) {
            return items;
        }
        let newItems = items.filter(item => (item.deleted == null || item.deleted == false));
        if (!search) {
            return newItems;
        }
        return newItems.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
}
