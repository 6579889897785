<div class="district-component">
    <tabpage-header [title]="headerTitle"
                    [subTitle]="headerSubTitle"
                    [actionButtonTitle]="actionButtonText"
                    [actionButtonVisible]="actionButtonVisible"
                    [actionButtonDisabled]="actionButtonDisabled"
                    [searchEnabled]="true"
                    [showBreadcrumb]="true"
                    (searchTextChanged)="onSearchTextChanged($event)"
                    (actionButtonClicked)="onActionButtonClicked($event)">
    </tabpage-header>
    <div #districtContent class="district-component-content">
        <div class="district-list">
            <div class="district-list-row" *ngFor="let district of districtsList | districtsListSearchFilter:searchText">
                <div class="district-list-row-name">
                    <sagra-input class="text-input" *ngIf="district.status != 1 && !district.analytical" [(model)]="district.name" [options]="district.status == 1 || district.analytical ? inputOptionsDisabled : inputOptions"></sagra-input>
                    <div class="text" *ngIf="district.status == 1 || district.analytical">{{district.name}}</div>
                </div>
                <div class="district-list-row-error" *ngIf="district.status == 3" title="{{district.errorInfo}}">
                    <div class="text">{{L('ERROR')}}</div>
                </div>
                <div class="district-list-row-in-progress" *ngIf="district.status == 1">
                    <div class="text">{{L('DURINGSHARING')}}</div>
                    <div class="loader">
                        <sagra-loader [options]="sagraLoaderOptions"></sagra-loader>
                    </div>
                </div>
                <div class="district-list-row-delete" [ngClass]="{'hidden': district.status == 1 || district.analytical}" (click)="deleteDistrict(district)">
                    <div class="icon icon-btn_delete"></div>
                    <div class="text">{{L('Delete')}}</div>
                </div>
            </div>
            <div class="district-list-new-row">
                <!--<div class="sgf-link-primary">
                  <div class="icon icon-add" (click)="addNewPosition()"></div>
                  <div class="text" (click)="addNewPosition()">{{L('Add_position')}}</div>
                </div>-->
                <sagra-input [(model)]="newDistrictName" [options]="newInputOptions"></sagra-input>
                <div class="sgf-link-primary" (click)="addNewPosition()" [ngClass]="{'disabled': (newDistrictName == null || newDistrictName.length == 0)}">{{L("Add")}}</div>
            </div>
        </div>
        <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
    </div>
</div>
