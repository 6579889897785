import { Component, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, QueryList, ViewChildren, AfterViewInit, OnDestroy } from "@angular/core";
import { SagraComboboxMultiselectComponent, SagraComboboxMultiselectGroupInterface, SagraComboboxMultiselectOptionsInterface, SagraComboboxMultiselectValue } from "@sagra/sagra-combobox-multiselect";
import { SagraInputOptionsModelInterface, SagraInputType, SagraInputComponent, SagraInputErrorType } from "@sagra/sagra-input";
import { SagraSwitchComponentLabelPosition, SagraSwitchComponentModelInterface } from "@sagra/sagra-switch";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { DatabaseService } from "../../services/databaseService/database.service";
import { SaveDraftRequest } from "../../models/database/saveDraftRequest.model";
import { ContextService } from "../../services/contextService/context.service";
import { Draft } from "../../models/database/draft.model";
import { SagraDialogService, SagraDialogType } from "@sagra/sagra-dialog";
import { Subject, Subscription, combineLatest, Observable } from "rxjs";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { SagraToastsService, SagraToastsStyle } from "@sagra/sagra-toasts";
import { DraftDefinition } from "../../models/database/draftDefinition.model";
import { map } from "rxjs/operators";
import { Server } from '../../models/database/server.model';
import { WinPointConfiguration } from "../../models/tenant/winPointConfiguration.model";
import { BIQService } from "../../services/biqService/biq.service";
import { BIQPackage } from "../../models/biq/biqPackage.model";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";
import { NewDatabaseModalState } from "../../models/newDatabaseModalState.model";
import { SagraModalOptionsInterface } from "@sagra/sagra-modal";
import { DialogsService } from "../../services/dialogsService/dialogs.service";
import { zip } from "rxjs";

const animationDuration: number = 200;

@Component({
    selector: 'dialog-new-database',
    templateUrl: './dialog-new-database.component.html',
    styleUrls: ['./dialog-new-database.component.scss']
})

export class DialogNewDatabaseComponent extends SagraTranslatable implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    //@Input() dialogDraft: Draft;
    //@Input() readOnly: boolean;
    //@Output() onCloseDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
    //@Output() onRefreshNeed: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('clientNameInput') clientNameInput: SagraInputComponent;
    @ViewChildren(SagraInputComponent) validateInputs: QueryList<SagraInputComponent>;
    @ViewChildren(SagraComboboxMultiselectComponent) validateCombos: QueryList<SagraComboboxMultiselectComponent>;
    @ViewChild('winpointLink') winpointLink: ElementRef;

    public show: boolean = false;
    public showContent: boolean = false;
    public readOnly: boolean = false;
    private modalState: NewDatabaseModalState;
    public dialogDraft: Draft;

    public portalDomain: string = ".emigo.biz";
    public domainItemsModel: string[];
    public showLoader: boolean;
    public errorsDialog: string[];
    public errorsToast: string[];
    public invalidForm: boolean = false;
    private canActivate: boolean;
    private componentTouched: boolean;
    private winpointConfiguration: WinPointConfiguration;

    public oldWorldModel: boolean = false;
    public clientNameModel: string;
    public databaseNameModel: string;
    public portalAddressModel: string;
    public tmePrefixModel: string;
    public tmePrefixServer: string;
    public licensesNumberModel: number;
    public portalUsersNumberModel: number;
    public languageModel: string = null;
    public languageItems: Array<SagraComboboxMultiselectGroupInterface> = [];
    public currencyModel: string = null;
    public currencyItems: Array<SagraComboboxMultiselectGroupInterface> = [];
    public serverNameModel: string;
    public serverItems: Array<SagraComboboxMultiselectGroupInterface> = [];
    public ownDomainModel: boolean;
    public emigoTemplateModel: string;
    public emigoTemplateItems: Array<SagraComboboxMultiselectGroupInterface> = [];
    public packageBIQModel: BIQPackage = new BIQPackage();
    public packageBIQItems: Array<SagraComboboxMultiselectGroupInterface> = [];
    public cacheRegionModel: string;
    public cacheRegionItems: Array<SagraComboboxMultiselectGroupInterface> = [];
    public serverFullNameModel: string;

    public packageId: string;

    public dialogWinpointVisible: boolean = false;
    public winpointConfModel: boolean;

    public loaderOptions: SagraLoaderOptionsInterface;
    public oldWorldSwitchOptions: SagraSwitchComponentModelInterface;
    public clientNameInputOptions: SagraInputOptionsModelInterface;
    public portalAddressInputOptions: SagraInputOptionsModelInterface;
    public tmePrefixInputOptions: SagraInputOptionsModelInterface;
    public licensesNumberInputOptions: SagraInputOptionsModelInterface;
    public portalUsersInputOptions: SagraInputOptionsModelInterface;
    public languageComboOptions: SagraComboboxMultiselectOptionsInterface;
    public currencyComboOptions: SagraComboboxMultiselectOptionsInterface;
    public serversComboOptions: SagraComboboxMultiselectOptionsInterface;
    public ownDomainSwitchOptions: SagraSwitchComponentModelInterface;
    public domainInputOptions: SagraInputOptionsModelInterface;
    public emigoTemplateComboOptions: SagraComboboxMultiselectOptionsInterface;
    public packageBIQComboOptions: SagraComboboxMultiselectOptionsInterface;
    public cacheRegionComboOptions: SagraComboboxMultiselectOptionsInterface;
    public winpointConfSwitchOptions: SagraSwitchComponentModelInterface;
    public options: SagraModalOptionsInterface;

    private subscriptions = new Subscription();
    private userName: string;
    private userEmail: string;
    public draftId: number;
    private serversList: Array<Server>;
    private packageItems: Array<BIQPackage>;
    private deleteSubject: Subject<any> = new Subject<any>();
    private activateSubject: Subject<any> = new Subject<any>();
    private cancelSubject: Subject<any> = new Subject<any>();

    constructor(public translationService: SagraTranslationService,
        private databaseService: DatabaseService,
        private contextService: ContextService,
        private dialogService: SagraDialogService,
        private dialogsService: DialogsService,
        private toastService: SagraToastsService,
        private messageService: MessageBusService,
        public biqService: BIQService,
    ) {
        super(translationService);

        this.options = {
            title: this.L('New_client'),
            maxHeight: '700px',
            buttons: [],
            closeOnEscapeHandler: this.cancelSubject,
            maxWidth: '500px',
            minWidth: '500px',
            fixScrollPadding: false
        }

        this.subscriptions.add(this.dialogsService.getNewDatabaseModalState().subscribe({
            next: data => {

                this.modalState = data;
                this.show = data.state;

                if (this.show) {
                    this.showContent = true;
                    if (data.draft) {
                        const draft: Draft = data.draft;

                        this.draftId = draft.id;
                        this.clientNameModel = draft.name;
                        if (draft.definition != null && draft.definition.length > 0) {
                            const definition = JSON.parse(draft.definition) as DraftDefinition;

                            this.portalAddressModel = definition.portalAddress;
                            this.tmePrefixModel = definition.prefixTME;
                            this.licensesNumberModel = definition.licenseNumber;
                            this.portalUsersNumberModel = definition.portalUsersNumber;
                            this.languageModel = definition.language;
                            this.currencyModel = definition.currency;
                            this.serverNameModel = definition.server;
                            this.serverFullNameModel = definition.serverFull;
                            this.databaseNameModel = definition.databaseName;

                            this.ownDomainModel = definition.domains != null && definition.domains.length > 0;
                            if (this.ownDomainModel) {
                                this.domainItemsModel = definition.domains;
                            }

                            this.emigoTemplateModel = definition.templateEmigo;
                            this.oldWorldModel = definition.oldWorld;
                            if (!this.oldWorldModel) {
                                if (definition.packageBIQ != null && definition.packageBIQ.id != null) {
                                    this.packageBIQModel = definition.packageBIQ;
                                    this.packageId = this.packageBIQModel.id;
                                }
                                this.cacheRegionModel = definition.cacheRegion;
                            }
                            this.winpointConfiguration = definition.winpointConfiguration;
                        }

                        this.checkCanActivate();
                    }
                    else {
                        this.draftId = 0;
                        this.clientNameModel = null;
                        this.portalAddressModel = null;
                        this.tmePrefixModel = null;
                        this.licensesNumberModel = null;
                        this.portalUsersNumberModel = null;
                        this.languageModel = null;
                        this.currencyModel = null;

                        this.ownDomainModel = false;
                        this.domainItemsModel = null;

                        this.emigoTemplateModel = null;
                        this.oldWorldModel = false;
                        this.packageBIQModel = new BIQPackage();
                        this.cacheRegionModel = null;
                        this.winpointConfiguration = null;
                        this.packageId = null;
                    }

                    this.winpointConfModel = this.winpointConfiguration != null;

                    let userModel = this.contextService.getUserModel();
                    if (userModel != null) {
                        this.userName = userModel.userName;
                        this.userEmail = userModel.userEmail;
                    }

                    this.prepareControlsOptions();

                    this.getDictionariesData();
                }
            }
        }));

        this.subscriptions.add(this.deleteSubject.asObservable().subscribe(
            x => this.deleteDraft()
        ));
        this.subscriptions.add(this.activateSubject.asObservable().subscribe(
            x => this.saveDraft(true)
        ));
        this.subscriptions.add(this.cancelSubject.asObservable().subscribe(
            x => this.onCancelClicked(null)
        ));
    }

    ngAfterViewInit() {

        //sprawdzenie formularza na wejściu do edycji
        if (this.dialogDraft != null) {
            setTimeout(() => { this.validateForm(false); }, 1500);
        }
    }

    ngOnInit() {

        this.prepareControlsOptions();

        this.cacheRegionItems = [];
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    /**
     * Kliknięcie na przycisk Anuluj
     * @param event
     */
    public onCancelClicked(event) {
        this.closeDialog();
    }

    /**
     * Kliknięcie na przycisk Usuń
     * @param event
     */
    public onDeleteClicked(event) {
        this.dialogService.showAlert({
            dialogType: SagraDialogType.confirm,
            message: this.L('Delete_draft_info') + '\n' + this.L('Ask_confirm_do'),
            backdropClose: true,
            buttons: [
                { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                { name: this.L('Delete'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.custom, className: 'sgf-btn-cherry-light', handler: this.deleteSubject }
            ]
        });
    }

    /**
     * Kliknięcie na przycisk Aktywuj
     * @param event
     */
    public onActivateClicked(event) {
        this.showLoader = true;
        // walidacja formularza
        this.validateForm(true);
    }

    /**
     * Wywoływana przez zamknięcie dialogu z konfiguracją WinPoint
     * @param event
     */
    public onDialogWinpointClose(event) {
        this.winpointConfiguration = event;
        this.dialogWinpointVisible = false;
    }

    private activateAfterValidation(isValid: boolean) {
        this.showLoader = false;
        if (!isValid) {
            return;
        }
        this.dialogService.showAlert({
            dialogType: SagraDialogType.confirm,
            message: this.L('Activate_draft_info') + '\n' + this.L('Ask_confirm_do'),
            backdropClose: true,
            buttons: [
                { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                { name: this.L('Activate'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.custom, className: 'sgf-btn-mint-light', handler: this.activateSubject }
            ]
        });
    }

    /**
     * Kliknięcie na przycisk Zapisz
     * @param event
     */
    public onSaveClicked(event) {
        // walidacja formularza
        if (!this.clientNameInput.validate()) {
            this.showLoader = false;
            this.toastService.pushToast({ moduleId: 1, message: "Wprowadź nazwę", style: SagraToastsStyle.warning });
            return;
        }

        this.saveDraft(false);
    }

    private saveDraft(activate: boolean) {
        this.showLoader = true;

        // zbudowanie wyniku dialogu
        const definition = this.buildDraftDefinition();

        // przygotowanie requesta do zapisu draftu
        const request = new SaveDraftRequest();
        if (this.draftId !== 0) {
            request.draftId = this.draftId;
        }
        request.name = definition.clientName;
        request.user = this.userName;
        request.userEmail = this.userEmail;
        request.definition = JSON.stringify(definition);
        // zapis draftu
        let component = this;
        this.subscriptions.add(this.databaseService.saveDraft(request).subscribe(response => {
            this.messageService.refreshDatabaseList();

            if (response != null) {
                component.draftId = response.idDraft; // identyfikator nowoutworzonego draftu
            }
            if (activate) {
                // kliknięto aktywację, więc przechodzimy do aktywowania
                if (definition.idDraft == null || definition.idDraft == 0) {
                    definition.idDraft = component.draftId;
                }
                this.activateDraft(definition);
            }
            else {
                // tylko zapis więc zamykamy dialog
                this.closeDialog();
                this.showLoader = false;
            }
        },
            (error) => {
                this.showLoader = false;
            }));
    }

    private activateDraft(definition: DialogResult) {
        const request = new SaveDraftRequest();

        request.draftId = this.draftId;
        request.name = definition.clientName;
        request.user = this.userName;
        request.userEmail = this.userEmail;
        request.definition = JSON.stringify(definition);

        this.subscriptions.add(this.databaseService.activateDraft(request).subscribe(result => {

            this.messageService.refreshDatabaseList();
            this.showLoader = false;
            this.closeDialog();
        },
            error => {
                this.messageService.refreshDatabaseList();
                this.showLoader = false;
            }));
    }

    private buildDraftDefinition(): DraftDefinition {
        const definition = new DraftDefinition();

        definition.clientName = this.clientNameModel;
        definition.portalAddress = this.portalAddressModel;
        definition.prefixTME = this.tmePrefixModel;
        definition.licenseNumber = this.licensesNumberModel;
        definition.portalUsersNumber = this.portalUsersNumberModel;
        definition.databaseName = this.databaseNameModel;
        definition.language = this.languageModel;
        definition.currency = this.currencyModel;
        definition.server = this.serverNameModel;
        definition.serverFull = this.serverFullNameModel;

        if (this.ownDomainModel) {
            definition.domains = [];
            for (const domain of this.domainItemsModel) {
                if (domain !== null && domain.length > 0) {
                    definition.domains.push(domain);
                }
            }
        }

        definition.templateEmigo = this.emigoTemplateModel;

        definition.oldWorld = this.oldWorldModel;
        if (!this.oldWorldModel) {
            definition.packageBIQ = this.packageBIQModel;
            definition.cacheRegion = this.cacheRegionModel;
        }

        if (this.winpointConfModel && this.winpointConfiguration != null) {
            definition.winpointConfiguration = this.winpointConfiguration;
        }

        return definition;
    }

    private deleteDraft() {
        this.showLoader = true;
        this.subscriptions.add(this.databaseService.deleteDraft(this.draftId, this.userName).subscribe((response) => {
            this.closeDialog();
            this.messageService.refreshDatabaseList();
            this.showLoader = false;
        },
            (error) => {
                this.showLoader = false;
            }
        ));
    }

    /**
     * Dodanie kolejnej domeny do wpisania
     * */
    public addClientDomain() {
        this.domainItemsModel.push("");
    }

    /**
     * Usunięcie domeny
     * @param domain
     */
    public removeClientDomain(domain) {
        for (let i = 0; i < this.domainItemsModel.length; i++) {
            if (this.domainItemsModel[i] == domain) {
                this.domainItemsModel.splice(i, 1);
                break;
            }
        }
    }

    /**
     * Właczenie opcji starego świata
     * @param event
     */
    public onOldWorldSwitchChange(event) {
        this.oldWorldModel = event

        this.checkCanActivate();
    }

    public onClientModelChange(event) {
        this.checkCanActivate();
    }

    public onPortalModelChange(event) {

        this.portalAddressModel = event;

        this.prepareDatabaseName();

        //if (this.componentTouched && this.portalAddressInputOptions.invalidTextCustom.length > 0) {
        //    this.portalAddressInputOptions.invalidTextCustom = '';
        //    this.portalAddressInputOptions = Object.create(this.portalAddressInputOptions);
        //}

        this.getPrefix();

        this.checkCanActivate();
    }

    //public onPortalFocus(event) {
    //    this.componentTouched = true;
    //}

    public onPrefixModelChange(event) {

        this.checkCanActivate();
    }

    public onLicensesNumberModelChange(event) {

        this.checkCanActivate();
    }

    public onPortalUsersNumberModelChange(event) {

        this.checkCanActivate();
    }
    public onLanguageModelChange(event) {
        if (event != null) {
            this.languageModel = event;
        }
        this.checkCanActivate();
    }
    public onCurrencyModelChange(event) {
        if (event != null) {
            this.currencyModel = event;
        }
        this.checkCanActivate();
    }
    public onServerModelChange(event) {
        if (this.serversList) {
            this.serverNameModel = event;
            const res = this.serversList.filter((item) => { return item.name == event; })
            if (res) {
                this.serverFullNameModel = res[0].fullName;
            }
        }
        this.checkCanActivate();
    }

    /**
     * Włączenie domen własnych klienta
     * @param event
     */
    public ownDomainSwitchChange(event) {

        this.ownDomainModel = event;

        if (event) {
            if (this.domainItemsModel == null) {
                this.domainItemsModel = [];
            }
            // domyślnie jedno miejsce na domenę
            if (this.domainItemsModel.length === 0) {
                this.domainItemsModel.push("");
            }
        }

        this.checkCanActivate();
    }

    public onDomainInputModelChange($event) {
        this.checkCanActivate();

    }

    public onTemplateModelChange(event) {

        this.checkCanActivate();
    }

    public onPackageBIQModelChange(event) {
        this.packageId = event;
        if (this.packageItems) {
            const result = this.packageItems.filter(item => { return item.id == event; });
            if (result) {
                this.packageBIQModel.name = result[0].name;
                this.packageBIQModel.id = result[0].id;
                this.packageBIQModel.isDemo = result[0].isDemo;
            }
        }
        this.checkCanActivate();
    }

    public onCacheRegionModelChange(event) {

        this.checkCanActivate();
    }

    private checkCanActivate() {
        //if (this.componentTouched) {
        this.validateLocal();
        //}
        this.canActivate = this.allRequiredFieldsFilled() && !this.invalidForm;
    }

    /**
     * Odpowiada za przygotowanie nazwy bazy danych
     * */
    private prepareDatabaseName() {
        this.databaseNameModel = "Emigo";
        if (this.portalAddressModel.length > 0) {
            this.databaseNameModel = this.databaseNameModel + this.portalAddressModel[0].toUpperCase() + this.portalAddressModel.slice(1);
        }
    }

    /**
     * Wywołanie zamknięcia dialogu
     * */
    private closeDialog() {
        this.show = false;
        setTimeout(() => {
            this.showContent = false;
        }, 500);
    }

    /**
     * Walidacja pól
     * */
    private validateForm(activating: boolean) {
        if (this.dialogDraft != null && this.dialogDraft.status == 2) {
            this.showLoader = false;
            return; // dla statusu w toku, nie sprawdzam poprawności
        }

        this.errorsDialog = [];

        let component = this;
        let hasErrors = !this.validateLocal();
        this.invalidForm = hasErrors;

        let validationArray = [];
        if (this.databaseNameModel != null && this.databaseNameModel.length > 0) {
            validationArray.push(this.databaseService.validateDbName(this.databaseNameModel));
        }
        if (this.portalAddressModel != null && this.portalAddressModel.length > 0) {
            validationArray.push(this.databaseService.validatePortalName(this.portalAddressModel));
        }
        if (this.tmePrefixModel != null && this.tmePrefixModel.length > 0) {
            validationArray.push(this.databaseService.validatePrefix(this.databaseNameModel, this.tmePrefixModel));
        }

        if (validationArray.length > 0) {
            combineLatest(validationArray).subscribe(([validDBName, validPortalName, validPrefix]: string[]) => {
                let hasErrors = false;

                if (validDBName != null && validDBName != "IS_VALID") {
                    hasErrors = true;
                    component.errorsDialog.push(component.getValidationErrorCaption(validDBName));
                }

                if (validPortalName != null && validPortalName != "IS_VALID") {
                    hasErrors = true;
                    component.errorsDialog.push(component.getValidationErrorCaption(validPortalName));
                    //    component.portalAddressInputOptions.invalidTextCustom = component.getValidationErrorCaption(validPortalName);
                    //    component.portalAddressInputOptions = Object.create(component.portalAddressInputOptions);
                };

                if (validPrefix != null && validPrefix != this.tmePrefixModel) {
                    hasErrors = true;
                    component.errorsDialog.push(component.getValidationErrorCaption(validPrefix));
                }

                component.invalidForm = component.invalidForm || hasErrors;
                if (activating) {
                    component.activateAfterValidation(!component.invalidForm);
                }

                this.checkCanActivate();
            },
                (error) => {
                    this.showLoader = false;
                });
        }
        else {
            if (activating) {
                this.activateAfterValidation(!component.invalidForm);
            }
        }
    }

    private validateLocal(): boolean {
        let hasErrors = false;

        if (this.validateInputs != null) {
            this.validateInputs.forEach(elem => {
                if (!elem.validate()) {
                    hasErrors = true;
                }
            });
        }

        if (this.validateCombos != null) {
            this.validateCombos.forEach(elem => {
                if (!elem.validate()) {
                    hasErrors = true;
                }
            });
        }

        this.invalidForm = hasErrors;

        return !hasErrors;
    }

    private getValidationErrorCaption(errorCode: string): string {
        switch (errorCode) {
            case "DNS_EXIST":
                return this.L('DNS_EXIST');
            case "EXIST_ADMINDB":
                return this.L('EXIST_ADMINDB');
            case "EXIST_DNNDB":
                return this.L('EXIST_DNNDB');
            case "EXIST_INTEGRA":
                return this.L('EXIST_INTEGRA');
            case "EMIGO_NOT_EXIST_ON_BEGINING":
                return this.L('EMIGO_NOT_EXIST_ON_BEGINING');
            case "EMIGO_EXIST":
                return this.L('EMIGO_EXIST');
            case "ONlY_ALPHANUMERIC":
                return this.L('ONlY_ALPHANUMERIC');
            case "MIN_8_CHARS":
                return this.L('MIN_8_CHARS');
            case "MAX_50_CHARS":
                return this.L('MAX_50_CHARS');
            case "MIN_3_CHARS":
                return this.L('MIN_3_CHARS');
            case "EXISTS_ON_ANOTHER_BASE":
                return this.L('EXISTS_ON_ANOTHER_BASE');
            case "OTHER EXISTS":
                return this.L('EXISTS');
            case "FIRST_CHAR_UPPER":
                return this.L('FIRST_CHAR_UPPER');
        }
    }
    /**
     * Sprawdza czy wszystkie wymagane pola są wypełnione
     * */
    public allRequiredFieldsFilled() {

        if (this.clientNameModel == null || this.clientNameModel.length == 0) return false;

        if (this.databaseNameModel == null || this.databaseNameModel.length == 0) return false;

        if (this.portalAddressModel == null || this.portalAddressModel.length == 0) return false;

        if (this.tmePrefixModel == null || this.tmePrefixModel.length == 0) return false;

        //if (this.licensesNumberModel == null || this.licensesNumberModel > 0) return false;

        //if (this.portalUsersNumberModel == null || this.portalUsersNumberModel > 0) return false;

        if (this.languageModel == null || this.languageModel.length == 0) return false;

        if (this.currencyModel == null || this.currencyModel.length == 0) return false;

        if (this.serverNameModel == null || this.serverNameModel.length == 0) return false;

        if (this.ownDomainModel) {
            for (let item of this.domainItemsModel) {
                if (item == null || item.length == 0) {
                    return false;
                }
            }
        }

        //if (this.emigoTemplateModel == null || this.emigoTemplateModel) return false;

        //if (this.packageBIQModel == null || this.packageBIQModel) return false;

        //if (this.cacheRegionModel == null || this.cacheRegionModel) return false;

        return true;
    }

    /**
     * Wymagane dla domen, żeby działał model
     * @param index
     * @param obj
     */
    trackByIndex(index: number, obj: any): any {
        return index;
    }

    public winpointConfSwitchChange(event) {

        this.winpointConfModel = event;
        setTimeout(() => {
            this.winpointLink.nativeElement.scrollIntoView(false);
        }, 100);

        //this.checkCanActivate();
    }

    openWinpointdialog(event) {
        this.dialogWinpointVisible = true;
    }

    private getDictionariesData() {
        this.showLoader = true;
        let tasks = zip(this.getServerList(), this.getCurrenciesList(), this.getLanguagesList(), this.getPackagesList());
        tasks.subscribe({
            next: data => {
                this.showLoader = false;
            },
            error: error => {
                this.showLoader = false;
            }
        })
    }

    getPrefix() {
        this.subscriptions.add(this.databaseService.getPrefix(this.databaseNameModel).subscribe((data) => {
            if (data) {
                if (this.tmePrefixModel == this.tmePrefixServer || this.tmePrefixModel == null || this.tmePrefixModel.length == 0) {
                    this.tmePrefixModel = data;
                }
                this.tmePrefixServer = data;
            }
        }));
    }

    getPackagesList(): Observable<any> {
        let task = this.biqService.getPackagesList();
        this.subscriptions.add(task.pipe(map(data => {
            for (let item of data) {
                item.value = item.id;
            }
            return data;
        })).subscribe(data => {
            this.packageItems = data;

            this.packageBIQItems = [];
            this.packageBIQItems.push({ list: data });

            if (this.packageBIQModel != null) {
                const list = this.packageItems.filter(item => { return item.id == this.packageBIQModel.id; });
                if (list != null && list.length > 0) {
                    this.packageBIQModel.name = list[0].name;
                    this.packageBIQModel.isDemo = list[0].isDemo;
                    this.packageId = this.packageBIQModel.id;
                }
            }
            else {
                this.packageBIQModel = null;
            }
        },
            (error) => {
            }
        ));
        return task;
    }

    getServerList(): Observable<any> {

        let task = this.databaseService.getServerList();
        this.subscriptions.add(task.subscribe((data) => {
            //this.subscriptions.add(this.databaseService.getServerList().subscribe((data) => {
            this.serversList = data;
            let servers = [];
            for (let item of data) {
                servers.push({ name: item.name, value: item.name });
            }
            this.serverItems = [];
            this.serverItems.push({ list: servers });
            if (this.serverNameModel) {
                const tmp = this.serverNameModel;
                this.serverNameModel = null;
                setTimeout(() => {
                    this.serverNameModel = tmp;
                });
            }
        }));

        return task;
    }

    getCurrenciesList(): Observable<any> {
        //this.currencyItems = [];
        let task = this.databaseService.getCurrenciesList();
        this.subscriptions.add(task.subscribe((data) => {
            if (data != null) {
                data = data.sortList("code");
                this.currencyItems = [{ list: data.map(x => new SagraComboboxMultiselectValue(x.name, x.code)) }];

                if (this.currencyModel) {
                    const tmp = this.currencyModel;
                    this.currencyModel = null;
                    setTimeout(() => {
                        this.currencyModel = tmp;
                    });
                }
            }
        }));
        return task;
    }

    getLanguagesList(): Observable<any> {
        this.languageItems = [];
        let task = this.contextService.getLanguagesList();
        this.subscriptions.add(task.subscribe((data) => {
            if (data != null) {
                data = data.sortList("codeForStaviator");
                this.languageItems = [{ list: data.map(x => new SagraComboboxMultiselectValue(x.codeForStaviator, x.codeForStaviator)) }];
                if (this.languageModel) {
                    const tmp = this.languageModel;
                    this.languageModel = null;
                    setTimeout(() => {
                        this.languageModel = tmp;
                    });
                }
            }
        }));
        return task;
    }


    /**
     * Przygotowanie obiektów do konfiguracji kontrolek
     * */
    private prepareControlsOptions() {

        this.loaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                borderWidth: 6
            }
        }

        this.oldWorldSwitchOptions = {
            labelPosition: SagraSwitchComponentLabelPosition.right,
            labelText: this.L('Old_world')
        };

        this.clientNameInputOptions = {
            type: SagraInputType.text,
            isRequired: true,
            characterLimit: 50,
            isDisabled: this.readOnly,
            invalidText: '',
            invalidTextCustom: '',
            errorType: SagraInputErrorType.borderOnly,
            returnInvalidValue: true
        };
        this.portalAddressInputOptions = {
            type: SagraInputType.text,
            isRequired: true,
            characterLimit: 45,
            isDisabled: this.readOnly,
            validationRegex: /^[a-zA-Z0-9]+$/,
            invalidText: '',
            invalidTextCustom: '',
            errorType: SagraInputErrorType.borderOnly,
            returnInvalidValue: true
        };

        this.tmePrefixInputOptions = {
            type: SagraInputType.text,
            isRequired: true,
            characterLimit: 25,
            isDisabled: this.readOnly,
            validationRegex: /^[a-zA-Z0-9]+$/,
            invalidText: '',
            invalidTextCustom: '',
            errorType: SagraInputErrorType.borderOnly,
            returnInvalidValue: true
        };
        this.licensesNumberInputOptions = {
            type: SagraInputType.number,
            isDisabled: this.readOnly,
            invalidText: '',
            invalidTextCustom: '',
            minValue: 0,
            maxValue: 999,
            characterLimit: 3,
            errorType: SagraInputErrorType.borderOnly,
        };
        this.portalUsersInputOptions = {
            type: SagraInputType.number,
            isDisabled: this.readOnly,
            invalidText: '',
            invalidTextCustom: '',
            minValue: 0,
            maxValue: 999,
            characterLimit: 3,
            errorType: SagraInputErrorType.borderOnly,
        };
        this.languageComboOptions = {
            multiselect: false,
            clearButton: false,
            groupedList: false,
            required: true,
            zIndex: 2001,
            disabled: this.readOnly,
        };
        this.currencyComboOptions = {
            multiselect: false,
            clearButton: false,
            groupedList: false,
            required: true,
            zIndex: 2001,
            disabled: this.readOnly
        };
        this.serversComboOptions = {
            multiselect: false,
            clearButton: false,
            groupedList: false,
            required: true,
            zIndex: 2001,
            disabled: this.readOnly
        };
        this.ownDomainSwitchOptions = {
            labelPosition: SagraSwitchComponentLabelPosition.right,
            labelText: this.L('Client_own_domain')
        };
        this.domainInputOptions = {
            type: SagraInputType.text,
            characterLimit: 100,
            isRequired: true,
            isDisabled: this.readOnly,
            invalidText: '',
            invalidTextCustom: '',
            validationRegex: /^(?:[a-z\d]+[a-z\d-]+\.){1,5}[a-z]{2,6}$/i,
            errorType: SagraInputErrorType.borderOnly,
            returnInvalidValue: true
        };
        this.emigoTemplateComboOptions = {
            multiselect: false,
            clearButton: false,
            groupedList: false,
            required: false,
            zIndex: 2001,
            disabled: this.readOnly
        };
        this.packageBIQComboOptions = {
            multiselect: false,
            clearButton: false,
            groupedList: false,
            required: false,
            zIndex: 2001,
            disabled: this.readOnly
        };
        this.cacheRegionComboOptions = {
            multiselect: false,
            clearButton: false,
            groupedList: false,
            required: false,
            zIndex: 2001,
            disabled: this.readOnly
        };
        this.winpointConfSwitchOptions = {
            labelPosition: SagraSwitchComponentLabelPosition.right,
            labelText: this.L('Winpoint_configuration_2')
        };
    }
}

class DialogResult {
    idDraft: number;
    clientName: string;
    portalAddress: string;
    prefixTME: string;
    licenseNumber: number;
    portalUsersNumber: number;
    language: string;
    currency: string;
    server: string;
    domains: string[];
    templateEmigo: string;
    packageBIQ: BIQPackage;
    cacheRegion: string;
}
