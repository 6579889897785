import { JobTaskType } from "./jobTask.model";

export interface JobStatus {
    id: string;
    timestamp: string;
    createdDate: string;
    author: string;
    description: string | null;
    tenant: string;
    currentTaskId: string | null;
    lastTaskId: string | null;
    failedTaskId: string | null;
    tasks: number | null;
    tasksCompleted: number | null;
    retryCount: number | null;
    errorDescription: string | null;
    stackTrace: string | null;
    jobState: JobState;
    hasManualProcess: boolean;
    jobTaskType: JobTaskType;
    taskDescription: string | null;
    startJobUDateUTC: string | null;
    startJobTaskDateUTC: string | null;
    environment: string;
    deleted: boolean | null;
    sequenceId: number | null;
    jobOperationType: JobOperationType | null;
}

export enum JobState {
    Running = 0,
    Suspended = 5,
    Stopping = 6,
    Stopped = 7,
    Manual = 10,
    Retrying = 20,
    Failed = 30,
    Completed = 40
}

export enum JobOperationType {
    None,
    ActivateRegister,
    ActivitiesActions,
    Processes,
    EmigoRole,
    EmigoDataSheet,
    Extract,
    TWR,
    Districts,
    BazaBaz,
    Hierarchies,
    Applications,
    Targets,
    PortalLogo,
    Cache,
    AADConsents,
    NewEmigo,
    NewF1
}
