import { Component } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";

@Component({
    selector: 'no-access',
    templateUrl: './no-access.component.html',
    styleUrls: ['./no-access.component.scss']
})

export class NoAccessComponent extends SagraTranslatable {
    constructor(public translationService: SagraTranslationService) {
        super(translationService);
    }
}
