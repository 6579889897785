import { Component, Pipe, PipeTransform } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { DatabaseData } from "../../models/database/database.model";
import { Router } from "@angular/router";
import { ContextService } from "../../services/contextService/context.service";
import { NavigationItemEnum } from "../../models/navigationItem.model";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { SelectedFilter } from "../../models/filters/selectedFilter";
import { FilterGroup } from "../../models/filters/filterGroup.model";
import { FilterItem } from "../../models/filters/filterItem.model";
import { DialogsService } from "../../services/dialogsService/dialogs.service";

@Component({
    selector: 'database-list',
    templateUrl: './database-list.component.html',
    styleUrls: ['./database-list.component.scss']
})

export class DatabaseListComponent extends SagraTranslatable {
    public selectedFilters: Array<SelectedFilter>;
    public filters: Array<FilterGroup>;

    constructor(public translationService: SagraTranslationService,
        private messageBusService: MessageBusService,
        private dialogsService: DialogsService,
        private contextService: ContextService,
        private router: Router
    ) {
        super(translationService);

        this.prepareFilters();
    }

    onDatabaseSelected(selectedDB: DatabaseData) {

        //this.contextService.getUserGRRPermissions().subscribe(permissions => {
        //    this.contextService.setCurrentGRRPermissions(permissions);
        this.router.navigate(['/dbinfo'], { queryParams: { id: selectedDB.name, nav: NavigationItemEnum.DatabaseInfo } });
        //});

    }

    newDatabaseButtonClick() {
        //this.messageBusService.showDialogNewDatabase(null);
        this.dialogsService.setNewDatabaseModalState({state: true, readonly: false});
    }

    onFilterChanged(event) {
        this.selectedFilters = event;
    }

    prepareFilters() {
        this.filters = [];
        let fGroup = new FilterGroup('status', this.L('Order_status'));
        fGroup.items.push(new FilterItem('1', this.L('Draft')));
        fGroup.items.push(new FilterItem('3', this.L('Error')));
        fGroup.items.push(new FilterItem('2', this.L('In_progress')));
        fGroup.items.push(new FilterItem('0', this.L('Active')));
        this.filters.push(fGroup);
    }
}
