import { Pipe, PipeTransform } from "@angular/core";
import { H5TableListData } from "../models/h5Tables/h5TableListData.model";

@Pipe({
    name: 'h5TablesListSearchFilter',
    pure: false
})
export class H5TablesListSearchFilter implements PipeTransform {
    transform(items: H5TableListData[], search: string): H5TableListData[] {
        if (!items || !search) {
            return items;
        }

        return items.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
}
