import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";
import { SagraDialogService, SagraDialogType } from "@sagra/sagra-dialog";
import { SagraInputErrorType, SagraInputIconPosition, SagraInputOptionsModelInterface, SagraInputStyle, SagraInputType } from "@sagra/sagra-input";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { SagraPlaceholderImage, SagraPlaceholderOptionsInterface } from "@sagra/sagra-placeholder";
import { SagraSegmentedControlOptionsInterface } from "@sagra/sagra-segmented-control";
import { SagraToggleButtonOptions, SagraToggleButtonStyle } from "@sagra/sagra-toggle-button";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Observable, Subject, Subscription, zip } from "rxjs";
import { map } from "rxjs/operators";
import { ResizeList, ShowPanel } from "../../animations/animations";
import { FunctionalityConfig } from "../../models/functionalities/functionalityConfig.model";
import { FunctionalityDetailsItem } from "../../models/functionalities/functionalityDetailsItem.model";
import { FunctionalityItem } from "../../models/functionalities/functionalityItem.model";
import { NavigationItemEnum } from "../../models/navigationItem.model";
import { FeedGroup } from "../../models/repository/feedGroup.model";
import { SelectedItem } from "../../models/selectedItem.model";
import { FunctionalitiesService } from "../../services/functionalitiesService/functionalities.service";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { OperationsHistoryService } from "../../services/operationsHistoryService/operationsHistory.service";
import { RepositoryService } from "../../services/repositoryService/repository.service";
import { TenantService } from "../../services/tenantService/tenant.service";
import { FilteredCounter } from "../../utils/filteredCounter";
import { Sorting } from "../../utils/sorting";

@Component({
    selector: "functionality-list",
    templateUrl: "./functionality-list.component.html",
    styleUrls: ["./functionality-list.component.scss"],
    animations: [ ShowPanel(), ResizeList() ]
})

export class FunctionalityListComponent extends SagraTranslatable implements OnInit, OnDestroy, OnChanges {
    @Input() dataSource: number;

    @ViewChild('detailsList') detailsList: ElementRef;

    private subscriptions = new Subscription();
    public headerTitle: string;
    public headerSubTitle: string = this.L("Displayed");
    public actionButtonText: string;
    public searchText: string;
    public tenantSearchText: string;

    public sortingReverse: boolean = false;
    public sortingColumn: string = 'name';
    public sortingDetailsReverse: boolean = false;
    public sortingDetailsColumn: string = 'sortStatus';

    public listFunctionalitiesItems: Array<FunctionalityItem>;
    public filteredListFunctionalitiesItems: Array<FunctionalityItem>;
    public listTenantsItems: Array<FunctionalityDetailsItem>;
    public filteredListTenantsItems: Array<FunctionalityDetailsItem>;
    private selectedFunctionalityItem: FunctionalityItem;
    public showLoader: boolean;
    public filteredCount: FilteredCounter;
    public filteredDetailsCount: FilteredCounter;

    public filteredDetailsListLength: number = 0;

    public detailsPanelVisible: boolean = false;
    public selectedTabId: number = 1;

    public showFunctionalitiesLoader: boolean;
    public showDetailsLoader: boolean;
    public firstDownloadingData: boolean = true;
    public downloadingDetailsData: boolean = false;

    private h5ActivitiesFilter: boolean = false;
    private operationsFilter: boolean = false;
    public showFilters: boolean;

    private functionalityDetailItemToShare: FunctionalityDetailsItem;
    public selectedFeedGroups: Array<number>;

    private shareItemConfirmSubject: Subject<any> = new Subject<any>();
    private shareItemGroupsSubject: Subject<any> = new Subject<any>();

    public functionalityConfig: FunctionalityConfig;

    public tenantSearchListInputOptions: SagraInputOptionsModelInterface;
    public segmentedControlOptions: SagraSegmentedControlOptionsInterface;
    public loaderOptions: SagraLoaderOptionsInterface;
    public placeholderListOptions: SagraPlaceholderOptionsInterface;
    public placeholderDetailsListOptions: SagraPlaceholderOptionsInterface;

    public h5ToggleBtnOptions: SagraToggleButtonOptions;
    public operationToggleBtnOptions: SagraToggleButtonOptions;


    constructor(public translationService: SagraTranslationService,
        private functionalitiesService: FunctionalitiesService,
        private dialogService: SagraDialogService,
        private tenantService: TenantService,
        public repositoryService: RepositoryService,
        public messageService: MessageBusService,
        private operationsHistoryService: OperationsHistoryService,
        private router: Router ) {

        super(translationService);

        this.tenantSearchListInputOptions = {
            placeholder: this.L("Search"),
            styleType: SagraInputStyle.standard,
            clearButton: true,
            errorType: SagraInputErrorType.borderOnly,
            icon: { className: 'icon-btn_search', position: SagraInputIconPosition.left }
        }

        this.segmentedControlOptions = {
            items: [{ id: 1, name: this.L('Productive'), iconClass: null },
                { id: 2, name: this.L('Test'), iconClass: null}]
        }

        this.loaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                borderWidth: 8,
                color: '#FFFFFF',
                size: 60
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }

        this.filteredCount = new FilteredCounter((oldVal, newVal) => {
            setTimeout(() => {
                this.headerSubTitle = this.L('Displayed') + ": " + newVal;
            });
        });
        this.filteredDetailsCount = new FilteredCounter((oldVal, newVal) => {
            setTimeout(() => {
                this.filteredDetailsListLength = newVal;
                console.log("Data source: " + this.dataSource + "  " + newVal);
            });
        });
    }

    ngOnInit() {
        this.headerTitle = this.getTitle();

        this.getAllFunctionalitiesItems();

        this.subscriptions.add(this.shareItemConfirmSubject.asObservable().subscribe(data => {
            this.shareItem(this.functionalityDetailItemToShare);
        }));
        this.subscriptions.add(this.shareItemGroupsSubject.asObservable().subscribe((data) => {
            this.selectedFeedGroups = data;
            this.finalShareItem();
        }));

        this.messageService.getRefreshRepositoryList().subscribe((next) => {
            const module = next.module;
            if (this.detailsPanelVisible && module == 'Sheets' && this.dataSource == NavigationItemEnum.Sheets
                || module == 'Activities' && this.dataSource == NavigationItemEnum.ActivitiesOperations
                || module == 'Processes' && this.dataSource == NavigationItemEnum.Processes
                || module == 'TWR' && this.dataSource == NavigationItemEnum.TWR
                || module == 'Extracts' && this.dataSource == NavigationItemEnum.Extracts) {
                //console.log("SignalR: call RefreshRepositoryList: " + this.dataSource + " next: " + JSON.stringify(next));
                //this.getDetailsItems();
                this.getAllFunctionalitiesItems();
            }
        });

        this.showFilters = this.dataSource == NavigationItemEnum.ActivitiesOperations;

        this.prepareFilterToggleButtons();
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onSearchTextChanged(event) {
        this.searchText = event;
        this.applyFilters();
    }

    onActionButtonClicked(event) {
    }

    onFunctionalityListItemClick(item) {
        this.selectedFunctionalityItem = item;
        this.openPanel();
        if (this.detailsList != null) {
            this.detailsList.nativeElement.scrollTop = 0;
        }
    }

    onDetailsListItemClick(item: FunctionalityDetailsItem) {

        this.router.navigate(['/dbinfo'], { queryParams: { id: item.tenant, nav: this.dataSource } });
    }

    onHistoryDetailsListItemClick(item: FunctionalityDetailsItem) {
        this.operationsHistoryService.openInNewTab(item.tenant, this.dataSource, item.id, item.guid);
    }

    onClosePanelClick(event) {
        this.closePanel();
    }

    onTabChanged(event) {
        this.selectedTabId = event;
        this.filterListTenantsItems();
        this.detailsList.nativeElement.scrollTop = 0;                       
    }

    onShareItemClick(item: FunctionalityDetailsItem) {
        this.functionalityDetailItemToShare = item;
        this.functionalityDetailItemToShare.guid = this.selectedFunctionalityItem.guid;
        if (this.dataSource != NavigationItemEnum.Sheets) {
            this.dialogService.showAlert(
                {
                    dialogType: SagraDialogType.confirm,
                    backdropClose: true,
                    title: this.L('Undone_operation') + "\n" + this.L('Sure_continue'),
                    buttons: [
                        { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                        { name: this.L('Share'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.shareItemConfirmSubject }
                    ]
                }
            );
        }
        else {
            this.shareItemConfirmSubject.next(null);
        }
    }

    getTitle(): string {
        if (this.dataSource == NavigationItemEnum.ActivitiesOperations) {
            return this.L('Acts_Opers');
        }
        else if (this.dataSource == NavigationItemEnum.Processes) {
            return this.L('Processes');
        }
        else if (this.dataSource == NavigationItemEnum.TWR) {
            return this.L('TWR');
        }
        else if (this.dataSource == NavigationItemEnum.Sheets) {
            return this.L('Sheets');
        }
        else if (this.dataSource == NavigationItemEnum.Extracts) {
            return this.L('Extracts');
        }
        else if (this.dataSource == NavigationItemEnum.H5Tables) {
            return this.L('Remove_tables');
        }
        else if (this.dataSource == NavigationItemEnum.Applications) {
            return this.L('Applications');
        }
    }

    getAllFunctionalitiesItems() {
        let method: Observable<Array<FunctionalityItem>> = null;
        if (this.dataSource == NavigationItemEnum.ActivitiesOperations) {
            method = this.functionalitiesService.getActivitiesFullList();
        }
        else if (this.dataSource == NavigationItemEnum.Processes) {
            method = this.functionalitiesService.getProcessesFullList();
        }
        else if (this.dataSource == NavigationItemEnum.TWR) {
            method = this.functionalitiesService.getTWRFullList();
        }
        else if (this.dataSource == NavigationItemEnum.Sheets) {
            method = this.functionalitiesService.getDataSheetsFullList();
        }
        else if (this.dataSource == NavigationItemEnum.Extracts) {
            method = this.functionalitiesService.getExtractsFullList();
        }
        else if (this.dataSource == NavigationItemEnum.H5Tables) {
            method = this.functionalitiesService.getH5TablesFullList();
        }
        else if (this.dataSource == NavigationItemEnum.Applications) {
            method = this.functionalitiesService.getApplicationsFullList();
        }

        this.showFunctionalitiesLoader = true;
        this.subscriptions.add(method.pipe(map(array => {
            for (let item of array) {
                if (this.dataSource == NavigationItemEnum.ActivitiesOperations || this.dataSource == NavigationItemEnum.Processes) {
                    item.tooltipModel = {
                        tooltipItems: [
                            { title: this.L('Catalog_name'), content: item.name },
                            { title: this.L('Display_name'), content: item.displayName },
                            { title: this.L('Id'), content: item.id.toString() },
                            { title: this.L('Description'), content: item.description },
                            { title: this.L('Note'), content: item.note }
                        ] };
                }
            }
            return array;
        })).subscribe(data => {
            this.listFunctionalitiesItems = data;

            this.applyFilters();
            if (this.detailsPanelVisible) {
                if (this.selectedFunctionalityItem != null) {
                    this.getDetailsItems();
                }
            }
            this.showFunctionalitiesLoader = false;
            this.firstDownloadingData = false;
        },
            (error) => {
                this.showFunctionalitiesLoader = false;
                this.firstDownloadingData = false;
                this.dialogService.showAlert({ dialogType: SagraDialogType.alert, title: error.message });
            }));
    }

    getDetailsItems() {
        this.downloadingDetailsData = true;
        let method: Observable<Array<FunctionalityDetailsItem>> = null;
        if (this.dataSource == NavigationItemEnum.ActivitiesOperations) {
            method = this.functionalitiesService.getActivitiesListDetails(this.selectedFunctionalityItem.id);
        }
        else if (this.dataSource == NavigationItemEnum.Processes) {
            method = this.functionalitiesService.getProcessesListDetails(this.selectedFunctionalityItem.id);
        }
        else if (this.dataSource == NavigationItemEnum.TWR) {
            method = this.functionalitiesService.getTWRListDetails(this.selectedFunctionalityItem.guid);
        }
        else if (this.dataSource == NavigationItemEnum.Sheets) {
            method = this.functionalitiesService.getDataSheetsListDetails(this.selectedFunctionalityItem.id);
        }
        else if (this.dataSource == NavigationItemEnum.Extracts) {
            method = this.functionalitiesService.getExtractsListDetails(this.selectedFunctionalityItem.id);
        }
        else if (this.dataSource == NavigationItemEnum.H5Tables) {
            method = this.functionalitiesService.getH5TablesListDetails(this.selectedFunctionalityItem.guid);
        }
        else if (this.dataSource == NavigationItemEnum.Applications) {
            method = this.functionalitiesService.getApplicationsListDetails(this.selectedFunctionalityItem.guid);
        }
        this.showDetailsLoader = true;
        this.subscriptions.add(method.pipe(map(items => {
            for (let item of items) {
                if (item.status == null)
                    item.status = 0;
                if (item.status == 0) {
                    //unshared
                    item.sortStatus = 4;
                }
                else if (item.status == 1) {
                    //inprogress
                    item.sortStatus = 1;
                }
                else if (item.status == 2) {
                    //shared
                    item.sortStatus = 3;
                }
                else {
                    //error
                    item.sortStatus = 2;
                }
            }
            return items;
        })).subscribe(data => {
            this.listTenantsItems = data;
            this.filterListTenantsItems();
            this.showDetailsLoader = false;
            this.downloadingDetailsData = false;
        },
            (error) => {
                this.showDetailsLoader = false;
                this.downloadingDetailsData = false;
                this.dialogService.showAlert({ dialogType: SagraDialogType.alert, title: error.message });
            }
        ));
    }

    sortFunctionalitiesColumn(column: string) {
        if (column != null) {
            if (column != this.sortingColumn) {
                this.sortingColumn = column;
                this.sortingReverse = false;
            }
            else {
                this.sortingReverse = !this.sortingReverse;
            }
        }
        let sortColumnType = 0;
        if (column == "shareCount") {
            sortColumnType = 1;
        }
        Sorting.sort(this.filteredListFunctionalitiesItems, this.sortingColumn, !this.sortingReverse, sortColumnType);
    }

    sortDetailsColumn(column: string) {
        if (column != null) {
            if (column != this.sortingDetailsColumn) {
                this.sortingDetailsColumn = column;
                this.sortingDetailsReverse = false;
            }
            else {
                this.sortingDetailsReverse = !this.sortingDetailsReverse;
            }
        }
        let secondColumn = null;
        if (this.sortingDetailsColumn != 'tenant') {
            secondColumn = 'tenant';
        }
        let sortColumnType = 0;
        if (this.sortingDetailsColumn == "sortStatus") {
            sortColumnType = 1;
        }
        Sorting.sortByTwoProperties(this.filteredListTenantsItems, this.sortingDetailsColumn, secondColumn, !this.sortingDetailsReverse, sortColumnType);
    }

    openPanel() {
        this.detailsPanelVisible = true;
        this.getDetailsItems();
    }

    closePanel() {
        this.selectedFunctionalityItem = null;
        this.detailsPanelVisible = false;
    }

    filterListTenantsItems() {
        if (this.tenantSearchText) {
            this.filteredListTenantsItems = this.listTenantsItems;
        }
        else {
            if (this.selectedTabId == 1) {
                this.filteredListTenantsItems = this.listTenantsItems.filter(item => { return item.testBase == false; });
            }
            else if (this.selectedTabId == 2) {
                this.filteredListTenantsItems = this.listTenantsItems.filter(item => { return item.testBase == true; });
            }
        }
        this.sortDetailsColumn(null);

        this.preparePlaceholderOptions();
    }

    private shareItem(item: FunctionalityDetailsItem) {
        this.showLoader = true;
        let tasks = zip(this.tenantService.getTenantConfiguration(item.tenant), this.repositoryService.getGroupsList(item.tenant));
        this.subscriptions.add(tasks.subscribe(data => {
            let oDataFeedRestrictions: boolean = data[0].oDataFeedRestrictions.value.toLowerCase() == "true";
            let feedGroups: Array<FeedGroup> = data[1];

            this.showLoader = false;

            if (oDataFeedRestrictions && (this.dataSource == NavigationItemEnum.TWR || this.dataSource == NavigationItemEnum.Sheets || this.dataSource == NavigationItemEnum.Extracts)) {
                this.messageService.showDialogFeedGroups({
                    feedGroups: feedGroups,
                    selectedFeedGroups: null,
                    cancelHandler: null,
                    publishHandler: this.shareItemGroupsSubject,
                });
            }
            else {
                this.finalShareItem();
            }
        },
            error => {
                this.showLoader = false;
                this.dialogService.showAlert({ dialogType: SagraDialogType.alert, title: error.message });
            }))
    }

    private finalShareItem() {
        const tenant = this.functionalityDetailItemToShare.tenant;
        const feedGroupsId: Array<number> = this.selectedFeedGroups;
        const itemsToShare: Array<SelectedItem> = [];
        itemsToShare.push({
            id: this.functionalityDetailItemToShare.id,
            guid: this.functionalityDetailItemToShare.guid,
            status: this.functionalityDetailItemToShare.status,
            selected: true,
            editable: false,
            items: null,
            originalEditable: false,
            originalSelected: false,
            baseType: 0
        });
        const methodShareName: string = this.getShareMethodName();

        this.subscriptions.add(this.repositoryService.assignElements(methodShareName, tenant, itemsToShare, feedGroupsId).subscribe(result => {
            this.getDetailsItems();
            this.showLoader = false;
        },
            error => {
                this.showLoader = false;
                this.dialogService.showAlert({ dialogType: SagraDialogType.alert, title: error.message });
            }));
    }

    private getShareMethodName() {
        if (this.dataSource == NavigationItemEnum.ActivitiesOperations) {
            return 'api/Repository/AssignActivities';
        }
        else if (this.dataSource == NavigationItemEnum.Processes) {
            return 'api/Repository/AssignProcesses';
        }
        else if (this.dataSource == NavigationItemEnum.TWR) {
            return 'api/Repository/AssignTWRs';
        }
        else if (this.dataSource == NavigationItemEnum.Sheets) {
            return 'api/Repository/AssignSheets';
        }
        else if (this.dataSource == NavigationItemEnum.Extracts) {
            return 'api/Repository/AssignExtracts';
        }
    }

    tenantSearchChanged(event) {
        this.filterListTenantsItems();
    }

    applyFilters() {
        let tmpListItems: Array<FunctionalityItem> = this.listFunctionalitiesItems;

        if (tmpListItems != null && this.h5ActivitiesFilter != this.operationsFilter) {
            tmpListItems = tmpListItems.filter(item => {
                if ((this.h5ActivitiesFilter && item.baseType == 32) || (this.operationsFilter && item.baseType == 513)) {
                    return true;
                }
            });
        }

        if (tmpListItems != null) {
            if (this.searchText) {
                tmpListItems = tmpListItems.filter(item => item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
            }
        }

        this.filteredListFunctionalitiesItems = tmpListItems;

        this.headerSubTitle = this.L('Displayed') + ": " + this.filteredListFunctionalitiesItems.length;

        this.sortFunctionalitiesColumn(null);

        this.preparePlaceholderOptions();
    }

    preparePlaceholderOptions() {
        this.placeholderListOptions = {
            title: this.L('Nothing_to_display'),
            image: {
                type: SagraPlaceholderImage.lightbulb
            }
        }

        if ((this.h5ActivitiesFilter || this.operationsFilter || this.searchText) && (this.filteredListFunctionalitiesItems == null || this.filteredListFunctionalitiesItems.length == 0)) {
            this.placeholderListOptions.title = this.L('Empty_search_holder');
            this.placeholderListOptions.image = { type: SagraPlaceholderImage.search };
        }

        this.placeholderDetailsListOptions = {
            title: this.L('Nothing_to_display'),
            image: {
                type: SagraPlaceholderImage.lightbulb
            }
        }

        if (this.tenantSearchText && (this.filteredDetailsListLength == 0)) {
            this.placeholderDetailsListOptions.title = this.L('Empty_search_holder');
            this.placeholderDetailsListOptions.image = { type: SagraPlaceholderImage.search };
        }
    }

    prepareFilterToggleButtons() {
        this.h5ToggleBtnOptions = {
            style: SagraToggleButtonStyle.primary,
            normalState: {
                text: this.L('H5_ACTIVITY')
            },
            activeState: {
                text: this.L('H5_ACTIVITY'),
                iconClass: 'icon-check'
            }
        };
        this.operationToggleBtnOptions = {
            style: SagraToggleButtonStyle.primary,
            normalState: {
                text: this.L('OPERATIONS')
            },
            activeState: {
                text: this.L('OPERATIONS'),
                iconClass: 'icon-check'
            }
        };
    }

    applyTypeFilters(type: string) {
        if (type == "H5") {
            this.h5ActivitiesFilter = !this.h5ActivitiesFilter;
        }
        else if (type == "OPERATIONS") {
            this.operationsFilter = !this.operationsFilter;
        }
        this.applyFilters();
    }

    testSignalR() {
        this.messageService.refreshRepositoryList({ tenant: '', module: 'Activities' });
    }
}
