import { Pipe, PipeTransform } from "@angular/core";
import { CheckboxItem } from "../models/checkboxItem.model";
import { DatabaseRole } from "../models/databaseRole.model";

@Pipe({
    name: 'rolesListSearchFilter',
    pure: false
})
export class RolesListSearchFilter implements PipeTransform {
  transform(items: Array<DatabaseRole>, search: string): Array<DatabaseRole> {
        if (!items || !search) {
            return items;
        }

        return items.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
}
