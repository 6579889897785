import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HierarchyData } from "../../models/hierarchy/hierarchyData.model";

@Injectable()
export class HierarchyService {
    private apiUrl: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    getHierarchyList(tenant: string): Observable<any> {
        return this.http.post<Array<HierarchyData>>('api/Hierarchy/GetHierarchyList', { dbName: tenant }).pipe(map((items) => {
            items = items.sort((a, b) => {
                if (a.hierarchyCode < b.hierarchyCode)
                    return -1;
                else if (a.hierarchyCode > b.hierarchyCode)
                    return 1;
                return 0;
            });
            if (items != null && items.length > 0) {
                let rootItem = items[0];
                for (let i = 1; i < items.length; i++) {
                    this.addHierarchy(rootItem, items[i]);
                }
                return [rootItem];
            }
            return items;
        }));
    }

    saveHierarchyItem(tenant: string, hierarchyItem: HierarchyData): Observable<any> {
        let data = {
            dbName: tenant,
            levelName: hierarchyItem.levelName,
            levelId: hierarchyItem.hierarchyCode.length,
            name: hierarchyItem.name,
            hierarchyCode: hierarchyItem.hierarchyCode,
            departmentId: hierarchyItem.departmentId,
            companyCode: hierarchyItem.companyCode,
            endLevel: hierarchyItem.endLevel,
            deleted: hierarchyItem.deleted
        }
        return this.http.post<Array<HierarchyData>>('api/Hierarchy/SaveHierarchyItem', data);
    }

    addHierarchy(destItem: HierarchyData, item: HierarchyData): HierarchyData {
        if (destItem.hierarchyCode.length + 1 == item.hierarchyCode.length) {
            if (destItem.subHierarchies == null) {
                destItem.subHierarchies = [];
            }
            destItem.subHierarchies.push(item);
            return destItem;
        }
        else {
            if (destItem.hierarchyCode.length + 1 < item.hierarchyCode.length) {
                if (destItem.subHierarchies == null) {
                    destItem.subHierarchies = [];
                }
                for (let i = 0; i < destItem.subHierarchies.length; i++) {
                    if (item.hierarchyCode.startsWith(destItem.subHierarchies[i].hierarchyCode)) {
                        return this.addHierarchy(destItem.subHierarchies[i], item);
                        break;
                    }
                }
            }
        }
        return null;
    }

    editHierarchy(destItem: HierarchyData, item: HierarchyData) {
        for (let i = 0; i < destItem.subHierarchies.length; i++) {
            if (destItem.subHierarchies[i].hierarchyCode == item.hierarchyCode) {
                destItem.subHierarchies[i] = item;
                break;
            }
            else if (item.hierarchyCode.startsWith(destItem.subHierarchies[i].hierarchyCode)) {
                this.editHierarchy(destItem.subHierarchies[i], item);
            }
        }
    }

    findLevelName(rootElement: HierarchyData, levelId: number): string {
        if (rootElement != null && rootElement.subHierarchies != null) {
            for (let i = 0; i < rootElement.subHierarchies.length; i++) {
                if (rootElement.subHierarchies[i].levelId == levelId) {
                    if (rootElement.subHierarchies[i].levelName != null) {
                        return rootElement.subHierarchies[i].levelName;
                    }
                }
                else {
                    let result = this.findLevelName(rootElement.subHierarchies[i], levelId);
                    if (result != null) {
                        return result;
                    }
                }
            }
        }
        return null;
    }

    hierarchyCodeExists(rootItem: HierarchyData, code: string): boolean {
        if (rootItem.subHierarchies != null) {
            for (let item of rootItem.subHierarchies) {
                if (item.hierarchyCode == code) {
                    return true;
                }
                else if (code.startsWith(item.hierarchyCode)) {
                    return this.hierarchyCodeExists(item, code);
                }
            }
        }
        return false;
    }

}
