export class FilteredCounter {
    private _count: number

    get count(): number {
        return this._count
    }
    set count(count: number) {
        this.valueChanged(this._count, count)
        this._count = count
    }

    valueChanged: (oldVal, newVal) => void

    constructor(valueChanged?: (oldVal, newVal) => void) {
        // return an empty function if no callback was provided in case you don't need
        // one or want to assign it later
        this.valueChanged = valueChanged || (() => { })
    }
}
