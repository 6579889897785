<div class="new-world-component">
    <div class="db-info-section-info-box">
        <span class="icon icon-tootltip_info_outline"></span><span>{{L('Env_params_info')}}</span>
    </div>
    <!--<div class="db-info-section">
        <div class="db-info-section-left">
            <div class="db-info-section-title">{{L('Biqsens_package')}}</div>
        </div>
        <div class="db-info-section-right">
            <biq-package [tenant]="tenant" [portal]="portal" (showLoaderChange)="onBiqShowLoaderChange($event)" [tenantConfiguration]="tenantConfiguration"></biq-package>
        </div>
        <sagra-loader [loaderType]="loaderOptions.loaderType" [backgroundSetings]="loaderOptions.backgroundSetings" *ngIf="showLoaderBiqPackage"></sagra-loader>
    </div>-->
    <div class="nw-actions-container">
        <!--<div class="nw-action-biq">
        <div class="nw-action-biq-title">{{L('Biqsens')}}</div>-->
        <!--<div class="nw-action-biq-demo-info">
            <div class="icon icon-tootltip_info_outline" *ngIf="demoPackageEndingInfo != null"></div>
            <div>{{demoPackageEndingInfo}}</div>
        </div>-->
        <!--<div class="nw-action-biq-content db-info-section-control">
                <div class="db-info-section-text"><span>{{L('Chosen_package_Biqsens')}}</span><span class="icon icon-tootltip_info_outline" *ngIf="demoPackageEndingInfo != null"></span></div>
                <div class="nw-package">
                    <sagra-combobox-multiselect class="nw-combo-packages" [(selectedValue)]="packageModel.id" [list]="packagesList" [options]="packageComboOptions" (selectedValueChange)="onPackageModelChange($event)"></sagra-combobox-multiselect>
                    <span class="nw-action-button sgf-link-primary" [ngClass]="{'disabled': !canSavePackage || disablePackage}" *ngIf="!packageLoader" (click)="onPackageSetClick()">{{L('Set')}}</span>
                    <div class="element-inprogress-status" *ngIf="packageLoader">
                        <div class="loader"><sagra-loader [options]="sagraLoaderOptions"></sagra-loader></div>
                        <div class="text">{{L('DURINGSHARING')}}</div>
                    </div>
                </div>
            </div>
        </div>-->



        <div class="nw-action-biq-title">
            <span>Biqsens</span>
            <span class="icon icon-tootltip_info_outline"
                    *ngIf="demoPackageEndingInfo != null"
                    [sagraTooltip]="demoTooltipOptions"
                    [sagraTooltipModel]="demoTooltipModel"></span>
        </div>
        <div class="nw-action-biq-content">
            <div class="db-info-section-text">
                <span>{{L('Chosen_package_Biqsens')}}</span>
            </div>
            <div class="nw-package">
                <sagra-combobox-multiselect class="nw-combo-packages"
                                            [(selectedValue)]="packageModel.id"
                                            [list]="packagesList"
                                            [disabled]="disablePackage"
                                            [multiselect]="false"
                                            [clearButton]="false"
                                            (selectedValueChange)="onPackageModelChange($event)">
                </sagra-combobox-multiselect>
                <button class="nw-action-button sgf-link-primary" [ngClass]="{'disabled': !canSavePackage || disablePackage}" *ngIf="!packageLoader" (click)="onPackageSetClick()">{{L('Set')}}</button>
                <div class="element-inprogress-status" *ngIf="packageLoader">
                    <div class="loader"><sagra-loader [options]="sagraLoaderOptions"></sagra-loader></div>
                    <div class="text">{{packageLoaderText}}</div>
                </div>
            </div>
            <div class="nw-package-dates db-info-section-control" *ngIf="packageModel != null && packageModel.isDemo && !demoUsed && !(currentPackage != null && currentPackage.id == packageModel.id)">
                <div class="db-info-section-text">{{L('Demo_duration')}}</div>
                <sagra-datetime-picker class="biq-package-date-input"
                                       [date]="demoDurationFromModel"
                                       [type]="datePickerType"
                                       (dateChange)="onBiqPackageFromDateChanged($event)"></sagra-datetime-picker>
                <sagra-datetime-picker class="biq-package-date-input"
                                       [date]="demoDurationToModel"
                                       [type]="datePickerType"
                                       [minDate]="demoDurationFromModel"
                                       (dateChange)="onBiqPackageToDateChanged($event)"></sagra-datetime-picker>
                <div class="sgf-btn-primary biq-package-section-button" *ngIf="demoEnabled" (click)="onTurnOffPackageClick()">{{L('Switch_off')}}</div>
            </div>
        </div>
        <div class="nw-action-title">{{L('Cache')}}</div>
        <div>
            <sagra-pill [type]="1" [text]="L('ACTIVE')" [pillClass]="'mint'" *ngIf="cacheEnabled"></sagra-pill>
            <sagra-pill [type]="1" [text]="L('INACTIVE')" [pillClass]="'ink-10'" *ngIf="!cacheEnabled"></sagra-pill>
        </div>
        <div>
            <button class="history-button sgf-btn-white-border" (click)="cacheHistoryButtonClick()">
                <span class="icon icon-restore"></span><span>{{L('Operation_history')}}</span>
            </button>
        </div>
        <div class="nw-action-title">{{L('Engine_H5')}}</div>
        <div>
            <sagra-pill [type]="1" [text]="L('ACTIVE')" [pillClass]="'mint'" *ngIf="engineH5Status.status==2"></sagra-pill>
            <sagra-pill [type]="1" [text]="L('INACTIVE')" [pillClass]="'cherry'" *ngIf="engineH5Status.status==3"></sagra-pill>
            <sagra-pill [type]="2" [text]="L('DURINGSHARING')" *ngIf="engineH5Status.status==1"></sagra-pill>
            <span class="nw-action-button sgf-link-primary" (click)="onEngineH5Click()" *ngIf="engineH5Status.status==0">{{L('Turn_on')}}</span>
        </div>
        <div></div>
        <div class="nw-action-title">{{L('Portal_parameters')}}</div>
        <div [sagraTooltip]="tooltipOptions" [sagraTooltipModel]="newWorldTooltipModel">
            <sagra-pill [type]="1" [text]="L('ACTIVE')" [pillClass]="'mint'" *ngIf="newWorldStatus==2"></sagra-pill>
            <sagra-pill [type]="2" [text]="L('DURINGSHARING')" *ngIf="newWorldStatus==1"></sagra-pill>
            <span class="nw-action-button sgf-link-primary" *ngIf="newWorldStatus==0" (click)="onNewWorldClick()">{{L('Turn_on')}}</span>
        </div>
        <div></div>
        <div class="nw-action-title">{{L('Agreements_aad')}}</div>
        <div>
            <!--<span class="element-active-status" *ngIf="false">{{L('ACTIVE')}}</span>
            <span class="element-inactive-status" *ngIf="false">{{L('INACTIVE')}}</span>
            <span class="element-error-status" *ngIf="false">{{L('ERROR')}}</span>-->
            <span class="nw-action-button sgf-link-primary" *ngIf="false">{{L('Turn_on')}}</span>
            <span class="nw-action-button sgf-link-primary" *ngIf="true" (click)="onAgreementsSetClick()">{{L('Set')}}</span>
        </div>
        <div>
            <button class="history-button sgf-btn-white-border" (click)="agreementsHistoryButtonClick()">
                <span class="icon icon-restore"></span><span>{{L('Operation_history')}}</span>
            </button>
        </div>
    </div>
</div>
