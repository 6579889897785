import { SagraTranslationService, SagraTranslatable } from "@sagra/sagra-translation";
import { Input, OnInit, AfterViewInit, Component, SimpleChanges, OnChanges, ViewChildren, QueryList, ViewChild } from "@angular/core";
import { DialogEditHierarchyOptionsInterface, DialogEditHierarchyOptions } from "../../../logic/dialogEditHierarchyOptions.interface";
import { SagraCheckboxComponentOptionsInterface, SagraCheckboxComponentSize, SagraCheckboxComponentCilckableArea, SagraCheckboxComponentLabelPosition, SagraCheckboxComponentListItemInterface } from "@sagra/sagra-checkbox";
import { SagraInputOptionsModelInterface, SagraInputComponent, SagraInputType } from "@sagra/sagra-input";
import { HierarchyService } from "../../../services/hierarchyService/hierarchy.service";
import { HierarchyData } from "../../../models/hierarchy/hierarchyData.model";
import { SagraToastsService, SagraToastsStyle } from "@sagra/sagra-toasts";
import { DialogOpacity, DialogZoom } from "../../../animations/animations";

const animationDuration: number = 200;

@Component({
    selector: 'dialog-edit-hierarchy',
    templateUrl: './dialog-edit-hierarchy.component.html',
    styleUrls: ['./dialog-edit-hierarchy.component.scss'],
    animations: [ DialogZoom(animationDuration), DialogOpacity(animationDuration) ]
})

export class DialogEditHierarchyComponent extends SagraTranslatable implements OnInit, OnChanges, AfterViewInit {
    @Input() options: DialogEditHierarchyOptionsInterface;
    @ViewChildren(SagraInputComponent) validateInputs: QueryList<SagraInputComponent>;
    @ViewChild('inputID') inputID: SagraInputComponent;

    public tenant: string;
    public visible: boolean;
    public animation: string;
    public parentHierarchyCode: string;
    public hierarchyNameModel: string;
    public parentUnitModel: string;
    public identifierModel: string;
    public departmentIdModel: number;
    public companyCodeModel: string;
    public endLevelModel: boolean;
    public invalidForm: boolean = false;
    public rootHierarchyItem: HierarchyData;
    public hierarchyItem: HierarchyData = new HierarchyData();
    public showLoader: boolean;
    public edition: boolean;
    public readOnly: boolean;

    public checkOptions: SagraCheckboxComponentOptionsInterface;
    public checkboxItem: SagraCheckboxComponentListItemInterface;
    public unitNameInputOptions: SagraInputOptionsModelInterface;
    public parentUnitInputOptions: SagraInputOptionsModelInterface;
    public identifierInputOptions: SagraInputOptionsModelInterface;
    public departmentIdInputOptions: SagraInputOptionsModelInterface;
    public codeSFInputOptions: SagraInputOptionsModelInterface;

    constructor(public translationService: SagraTranslationService,
        private sagraToastsService: SagraToastsService,
        private hierarchyService: HierarchyService,
    ) {
        super(translationService);
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            if (this.options) {
                this.options = new DialogEditHierarchyOptions(
                    this.options.tenant,
                    this.options.levelName,
                    this.options.rootHierarchyItem,
                    this.options.hierarchyItem,
                    this.options.parentHierarchyCode,
                    this.options.cancelHandler,
                    this.options.saveHandler,
                );

                this.tenant = this.options.tenant;
                //this.parentHierarchyCode = this.options.parentHierarchyCode

                this.rootHierarchyItem = this.options.rootHierarchyItem;
                this.parentUnitModel = this.options.parentHierarchyCode;
                this.identifierModel = '';

                this.edition = false;

                if (this.options.hierarchyItem != null) {
                    this.hierarchyItem = this.options.hierarchyItem;
                    this.hierarchyNameModel = this.hierarchyItem.name;
                    this.departmentIdModel = this.hierarchyItem.departmentId;
                    this.companyCodeModel = this.hierarchyItem.companyCode;
                    this.endLevelModel = this.hierarchyItem.endLevel;

                    if (this.hierarchyItem.hierarchyCode != null && this.hierarchyItem.hierarchyCode.length > 0) {
                        this.parentUnitModel = this.hierarchyItem.hierarchyCode.substring(0, this.hierarchyItem.hierarchyCode.length - 1);
                        this.identifierModel = this.hierarchyItem.hierarchyCode.substring(this.hierarchyItem.hierarchyCode.length - 1);
                    }

                    this.edition = true;
                    this.readOnly = this.hierarchyItem.deleted;
                }
                else {
                    this.hierarchyItem = new HierarchyData();
                    this.hierarchyItem.levelName = this.options.levelName;
                    //this.hierarchyItem.endLevel = false;
                    this.hierarchyNameModel = '';
                    this.identifierModel = '';
                    this.departmentIdModel = null;
                    this.companyCodeModel = '';
                    this.endLevelModel = false;
                    this.readOnly = false;
                }

                this.visible = true;
                this.animation = 'in';
            }
        }
        this.prepareControlsOptions();

        this.checkboxItem = {
            labelText: this.L('End_level'),
            disabled: this.readOnly || !this.canSetEndLevel(),
            state: false
        }
    }

    ngAfterViewInit(): void {
        if (this.hierarchyItem != null && this.hierarchyItem.hierarchyCode != null) {
            this.validateForm();
        }
    }

    onBackdropClick(event) {

    }

    onSaveClick() {
        if (this.validateOnSave()) {
            this.showLoader = true;

            let item = new HierarchyData();
            item.name = this.hierarchyNameModel;
            item.hierarchyCode = this.parentUnitModel + this.identifierModel;
            item.departmentId = this.departmentIdModel;
            item.companyCode = this.companyCodeModel;
            item.endLevel = this.endLevelModel;
            item.expanded = this.hierarchyItem.expanded;
            item.levelId = item.hierarchyCode.length;
            item.levelName = this.hierarchyItem.levelName;
            item.subHierarchies = this.hierarchyItem.subHierarchies;
            item.deleted = this.hierarchyItem.deleted;

            this.hierarchyService.saveHierarchyItem(this.tenant, item).subscribe(data => {
                this.showLoader = false;
                this.closeDialog();
                if (this.options.saveHandler != null) {
                    this.options.saveHandler.next({
                        edition: this.edition,
                        hierarchy: item
                    });
                }
            },
                error => {
                    this.showLoader = false;
                    //this.sagraToastsService.pushToast({
                    //    moduleId: 1,
                    //    message: error,
                    //    style: SagraToastsStyle.danger
                    //});
                });
        }
    }

    onCancelClick() {
        this.closeDialog();
    }

    onUnitNameModelChange(event) {
        //console.log("NAME - event:" + event + "    :" + this.hierarchyNameModel);

        if (this.hierarchyNameModel != event) {
            this.hierarchyNameModel = event;
            this.validateForm();
        }
    }

    onIdentifierModelChange(event) {
        //console.log("IDENTIFIER - event:" + event + "    :" + this.identifierModel);

        //if (this.identifierModel != event) {
            let hierarchyCode = this.parentUnitModel + this.identifierModel;
            if (hierarchyCode == this.companyCodeModel || this.companyCodeModel == '') {
                this.companyCodeModel = this.parentUnitModel + event;
            }

            //this.identifierModel = event;
            setTimeout(() => { this.validateForm(); }, 200);
        //}
    }

    onDepartmentIdModelChange(event) {
        //console.log("DEPARTMENT - event:" + event + "    :" + this.departmentIdModel);

        if (this.departmentIdModel == null) {
            this.departmentIdModel = event;
        }

        if (this.departmentIdModel != event) {
            this.departmentIdModel = event;
            this.validateForm();
        }
    }

    onCodeSFModelChange(event) {
        //console.log("COMPANY CODE - event:" + event + "    :" + this.companyCodeModel);

        if (this.companyCodeModel != event) {
            this.companyCodeModel = event;
            this.validateForm();
        }
    }

    onEndLevelCheckboxClick(value) {
        //console.log("END LEVEL - event:" + value + "    :" + this.endLevelModel);

        if (this.endLevelModel != value) {
            this.endLevelModel = value;
            this.validateForm();
        }
    }

    validateForm() {
        let hasErrors = false;

        if (this.validateInputs != null) {
            this.validateInputs.forEach(elem => {
                if (!elem.validate()) {
                    hasErrors = true;
                }
            });
        }

        this.invalidForm = hasErrors;

        return !hasErrors;
    }

    validateOnSave() {
        let hasErrors = false;

        hasErrors = !this.validateForm();

        if (!this.edition) {
            let hierarchyCode = this.parentUnitModel + this.identifierModel;
            if (this.hierarchyService.hierarchyCodeExists(this.rootHierarchyItem, hierarchyCode)) {
                hasErrors = true;
                this.identifierModel = '';
                this.sagraToastsService.pushToast({
                    moduleId: 1,
                    message: this.L('Hierarchy_id_exists'),
                    style: SagraToastsStyle.danger
                });
            }
            this.invalidForm = hasErrors;
        }
        return !hasErrors;
    }

    prepareControlsOptions() {
        this.unitNameInputOptions = {
            isDisabled: this.readOnly,
            isRequired: true,
            characterLimit: 50,
            returnInvalidValue: true
        }
        this.parentUnitInputOptions = {
            isDisabled: true,
        }
        this.identifierInputOptions = {
            isDisabled: this.readOnly || this.edition,
            isRequired: true,
            characterLimit: 1,
            validationRegex: /^[a-zA-Z0-9]$/,
            returnInvalidValue: true
        }
        this.departmentIdInputOptions = {
            isDisabled: this.readOnly,
            maxValue: 2147483647,
            minValue: -2147483647,
            type: SagraInputType.number,
            returnInvalidValue: true
        }
        this.codeSFInputOptions = {
            isDisabled: this.readOnly,
            isRequired: true,
            characterLimit: 10,
            returnInvalidValue: true
        }
        this.checkOptions = {
            checkboxsize: SagraCheckboxComponentSize.medium,
            clickable: SagraCheckboxComponentCilckableArea.wholeItem,
            labelPosition: SagraCheckboxComponentLabelPosition.right,
            singleLineLabel: true
        }
    }

    canSetEndLevel() {
        if (this.hierarchyItem.subHierarchies != null && this.hierarchyItem.subHierarchies.length > 0) {
            for (let item of this.hierarchyItem.subHierarchies) {
                if (!item.deleted) {
                    return false;
                }
            }
        }
        return true;
    }

    closeDialog() {
        setTimeout(() => {
            this.visible = false;
        }, animationDuration);

        this.animation = 'out';
    }
}
