<div class="notification-component">
    <div class="notification-background" (click)="onCloseClick()" [@showBackground]="showNotification ? 'show' : 'hide'"></div>
    <div class="notification-content" [@showNotifications]="showNotification ? 'show' : 'hide'">
        <div class="notification-title">
            <div class="notification-title-text">{{L('Notifications')}}</div>
            <div class="notification-title-close" (click)="onCloseClick()">
                <span class="icon icon-btn_close"></span>
            </div>
        </div>
        <div class="notification-list">
            <ng-scrollbar class="sagra-scrollbar">
                <div class="notification-group-row" *ngFor="let tenant of notificationsList">
                    <div class="notification-group-row-text">{{tenant.tenant}}</div>
                    <div class="notification-item-row" *ngFor="let item of tenant.items">
                        <div class="notification-item-row-icon">
                            <div class="notification-item-row-icon-error icon icon-warning2" *ngIf="item.isError"></div>
                            <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="!item.isError"></sagra-loader>
                        </div>
                        <div class="notification-item-row-text">{{item.type}}</div>
                        <div class="notification-item-row-counter">{{item.countCompleted}}/{{item.countAll}}</div>
                        <div class="notification-item-row-link" (click)="goToModuleClick(tenant.tenant, item.type)">
                            <div class="icon icon-keyboard_arrow_right"></div>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
        </div>
    </div>
</div>
