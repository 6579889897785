import { Inject, Injectable } from "@angular/core";
import { SagraTranslationService } from "@sagra/sagra-translation";
import { Observable, Subject } from "rxjs";
import { AgreementsModalState } from "../../models/agreementsModalState.model";
import { NewDatabaseModalState } from "../../models/newDatabaseModalState.model";
import { TargetingModalState } from "../../models/targetingModalState.model";
import { MessageBusService } from "../messageBusService/messageBusService";

@Injectable({ providedIn: 'root' })

export class DialogsService {

    private modalNewDatabaseStateSubject: Subject<any> = new Subject<any>();
    private modalAgreementsStateSubject: Subject<any> = new Subject<any>();
    private modalTargetingStateSubject: Subject<any> = new Subject<any>();

    constructor(
        private translationService: SagraTranslationService,
        private messageService: MessageBusService,
    ) {
    }

    //#region msg bus
    setNewDatabaseModalState(state: NewDatabaseModalState) {
        this.modalNewDatabaseStateSubject.next(state);
    }
    getNewDatabaseModalState(): Observable<NewDatabaseModalState> {
        return this.modalNewDatabaseStateSubject.asObservable();
    }

    setAgreementsModalState(state: AgreementsModalState) {
        this.modalAgreementsStateSubject.next(state);
    }
    getAgreementsModalState(): Observable<AgreementsModalState> {
        return this.modalAgreementsStateSubject.asObservable();
    }

    setTargetingModalState(state: TargetingModalState) {
        this.modalTargetingStateSubject.next(state);
    }
    getTargetingModalState(): Observable<TargetingModalState> {
        return this.modalTargetingStateSubject.asObservable();
    }
    //#region msg bus
}
