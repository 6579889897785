
export class Sorting {
    public static sort(value: Array<any>, column: string, ascending: boolean, dataType: SortingColumnDataType = 0) {
        if (ascending == null)
            ascending = true;

        // no array
        if (!value || value.length <= 1) {
            return value;
        }
        if (!column || column === '') {
            if (ascending) {
                return value.sort();
            }
            else {
                return value.sort().reverse();
            }
        }

        value = value.sort((a, b) => {
            if (dataType == 1) { // number
                return this.sortByNumber(a, b, column)
            }
            else if (dataType == 2) { // moment
                return this.sortByDate(a, b, column);
            }
            else {
                return this.sortByText(a, b, column);
            }
        });
        if (ascending) {
            return value;
        }
        else {
            return value.reverse();
        }
    }

    private static sortByText(a: any, b: any, column: string, ascending: boolean = true) {
        let aObj: string = String(a[column] ? a[column] : "").toLowerCase();
        let bObj: string = String(b[column] ? b[column] : "").toLowerCase();
        if (aObj) {
            aObj = aObj.toLocaleLowerCase();
        }
        if (bObj) {
            bObj = bObj.toLocaleLowerCase();
        }
        if (ascending) {
            return aObj.localeCompare(bObj);
        }
        else {
            return bObj.localeCompare(aObj);
        }
    }

    private static sortByNumber(a: any, b: any, column: string, ascending: boolean = true) {
        let aObj: number = a[column];
        let bObj: number = b[column];
        if (ascending) {
            return aObj < bObj ? -1 : aObj === bObj ? 0 : 1
        }
        else {
            return aObj > bObj ? -1 : aObj === bObj ? 0 : 1
        }
    }

    private static sortByDate(a: any, b: any, column?: string, ascending: boolean = true) {
        const aObj: moment.Moment = a[column];
        const bObj: moment.Moment = b[column];

        if (!aObj && !bObj) {
            return 0;
        }
        else if (!aObj) {
            return 1;
        }
        else if (!bObj) {
            return -1;
        }
        if (ascending) {
            return aObj.isBefore(bObj) ? 1 : aObj.isSame(bObj) ? 0 : -1;
        }
        else {
            return bObj.isBefore(aObj) ? 1 : aObj.isSame(bObj) ? 0 : -1;
        }
    }

    public static sortByTwoProperties(collection: Array<any>, mainProperty: string, secondaryProperty: string, ascending: boolean, mainPropertyType: SortingColumnDataType = 0, secondaryPropertyType: SortingColumnDataType = 0) {

        // no array
        if (!collection || collection.length <= 1) {
            return;
        }

        collection = collection.sort((a, b) => {
            let result = this.compareItems(a, b, mainProperty, ascending, mainPropertyType);
            if (result == 0 && secondaryProperty) {
                result = this.compareItems(a, b, secondaryProperty, true, secondaryPropertyType);
            }
            return result;
        })
    }

    private static compareItems(a: any, b: any, property: string, ascending: boolean, propertyType: SortingColumnDataType) {
        if (propertyType == 1) { // number
            return this.sortByNumber(a, b, property, ascending)
        }
        else if (propertyType == 2) { // moment
            return this.sortByDate(a, b, property, ascending);
        }
        else {
            return this.sortByText(a, b, property, ascending);
        }
    }
}

export enum SortingColumnDataType {
    string = 0,
    number = 1,
    date = 2
}
