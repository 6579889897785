<div #itemsGroupToClick class="db-details-navigation">
    <div class="db-details-nav-list">
        <ng-scrollbar class="sagra-scrollbar" [autoHeightDisabled]="true" [track]="'vertical'">
            <div class="wideList">
                <ng-container *ngFor="let group of navGroups; let first=first;">
                    <div class="db-details-nav-separator" *ngIf="!first && group.name != null && group.name.length > 0"></div>
                    <div class="db-details-nav-header" *ngIf="group.name != null && group.name.length > 0">{{group.name}}</div>
                    <ng-container *ngFor="let item of group.items">
                        <div class="db-details-nav-item" *ngIf="!item.hidden" (click)="navigationItemClick($event, item)" [ngClass]="{'selected': item.id==selectedItem, 'disabled': item.disabled}">
                            <span class="db-details-nav-item-icon icon {{item.iconClass}}"></span>
                            <div class="db-details-nav-item-text">{{item.name}}</div>
                            <span class="db-details-nav-item-arrow icon icon-keyboard_arrow_right"></span>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="db-details-nav-filters" *ngIf="showFilters">
                    <database-list-filters [filters]="filters" (filterChanged)="onFilterChanged($event)"></database-list-filters>
                </div>
            </div>
            <div class="narrowList">
                <ng-container *ngFor="let group of navGroups; let first=first">
                    <div class="db-details-nav-separator" *ngIf="!first && group.name != null && group.name.length > 0"></div>
                    <ng-container *ngFor="let item of group.items">
                        <div class="db-details-nav-tile"
                             *ngIf="!item.hidden"
                             [ngClass]="{'selected': item.id==selectedItem, 'disabled': item.disabled}"
                             sagraTooltip [sagraTooltipModel]="item.tooltip"
                             (click)="navigationItemClick($event, item)"
                             >
                            <div class="db-details-nav-tile-icon icon {{item.iconClass}}"></div>
                            <div class="db-details-nav-tile-text">{{item.shortName || item.name}}</div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-scrollbar>
    </div>
</div>
