import { ViewChild } from "@angular/core";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { SagraInputComponent, SagraInputOptionsModelInterface } from "@sagra/sagra-input";
import { SagraModalOptionsInterface } from "@sagra/sagra-modal";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Subject } from "rxjs";
import { Subscription } from "rxjs";
import { TargetingGroup } from "../../../models/targeting/targetingGroup.model";
import { TargetingModalState } from "../../../models/targetingModalState.model";
import { DialogsService } from "../../../services/dialogsService/dialogs.service";

@Component({
    selector: 'manage-targeting-modal',
    templateUrl: './manage-targeting-modal.component.html',
    styleUrls: ['./manage-targeting-modal.component.scss']
})

export class ManageTargetingModalComponent extends SagraTranslatable implements OnChanges {

    //#region Init

    @ViewChild('input') inputComponent: SagraInputComponent;

    public show: boolean = false;
    public inputValue: string = "";
    public inputRegex = new RegExp('^[A-Za-z0-9 ]+$');
    public targetingGroupNameMaxLength: number = 20;
    public title: string = "";
    public modalState: TargetingModalState;
    private subscriptions = new Subscription();

    public options: SagraModalOptionsInterface;
    public inputOptions: SagraInputOptionsModelInterface

    private cancelSubject: Subject<any> = new Subject<any>();

    constructor(public translationService: SagraTranslationService, private dialogsService: DialogsService) {
        super(translationService);

        this.inputOptions = {
            clearButton: true,
            characterLimit: this.targetingGroupNameMaxLength,
            validationRegex: this.inputRegex,
            invalidText: this.L('IncorrectValue'),
            isRequired: true,
            returnInvalidValue: true
        }

        this.options = {
            title: this.L('New_client'),
            maxHeight: '700px',
            buttons: [],
            closeOnEscapeHandler: this.cancelSubject,
            maxWidth: '400px',
            minWidth: '400px',
            fixScrollPadding: false
        }

        this.dialogsService.getTargetingModalState().subscribe({
            next: data => {
                this.modalState = data;
                this.show = data.state;

                this.title =  data.groupForLocation ? this.L('TargetingGroupForLocation') : this.L('TargetingGroupForStaff');

                if (data.targetingGroup != null) {
                    this.inputValue = data.targetingGroup.name;
                }
                else {
                    this.inputValue = "";
                }
            }
        });

        this.subscriptions.add(this.cancelSubject.asObservable().subscribe(
            x => this.onCancelClicked(null)
        ));
        
    }

    ngOnChanges(change: SimpleChanges) {
    }

    //#endregion

    public save() {
        if (!this.inputComponent.validate())
            return;

        //this.showLoader = true;

        if (this.modalState.targetingGroup) {
            this.modalState.targetingGroup.name = this.inputValue;
            if (this.modalState.editTargetingGroupHandler != null) {
                this.modalState.editTargetingGroupHandler.next(this.modalState.targetingGroup);
            }
        } else {
            if (this.modalState.addTargetingGroupHandler != null) {
                this.modalState.addTargetingGroupHandler.next(this.inputValue);
            }
        }
    }

    public delete() {
        //this.showLoader = true;
        if (this.modalState.deleteTargetingGroupHandler != null) {
            this.modalState.deleteTargetingGroupHandler.next(this.modalState.targetingGroup);
        }
    }

    public closeModal() {
        this.show = false;
    }

    public onCancelClicked(event) {
        this.closeModal();
    }
}

