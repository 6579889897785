import { Inject, Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { RepositoryListData } from "../../models/repository/repositoryListData.model";
import { catchError, map } from "rxjs/operators";
import { RepositoryAssignRequestItem } from "../../models/repository/repositoryAssignRequestItem.model";
import { RepositoryAssignResult } from "../../models/repository/repositoryAssignResult.model";
import { SelectedItem } from "../../models/selectedItem.model";
import { FeedGroup } from "../../models/repository/feedGroup.model";
import { SagraTranslationService } from "@sagra/sagra-translation";
import { ActivityUse } from "src/app/models/repository/activityUse.model";

@Injectable()
export class RepositoryService {
    private apiUrl: string;

    constructor(private http: HttpClient,
        public translationService: SagraTranslationService,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    getElementsList(method: string, portalName: string): Observable<RepositoryListData[]> {
        return this.http.post<RepositoryListData[]>(method, { Context: portalName }).pipe(
            map(data => {
                for (let item of data) {
                    if (item.status == null)
                        item.status = 0;
                }
                return data;
            })
        );
    }

    assignElements(method: string, tenant: string, itemsList: SelectedItem[], selectedFeedGroups: number[]): Observable<string> {
        let assignRequest: RepositoryAssignRequestItem[] = [];

        for (let act of itemsList) {
            if ((act.selected != act.originalSelected) || act.editable != act.originalEditable) {
                assignRequest.push(new RepositoryAssignRequestItem(act.id, act.guid, act.status, act.editable, act.selected));
            }
        }
        if (assignRequest.length == 0)
            return of(null);

        return this.http.post(method, { dbName: tenant, assignItems: assignRequest, feedGroups: selectedFeedGroups }, { responseType: 'text' });
    }

    //unAssignElements(method: string, tenant: string, itemsList: SelectedItem[], selectedFeedGroups: number[]): Observable<string> {
    //    let unAssignRequest: RepositoryAssignRequestItem[] = [];

    //    for (let act of itemsList) {
    //        if ((act.selected != act.originalSelected) || act.editable != act.originalEditable) {
    //            unAssignRequest.push(new RepositoryAssignRequestItem(act.id, act.guid, act.status, act.editable, act.selected));
    //        }
    //    }
    //    if (unAssignRequest.length == 0)
    //        return of(null);

    //    return this.http.post(method, { dbName: tenant, assignItems: unAssignRequest, feedGroups: selectedFeedGroups }, { responseType: 'text' });
    //}

    assignRoles(tenant: string, itemsList: number[]): Observable<string> {

        return this.http.post("api/Repository/AssignRoles", { dbName: tenant, assignItems: itemsList }, { responseType: 'text' });
    }

    getGroupsList(tenant: string): Observable<FeedGroup[]> {
        let observable = this.http.post<FeedGroup[]>(this.apiUrl + 'api/Repository/GetGroupsList', { dbName: tenant }).pipe(map(items => {
            for (let group of items) {
                if (group.defaultGroup) {
                    group.name = group.name + " (" + this.translationService.translate('default') + ")";
                }
            }            
            return items;
        }));
        return observable;
    }

    getActivityUseList(tenant: string, activityPrimeTypeGuid: string): Observable<ActivityUse[]> {
        let observable = this.http.post<ActivityUse[]>(this.apiUrl + 'api/Repository/GetActivityUseList', { dbName: tenant, guid: activityPrimeTypeGuid });
        return observable;
    }

    getProcessesUseList(tenant: string, guid: string): Observable<ActivityUse[]> {
        let observable = this.http.post<ActivityUse[]>(this.apiUrl + 'api/Repository/GetProcessesUseList', { dbName: tenant, guid: guid });
        return observable;
    }
}
