<div class="hierarchy-tree-component">  
    <div class="ht-row">
        <div class="ht-expand" [ngClass]="{'collapsed': !expanded}">
            <span class="icon icon-group_less" [hidden]="currentHierarchyItem.subHierarchies == null" (click)="expandItem()"></span>
        </div>
        <div class="ht-text" [ngClass]="{'inactive': currentHierarchyItem.deleted}" (click)="onEditHierarchyClick()">{{currentHierarchyItem.levelName}}: {{currentHierarchyItem.name}}</div>
        <div class="ht-add"  [ngClass]="{'inactive': hideAdding}" customTooltip [customTooltipOptions]="tooltipOptions">
            <span class="sgf-link-primary" [ngClass]="{'disabled': currentHierarchyItem.endLevel}" (click)="onAddHierarchyClick()">
                <span class="icon icon-flow-cascade"></span>{{L('Add')}}
            </span>
        </div>
        <div class="ht-active">
            <sagra-checkbox *ngIf="!showLoader"
                            [state]="activateCheckModel"
                            [labelText]="checkboxItem.labelText"
                            [labelPosition]="checkboxOptions.labelPosition"
                            [className]="checkboxActivate"
                            [checkboxsize]="checkboxOptions.checkboxsize"
                            [clickable]="checkboxOptions.clickable"
                            [singleLineLabel]="checkboxOptions.singleLineLabel"
                            [disabled]="checkboxItem.disabled"
                            (update)="onActivateCheckboxClick($event)"></sagra-checkbox>
            
            <div class="element-inprogress-status" *ngIf="showLoader">
                <div class="loader"><sagra-loader [options]="loaderOptions"></sagra-loader></div>
                <div class="text">{{L('DURINGSHARING')}}</div>
            </div>
        </div>
    </div>
    <div class="ht-sub-elements" [@hidegroup]="expanded ? 'open' : 'closed'">
        <hierarchy-tree [tenant]="tenant" [hierarchyItem]="item" [hierarchyRoot]="rootHierarchyItem" *ngFor="let item of currentHierarchyItem.subHierarchies"></hierarchy-tree>
    </div>
</div>
