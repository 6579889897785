<div class="h5-tables-component">
    <div class="h5-tables-content" [@resizeList]="detailsPanelVisible ? 'end' : 'begin'">
        <tabpage-header [title]="headerTitle"
                        [actionButtonVisible]="false"
                        [searchEnabled]="true"
                        [historyButtonVisible]="true"
                        [showBreadcrumb]="true"
                        [counterText]="counterText"
                        (searchTextChanged)="onSearchTextChanged($event)"
                        (historyButtonClicked)="onHistoryButtonClicked($event)">
        </tabpage-header>
        <div class="repository-component-body" *ngIf="firstDownloadingData || (filteredTables != null && filteredTables.length > 0)">
            <div class="h5-tables-list-header">
                <div class="h5-tables-list-header-column sort name" [ngClass]="{'sorting': sortingColumn=='name'}" (click)="sortColumn('name')">
                    {{L('Name')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="h5-tables-list-header-column history">
                </div>
            </div>
            <div class="h5-tables-list">
                <cdk-virtual-scroll-viewport #virtualScroll class="virtualScroll" [itemSize]="50" [minBufferPx]="350" [maxBufferPx]="500">
                    <div class="h5-tables-list-row"
                         *cdkVirtualFor="let item of filteredTables"
                         [ngClass]="{'selected': selectedTable != null && item.guid==selectedTable.guid}"
                         (click)="onListItemClick(item)">
                        <div class="h5-tables-list-column name"><span class="center-vert">{{item.name}}</span></div>
                        <div class="h5-tables-list-column history">
                            <span class="center-vert icon icon-restore sgf-link-light" title="{{L('Operation_history')}}" (click)="onHistoryListItemClick(item); $event.stopPropagation();"></span>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
        <div class="container-for-placeholder" *ngIf="!firstDownloadingData && (filteredTables == null || filteredTables.length == 0)">
            <sagra-placeholder [title]="placeholderOptions.title"
                               [description]="placeholderOptions.description"
                               [button]="placeholderOptions.button"
                               [image]="placeholderOptions.image">
            </sagra-placeholder>
        </div>
    </div>
    <div class="h5-tables-details-panel" [@showPanel]="detailsPanelVisible ? 'end' : 'begin'" *ngIf="detailsPanelVisible">
        <div class="htdp-container">
            <div class="htdp-header">
                <div class="htdp-header-title">{{selectedTable.name}}</div>
                <div class="htdp-header-close icon icon-btn_close sgf-link-secondary" (click)="onClosePanelClick($event)"></div>
            </div>
            <div class="htdp-content">
                <ng-scrollbar class="sagra-scrollbar">
                    <!--<div class="htdp-section">
                        <div class="htdp-section-title">{{L('Last_entry')}}</div>
                        <div class="htdp-section-value">12.03.2020 17:34</div>
                    </div>-->
                    <div class="htdp-relations" *ngIf="tableUsedPlaces != null && tableUsedPlaces.length > 0">
                        <div class="htdp-rel-tenant" *ngFor="let tenant of tableUsedPlaces">
                            <div class="htdp-rel-tenant-name" *ngIf="isSolutions">{{tenant.tenantName}}</div>
                            <!--<div class="htdp-rel-tenant-label">{{L('Last_entry')}}:</div>
                            <div class="htdp-rel-tenant-date">12.03.2020 17:34</div>-->
                            <div class="htdp-rel-separator" *ngIf="isSolutions"></div>
                            <div class="htdp-rel-source" *ngFor="let placeType of tenant.tableUsedPlaces">
                                <div class="htdp-rel-source-type">{{getPlaceTypeName(placeType.placeType)}}</div>
                                <div class="htdp-rel-source-name" *ngFor="let place of placeType.places">{{place}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="htdp-no-relations" *ngIf="false">{{L('No_table_relations')}}</div>
                </ng-scrollbar>
            </div>
            <div class="htdp-footer">
                <div class="htdp-delete-info" *ngIf="tableUsedPlaces != null && tableUsedPlaces.length > 0  && (!selectedTable.global || isSolutions)">
                    <span class="icon icon-tootltip_info_outline"></span>
                    <div>{{L('H5_delete_btn_info')}}</div>
                </div>
                <div class="htdp-btn-delete sgf-btn-danger" *ngIf="(tableUsedPlaces == null || tableUsedPlaces.length == 0) && (!selectedTable.global || isSolutions)" (click)="onDeleteButtonClick($event)">
                    {{L('Delete_table')}}
                </div>
                <div class="htdp-delete-info" *ngIf="selectedTable.global && !isSolutions">
                    <span class="icon icon-tootltip_info_outline"></span>
                    <div>{{L('H5_delete_btn_info_global')}}</div>
                </div>
            </div>
        </div>
        <sagra-loader [options]="sagraLoaderOptions" *ngIf="showLoaderDetails"></sagra-loader>
    </div>
    <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" [options]="sagraLoaderOptions" *ngIf="showLoader"></sagra-loader>
</div>
