<div class="db-list-component">
    <div class="db-list-search">
        <sagra-input class="search-input" [(model)]="searchText" [options]="searchListInputOptions" (update)="onSearchTextChange($event)"></sagra-input>
    </div>
    <div class="db-list-header" *ngIf="draftsToShowList != null && draftsToShowList.length > 0">
        <div class="db-list-header-column sort name" [ngClass]="{'sorting': sortingColumn=='name'}" (click)="sortColumn('name')">
            <span class="caption">{{L('Client_name')}}</span>
            <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
        </div>
        <div class="db-list-header-column sort person" [ngClass]="{'sorting': sortingColumn=='activatingUser'}" (click)="sortColumn('activatingUser')">
            <span class="caption">{{L('Activating_person')}}</span>
            <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
        </div>
        <div class="db-list-header-column sort status" [ngClass]="{'sorting': sortingColumn=='statusSort'}" (click)="sortColumn('statusSort')">
            <span class="caption">{{L('Status')}}</span>
            <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
        </div>
        <div class="db-list-header-column sort date" [ngClass]="{'sorting': sortingColumn=='dateSort'}" (click)="sortColumn('dateSort')">
            <span class="caption">{{L('Last_modification')}}</span>
            <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
        </div>
    </div>
    <div class="db-list-rows" *ngIf="draftsToShowList != null && draftsToShowList.length > 0">
        <div class="db-list-row" *ngFor="let item of draftsToShowList" (click)="showDetails(item)" [sagraTooltip]="tooltipOptions" [sagraTooltipModel]="item.tooltipModel">
            <div class="db-list-column name"><span class="center-vert">{{item.name}}</span></div>
            <div class="db-list-column person"><span class="center-vert">{{item.activatingUser}}</span></div>
            <div class="db-list-column status">
                <sagra-pill class="center-vert" [type]="item.status == 2 ? 2 : 3" [text]="statusText(item)" [pillClass]="statusClass(item)"></sagra-pill>
            </div>
            <div class="db-list-column date"><span class="center-vert">{{item.modifyDate}}</span></div>
        </div>
    </div>
    <sagra-placeholder class="list-placeholder" *ngIf="!downloadingData && (draftsToShowList == null || draftsToShowList.length == 0)"
                       [title]="placeholderOptions.title"
                       [description]="placeholderOptions.description"
                       [button]="placeholderOptions.button"
                       [image]="placeholderOptions.image">
    </sagra-placeholder>
</div>

