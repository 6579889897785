import * as moment from 'moment';
import { Component, ViewChild, ElementRef, OnInit, Renderer2, OnDestroy, Inject } from '@angular/core';
import { SagraTranslationService, SagraTranslatable } from '@sagra/sagra-translation';
import { SagraDialogService, SagraDialogOptionsInterface } from '@sagra/sagra-dialog';
import { SagraToastsOptionsInterface, SagraToastsService, SagraToastsModelInterface, SagraToastsType } from '@sagra/sagra-toasts';
import { Title } from '@angular/platform-browser';
import { MessageBusService } from './services/messageBusService/messageBusService';
import { ContextService } from './services/contextService/context.service';
import { zip, Subscription, filter, takeUntil, Subject } from 'rxjs';
import { SignalRService } from './services/signalrService/singnalr.service';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { Draft } from './models/database/draft.model';
import { DialogFeedGroupsOptionsInterface } from './logic/dialogFeedGroupsOptions.interface';
import { DialogAgreementsOptionsInterface } from './logic/dialogAgreementsOptions.interface';
import { DialogEditHierarchyOptionsInterface } from './logic/dialogEditHierarchyOptions.interface';
import { DateAdapter } from '@angular/material/core';
import { SagraLoaderOptionsInterface, SagraLoaderType } from '@sagra/sagra-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogSelectCacheVersionOptionsInterface } from './logic/dialogSelectCacheVersionOptions.interface';
import { MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { ConfigurationService } from './services/configurationService/configuration.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [trigger('hidenotifications', [
        state('open', style({ right: '0%', width: '100%' })),
        state('closed', style({ right: '-100%', width: '0%' })),
        transition('open => closed', [animate('0.4s')])
    ])]
})
export class AppComponent extends SagraTranslatable implements OnInit, OnDestroy {
    @ViewChild('iframeTokenRefresh') iframeTokenRefresh: ElementRef;
    @ViewChild('notificationPanel') notificationPanel: ElementRef;
    private subscriptions = new Subscription();
    public dialogOptions: SagraDialogOptionsInterface;
    public toastsOptions: SagraToastsOptionsInterface;
    public appName: string;
    public refreshToken: boolean = false;
    public hideHeader: boolean = false;
    public showLoader: boolean = false;
    public showLoaderOnStart: boolean = true; // odpowiada za pokazanie loadera na starcie aplikacji
    public showNotification: boolean = false;
    public dialogNewVisible: boolean = false;
    public dialogNewDraft: Draft;
    public dialogNewReadOnly: boolean;
    public selectVersionDialogData: any;
    public showSelectVersion: boolean;

    public dialogFeedGroupsOptions: DialogFeedGroupsOptionsInterface;
    public dialogAgreementsOptions: DialogAgreementsOptionsInterface;
    public dialogEditHierarchyOptions: DialogEditHierarchyOptionsInterface;
    public loaderOptions: SagraLoaderOptionsInterface;

    private readonly _destroying$ = new Subject<void>();
    public loggedIn: boolean = false;
    private logoutSubject: Subject<any> = new Subject<any>();
    constructor(public translationService: SagraTranslationService,
        private dialogService: SagraDialogService,
        private appConfig: ConfigurationService,
        //private toastService: SagraToastsService,
        private titleService: Title,
        private messageService: MessageBusService,
        private contextService: ContextService,
        private signalrService: SignalRService,
        private dateAdapter: DateAdapter<any>,
        private router: Router,
        private routerActivated: ActivatedRoute,
        private authService: MsalService,
        private broadcastService: MsalBroadcastService,
        @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfiguration,
        @Inject('BASE_URL') baseUrl: string
    ) {
        super(translationService);

        this.appName = this.L('PageTitle');
        this.titleService.setTitle(this.L('PageTitle'));

        this.dateAdapter.setLocale('pl');

        this.toastsOptions = { moduleId: 1, type: SagraToastsType.toast };

        this.subscriptions.add(this.messageService.getRefreshToken().subscribe(() => {
            this.refreshTokenAction();
        }));

        this.subscriptions.add(this.dialogService.getAlert().subscribe(dialogOptions => {
            this.dialogOptions = dialogOptions;
        }));

        //this.subscriptions.add(this.toastService.getToast().subscribe(toastOptions => {
        //  this.toastOptions = toastOptions;
        //}));

        this.subscriptions.add(this.messageService.getShowLoader().subscribe((show) => {
            this.showLoader = show;
        }));

        this.subscriptions.add(this.messageService.getNotificationPanel().subscribe((show) => {
            this.showNotificationPanel(show);
        }));

        this.subscriptions.add(this.messageService.getShowDialogNewDatabase().subscribe((draft) => {
            this.showDialogNewDatabase(true, draft);
        }));

        this.subscriptions.add(this.messageService.getShowDialogFeedGroups().subscribe((dialogOptions) => {
            this.showDialogFeedGroups(dialogOptions);
        }));

        //this.subscriptions.add(this.messageService.getShowDialogAgreements().subscribe((dialogOptions) => {
        //    this.showDialogAgreements(dialogOptions);
        //}));
        this.subscriptions.add(this.messageService.getShowDialogEditHierarchy().subscribe((dialogOptions) => {
            this.showDialogEditHierarchy(dialogOptions);
        }));
        this.subscriptions.add(this.messageService.getShowDialogSelectCacheVersion().subscribe((dialogOptions) => {
            this.showDialogSelectCacheVersion(dialogOptions);
        }));

        this.loaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                borderWidth: 8,
                color: '#FFFFFF',
                size: 60
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }

        const rawPath = window.location.pathname;
        const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
        let currentUrlPath = rawPath.slice(1).split(urlDelimitators)[0];

        if (currentUrlPath == "history") {
            this.hideHeader = true;
        }
    }

    async ngOnInit(): Promise<void> {

        this.broadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            })

        this.logoutSubject.subscribe(() => { this.logout() });

        await this.appConfig.loadConfig();
        moment.locale(this.translationService.getLocale());

        let tasks = zip(
            this.contextService.getUserData(),
            //this.contextService.getUserGRRPermissions()
        );

        this.subscriptions.add(tasks.subscribe(result => {
            this.showLoaderOnStart = false;
            //this.messageService.setUserProfile(result[0]);
            this.contextService.setUserModel(result[0]);

            //this.contextService.setCurrentGRRPermissions(result[1]);

            this.startSignalr(result[0].userEmail);
        }));
    }

    setLoginDisplay() {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount() {
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
        activeAccount = this.authService.instance.getActiveAccount();
        //this.userProfile = {
        //  userName: activeAccount.name,
        //  userEmail: activeAccount.username,
        //  links: [
        //    {
        //      handlerType: SagraProfileDropdownLinkType.javascript,
        //      handler: this.logoutSubject,
        //      name: this.L('Logout')
        //    }]
        //};
    }

    logout() {
        this.authService.logoutRedirect();//logout();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public closeContextMenu() {
        this.messageService.closeContextMenuEvent();
    }

    public finish() {
        this.refreshToken = false;
    }

    public onDialogNewClose(value) {
        this.showDialogNewDatabase(false, null);
    }

    private refreshTokenAction() {
        this.refreshToken = true;
    }

    private startSignalr(userName: string) {
        this.signalrService.startConnection(userName);
        this.signalrService.initializeEvents(this.signalrService.hubConnectionProd);
        this.signalrService.initializeEvents(this.signalrService.hubConnectionDev);
    }

    private showNotificationPanel(show: boolean) {
        if (this.notificationPanel != null) {
            if (show) {
                this.showNotification = true;
            }
            else {
                this.showNotification = false;
            }
        }
    }

    showDialogNewDatabase(show: any, draft: Draft) {
        this.dialogNewDraft = draft;
        if (draft != null) {
            this.dialogNewReadOnly = (draft.status != 1);
        }
        this.dialogNewVisible = show;
    }

    showDialogFeedGroups(dialogOptions: DialogFeedGroupsOptionsInterface) {
        this.dialogFeedGroupsOptions = dialogOptions;
    }

    showDialogAgreements(dialogOptions: DialogAgreementsOptionsInterface) {
        this.dialogAgreementsOptions = dialogOptions;
    }

    showDialogEditHierarchy(dialogOptions: DialogEditHierarchyOptionsInterface) {
        this.dialogEditHierarchyOptions = dialogOptions;
    }

    showDialogSelectCacheVersion(dialogOptions: DialogSelectCacheVersionOptionsInterface) {
        this.showSelectVersion = dialogOptions.show;
        this.selectVersionDialogData = dialogOptions;
    }
}
