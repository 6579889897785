import { Pipe, PipeTransform } from "@angular/core";
import { DatabaseData } from "../models/database/database.model";

@Pipe({
    name: 'databaseListSearchFilter',
    pure: false
})

export class DatabaseListSearchFilter implements PipeTransform {
    transform(items: DatabaseData[], search: string): DatabaseData[] {
        if (!items || !search) {
            return items;
        }

        return items.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
}
