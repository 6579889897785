import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { SagraProfileDropdownModelInterface, SagraProfileDropdownLinkType } from "@sagra/sagra-profile-dropdown";
import { UserModel } from "../../models/user.model";
import { Subject, Subscription } from "rxjs";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { ContextService } from "../../services/contextService/context.service";
import { SagraTabInterface, SagraTabsOptionsInterface } from "@sagra/sagra-tabs";
import { Router } from "@angular/router";
import { NavigationItemEnum } from "../../models/navigationItem.model";
import { BIQService } from "../../services/biqService/biq.service";
import { StaviatorUserContext } from "../../models/staviatorUserContext.model";

@Component({
    selector: 'header-layout',
    templateUrl: './header-layout.component.html',
    styleUrls: ['./header-layout.component.scss']
})

export class HeaderLayoutComponent extends SagraTranslatable implements OnInit, OnDestroy {
    @Input() appName: string;
    private subscriptions = new Subscription();
    public userProfile: SagraProfileDropdownModelInterface;
    public toggleLanguageSubject: Subject<any> = new Subject<any>();
    public showNotificationBell: boolean;
    public activeTab: number = 0;
    public tabsOptions: SagraTabsOptionsInterface;
    public tabs: Array<SagraTabInterface>;
    private staviatorUserContext: StaviatorUserContext;

    constructor(public transaltionService: SagraTranslationService,
        private messageBusService: MessageBusService,
        private contextService: ContextService,
        private biqService: BIQService,
        private router: Router) {
        super(transaltionService);

        this.toggleLanguageSubject.asObservable().subscribe(x => this.toggleLanguage());

        this.userProfile = new UserModel();

        this.userProfile.links = [{
            handlerType: SagraProfileDropdownLinkType.javascript,
            handler: this.toggleLanguageSubject,
            name: this.L('ChangeLanguage')
        }, {
            name: this.L('Logout'),
            url: "/Account/SignOut?returnUrl=/",
            handlerType: SagraProfileDropdownLinkType.url
        }];

        this.messageBusService.getLogoutUrl().subscribe(path => {
            let linkLogOut = this.userProfile.links.find(x => x.handlerType == SagraProfileDropdownLinkType.url && x.url.indexOf('SignOut') > 0);
            linkLogOut.url = "/Account/SignOut?returnUrl=" + path;
        });
    }

    ngOnInit(): void {
        let userModel = this.contextService.getUserModel();
        //this.messageBusService.getUserProfile().subscribe(userModel => {
        this.userProfile.userEmail = userModel.userEmail;
        this.userProfile.userName = userModel.userName;

        this.messageBusService.getNotificationBell().subscribe(show => {
            this.showNotificationBell = show;
        });

        this.subscriptions.add(this.router.routerState.root.queryParams.subscribe(params => {
            if (params.tab && ['1', '2', '3'].includes(params.tab)) {
                this.activeTab = params.tab;
            }
            else {
                this.activeTab = 0;
            }
        }));

        this.prepareTabs();

        this.getStaviatorUserContext();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onNotificationClick() {
        this.messageBusService.showNotificationPanel(true);
    }

    getStaviatorUserContext() {
        this.subscriptions.add(this.biqService.getStaviatorUserContext().subscribe({
            next: data => {
                this.staviatorUserContext = data;
                this.prepareTabs();
            },
            error: error => {
            }
        }));
    }

    public toggleLanguage() {
        var locale = this.translationService.getLocale();
        if (locale == "pl-PL") {
            this.translationService.setLocale("en-GB");
        } else {
            this.translationService.setLocale("pl-PL");
        }

        window.location.reload();
    }

    updateTab(event: number) {
        this.activeTab = event;

        if (this.activeTab == 0) {
            this.router.navigate(['/'], { queryParams: { tab: 0 } });
        }
        else if (this.activeTab == 1) {
            this.router.navigate(['/functions'], { queryParams: { tab: 1, nav: NavigationItemEnum.ActivitiesOperations } });
        }
        else if (this.activeTab == 2) {
            this.router.navigate(['/cache'], { queryParams: { tab: 2 } });
        }        
        else if (this.activeTab == 3) {
            this.router.navigate(['/monitoring'], { queryParams: { tab: 3 } });
        }
    }

    navigateHome() {
        this.router.navigate(["/"]);
    }

    prepareTabs() {
        this.tabs = [{ id: 0, name: this.L("Clients") },
            { id: 1, name: this.L("Functionalities") },
            { id: 3, name: this.L("Monitoring") }];

        if (this.staviatorUserContext != null && this.staviatorUserContext.showCacheList) {
            this.tabs.push({ id: 2, name: this.L("Cache_managing") });
        }
    }
}
