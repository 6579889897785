import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { extend } from "webdriver-js-extender";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { trigger, state, transition, style, animate } from "@angular/animations";
import { NotificationsTenant } from "../../models/notifications/notificationsTenant";
import { Router } from "@angular/router";
import { NavigationItemEnum } from "../../models/navigationItem.model";
import { Subscription } from "rxjs";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";

@Component({
    selector: "notification-panel",
    templateUrl: "./notification-panel.component.html",
    styleUrls: ["./notification-panel.component.scss"],
    animations: [trigger('showNotifications', [
        state('show', style({ right: '0%' })),
        state('hide', style({ right: '-100%' })),
        transition('show => hide', [animate('0.4s ease-in-out')]),
        transition('hide => show', [animate('0.4s ease-in-out')])
    ]),
        trigger('showBackground', [
            state('show', style({ opacity: '0.3' })),
            state('hide', style({ opacity: '0.0' })),
            //transition('show => hide', [animate('0.4s ease-in-out')]),
            transition('hide => show', [animate('0.4s ease-in-out')])
        ])]
})

export class NotificationPanelComponent extends SagraTranslatable implements OnInit, OnDestroy {
    @Input() showNotification: boolean;

    private subscriptions = new Subscription();
    public notificationsList: NotificationsTenant[];
    public sagraLoaderOptions: SagraLoaderOptionsInterface;

    constructor(public translationService: SagraTranslationService, private messageService: MessageBusService, private router: Router) {
        super(translationService);

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 20,
                borderWidth: 4
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }
    }

    ngOnInit(): void {
        this.subscriptions.add(this.messageService.getRefreshNotifications().subscribe(data => {
            //console.log("SignalR: call RefreshNotificationList");
            this.notificationsList = data;
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onCloseClick() {
        this.messageService.showNotificationPanel(false);
    }

    goToModuleClick(dbName: string, module: string) {
        this.onCloseClick();

        let item: number;
        switch (module) {
            case "Processes":
                item = NavigationItemEnum.Processes
                break;
            case "Activity": // Czynności i działania
                item = NavigationItemEnum.ActivitiesOperations;
                break;
            case "TWR":
                item = NavigationItemEnum.TWR;
                break;
            case "EmigoRole":
                item = NavigationItemEnum.Roles;
                break;
            case "EmigoDataSheet":
                item = NavigationItemEnum.Sheets;
                break;
            case "ActivateRegister":
                item = NavigationItemEnum.Register;
                break;
            case "Districts":
                item = NavigationItemEnum.Districts;
                break;
        }
        this.router.navigate(['/dbinfo'], { queryParams: { id: dbName, nav: item } });
    }
}
