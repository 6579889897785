<div class="repository-component">
    <div class="repository-component-content" [ngClass]="{'showFilters': showFilters}">
        <tabpage-header [title]="headerTitle"
                        [subTitle]="headerSubTitle"
                        [actionButtonTitle]="actionButtonText"
                        [actionButtonVisible]="true"
                        [actionButtonDisabled]="toShareCounter == 0"
                        [historyButtonVisible]="true"
                        [searchEnabled]="true"
                        [showBreadcrumb]="true"
                        [counterText]="counterText"
                        (searchTextChanged)="onSearchTextChanged($event)"
                        (actionButtonClicked)="onActionButtonClicked($event)"
                        (historyButtonClicked)="onHistoryButtonClicked($event)">
        </tabpage-header>
        <div class="repository-list-filters" *ngIf="dataSource == 2 || dataSource == 3">
            <div class="repository-list-filter">
                <span class="repository-list-filter-caption">{{L('Status')}}</span>
                <sagra-toggle-button [style]="shareToggleBtnOptions.style"
                                     [normalState]="shareToggleBtnOptions.normalState"
                                     [activeState]="shareToggleBtnOptions.activeState"
                                     (click)="applyStatusFilters('SHARED')">
                </sagra-toggle-button>
                <sagra-toggle-button [style]="unshareToggleBtnOptions.style"
                                     [normalState]="unshareToggleBtnOptions.normalState"
                                     [activeState]="unshareToggleBtnOptions.activeState"
                                     (click)="applyStatusFilters('NOTSHARED')">
                </sagra-toggle-button>
            </div>
            <div class="repository-list-filter" *ngIf="dataSource == 2">
                <span class="repository-list-filter-caption">{{L('Typ')}}</span>
                <sagra-toggle-button [style]="h5ToggleBtnOptions.style"
                                     [normalState]="h5ToggleBtnOptions.normalState"
                                     [activeState]="h5ToggleBtnOptions.activeState"
                                     (click)="applyTypeFilters('H5')">
                </sagra-toggle-button>
                <sagra-toggle-button [style]="operationToggleBtnOptions.style"
                                     [normalState]="operationToggleBtnOptions.normalState"
                                     [activeState]="operationToggleBtnOptions.activeState"
                                     (click)="applyTypeFilters('OPERATIONS')">
                </sagra-toggle-button>
                <sagra-toggle-button [style]="otherToggleBtnOptions.style"
                                     [normalState]="otherToggleBtnOptions.normalState"
                                     [activeState]="otherToggleBtnOptions.activeState"
                                     (click)="applyTypeFilters('OTHERS')">
                </sagra-toggle-button>
            </div>
        </div>
        <div class="repository-component-body" *ngIf="firstDownloadingData || (filteredListItems != null && filteredListItems.length > 0)">
            <div class="repository-list-header">
                <div class="repository-list-header-column sort name"
                        [ngClass]="{'sorting': sortingColumn=='name',
                            'with-edit': dataSource==4,
                            'with-type': dataSource==2}"
                        (click)="sortColumn('name')">
                    <div class="column-check" [ngClass]="{'showCheckColumn': showCheckColumn}">
                        <sagra-checkbox class="center-vert"
                                        [state]="allItemsSelected"
                                        [options]="checkboxListOptions"
                                        (update)="selectAllItems($event)"
                                        (click)="$event.stopPropagation()"></sagra-checkbox>
                    </div>
                    {{L('Name')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="repository-list-header-column sort baseType" *ngIf="dataSource==2" [ngClass]="{'sorting': sortingColumn=='baseTypeName'}" (click)="sortColumn('baseTypeName')">
                    {{L('Type')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="repository-list-header-column editable" *ngIf="dataSource==4">
                    {{L('Editable(Sagra)')}}
                </div>
                <div class="repository-list-header-column sort status" [ngClass]="{'sorting': sortingColumn=='sortStatus'}" (click)="sortColumn('sortStatus')">
                    {{L('Status')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="repository-list-header-column history" *ngIf="allowItemHistory">
                </div>
            </div>
            <div #list class="repository-list"
                 [@blinkList]="blinkList ? 'hide' : 'show'" (@blinkList.done)="onEndBlinkAnimation()">
                <cdk-virtual-scroll-viewport #virtualScroll class="virtualScroll" [itemSize]="50" [minBufferPx]="350" [maxBufferPx]="500">
                    <div class="repository-list-row"
                         *cdkVirtualFor="let item of filteredListItems"
                         [ngClass]="{'disableHover': showCheckColumn || ((item.status==2 || item.status==13) && !allowUnShareOnList),
                                    'selected': detailsRepoItem != null && item.id==detailsRepoItem.id,
                                    'noPointer': showCheckColumn || !allowDetailsPanel}"
                         sagraTooltip [sagraTooltipModel]="item.tooltip"
                         (click)="onItemClick(item)">
                        <div class="repository-list-column name"
                             [ngClass]="{'with-edit': dataSource==4,
                                        'with-type': dataSource==2}">
                            <div class="column-check"
                                 [ngClass]="{'showCheckColumn': showCheckColumn, 'pointerCursor': !showCheckColumn || ((item.status != 2 && item.status != 13 && selectUnsharedItems) || ((item.status == 2 || item.status == 13) && selectSharedItems))}"
                                 (click)="selectItem(!isItemSelected(item), item);$event.stopPropagation()">
                                <sagra-checkbox *ngIf="!showCheckColumn || ((item.status != 2 && item.status != 13 && selectUnsharedItems) || ((item.status == 2 || item.status == 13) && selectSharedItems))"
                                                class="center-vert"
                                                [state]="isItemSelected(item)"
                                                [disabled]="(item.status==1 || item.status==11 || (!allowUnShare && item.status == 2))"
                                                [options]="checkboxListOptions"
                                                (update)="selectItem($event, item)"
                                                (click)="$event.stopPropagation()"></sagra-checkbox>
                            </div>
                            <span class="center-vert">{{item.name}}</span>
                        </div>
                        <div class="repository-list-column baseType" *ngIf="dataSource==2">
                            <span class="center-vert">{{item.baseTypeName}}</span>
                        </div>
                        <div class="repository-list-column editable" *ngIf="dataSource==4">
                            <sagra-checkbox class="center-vert" [state]="isItemEditable(item)" [disabled]="(item.status != 2 && !item.selected)" (update)="selectItemEditable($event, item)" [options]="checkboxListOptions"></sagra-checkbox>
                        </div>
                        <div class="repository-list-column status">
                            <sagra-pill class="center-vert" *ngIf="item.status != 0" [type]="item.status == 1 || item.status == 11 ? 2 : 3" [text]="statusTextForPills(item)" [pillClass]="statusClass(item)"></sagra-pill>
                        </div>
                        <div class="repository-list-column history">
                            <span class="center-vert icon icon-restore sgf-link-light sgf-noTooltip" *ngIf="allowItemHistory" title="{{L('Operation_history')}}" (click)="onHistoryListItemClick($event, item)"></span>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
        <div class="container-for-placeholder" *ngIf="!firstDownloadingData && (filteredListItems == null || filteredListItems.length == 0)">
            <sagra-placeholder [title]="placeholderOptions.title"
                               [description]="placeholderOptions.description"
                               [button]="placeholderOptions.button"
                               [image]="placeholderOptions.image">
            </sagra-placeholder>
        </div>
    </div>
    <div class="repository-details-panel" *ngIf="detailsPanelVisible" [@showPanel]="detailsPanelVisible ? 'end' : 'begin'">
        <div class="repository-details-container">
            <div class="repository-details-header">
                <div class="repository-details-header-title">
                    <div class="sub-title">{{detailsRepoItem.baseTypeName}}</div>
                    <div class="main-title">{{detailsRepoItem.name}}</div>
                </div>
                <div class="repository-details-header-close icon icon-btn_close sgf-link-light" (click)="onClosePanelClick($event)"></div>
                <sagra-pill [type]="detailsRepoItem.status == 1 || detailsRepoItem.status == 11 ? 2 : 3" [text]="statusText(detailsRepoItem)" [pillClass]="statusClass(detailsRepoItem)"></sagra-pill>
                <button class="repository-details-btn sgf-btn-primary"
                        [ngClass]="{'disabled': detailsRepoItem.status==1 || detailsRepoItem.status==11}"
                         *ngIf="detailItemsList?.length == 0"
                        (click)="onDetailsShareBtnClick()">
                    <span class="icon" [ngClass]="detailsRepoItem.status == 2 ? 'icon-block' : 'icon-share'"></span>
                    <span>{{detailsRepoItem.status != 2 && detailsRepoItem.status != 13 ? L('Share') : L('Stop_sharing')}}</span>
                </button>
                <div class="repository-details-unshare-info" *ngIf="detailItemsList?.length > 0">
                    <span class="icon icon-notification"></span>
                    <div class="text">{{L('Unshare_details_info')}}</div>
                </div>
                <div class="repository-details-list-title">{{L('Bind_list')}}</div>
            </div>
            <div class="repository-details-content" >
                <ng-scrollbar class="sagra-scrollbar">
                    <ng-container *ngIf="detailItemsList?.length > 0">
                        <ng-container *ngFor="let group of bindingGroups">
                            <ng-template *ngTemplateOutlet="detailsGroup; context: {colExpOptions: colExpOptions, group: group}"></ng-template>
                        </ng-container>
                    </ng-container>
                    <div class="repository-details-list placeholder" *ngIf="!showDetailsLoader && detailItemsList?.length == 0">
                        <sagra-placeholder class=""
                                           [title]="placeholderDetailsListOptions.title"
                                           [description]="placeholderDetailsListOptions.description"
                                           [button]="placeholderDetailsListOptions.button"
                                           [image]="placeholderDetailsListOptions.image">
                        </sagra-placeholder>
                    </div>
                </ng-scrollbar>
            </div>
        </div>
        <sagra-loader class="repository-details-loader"
                      *ngIf="showDetailsLoader"
                      [loaderType]="sagraLoaderOptions.loaderType"
                      [backgroundSetings]="sagraLoaderOptions.backgroundSetings"></sagra-loader>
    </div>
    <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
</div>
<!--<no-access *ngIf="!permission.canRead"></no-access>-->

<ng-template #detailsGroup let-group="group" let-colExpOptions="colExpOptions">
    <ng-container *ngIf="group.items?.length > 0">
        <sagra-collapse-expand [(expanded)]="group.expanded"
                               [animationDuration]="colExpOptions.duration"
                               [animationEasing]="colExpOptions.easing"
                               [expandId]="group.id">
            <div class="repository-details-group" [sagraCollapseExpandButton]="group.id">
                <div class="repository-details-group-indicator icon icon-group_more" [ngClass]="{'expanded': group.expanded}"></div>
                <div class="repository-details-group-icon" [ngClass]="group.iconClass"></div>
                <div class="repository-details-group-caption">{{group.name}}</div>
                <div class="repository-details-group-counter"><span>{{group.items.length}}</span></div>
            </div>
            <div [sagraCollapseExpandContent]="group.id">
                <div class="repository-details-bind-row" *ngFor="let item of group.items">
                    <div class="repository-details-bind-name">{{item.name}}</div>
                    <div class="repository-details-bind-id">ID {{item.id}}</div>
                </div>
            </div>
        </sagra-collapse-expand>
    </ng-container>
</ng-template>
