import { Component, Input, OnDestroy, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { BreadcrumbItem, Breadcrumbs } from "../../models/breadcrumbs.model";
import { BreadcrumbsService } from "./breadcrumbs.service";

@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})

export class BreadcrumbsComponent implements OnDestroy {
    @Input() breadcrumbsId: number;

    public breadcrumbs: Breadcrumbs;
    private subscriptions: Subscription = new Subscription();

    constructor(public breadcrumbsService: BreadcrumbsService) {
        this.subscriptions.add(this.breadcrumbsService.getBreadcrumbsSubject().subscribe((data) => {
            if (data.id == this.breadcrumbsId) {
                this.breadcrumbs = data;
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.breadcrumbs == null) {
            this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs(this.breadcrumbsId);
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onLinkClick(item: BreadcrumbItem) {
        if (item != null) {
            item.handler.next(null);
        }
    }
}
