import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatabaseData } from '../../models/database/database.model';
import { SaveDraftRequest } from '../../models/database/saveDraftRequest.model';
import { Draft } from '../../models/database/draft.model';
import { Server } from '../../models/database/server.model';
import { Currency } from '../../models/database/currency.model';

@Injectable()
export class DatabaseService {
    private apiUrl: string;

    private serverList: Observable<Array<Server>>;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    getDatabaseList(): Observable<DatabaseData[]> {
        let observable = this.http.post<DatabaseData[]>(this.apiUrl + 'api/Database/GetDatabaseList', {});
        return observable;
    }
    getDraftList(): Observable<Draft[]> {
        let observable = this.http.post<Draft[]>(this.apiUrl + 'api/Database/GetDraftList', {});
        return observable;
    }

    getServerList(): Observable<Server[]> {
        if (!this.serverList) {
            this.serverList = this.http.post<Server[]>(this.apiUrl + 'api/Database/GetServerList', {});
        }
        return this.serverList;
    }

    saveDraft(request: SaveDraftRequest): Observable<any> {
        let observable = this.http.post<any>(this.apiUrl + 'api/Database/SaveDraft', request);
        return observable;
    }

    deleteDraft(idDraft: number, user: string): Observable<any> {
        let observable = this.http.post<any>(this.apiUrl + 'api/Database/DeleteDraft', { idDraft: idDraft, userName: user });
        return observable;
    }

    activateDraft(request: SaveDraftRequest): Observable<any> {
        let observable = this.http.post<any>(this.apiUrl + 'api/Database/ActivateDraft', request);
        return observable;
    }

    validateDbName(dbName: string): Observable<string> {
        let observable = this.http.post<any>(this.apiUrl + 'api/Database/ValidateDbName', { dbName: dbName });
        return observable;
    }

    validatePortalName(portalName: string): Observable<string> {
        let observable = this.http.post<any>(this.apiUrl + 'api/Database/ValidatePortalName', { portalName: portalName });
        return observable;
    }

    getPrefix(dbName: string): Observable<string> {
        let observable = this.http.post<any>(this.apiUrl + 'api/Database/GetPrefixTME', { dbName: dbName });
        return observable;
    }

    validatePrefix(dbName: string, prefix: string): Observable<string> {
        let observable = this.http.post<any>(this.apiUrl + 'api/Database/GetPrefixTME', { dbName: dbName, prefix: prefix });
        return observable;
    }

    getDraftsInfo(): Observable<any> {
        let observable = this.http.post<any>(this.apiUrl + 'api/Database/GetDraftsInfo', { });
        return observable;
    } 

    getCurrenciesList(): Observable<Array<Currency>> {
        let observable = this.http.get<Array<Currency>>(this.apiUrl + 'api/Database/GetCurrenciesList');
        return observable;
    }
}
