<div class="winpoint-component">
    <tabpage-header [title]="headerTitle"
                    [actionButtonTitle]="actionButtonTitle"
                    [actionButtonVisible]="true"
                    [actionButtonDisabled]="!actionButtonEnabled"
                    [searchEnabled]="false"
                    [showBreadcrumb]="true"
                    (actionButtonClicked)="onActionButtonClicked($event)">
        >
    </tabpage-header>
    <div class="winpoint-main-switches">
        <div class="winpoint-main-switch">
            <sagra-switch class="dlg-switch" [state]="winPointData.localizationsDB" [options]="localizationsSwitchOptions" [disabled]="true"></sagra-switch>
        </div>
        <div class="winpoint-main-switch">
            <sagra-switch class="dlg-switch" [state]="winPointData.medicalStaffDB" [options]="medicalStaffSwitchOptions" [disabled]="true"></sagra-switch>
        </div>
    </div>
    <div class="winpoint-line-separator"></div>
    <div class="winpoint-content-scroll">
        <ng-scrollbar class="sagra-scrollbar" [autoHeightDisabled]="true">
            <div class="winpoint-section">
                <div class="winpoint-section-header">
                    <div class="winpoint-section-header-title">{{L('Settings_config_params')}}</div>
                </div>
                <div class="winpoint-control-sub-text">
                    <div class="winpoint-control">
                        <sagra-switch class="winpoint-switch" [state]="winPointData.demo" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="demoSwitchChange($event)"></sagra-switch>
                    </div>
                    <div class="winpoint-control-texts">
                        <div class="winpoint-switch-main-text">{{L('DB_test')}}</div>
                        <div class="winpoint-switch-sub-text">{{L('DB_test_info')}}</div>
                    </div>
                </div>
                <div class="winpoint-control-sub-text">
                    <div class="winpoint-control">
                        <sagra-switch class="winpoint-switch" [state]="winPointData.getProposals" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="getProposalsSwitchChange($event)"></sagra-switch>
                    </div>
                    <div class="winpoint-control-texts">
                        <div class="winpoint-switch-main-text">{{L('Download_proposals')}}</div>
                        <div class="winpoint-switch-sub-text">{{L('Download_proposals_info')}}</div>
                    </div>
                </div>
                <div class="winpoint-control-sub-text">
                    <div class="winpoint-control">
                        <sagra-switch class="winpoint-switch" [state]="winPointData.sendLocalizations" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendLocalizationsSwitchChange($event)"></sagra-switch>
                    </div>
                    <div class="winpoint-control-texts">
                        <div class="winpoint-switch-main-text">{{L('Send_localization')}}</div>
                        <div class="winpoint-switch-sub-text">{{L('Send_localization_info')}}</div>
                    </div>
                </div>
                <div class="winpoint-control-sub-text">
                    <div class="winpoint-control">
                        <sagra-switch class="winpoint-switch" [state]="winPointData.sendCategories" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendCategoriesSwitchChange($event)"></sagra-switch>
                    </div>
                    <div class="winpoint-control-texts">
                        <div class="winpoint-switch-main-text">{{L('Send_categories')}}</div>
                        <div class="winpoint-switch-sub-text">{{L('Send_categories_info')}}</div>
                    </div>
                </div>
                <div class="winpoint-control-sub-text">
                    <div class="winpoint-control">
                        <sagra-switch class="winpoint-switch" [state]="winPointData.sendProposals" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendProposalsSwitchChange($event)"></sagra-switch>
                    </div>
                    <div class="winpoint-control-texts">
                        <div class="winpoint-switch-main-text">{{L('Send_messages')}}</div>
                        <div class="winpoint-switch-sub-text">{{L('Send_messages_info')}}</div>
                    </div>
                </div>
            </div>
            <div class="winpoint-line-separator"></div>
            <div class="winpoint-section">
                <div class="winpoint-section-header">
                    <div class="winpoint-section-header-title">{{L('Services_additionally_payable')}}</div>
                </div>
                <div class="winpoint-control-sub-text">
                    <div class="winpoint-control">
                        <sagra-switch class="winpoint-switch" [state]="winPointData.getClientBB" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="getClientBBSwitchChange($event)"></sagra-switch>
                    </div>
                    <div class="winpoint-control-texts">
                        <div class="winpoint-switch-main-text">{{L('Download_Client_BB')}}</div>
                        <div class="winpoint-switch-sub-text">{{L('Download_Client_BB_info')}}</div>
                    </div>
                </div>
                <div class="winpoint-control-sub-text">
                    <div class="winpoint-control">
                        <sagra-switch class="winpoint-switch" [state]="winPointData.sendPayers" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendPayersSwitchChange($event)"></sagra-switch>
                    </div>
                    <div class="winpoint-control-texts">
                        <div class="winpoint-switch-main-text">{{L('Send_payers')}}</div>
                        <div class="winpoint-switch-sub-text">{{L('Send_payers_info')}}</div>
                    </div>
                </div>
                <div class="winpoint-control-sub-text">
                    <div class="winpoint-control">
                        <sagra-switch class="winpoint-switch" [state]="winPointData.sendHistCecha1" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendHistCecha1SwitchChange($event)"></sagra-switch>
                    </div>
                    <div class="winpoint-control-texts">
                        <div class="winpoint-switch-main-text">{{L('Send_HistCecha1')}}</div>
                        <div class="winpoint-switch-sub-text">{{L('Send_HistCecha1_info')}}</div>
                    </div>
                </div>
                <div class="winpoint-control-sub-text">
                    <div class="winpoint-control">
                        <sagra-switch class="winpoint-switch" [state]="winPointData.sendHistCecha2" [options]="controlSwitchOptions" [disabled]="readOnly" (stateChange)="sendHistCecha2SwitchChange($event)"></sagra-switch>
                    </div>
                    <div class="winpoint-control-texts">
                        <div class="winpoint-switch-main-text">{{L('Send_HistCecha2')}}</div>
                        <div class="winpoint-switch-sub-text">{{L('Send_HistCecha2_info')}}</div>
                    </div>
                </div>
                <div class="winpoint-expand-link">
                    <div (click)="onHiddenParamsClicked($event)">
                        <span class="icon icon-keyboard_arrow_down" [ngClass]="{'collapsed': !hiddenParamsExpanded}"></span>{{L('Show_hidden_params')}}
                    </div>
                </div>
                <div class="hiddenParams" #hiddenParams [@hiddenParamsExpanded]="hiddenParamsExpanded ? 'open' : 'close'">
                    <div class="winpoint-control-text">
                        <div class="winpoint-control">
                            <sagra-switch class="winpoint-switch" [state]="winPointData.nipRequired" [options]="controlSwitchOptions" [disabled]="true"></sagra-switch>
                        </div>
                        <div class="winpoint-switch-text">{{L('NIP_required')}}</div>
                    </div>
                    <div class="winpoint-control-text">
                        <div class="winpoint-control">
                            <sagra-switch class="winpoint-switch" [state]="winPointData.sendDictionaries" [options]="controlSwitchOptions" [disabled]="true"></sagra-switch>
                        </div>
                        <div class="winpoint-switch-text">{{L('Send_dictionaries')}}</div>
                    </div>
                    <!--<div class="winpoint-control-text">
                        <div class="winpoint-control">
                            <sagra-switch class="winpoint-switch" [state]="winPointData.sendErrands" [options]="controlSwitchOptions" [disabled]="true"></sagra-switch>
                        </div>
                        <div class="winpoint-switch-text">{{L('Send_Errands')}}</div>
                    </div>-->
                    <div class="winpoint-control-text">
                        <div class="winpoint-control">
                            <sagra-switch class="winpoint-switch" [state]="winPointData.sendClasses" [options]="controlSwitchOptions" [disabled]="true"></sagra-switch>
                        </div>
                        <div class="winpoint-switch-text">{{L('Send_Classes')}}</div>
                    </div>
                    <div class="winpoint-control-text">
                        <div class="winpoint-control">
                            <sagra-switch class="winpoint-switch" [state]="winPointData.addMissingLocalizations" [options]="controlSwitchOptions" [disabled]="true"></sagra-switch>
                        </div>
                        <div class="winpoint-switch-text">{{L('Add_Missing_Localizactions')}}</div>
                    </div>
                    <div class="winpoint-control-text">
                        <div class="winpoint-control">
                            <sagra-switch class="winpoint-switch" [state]="winPointData.getRelations" [options]="controlSwitchOptions" [disabled]="true"></sagra-switch>
                        </div>
                        <div class="winpoint-switch-text">{{L('Download_Relations')}}</div>
                    </div>
                    <div class="winpoint-control-text">
                        <div class="winpoint-control">
                            <sagra-switch class="winpoint-switch" [state]="winPointData.clientDeleted" [options]="controlSwitchOptions" [disabled]="true"></sagra-switch>
                        </div>
                        <div class="winpoint-switch-text">{{L('Client_deleted_status')}}</div>
                    </div>
                    <div class="winpoint-control-text">
                        <div class="winpoint-control">
                            <sagra-switch class="winpoint-switch" [state]="winPointData.sendRelations" [options]="controlSwitchOptions" [disabled]="true"></sagra-switch>
                        </div>
                        <div class="winpoint-switch-text">{{L('Send_Relations')}}</div>
                    </div>
                </div>
            </div>
        </ng-scrollbar>
    </div>
    <div class="winpoint-loader" *ngIf="readOnly || showLoader">
        <div>
            <sagra-loader [options]="sagraLoaderOptions" *ngIf="showLoader"></sagra-loader>
            <sagra-loader [options]="sagraLoaderTextOptions" *ngIf="readOnly"></sagra-loader>
        </div>
    </div>
    <!--<div class="winpoint-line-separator"></div>
    <div class="winpoint-footer">
        <button class="sgf-btn-secondary winpoint-btn-save" *ngIf="!readOnly" (click)="onSaveClicked($event)">{{L('Save')}}</button>
    </div>-->
</div>
