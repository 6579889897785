import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HistoryLog } from "../../models/operationsHistory/historyLog.model";
import { HistoryLogRequest } from "../../models/operationsHistory/historyLogRequest.model";

@Injectable()
export class OperationsHistoryService {
    private apiUrl: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    openInNewTab(tenant?: string, dataSource?: number, id?: number, guid?: string) {

        let url = '/history';
        let parameters = new Map();
        if (tenant != null && tenant.length > 0) {
            parameters.set('tenant', tenant);
        }
        if (dataSource != null) {
            parameters.set('source', dataSource);
        }
        if (id != null && id != 0) {
            parameters.set('id', id);
        }
        if (guid != null && guid.length > 0) {
            parameters.set('guid', guid);
        }

        let parametersString: string = '';
        for (const [key, value] of parameters.entries()) {
            parametersString += (parametersString.length == 0 ? '?' : '&');
            parametersString += key + '=' + value;
        }
        url = url + parametersString;
        const windowName = 'WN_Repository_' + dataSource;
        window.open(url, windowName);
    }

    getActivitiesLog(request: HistoryLogRequest): Observable<HistoryLog> {
        return this.http.post<HistoryLog>("api/OperationsHistory/GetActivitiesLog", request).pipe(map(item => {
            for (let log of item.logs) {
                if (log.occurenceDate != null) {
                    log.occurenceDate = moment(log.occurenceDate);
                }
            }
            return item;
        }));
    }

    getProcessesLog(request: HistoryLogRequest): Observable<HistoryLog> {
        return this.http.post<HistoryLog>("api/OperationsHistory/GetProcessesLog", request).pipe(map(item => {
            for (let log of item.logs) {
                if (log.occurenceDate != null) {
                    log.occurenceDate = moment(log.occurenceDate);
                }
            }
            return item;
        }));
    }

    getRepositoryLog(request: HistoryLogRequest): Observable<HistoryLog> {
        return this.http.post<HistoryLog>("api/OperationsHistory/GetRepositoryLog", request).pipe(map(item => {
            for (let log of item.logs) {
                if (log.occurenceDate != null) {
                    log.occurenceDate = moment(log.occurenceDate);
                }
            }
            return item;
        }));
    }

    getDataSheetsLog(request: HistoryLogRequest): Observable<HistoryLog> {
        return this.http.post<HistoryLog>("api/OperationsHistory/GetDataSheetsLog", request).pipe(map(item => {
            for (let log of item.logs) {
                if (log.occurenceDate != null) {
                    log.occurenceDate = moment(log.occurenceDate);
                }
            }
            return item;
        }));
    }

    getCacheLog(request: HistoryLogRequest): Observable<HistoryLog> {
        return this.http.post<HistoryLog>("api/OperationsHistory/GetCacheLog", request).pipe(map(item => {
            for (let log of item.logs) {
                if (log.occurenceDate != null) {
                    log.occurenceDate = moment(log.occurenceDate);
                }
            }
            return item;
        }));
    }
}
