export class RepositoryAssignRequestItem {
    public id?: number;
    public tableGUID?: string;
    public status: number;
    public editable?: boolean;
    public share: boolean;

    constructor(id: number, tableGUID: string, status: number, editable: boolean, share: boolean) {
        this.id = id;
        this.tableGUID = tableGUID;
        this.status = status;
        this.editable = editable;
        this.share = share;
    }
}
