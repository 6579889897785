<div class="biq-package-component">
    <div class="db-info-section-control" *ngIf="(currentPackage == null || currentPackage.isDemo)  && !demoUsed">
        <div class="db-info-section-text">{{L('Demo_duration')}}</div>
        <!--<div class="icon icon-btn_datepicker biq-package-date-icon" [owlDateTimeTrigger]="dt1"></div>-->
        <!--<input class="biq-package-date-input sgf-input type-standard" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [(ngModel)]="demoDurationFromModel" readonly />-->
        <!--<owl-date-time [pickerType]="'calendar'" (afterPickerClosed)="afterPickerClosed($event)" [disabled]="true" #dt1></owl-date-time>-->
        <!--<div class="icon icon-btn_datepicker biq-package-date-icon" [owlDateTimeTrigger]="dt2"></div>-->
        <!--<input class="biq-package-date-input sgf-input type-standard" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" [(ngModel)]="demoDurationToModel" [min]="demoDurationToMinDate" readonly />-->
        <!--<owl-date-time [pickerType]="'calendar'" (afterPickerClosed)="afterPickerClosed($event)" #dt2></owl-date-time>-->
        <sagra-datetime-picker class="biq-package-date-input"
                               [date]="demoDurationFromModel"
                               [type]="datePickerType"
                               (dateChange)="onBiqPackageFromDateChanged($event)"></sagra-datetime-picker>
        <sagra-datetime-picker class="biq-package-date-input"
                               [date]="demoDurationToModel"
                               [type]="datePickerType"
                               [minDate]="demoDurationFromModel"
                               (dateChange)="onBiqPackageToDateChanged($event)"></sagra-datetime-picker>
        <div class="sgf-btn-primary biq-package-section-button" *ngIf="demoEnabled" (click)="onTurnOffPackageClick()">{{L('Switch_off')}}</div>
    </div>
    <div class="db-info-section-buttons" *ngIf="(currentPackage == null || currentPackage.isDemo)  && !demoUsed">
        <div class="sgf-btn-border db-info-section-button" (click)="onCancelPackageClick()">{{L('Cancel')}}</div>
        <div class="sgf-btn-primary db-info-section-button" [ngClass]="{'disabled': !canSavePackage}" (click)="onSavePackageClick()">{{L('Save')}}</div>
    </div>
    <div class="db-info-section-control" *ngIf="(currentPackage != null && !currentPackage.isDemo) || demoUsed">
        <div class="biq-package-unavailable-info">
            <div class="icon icon icon-warning2"></div>
            <div class="biq-package-unavailable-info-text">{{L('Unavailable_demo_info')}}</div>
        </div>
    </div>
</div>
