import { Pipe, PipeTransform } from "@angular/core";
import { FunctionalityItem } from "../models/functionalities/functionalityItem.model";
import { FunctionalityDetailsItem } from "../models/functionalities/functionalityDetailsItem.model";

@Pipe({
    name: 'functionalitiesListSearchFilter',
    pure: false
})
export class FunctionalitiesListSearchFilter implements PipeTransform {
    transform(items: FunctionalityItem[], search: string, filterMetadata): FunctionalityItem[] {
        if (!items || !search) {
            const newVal = items != null ? items.length : 0;
            if (filterMetadata.count != newVal) {
                filterMetadata.count = newVal;
            }
            return items;
        }
        const retItems = items.filter(item => {
            return (item.name != null && item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                || (item.displayName && item.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                || (item.id && item.id.toString().indexOf(search.toLowerCase()) !== -1)
                || (item.guid && item.guid.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        });

        const newVal = retItems != null ? retItems.length : 0;
        if (filterMetadata.count != newVal) {
            filterMetadata.count = newVal;
        }
        return retItems;
    }
}

@Pipe({
    name: 'functionalityDetailSearchFilter',
    pure: false
})
export class FunctionalityDetailSearchFilter implements PipeTransform {
    transform(items: FunctionalityDetailsItem[], search: string, filterMetadata): FunctionalityDetailsItem[] {
        if (!items || !search) {
            const newVal = items != null ? items.length : 0;
            if (filterMetadata.count != newVal) {
                filterMetadata.count = newVal;
            }
            return items;
        }

        const retItems = items.filter(item => item.tenant.toLowerCase().indexOf(search.toLowerCase()) !== -1);

        const newVal = retItems != null ? retItems.length : 0;
        if (filterMetadata.count != newVal) {
            filterMetadata.count = newVal;
        }

        return retItems;
    }
}
