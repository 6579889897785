import { Subject } from "rxjs";

export class DialogFeedGroupsOptions implements DialogFeedGroupsOptionsInterface {
    feedGroups: any[];
    selectedFeedGroups: any[];
    publishHandler: Subject<any>;
    cancelHandler: Subject<any>;

    constructor(feedGroups: Array<any>, selectedFeedGroups: Array<any>, cancelHandler: Subject<any>, publishHandler: Subject<any>) {
        this.feedGroups = feedGroups;
        this.selectedFeedGroups = selectedFeedGroups;
        this.publishHandler = publishHandler;
        this.cancelHandler = cancelHandler;
    }
}

export interface DialogFeedGroupsOptionsInterface {
    feedGroups: Array<any>;
    selectedFeedGroups: Array<any>;
    publishHandler: Subject<any>;
    cancelHandler: Subject<any>;
}
