import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { DistrictData } from "../../models/districts/districtData";
import { map } from "rxjs/operators";
import { MessageBusService } from "../messageBusService/messageBusService";
import { NotificationsTenant } from "../../models/notifications/notificationsTenant";
import { NotificationItem } from "../../models/notifications/notificationItem";

@Injectable({
    providedIn: 'root'
})

export class NotificationService {
    private apiUrl: string;

    constructor(private http: HttpClient,
        private messageService: MessageBusService,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }


    public refreshNotifications(data) {

        let notifications: NotificationsTenant[] = [];
        let arrObjects = JSON.parse(data);
        for (let obj of arrObjects) {
            let newTenant = new NotificationsTenant();
            newTenant.tenant = obj.Tenant;
            newTenant.items = [];
            for (let item of obj.Tasks) {
                let newItem = new NotificationItem();
                newItem.type = item.Type;
                newItem.countCompleted = item.Completed;
                newItem.countAll = item.All;
                newItem.isError = item.Error;
                newTenant.items.push(newItem);
            }
            notifications.push(newTenant);
        }

        this.messageService.refreshNotifications(notifications);

        this.messageService.showNotificationBell(notifications != null && notifications.length > 0);
    }
}
