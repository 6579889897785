import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Breadcrumbs } from "../../models/breadcrumbs.model";

@Injectable({
    providedIn: 'root'
})

export class BreadcrumbsService {
    private breadcrumbsSubject: Subject<Breadcrumbs> = new Subject<Breadcrumbs>();

    private breadCrumbsCache: Array<Breadcrumbs> = [];

    public getBreadcrumbs(breadcrumbId: number): Breadcrumbs {
        const res = this.breadCrumbsCache.filter(x => x.id == breadcrumbId);
        if (res != null && res.length > 0) {
            return res[0];
        }
        return null;
    }

    public getBreadcrumbsSubject(): Observable<Breadcrumbs> {
        return this.breadcrumbsSubject.asObservable();
    }

    public setBreadcrumbs(breadcrumbs: Breadcrumbs): void {
        let bFound = false;
        for (let i = 0; i < this.breadCrumbsCache.length; i++) {
            if (this.breadCrumbsCache[i].id == breadcrumbs.id) {
                bFound = true;
                if (breadcrumbs != null) {
                    this.breadCrumbsCache[i] = breadcrumbs;
                }
                else {
                    this.breadCrumbsCache = this.breadCrumbsCache.splice(i, 1);
                }
                break;
            }
        }
        if (!bFound) {
            this.breadCrumbsCache.push(breadcrumbs);
        }
        this.breadcrumbsSubject.next(breadcrumbs);
    }
}
