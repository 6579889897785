import { BIQPackage } from "./biqPackage.model";

export class BiqPackageRequest {
    public dbName: string;
    public portalName: string;
    public currentPackage: BIQPackage;
    public newPackage: BIQPackage;
    public startBiqDemoDate: string;
    public endBiqDemoDate: string;
    public startPumpDataDate: string;
    public endPumpDataDate: string;
}
