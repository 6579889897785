import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from "rxjs";
import { map, share } from 'rxjs/operators';
import { UserModel, UserModelInterface } from '../../models/user.model';
import { DbConfiguration } from '../../models/dbConfiguration';
import { TenantPermissions } from '../../models/tenant/tenantPermissions.model';
import { Permission } from '../../models/permission.model';
import { Language } from '../../models/database/language.model';

@Injectable({
    providedIn: 'root'
})

export class ContextService {
    private currentUserModel: UserModelInterface;
    private currentGRRPermissions: TenantPermissions;
    private apiUrl: string;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.apiUrl = baseUrl;
    }

    public getUser(context: string): Observable<UserModel> {
        return this.http.post<UserModel>(this.apiUrl + 'api/Context/GetUserForDBName', { Context: context }, { observe: "response" })
            .pipe(
                map(response => {
                    return response.body as UserModel;
                }),
                share());
    }

    public getUserData(): Observable<UserModel> {
        return this.http.post<UserModel>(this.apiUrl + 'api/Context/GetUserData', null);
    }

    public setUserModel(userData: UserModelInterface) {
        this.currentUserModel = userData;
    }

    public getUserModel(): UserModelInterface {
        return this.currentUserModel;
    }

    public getUserContext(context: string): Observable<UserModel> {
        return this.http.post<UserModel>(this.apiUrl + 'api/Context/GetUserContext', { Context: context }, { observe: "response" })
            .pipe(
                map(response => {
                    return response.body as UserModel;
                }),
                share());
    }

    private getDBConfigurationForDBName(databaseName: string): Observable<DbConfiguration> {
        return this.http.post<DbConfiguration>(this.apiUrl + 'api/Context/GetDBConfigurationForDBName', { Context: databaseName });
    }

    //Uprawnienia do GRR
    public getUserGRRPermissions(): Observable<TenantPermissions> {
        return this.http.post<TenantPermissions>(this.apiUrl + 'api/Context/GetUserGRRPermissions', null);
    }

    public setCurrentGRRPermissions(permissions: TenantPermissions) {
        this.currentGRRPermissions = permissions;
    }

    public getCurrentGRRPermission(permissionName: string): Permission {
        if (this.currentGRRPermissions != null && this.currentGRRPermissions.permissions != null) {
            for (let i = 0; i < this.currentGRRPermissions.permissions.length; i++) {
                if (this.currentGRRPermissions.permissions[i].permissionName == permissionName) {
                    return this.currentGRRPermissions.permissions[i];
                }
            }
        }
        let permission = new Permission();
        permission.permissionName = permissionName;
        return permission;
    }

    getLanguagesList(): Observable<Array<Language>> {
        let observable = this.http.get<Array<Language>>(this.apiUrl + 'api/Context/GetLanguagesList');
        return observable;
    }
}

