import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { SagraDialogOptionsInterface, SagraDialogService, SagraDialogType } from "@sagra/sagra-dialog";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { SagraSwitchComponentLabelPosition, SagraSwitchComponentModelInterface } from "@sagra/sagra-switch";
import { SagraToastsService, SagraToastsStyle } from "@sagra/sagra-toasts";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation"
import { from, Subject, Subscription } from "rxjs";
import { ApplicationAAD } from "../../models/tenant/applicationAAD.model";
import { TenantService } from "../../services/tenantService/tenant.service";
import { Clipboard } from '@angular/cdk/clipboard';
import * as moment from "moment";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { SagraDatetimePickerType } from "@sagra/sagra-datetime-picker";
import { StatusDefinition, StatusType } from "../status-pill/status-pill.component";
import { SagraTooltipInterface, SagraTooltipItem, SagraTooltipOptionsInterface, SagraTooltipOrientation } from "@sagra/sagra-tooltip";
import { NavigationItemEnum } from "../../models/navigationItem.model";
import { OperationsHistoryService } from "../../services/operationsHistoryService/operationsHistory.service";
import { SagraPlaceholderImage, SagraPlaceholderOptionsInterface } from "@sagra/sagra-placeholder";
import { Sorting } from "../../utils/sorting";
import { ResizeList, ShowPanel } from "../../animations/animations";
import { NgScrollbar } from "ngx-scrollbar";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";
import { Utils } from "../../utils/utils";

@Component({
    selector: 'applications-list',
    templateUrl: './applications-list.component.html',
    styleUrls: ['./applications-list.component.scss'],
    animations: [ ShowPanel(), ResizeList() ]
})

export class ApplicationsListComponent extends SagraTranslatable implements OnInit, OnDestroy {
    @Input() tenant: string;
    @ViewChild('perfectScroll') perfectScroll: NgScrollbar;

    private subscriptions = new Subscription();
    public headerTitle = this.L('Emigo_Applications');
    public applicationsList: Array<ApplicationAAD>;
    public filteredApplicationsList: Array<ApplicationAAD>;
    public selectedApp: ApplicationAAD;
    public showLoader: boolean;
    public detailsPanelVisible: boolean = false;
    public temporalLimitationsModel: boolean = false;
    public tempLimitationsStartModel: moment.Moment;
    public tempLimitationsEndModel: moment.Moment;
    public permissionsModel: boolean;
    public sortingReverse: boolean = false;
    public sortingColumn: string = 'name';
    public aadLink: string;
    public dataChanged: boolean;
    private applicationToOpen: ApplicationAAD;
    private closingPanel: boolean;
    public readOnlyPanel: boolean;
    public readOnlyStart: boolean;
    public startMinDate: moment.Moment;
    public endMinDate: moment.Moment;
    public datePickerType: SagraDatetimePickerType;
    public statuses: Array<StatusDefinition>;
    public searchText: string;
    public disablePermissionsSwitch: boolean;
    public firstDownloadingData: boolean = true;

    public parentApp: ApplicationAAD;
    public parentAppAADLink: string;
    public parentAppSwitchModel: boolean = false;

    public appTooltipItems: Array<SagraTooltipItem> = [{ content: this.L('New_Portal_Console') }];
    public appTooltipModel: SagraTooltipInterface;
    public tooltipActivationOptions: SagraTooltipOptionsInterface;
    public tooltipActivationModel: SagraTooltipInterface;


    public placeholderOptions: SagraPlaceholderOptionsInterface;

    public secondaryApps: Array<ApplicationAAD>;

    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    public sagraRowLoaderOptions: SagraLoaderOptionsInterface;
    public permissionsSwitchOptions: SagraSwitchComponentModelInterface;
    public tempLimitationsSwitchOptions: SagraSwitchComponentModelInterface;

    private closePanelSaveSubject = new Subject<string>();
    private closePanelNoSaveSubject = new Subject<string>();
    private changeRestrictionsSubject = new Subject<string>();
    private switchOffSubject = new Subject<string>();

    constructor(public translationService: SagraTranslationService,
        public dialogService: SagraDialogService,
        private tenantService: TenantService,
        private toastService: SagraToastsService,
        private messageService: MessageBusService,
        private operationService: OperationsHistoryService,
        private clipboard: Clipboard
    ) {
        super(translationService);

        this.closePanelNoSaveSubject.subscribe(data => {
            this.dataChanged = false;
            if (this.closingPanel) {
                this.closePanel();
            }
            else {
                this.openPanel(this.applicationToOpen);
            }
        });

        this.closePanelSaveSubject.subscribe(data => {
            this.saveAccessData();
        })
        this.changeRestrictionsSubject.subscribe(data => {
            this.saveAccessDataFinal();
        });
        this.switchOffSubject.subscribe(data => {
            this.saveAccessDataFinal();
        });

        this.messageService.getRefreshApplications().subscribe(data => {
            this.getApplicationsList();
        });

        this.datePickerType = SagraDatetimePickerType.date;

        this.statuses = [
            { id: 1, name: this.L('In_progress'), type: StatusType.inProgress },
            { id: 2, name: this.L('Active_2'), type: StatusType.green },
            { id: 3, name: this.L('ERROR'), type: StatusType.red },
            { id: 4, name: this.L('SWITCHED_OFF'), type: StatusType.grey },
            { id: 5, name: this.L('PLANNED'), type: StatusType.green },
        ]

        this.appTooltipModel = { tooltipItems: this.appTooltipItems };

        this.tooltipActivationOptions = {
            orientation: SagraTooltipOrientation.horizontal
        }

        this.preparePlaceholderOptions();
    }

    ngOnInit() {
        this.prepareControlOptions();

        this.getApplicationsList();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onPermissionsSwitchChange(event) {
        this.permissionsModel = event;
        this.checkDataChanged();
    }

    onTempLimitationsSwitchChange(event) {
        this.temporalLimitationsModel = event;
        this.checkDataChanged();
    }

    onTempLimitationsStartDateChanged(event) {
        this.tempLimitationsStartModel = event;
        this.setEndMinDate(this.tempLimitationsStartModel);
        setTimeout(() => { this.checkDataChanged(); }, 0);
        
    }

    onTempLimitationsEndDateChanged(event) {
        this.tempLimitationsEndModel = event;
        setTimeout(() => { this.checkDataChanged(); }, 0);
    }

    onClearTempLimitationsStartDate() {
        this.tempLimitationsStartModel = null;
        this.setEndMinDate(this.startMinDate);
        this.checkDataChanged();
    }

    onClearTempLimitationsEndDate() {
        this.tempLimitationsEndModel = null;
        this.checkDataChanged();
    }

    onListItemClick(item) {
        if (this.selectedApp == null || this.selectedApp.id != item.id) {
            this.openPanel(item);
        }
        else if (this.selectedApp.id == item.id) {
            if(this.detailsPanelVisible) {
                this.closePanel();
            }
            else {
                this.openPanel(item);
            }
        }
    }

    onClosePanelClick(event) {
        this.closePanel();
    }

    onSaveButtonClick() {
        this.selectedApp.startDate = this.tempLimitationsStartModel;
        this.selectedApp.endDate = this.tempLimitationsEndModel;
        
        this.saveAccessDataFinal();
    }

    onCopyLinkClick(link: string) {
        this.clipboard.copy(link);
        this.toastService.pushToast({ moduleId: 1, message: this.L('Link_clipboard'), style: SagraToastsStyle.success });
    }

    onSearchTextChanged(searchText: string) {
        this.searchText = searchText;

        this.applyFilters();

        this.preparePlaceholderOptions();
    }

    onParentAppSwitchChange(event) {
        this.parentAppSwitchModel = event;

        if (event) {
            this.perfectScroll.update();
            this.perfectScroll.scrollTo({bottom: 0});
        }
    }

    onLinkedApplicationClick(item) {
        this.onListItemClick(item);
    }

    onHistoryButtonClicked(event) {
        this.operationService.openInNewTab(this.tenant, NavigationItemEnum.Applications, null, null);
    }

    onHistoryListItemClick(item: ApplicationAAD) {
        this.operationService.openInNewTab(this.tenant, NavigationItemEnum.Applications, null, item.id);
    }

    getApplicationsList() {
        this.showLoader = true;
        this.subscriptions.add(this.tenantService.getApplicationsAAD(this.tenant, 3).subscribe(data => {
            this.applicationsList = data;

            this.checkApplicationActivation();

            this.applyFilters();

            this.firstDownloadingData = false;
            this.showLoader = false;
        },
            (error) => {
                this.firstDownloadingData = false;
                this.showLoader = false;
        }));
    }

    getStatusText(application: ApplicationAAD) {
        const status = this.getCurrentStatus(application);
        switch (status) {
            case 1:
                return this.L('In_progress');
            case 2:
                return this.L('Active_2');
            case 3:
                return this.L('ERROR');
            case 4:
                return this.L('SWITCHED_OFF');
            case 5:
                return this.L('PLANNED');
        }
        return '';
    }

    getStatusesClass(application: ApplicationAAD) {
        const status = this.getCurrentStatus(application);
        switch(status){
            case 1:
                return '';
            case 2:
                return 'mint';
            case 3:
                return 'cherry';
            case 4:
                return 'ink';
            case 5:
                return 'mint';
        }
        return '';
    }

    getCurrentStatus(application: ApplicationAAD) {
        let status = 0;
        let today = moment().startOf('day');
        switch (application.status) {
            case 0:
                status = 4;
                //today = moment().startOf('day');
                //if (application.startDate != null && application.startDate.isAfter(today)) {
                //    status = 3;
                //}
                break;
            case 1:
            case 4:
                status = 1;
                //today = moment().startOf('day');
                //if (application.startDate != null) {
                //    if (application.startDate.isAfter(today))
                //    status = 3;
                //}
                //if (application.endDate != null && application.endDate.isAfter(today)) {
                //    status = 3;
                //}
                break;
            case 2:
                status = 2;
                if (application.startDate != null && application.startDate.isAfter(today)) {
                    status = 1;
                }
                break;
            case 3:
                status = 3;
                break;
            case 5:
                status = 5;
                break;
        }
        return status;
    }

    getDatesValue(application: ApplicationAAD): string {
        let result = '';
        if (application.startDate == null && application.endDate == null) {
            if (application.status == 2 || application.status == 4) {
                result = this.L('INDEFINITELY');
            }
        }
        else {
            if (application.startDate != null) {
                result = application.startDate.format('DD.MM.YYYY');
            }
            if (application.endDate != null) {
                result += " - ";
                result += application.endDate.format('DD.MM.YYYY');
            }
        }
        return result;
    }

    openPanel(application: ApplicationAAD) {
        if (this.dataChanged) {
            this.applicationToOpen = application;
            this.showDialogClosePanel(false);
            return;
        }
        this.applicationToOpen = null;

        this.selectedApp = application;
        this.readOnlyPanel = (this.selectedApp.status == 1 || this.selectedApp.status == 4);
        this.readOnlyStart = (this.selectedApp.status == 2);
        if (this.readOnlyStart) {
            this.startMinDate = null;
        }
        else {
            this.startMinDate = moment().add(1, 'day').startOf('day');
        }
        this.permissionsModel = this.selectedApp.status == 1 || this.selectedApp.status == 2 || this.selectedApp.status == 5;
        this.disablePermissionsSwitch = this.permissionsModel && !this.selectedApp.canBeDelete;
        if (this.selectedApp.status != 0) {
            this.tempLimitationsStartModel = this.selectedApp.startDate;
            this.tempLimitationsEndModel = this.selectedApp.endDate;
        }
        else {
            this.tempLimitationsStartModel = null;
            this.tempLimitationsEndModel = null;
        }
        this.temporalLimitationsModel = this.tempLimitationsStartModel != null || this.tempLimitationsEndModel != null;

        this.setEndMinDate(this.tempLimitationsStartModel);

        this.aadLink = Utils.getAADLink(this.selectedApp.id);

        if (application.businessParentIDAPP) {
            this.parentApp = this.applicationsList.find(item => { return item.id == application.businessParentIDAPP; });
            this.parentAppAADLink = Utils.getAADLink(this.parentApp.id);
        }
        else {
            this.parentApp = null;
            this.parentAppAADLink = null;
        }

        if (!application.canBeActivated) {
            this.tooltipActivationModel = {
                tooltipItems: [{ title: null, content: application.activationInfo }]
            };
        }
        else {
            this.tooltipActivationModel = null;
        }

        this.detailsPanelVisible = true;
        this.dataChanged = false;

        this.prepareSecondaryApps();
    }

    closePanel() {
        this.selectedApp = null;
        if (this.dataChanged) {
            this.showDialogClosePanel(true);
        }
        else {
            this.detailsPanelVisible = false;
        }
    }

    showDialogClosePanel(closing: boolean) {
        this.closingPanel = closing;
        let dialogOptions: SagraDialogOptionsInterface = {
            dialogType: SagraDialogType.confirm,
            title: this.L('Exit_edition'),
            message: this.L('Ask_app_exit_edition'),
            buttons: [
                { name: this.L('Close_no_save'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary, handler: this.closePanelNoSaveSubject },
                { name: this.L('Save'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.closePanelSaveSubject }
            ]
        };
        this.dialogService.showAlert(dialogOptions);
    }

    saveAccessData() {
        const today = moment().startOf('day');

        if (this.selectedApp.status != 0 && !this.temporalLimitationsModel) {
            let dialogOptions: SagraDialogOptionsInterface = {
                dialogType: SagraDialogType.confirm,
                title: this.L('Switch_off_app'),
                message: this.L('Ask_app_switch_off'),
                buttons: [
                    { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary},
                    { name: this.L('Switch_off_now'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.switchOffSubject }
                ]
            };
            this.dialogService.showAlert(dialogOptions);
            return;
        }
        else if (this.selectedApp.status == 2 && this.selectedApp.startDate != null && this.selectedApp.startDate.isAfter(today)) {
            let dialogOptions: SagraDialogOptionsInterface = {
                dialogType: SagraDialogType.confirm,
                title: this.L('Changes_restrictions'),
                message: this.L('Ask_app_changes_restrictions'),
                buttons: [
                    { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                    { name: this.L('Save'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.changeRestrictionsSubject }
                ]
            };
            this.dialogService.showAlert(dialogOptions);
            return;
        }

        this.saveAccessDataFinal();
    }

    saveAccessDataFinal() {
        this.showLoader = true;

        let observable = null;
        const applications = new Array<ApplicationAAD>();

        let application = this.prepareSendApplicationData(this.selectedApp);
        applications.push(application);

        if (this.parentAppSwitchModel && this.getCurrentStatus(this.parentApp) == 4) {
            application = this.prepareSendApplicationData(this.parentApp);
            applications.push(application);
        }

        if (this.selectedApp.status == 0 || !this.permissionsModel) {
            observable = this.tenantService.saveApplicationAccess(this.tenant, applications, this.permissionsModel);
        }
        else {
            observable = this.tenantService.editApplicationAccess(this.tenant, applications);
        }

        this.subscriptions.add(observable.subscribe(data => {
            this.dataChanged = false;
            this.closePanel();

            this.getApplicationsList();

        },
            error => {
                console.log(error);
                this.showLoader = false;
            }));
    }

    prepareSendApplicationData(app: ApplicationAAD) {
        let application = new ApplicationAAD();
        application.id = app.id;
        application.name = app.name;
        if (this.permissionsModel && this.temporalLimitationsModel) {
            application.startDate = this.selectedApp.startDate;
            application.endDate = this.selectedApp.endDate;
            if (application.startDate == null) {
                application.startDate = this.startMinDate;
            }
        }
        if (this.permissionsModel) {
            if (application.startDate == null) {
                application.startDate = this.startMinDate;
            }
        }
        return application;
    }

    sortColumn(column: string, changeSort: boolean = true) {
        if (changeSort) {
            if (column != this.sortingColumn) {
                this.sortingColumn = column;
                this.sortingReverse = false;
            }
            else {
                this.sortingReverse = !this.sortingReverse;
            }
        }
        let secondColumn = null;
        if (this.sortingColumn != 'name') {
            secondColumn = 'name';
        }
        let sortColumnType = 0;
        if (this.sortingColumn == 'sortStatus') {
            sortColumnType = 1;
        }
        Sorting.sortByTwoProperties(this.filteredApplicationsList, this.sortingColumn, secondColumn, !this.sortingReverse, sortColumnType);

        //this.applicationsList = this.applicationsList.sort((a, b) => {
        //    let item1 = String(a[this.sortingColumn] ? a[this.sortingColumn] : "").toLowerCase();
        //    let item2 = String(b[this.sortingColumn] ? b[this.sortingColumn] : "").toLowerCase();
        //    if (this.sortingReverse) {
        //        return item2.localeCompare(item1);
        //    }
        //    else {
        //        return item1.localeCompare(item2);
        //    }
        //    return 0;
        //});
    }

    checkDataChanged() {
        this.dataChanged = false;
        if (this.selectedApp != null) {
            if (this.permissionsModel != (this.selectedApp.status == 1 || this.selectedApp.status == 2 || this.selectedApp.status == 5)) {
                this.dataChanged = true;
            }
            if (this.selectedApp.status != 1) { // nie w toku
                let date1 = null;
                let date2 = null;
                if (this.temporalLimitationsModel && this.tempLimitationsStartModel != null) {
                    date1 = this.tempLimitationsStartModel.format('DD.MM.YYYY');
                }
                if (this.selectedApp.status != 0 && this.selectedApp.startDate != null) {
                    date2 = this.selectedApp.startDate.format('DD.MM.YYYY');
                }
                if (date1 != date2) {
                    this.dataChanged = true;
                }
                date1 = null;
                date2 = null;
                if (this.temporalLimitationsModel && this.tempLimitationsEndModel != null) {
                    date1 = this.tempLimitationsEndModel.format('DD.MM.YYYY');
                }
                if (this.selectedApp.status != 0 && this.selectedApp.endDate != null) {
                    date2 = this.selectedApp.endDate.format('DD.MM.YYYY');
                }
                if (date1 != date2) {
                    this.dataChanged = true;
                }
            }
        }
    }

    setEndMinDate(refDate: moment.Moment) {
        if (refDate != null) {
            this.endMinDate = moment(refDate).add(1, 'day').startOf('day');
            if (this.endMinDate.isSameOrBefore(moment().startOf('day'))) {
                this.endMinDate = moment().add(1, 'day').startOf('day');
            }
        }
        else {
            this.endMinDate = moment().add(1, 'day').startOf('day');
        }

        if (this.tempLimitationsEndModel != null) {
            if (this.endMinDate.isAfter(this.tempLimitationsEndModel)) {
                this.endMinDate = moment(this.tempLimitationsEndModel);
            }
        }
    }

    prepareControlOptions() {
        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 40,
                borderWidth: 5
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        };

        this.permissionsSwitchOptions = {
            labelText: this.L("Enable_permissions"),
            labelPosition: SagraSwitchComponentLabelPosition.right
        }

        this.tempLimitationsSwitchOptions = {
            labelText: this.L("Temporal_limitations"),
            labelPosition: SagraSwitchComponentLabelPosition.right
        }

        this.sagraRowLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            backgroundSetings: {
                backgroundColor: 'transparent'
            },
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            }
        }
    }

    prepareSecondaryApps() {
        if (this.selectedApp != null && this.selectedApp.businessSuiteAPP) {
            this.secondaryApps = this.applicationsList.filter(item => { return item.businessParentIDAPP == this.selectedApp.id; });
        }
        else {
            this.secondaryApps = null;
        }
    }

    preparePlaceholderOptions() {
        this.placeholderOptions = {
            title: this.L('Nothing_to_display'),
            image: {
                type: SagraPlaceholderImage.lightbulb
            }
        }

        if (this.searchText && (this.filteredApplicationsList == null || this.filteredApplicationsList.length == 0)) {
            this.placeholderOptions.title = this.L('Empty_search_holder');
            this.placeholderOptions.image = { type: SagraPlaceholderImage.search };
        }
    }

    applyFilters() {
        let tmpListItems: Array<ApplicationAAD> = this.applicationsList;

        if (this.applicationsList != null) {
            if (this.searchText) {
                tmpListItems = tmpListItems.filter(item => item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
            }
        }

        this.filteredApplicationsList = tmpListItems;

        this.sortColumn(this.sortingColumn, false);

        this.preparePlaceholderOptions();
    }

    checkApplicationActivation() {
        let array: Array<any> = [];
        array = this.applicationsList.filter(x => x.checkCanBeOn);
        if (array != null && array.length > 0) {
            for (let app of array) {
                this.subscriptions.add(this.tenantService.canApplicationBeActivated(this.tenant).subscribe(data => {
                    app.canBeActivated = data.canBeOn;
                    app.activationInfo = data.comment + '\r\n' + data.commentDetails;
                }));
            }
        }
    }
}
