<div class="db-details">
    <database-details-navigation [navGroups]="navigationItems" [selectedItem]="selectedItem" (selectedItemChanged)="onSelectedItemChanged($event)"></database-details-navigation>
    <div class="db-details-right">
        <database-info [tenant]="tenantId" [hidden]="selectedItem != 1" [tenantConfiguration]="tenantConfiguration"></database-info>
        <!-- ActivitiesOperations -->
        <repository-list *ngIf="selectedItem == 2" [tenant]="tenantId" [dataSource]="2" [hidden]="selectedItem != 2" [allowUnShare]="true" [tenantConfiguration]="tenantConfiguration"></repository-list>
        <!-- Processes -->
        <repository-list *ngIf="selectedItem == 3" [tenant]="tenantId" [dataSource]="3" [hidden]="selectedItem != 3" [allowUnShare]="true" [tenantConfiguration]="tenantConfiguration"></repository-list>
        <!-- TWR -->
        <repository-list *ngIf="selectedItem == 4" [tenant]="tenantId" [dataSource]="4" [hidden]="selectedItem != 4" [tenantConfiguration]="tenantConfiguration"></repository-list>
        <roles-list [tenant]="tenantId" [hidden]="selectedItem != 5"></roles-list>
        <!-- Sheets -->
        <repository-list *ngIf="selectedItem == 6" [tenant]="tenantId" [dataSource]="6" [hidden]="selectedItem != 6" [allowUnShare]="true" [tenantConfiguration]="tenantConfiguration"></repository-list>
        <districts-list [tenant]="tenantId" [hidden]="selectedItem != 7"></districts-list>
        <register-list [tenant]="tenantId" [hidden]="selectedItem != 8"></register-list>
        <!-- Extracts -->
        <repository-list *ngIf="selectedItem == 9" [tenant]="tenantId" [dataSource]="9" [hidden]="selectedItem != 9" [allowUnShare]="true" [tenantConfiguration]="tenantConfiguration"></repository-list>
        <winpoint-config [tenant]="tenantId" [hidden]="selectedItem != 10" [winPointConfiguration]="tenantConfiguration.winPointConfiguration" [dbAddress]="tenantConfiguration.dbAddress"></winpoint-config>
        <h5-tables *ngIf="selectedItem == 11" [tenant]="tenantId" [hidden]="selectedItem != 11"></h5-tables>
        <hierarchy-list [tenant]="tenantId" [hidden]="selectedItem != 12"></hierarchy-list>
        <applications-list [tenant]="tenantId" [hidden]="selectedItem != 13"></applications-list>
        <targeting-list [tenant]="tenantId" [hidden]="selectedItem != 14"></targeting-list>
    </div>
</div>

