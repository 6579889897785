import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map, share } from 'rxjs';


@Injectable()
export class ConfigurationService {
    private apiUrl: string;
    private configuration: IAppConfiguration;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    async loadConfig() {
        this.configuration = await lastValueFrom(
            this.http.get<IAppConfiguration>(this.apiUrl + 'api/Configuration/ConfigurationData')
            .pipe(
                map(result => {
                    return result;
                })
            )
        );

    }

    get staviatorApiUrl() {
        return this.configuration.staviatorApiUrl;
    }
}

export interface IAppConfiguration {
    staviatorApiUrl: string;
}
