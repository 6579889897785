import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { SagraInputOptionsModelInterface, SagraInputStyle, SagraInputIconPosition, SagraInputErrorType } from "@sagra/sagra-input";

@Component({
    selector: 'tabpage-header',
    templateUrl: './tabpage-header.component.html',
    styleUrls: ['./tabpage-header.component.scss']
})

export class TabPageHeaderComponent extends SagraTranslatable implements OnChanges {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() counterText: string;
    @Input() actionButtonTitle: string;
    @Input() actionButtonVisible: boolean;
    @Input() actionButtonDisabled: boolean;
    @Input() historyButtonVisible: boolean;
    @Input() searchEnabled: boolean;
    @Input() shortMode: boolean;
    @Input() showBreadcrumb: boolean;
    @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() actionButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() historyButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    public searchText: string;
    public historyButtonTitle: string = this.L("Operation_history");
    public searchListInputOptions: SagraInputOptionsModelInterface;

    constructor(public translationService: SagraTranslationService) {
        super(translationService);

        this.searchListInputOptions = {
            placeholder: this.L("Search"),
            styleType: SagraInputStyle.standard,
            clearButton: true,
            errorType: SagraInputErrorType.borderOnly,
            icon: { className: 'icon-btn_search', position: SagraInputIconPosition.left }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    searchChanged(searchText) {
        this.searchTextChanged.emit(searchText);
    }

    actionButtonClick() {
        this.actionButtonClicked.emit();
    }
    historyButtonClick() {
        this.historyButtonClicked.emit();
    }
}
