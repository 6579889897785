import { Component, Input, OnInit, OnDestroy, SimpleChanges, SimpleChange } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { SagraCheckboxComponentSize } from "@sagra/sagra-checkbox";
import { DatabaseRole } from "../../models/databaseRole.model";
import { RepositoryService } from "../../services/repositoryService/repository.service";
import { SagraDialogService, SagraDialogType } from "@sagra/sagra-dialog";
import { Subject, Subscription } from "rxjs";
import { RoleService } from "../../services/roleService/role.service";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { SelectedItem } from "../../models/selectedItem.model";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";

@Component({
    selector: 'roles-list',
    templateUrl: './roles-list.component.html',
    styleUrls: ['./roles-list.component.scss']
})

export class RolesListComponent extends SagraTranslatable implements OnInit, OnDestroy {
    @Input() tenant: string;

    private subscriptions = new Subscription();
    public checkboxSize: SagraCheckboxComponentSize = SagraCheckboxComponentSize.small;
    public roles: Array<DatabaseRole>;
    public headerTitle: string = this.L('Roles');
    public actionButtonTitle: string = this.L('ShareRoles');
    public toShareCounter: number = 0;
    public searchText: string;
    public showLoader: boolean;

    public selectedItems: Array<SelectedItem> = [];

    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    public roleLoaderOptions: SagraLoaderOptionsInterface;

    constructor(public translationService: SagraTranslationService,
        private roleService: RoleService,
        private repositoryService: RepositoryService,
        private dialogService: SagraDialogService,
        private messageService: MessageBusService
    ) {

        super(translationService);

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }

        this.roleLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 10,
                borderWidth: 2,
                color: '#000000'
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }
    }

    ngOnInit(): void {
        this.showLoader = true;

        this.messageService.getRefreshRolesList().subscribe((next) => {
            if (next.tenant == this.tenant.toLowerCase()) {
                //console.log("SignalR: call RefreshRolesList next: " + JSON.stringify(next));
                this.getRolesList();
            }
        });

        this.getRolesList();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        let currentItem: SimpleChange = changes.tenant;
        if (currentItem.currentValue) {
            this.tenant = changes.tenant.currentValue;
            if (currentItem.previousValue) {
                this.showLoader = true;
                this.getRolesList();
            }
        }

    }

    onSearchTextChanged(searchText) {
        this.searchText = searchText;
    }

    onActionButtonClicked(event) {
        let subject: Subject<any> = new Subject<any>();
        this.subscriptions.add(subject.asObservable().subscribe(
            x => this.assignRoles()
        ));

        this.dialogService.showAlert({
            dialogType: SagraDialogType.confirm,
            message: this.L('AskSharePermanent'),
            backdropClose: true,
            buttons: [
                { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                { name: this.L('Download'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.primary, handler: subject }
            ]
        });
    }

    getRolesList() {
        this.subscriptions.add(this.roleService.getPortalRoles(this.tenant).subscribe(response => {
            this.roles = response as Array<DatabaseRole>;
            this.checkSelectedItems();
            this.showLoader = false;
        }, (error) => {
            this.showLoader = false;
        }));
    }

    assignRoles() {
        let assignID: number[] = [];
        for (let item of this.selectedItems) {
            assignID.push(item.id as number);
        }
        if (assignID.length > 0) {
            this.showLoader = true;
            this.subscriptions.add(this.repositoryService.assignRoles(this.tenant, assignID).subscribe(data => {
                this.selectedItems = [];
                this.toShareCounter = this.selectedItems.length;
                this.getRolesList();
            }, (error) => {
                this.showLoader = false;
            }));
        }
    }

    onCheckBoxUpdate(state: boolean, role: DatabaseRole) {

        this.setItemSelection(role, state);

    }

    setItemSelection(item: DatabaseRole, state: boolean) {
        var selArr = this.selectedItems.filter((elem) => { return elem.id == item.id; });
        if (selArr.length > 0) {
            var selItem = selArr[0];
            selItem.selected = state;
            if (selItem.selected == selItem.originalSelected) {
                for (var i = 0; i < this.selectedItems.length; i++) {
                    if (this.selectedItems[i].id == selArr[0].id) {
                        this.selectedItems.splice(i, 1);
                        break;
                    }
                }
            }
        }
        else {
            var newItem = new SelectedItem(item.shared, false);
            newItem.id = item.id;
            newItem.selected = state;
            this.selectedItems.push(newItem);
        }

        this.toShareCounter = this.selectedItems.length;
    }

    isItemSelected(item: DatabaseRole) {
        var selArr = this.selectedItems.filter(elem => { return item.id == elem.id && elem.selected; });
        if (selArr.length > 0 || item.shared) {
            return true;
        }
        return false;
    }

    /**
     * Sprawdza poprawość listy zaznaczonych elementów. Czy jeszcze istnieją i czy nie należy ich usunąć.
     */
    checkSelectedItems() {
        let tmpSelectedItems: SelectedItem[] = [];

        for (let item of this.selectedItems) {
            let items = this.roles.filter(elem => { return elem.id == item.id; });
            if (items.length > 0) {
                var selItem = items[0];
                item.originalSelected = selItem.shared;
                if (item.originalSelected != selItem.shared) {
                    tmpSelectedItems.push(item);
                }
            }
        }

        this.selectedItems = tmpSelectedItems;
    }
}
