import * as moment from 'moment';
import { Component, Output, EventEmitter, OnDestroy, Input, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { DatabaseService } from "src/app/services/databaseService/database.service";
import { DatabaseData } from "../../models/database/database.model";
import { Router } from "@angular/router";
import { SagraInputOptionsModelInterface, SagraInputStyle, SagraInputIconPosition, SagraInputErrorType } from "@sagra/sagra-input";
import { Subscription, zip } from "rxjs";
import { map } from 'rxjs/operators';
import { SelectedFilter } from "../../models/filters/selectedFilter";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { Draft } from "../../models/database/draft.model";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { SagraTooltipOptionsInterface, SagraTooltipOrientation } from '@sagra/sagra-tooltip';
import { SagraPlaceholderImage, SagraPlaceholderOptionsInterface } from '@sagra/sagra-placeholder';
import { Sorting } from '../../utils/sorting';
import { DialogsService } from '../../services/dialogsService/dialogs.service';

@Component({
    selector: 'database-list-list',
    templateUrl: './database-list-list.component.html',
    styleUrls: ['./database-list-list.component.scss']
})

export class DatabaseListListComponent extends SagraTranslatable implements OnInit, OnDestroy, OnChanges {
    @Input() filters: Array<SelectedFilter>;
    @Output() databaseSelected: EventEmitter<DatabaseData> = new EventEmitter<DatabaseData>();
    private subscriptions = new Subscription();

    private draftList: Array<Draft>;
    public draftsToShowList: Array<Draft>;
    public searchText: string;
    public searchListInputOptions: SagraInputOptionsModelInterface;
    public sortingReverse: boolean = false;
    public sortingColumn: string = 'statusSort';
    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    public tooltipOptions: SagraTooltipOptionsInterface;
    public placeholderOptions: SagraPlaceholderOptionsInterface;
    public downloadingData: boolean;

    constructor(public translationService: SagraTranslationService,
        private databaseService: DatabaseService,
        private messageService: MessageBusService,
        private dialogsService: DialogsService,
        private router: Router
    ) {
        super(translationService);

        this.prepareControlsOptions();
    }

    ngOnInit(): void {
        //this.subscriptions.add(databaseService.getDatabaseList().pipe(map(items => items.filter(item => !item.deleted))).subscribe(response => {

        this.messageService.getRefreshDatabaseList().subscribe((next) => {
            //console.log("SignalR: call RefreshDatabaseList");
            this.refreshDatabaseList();
        });

        this.refreshDatabaseList();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters && changes.filters.previousValue != changes.filters.currentValue) {
            this.applyFilters();
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onSearchTextChange(event) {
        this.applyFilters();
    }

    private refreshDatabaseList() {
        let tasks = zip(this.databaseService.getDraftList(), this.databaseService.getDraftsInfo());
        this.messageService.showLoader(true);
        this.downloadingData = true;
        this.subscriptions.add(tasks
            .pipe(map(array => {
                for (const item of array[0]) {
                    if (item.status === 0) { item.statusSort = 4 } // aktywny
                    else if (item.status === 1) { item.statusSort = 1 } // draft
                    else if (item.status === 2) { item.statusSort = 3 } // błąd
                    else if (item.status === 3) { item.statusSort = 2 } // w toku

                    item.dateSort = 0;

                    if (item.modifyDate != null) {
                        item.dateSort = parseInt(moment(item.modifyDate).format('X'));
                        item.modifyDate = moment(item.modifyDate).format('DD MMM YYYY')
                    }
                }

                for (const item of array[1]) {
                    var result = array[0].filter(x => {
                        if (x.status == 2) {
                            let dbName = x.dbName?.toLowerCase();
                            let tenant = item.tenant?.toLowerCase()
                            return dbName == tenant;
                        }
                        return false;
                    });
                    if (result != null && result.length > 0) {
                        result[0].tooltipModel = {
                            tooltipItems: [{
                                    title: this.L("Stage"),
                                    content: item.tasksCompleted + "/" + item.tasks
                                },
                                {
                                    title: this.L("Current_step"),
                                    content: this.getStepDescription(item.jobStateEnum)
                                },
                                {
                                    title: "",
                                    content: item.author
                                },
                                {
                                    title: "",
                                    content: item.creationDate
                                }]
                        }
                    }
                }
                return array;
            }))
            .subscribe(response => {
                this.draftList = response[0];
                this.applyFilters();

                this.downloadingData = false;
                this.messageService.showLoader(false);
            },
                (error) => {
                    this.downloadingData = false;
                    this.messageService.showLoader(false);
                }));
    }

    public showDetails(draft: Draft) {
        let db = new DatabaseData();
        db.name = draft.dbName;
        if (draft.status == 0) {
            this.databaseSelected.emit(db);
            //this.router.navigate(['/dbinfo'], { queryParams: { id: db.name } });
        }
        else {
            this.dialogsService.setNewDatabaseModalState({ state: true, readonly: false, draft: draft });
        }
    }

    public sortColumn(column: string, changeSort: boolean = true) {
        if (changeSort) {
            if (column != this.sortingColumn) {
                this.sortingColumn = column;
                this.sortingReverse = false;
            }
            else {
                this.sortingReverse = !this.sortingReverse;
            }
        }
        let secondColumn = null;
        if (this.sortingColumn != 'name') {
            secondColumn = 'name';
        }
        let sortColumnType = 0;
        if (this.sortingColumn == 'statusSort') {
            sortColumnType = 1;
        }
        Sorting.sortByTwoProperties(this.draftsToShowList, this.sortingColumn, secondColumn, !this.sortingReverse, sortColumnType);

        //this.draftsToShowList = this.draftsToShowList.sort((a, b) => {
        //    let item1 = a[this.sortingColumn];
        //    let item2 = b[this.sortingColumn];
        //    if (typeof item1 === 'string') { item1 = item1.toLowerCase() }
        //    if (typeof item2 === 'string') { item2 = item2.toLowerCase() }
        //    if (this.sortingReverse) {
        //        if (item1 > item2)
        //            return -1;
        //        if (item1 < item2)
        //            return 1;
        //    }
        //    else {
        //        if (item1 < item2)
        //            return -1;
        //        if (item1 > item2)
        //            return 1;
        //    }
        //    return 0;
        //});
    }

    statusClass(item: Draft) {
        if (item.status <= 0) {
            return 'mint';
        }
        else if (item.status == 3) {
            return 'cherry';
        }
        else if (item.status == 2) {
            return 'empty';
        }
        else if (item.status == 1) {
            return 'pacific';
        }
        return '';
    }

    statusText(item: Draft) {
        switch (item.status) {
            case 0:
                return this.L("ACTIVE");
            case 1:
                return this.L("Draft");
            case 2:
                return this.L("DURINGSHARING");
            case 3:
                return this.L("ERROR");
            default:
                return "";
        }
    }

    prepareControlsOptions(): void {

        this.searchListInputOptions = {
            placeholder: this.L("Search"),
            styleType: SagraInputStyle.standard,
            clearButton: true,
            errorType: SagraInputErrorType.borderOnly,
            icon: { className: 'icon-btn_search', position: SagraInputIconPosition.left }
        }

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            }
        }

        this.tooltipOptions = {
            orientation: SagraTooltipOrientation.horizontal
        }

        this.preparePlaceholderOptions();
    }

    applyFilters() {
        let component = this;
        let tmpList = [];
        if (this.draftList != null) {
            tmpList = this.draftList.filter((item) => {
                if (component.filters != null && component.filters.length > 0) {
                    for (let group of component.filters) {
                        let add = false;
                        for (let filterItem of group.items) {
                            if (group.idGroup == "status") {
                                if (filterItem.id == item.status.toString()) {
                                    add = true;
                                }
                            }
                        }
                        return add;
                    }
                }
                return true;
            })

            if (this.searchText) {
                tmpList = tmpList.filter(item => item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
            }

        }
        this.draftsToShowList = tmpList;
        this.sortColumn(this.sortingColumn, false);

        this.preparePlaceholderOptions();
    }

    preparePlaceholderOptions() {
        this.placeholderOptions = {
            title: this.L('Nothing_to_display'),
            image: {
                type: SagraPlaceholderImage.lightbulb
            }
        }

        if (((this.filters != null && this.filters.length > 0) || this.searchText) && (this.draftsToShowList == null || this.draftsToShowList.length == 0)) {
            this.placeholderOptions.title = this.L('Empty_search_holder');
            this.placeholderOptions.image = { type: SagraPlaceholderImage.search };
        }
    }

    getStepDescription(state: number) {
        switch (state) {
            case 0:
                return this.L("Step_running");
            case 5:
                return this.L("Step_suspended");
            case 10:
                return this.L("Step_manual");
            case 20:
                return this.L("Step_retrying");
            case 30:
                return this.L("Step_failed");
            case 40:
                return this.L("Step_completed");
            default:
                return '';
        }
    }
}
