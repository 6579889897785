export class DistrictData {
    public id: number;
    public name: string;
    public status: number;
    public errorInfo: string;
    public analytical: boolean;

    public new: boolean;
    public deleted: boolean;
    public originalName: string;
    public adding: boolean;
}
