import { OnDestroy, OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SagraComboboxMultiselectArrowStyle, SagraComboboxMultiselectGroupInterface, SagraComboboxMultiselectOptionsInterface, SagraComboboxMultiselectValue } from "@sagra/sagra-combobox-multiselect";
import { SagraDatetimePickerOptionsInterface, SagraDatetimePickerType } from "@sagra/sagra-datetime-picker";
import { SagraInputErrorType, SagraInputIconPosition, SagraInputOptionsModelInterface, SagraInputStyle } from "@sagra/sagra-input";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { SagraPlaceholderImage, SagraPlaceholderOptionsInterface } from "@sagra/sagra-placeholder";
import { SagraRadioComponentModelInterface } from "@sagra/sagra-radio";
import { SagraTooltipInterface } from "@sagra/sagra-tooltip";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Observable, Subscription } from "rxjs";
import { NavigationItemEnum } from "../../models/navigationItem.model";
import { HistoryFilter } from "../../models/operationsHistory/historyFilter.model";
import { HistoryLog } from "../../models/operationsHistory/historyLog.model";
import { HistoryLogItem } from "../../models/operationsHistory/historyLogItem.model";
import { HistoryLogRequest } from "../../models/operationsHistory/historyLogRequest.model";
import { OperationsHistoryService } from "../../services/operationsHistoryService/operationsHistory.service";
import { Utils } from "../../utils/utils";

@Component({
    selector: 'operations-history',
    templateUrl: './operations-history.component.html',
    styleUrls: ['./operations-history.component.scss']
})

export class OperationsHistoryComponent extends SagraTranslatable implements OnInit, OnDestroy {

    private subscriptions = new Subscription();

    private tenant: string;
    public dataSource: number;
    private elementId: number;
    private elementGuid: string;

    public showLoader: boolean;
    public searchText: string;
    public typeComboListSelectedValue: number;
    public operationComboListSelectedValue: number;
    public allowSorting: boolean;

    public headerTitle: string = "";
    public headerSubTitle: string = this.L('Results') + ':';

    public sortingColumn: string = 'Nazwa';
    public sortingReverse: boolean;

    public listItems: Array<HistoryLogItem>;

    public statusRadioModel: number = -1;
    public periodRadioModel: number = 0;
    public ordererModel: string;
    public searchNameModel: string;
    private currentFilter: HistoryFilter = {};

    public typeComboList: Array<SagraComboboxMultiselectGroupInterface>;
    public typeComboOptions: SagraComboboxMultiselectOptionsInterface;
    public operationComboList: Array<SagraComboboxMultiselectGroupInterface>;
    public operationComboOptions: SagraComboboxMultiselectOptionsInterface;
    public searchInputOptions: SagraInputOptionsModelInterface;
    public periodRadio: SagraRadioComponentModelInterface;
    public periodDatePickerModel: SagraDatetimePickerOptionsInterface;
    public statusRadio: SagraRadioComponentModelInterface;
    public placeholderOptions: SagraPlaceholderOptionsInterface;
    public sagraLoaderOptions: SagraLoaderOptionsInterface;

    private Utils: typeof Utils = Utils;

    constructor(public translationService: SagraTranslationService,
        private operationsHistoryService: OperationsHistoryService,
        private router: Router) {
        super(translationService);

        this.placeholderOptions = {
            title: this.L("Nothing_to_display"),
            description: null,
            image: {
                type: SagraPlaceholderImage.lightbulb
            }
        }

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 40,
                borderWidth: 5
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        };

    }

    ngOnInit() {
        this.router.routerState.root.queryParams.subscribe(params => {
            if (params.tenant) {
                if (params.tenant == 'undefined' || params.tenant == "null") {
                }
                else {
                    this.tenant = params.tenant;
                }
            }
            if (params.source) {
                if (params.source == 'undefined' || params.source == "null") {

                }
                else {
                    this.dataSource = params.source;
                }
            }
            if (params.id) {
                this.elementId = params.id;
            }
            if (params.guid) {
                this.elementGuid = params.guid;
            }
        });

        this.headerTitle = this.getTitle();

        this.getOperationsHistoryItems({ idTenant: this.tenant, filter: this.currentFilter });

        this.allowSorting = this.dataSource != 101;

        this.prepareControls();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onSearchHeaderTextChanged(event) {
    }

    sortColumn(column: string) {
        if (!this.allowSorting) {
            return;
        }

        if (column != this.sortingColumn) {
            this.sortingColumn = column;
            this.sortingReverse = false;
        }
        else {
            this.sortingReverse = !this.sortingReverse;
        }

        let request: HistoryLogRequest = {
            idTenant: this.tenant,
            filter: this.currentFilter
        };
        if (this.elementId != null) {
            request.idElement = this.elementId;
        }
        if (this.elementGuid != null) {
            request.guidElement = this.elementGuid;
        }
        this.getOperationsHistoryItems(request);
    }

    getTitle(): string {
        if (this.dataSource == NavigationItemEnum.ActivitiesOperations) {
            return this.L('Acts_Opers');
        }
        else if (this.dataSource == NavigationItemEnum.Processes) {
            return this.L('Processes');
        }
        else if (this.dataSource == NavigationItemEnum.TWR) {
            return this.L('TWR');
        }
        else if (this.dataSource == NavigationItemEnum.Sheets) {
            return this.L('Sheets');
        }
        else if (this.dataSource == NavigationItemEnum.Extracts) {
            return this.L('Extracts');
        }
        else if (this.dataSource == NavigationItemEnum.H5Tables) {
            return this.L('Remove_tables');
        }
        else if (this.dataSource == NavigationItemEnum.Applications) {
            return this.L('Applications');
        }
        else if (this.dataSource == 100) {
            return this.L('Agreements_aad');
        }
        else if (this.dataSource == 101) {
            return this.L('Cache');
        }
    }

    getOperationsHistoryItems(request: HistoryLogRequest) {
        this.showLoader = true;

        if (this.elementId != null) {
            request.idElement = this.elementId;
        }
        if (this.elementGuid != null) {
            request.guidElement = this.elementGuid;
        }

        if (request.filter != null) {
            request.filter.sortingColumn = this.sortingColumn;
            request.filter.sortingDirection = this.sortingReverse ? 'asc' : 'desc';
        }

        let method: Observable<HistoryLog> = null;
        if (this.dataSource == NavigationItemEnum.ActivitiesOperations) {
            method = this.operationsHistoryService.getActivitiesLog(request);
            if (this.elementId != null) {
                request.idElement = this.elementId;
            }
        }
        else if (this.dataSource == NavigationItemEnum.Processes) {
            method = this.operationsHistoryService.getProcessesLog(request);
            if (this.elementId != null) {
                request.idElement = this.elementId;
            }
        }
        else if (this.dataSource == NavigationItemEnum.Sheets) {
            method = this.operationsHistoryService.getDataSheetsLog(request);
            request.type = 2
            if (this.elementGuid != null) {
                request.guidElement = this.elementGuid;
            }
            if (this.elementId != null) {
                request.idElement = this.elementId;
            }
        }
        else if (this.dataSource == NavigationItemEnum.Extracts) {
            method = this.operationsHistoryService.getRepositoryLog(request);
            request.type = 3;
            if (this.elementId != null) {
                request.idElement = this.elementId;
            }
        }
        else if (this.dataSource == NavigationItemEnum.TWR) {
            method = this.operationsHistoryService.getRepositoryLog(request);
            request.type = 1;
            if (this.elementGuid != null) {
                request.guidElement = this.elementGuid;
            }
        }
        else if (this.dataSource == NavigationItemEnum.Applications) {
            method = this.operationsHistoryService.getRepositoryLog(request);
            request.type = 4;
            if (this.elementId != null) {
                request.idElement = this.elementId;
            }
        }
        else if (this.dataSource == 100) { //zgody AAD
            method = this.operationsHistoryService.getRepositoryLog(request);
            request.type = 100;
            if (this.elementId != null) {
                request.idElement = this.elementId;
            }
        }
        else if (this.dataSource == 101) { //cache
            method = this.operationsHistoryService.getCacheLog(request);
            request.type = 100;
            if (this.elementId != null) {
                request.idElement = this.elementId;
            }
        }
        else if (this.dataSource == NavigationItemEnum.H5Tables) {
            method = this.operationsHistoryService.getRepositoryLog(request);
            request.type = 101;
            if (this.elementId != null) {
                request.idElement = this.elementId;
            }
        }

        this.subscriptions.add(method.subscribe((data) => {
            this.listItems = data.logs;
            if (this.dataSource == 2) {
                this.listItems.forEach(item => item.tooltip = this.prepareTooltipOptions(item));
            }

            const count = this.listItems.length;
            this.headerSubTitle = this.L('Results') + ':  ' + count + (count>=100 ? '+' : '');

            if (data.types != null) {
                this.typeComboList = [{ list: [] }];
                for (let type of data.types) {
                    this.typeComboList[0].list.push(new SagraComboboxMultiselectValue(type.type, type.idType));
                }
            }
            this.showLoader = false;
        },
            (error) => {
                this.showLoader = false;
            }));
    }


    prepareControls() {
        this.periodRadio = {
            values: [{
                    name: this.L('No_date'),
                    value: 0
                },
                {
                    name: this.L('Last_week'),
                    value: 1
                },
                {
                    name: this.L('Last_month'),
                    value: 2
                },
                {
                    name: this.L('Last_year'),
                    value: 3
                },
                {
                    name: this.L('Manual_range'),
                    value: 4
                }]
        }

        this.statusRadio = {
            values: [{
                name: this.L('All'),
                value: -1
            },
            {
                name: this.L('Successful'),
                value: 2
            },
            {
                name: this.L('Not_successful'),
                value: 3
            },
            {
                name: this.L('In_progress'),
                value: 1
            }]
        }

        this.statusRadio.values.push({ name: this.L('Planned'), value: 4 });

        this.periodDatePickerModel = {
            type: SagraDatetimePickerType.dateRange
        }

        this.searchInputOptions = {
            placeholder: this.L("Search"),
            styleType: SagraInputStyle.standard,
            clearButton: true,
            errorType: SagraInputErrorType.borderOnly,
            icon: { className: 'icon-btn_search', position: SagraInputIconPosition.left }
        }

        this.typeComboOptions = {
            multiselect: false,
            clearButton: false,
            arrowStyle: SagraComboboxMultiselectArrowStyle.dobule,
            emptyPosition: {
                name: '',
                value: 0
            }
            
        }
        this.operationComboOptions = {
            multiselect: false,
            clearButton: false,
            arrowStyle: SagraComboboxMultiselectArrowStyle.dobule,
            emptyPosition: {
                name: '',
                value: 0
            }
        }

        this.operationComboList = [{
            list: []
        }]

        if (this.dataSource == NavigationItemEnum.H5Tables) {
            this.operationComboList[0].list.push({ name: this.L("Remove"), value: 3 });
        }
        else {
            this.operationComboList[0].list.push({ name: this.L("Share_2"), value: 1 });
            this.operationComboList[0].list.push({ name: this.L("Unshare"), value: 2 });
        }
    }

    statusClass(item: any) {
        if (item.status == 2) {
            return 'mint';
        }
        if (item.status == 3) {
            return 'cherry';
        }
        if (item.status == 4) {
            return 'mint';
        }
        if (item.status == 1) {
            return '';//inprogress
        }
        return '';
    }

    applyFilters() {
        let request: HistoryLogRequest = {
            idTenant: this.tenant,
        };
        this.currentFilter = {};
        //nazwa
        this.currentFilter.name = this.searchNameModel;
        //okres
        if (this.periodRadioModel != 0) {
            this.currentFilter.period = this.periodRadioModel;
            if (this.periodRadioModel == 4 && this.periodDatePickerModel.comparisonDates != null) {
                this.currentFilter.periodStart = this.periodDatePickerModel.comparisonDates.start.utc(true);
                this.currentFilter.periodEnd = this.periodDatePickerModel.comparisonDates.end.utc(true);
            }
        }
        //zlecający
        this.currentFilter.orderer = this.ordererModel;
        //typ
        this.currentFilter.type = this.typeComboListSelectedValue;
        //operacja
        if (this.operationComboListSelectedValue != 0) {
            this.currentFilter.operation = this.operationComboListSelectedValue;
        }
        //status
        if (this.statusRadioModel > -1) {
            this.currentFilter.status = this.statusRadioModel;
        }
        else {
            this.currentFilter.status = null;
        }
        //sortowanie
        this.currentFilter.sortingColumn = this.sortingColumn;
        //kierunek sortowania
        this.currentFilter.sortingDirection = this.sortingReverse ? 'asc' : 'desc';

        request.filter = this.currentFilter;

        this.getOperationsHistoryItems(request);
    }

    clearFilters() {
        this.searchNameModel = null;
        this.typeComboListSelectedValue = null;
        this.operationComboListSelectedValue = null;
        this.statusRadioModel = -1;
        this.periodRadioModel = 0;
        this.periodDatePickerModel.comparisonDates = null;
        this.ordererModel = null;
    }

    prepareTooltipOptions(item: HistoryLogItem) {
        if (item != null) {
            var options: SagraTooltipInterface = {
                tooltipItems: []
            };

            options.tooltipItems.push({ title: this.L('Catalog_name'), content: item.name });
            options.tooltipItems.push({ title: this.L('Display_name'), content: item.displayName });
            return options;
        }
    }
}
