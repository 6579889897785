export enum JobTaskType {
    Empty = 0,
    AW2 = 1,
    ActivateRegister = 2,
    Activity = 3,
    API = 4,
    Districts = 5,
    DomainRegister = 6,
    KeyBuilder = 7,
    Processes = 8,
    EmigoDataSheet = 9,
    EmigoRole = 10,
    SqlScripts = 11,
    TWR = 12,
    PowerShell = 13,
    Terraform = 14,
    Extract = 15,
    PortalLogo = 16,
    BazaBaz = 17,
    EngineH5Uploader = 18,
    MsGraphApi = 19,
    UserManagment = 20,
    Manual = 998,
    ManualAutoCompleted = 999,
    test_service1 = 1000,
    test_service2 = 1001
}
