import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { H5TableListData } from "../../models/h5Tables/h5TableListData.model";

@Injectable()
export class H5TablesService {
    private apiUrl: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    getTables(tenant: string): Observable<H5TableListData[]> {
        return this.http.post<H5TableListData[]>("api/H5Tables/GetTables", { dbName: tenant });
    }

    getTableDetails(tenant: string, tableName: string, tableGuid: string): Observable<any> {
        return this.http.post<any>("api/H5Tables/GetTableDetails", { dbName: tenant, tableName: tableName, tableGuid: tableGuid });
    }

    deleteTable(tenant: string, tableGuid: string, tableName: string): Observable<any> {
        return this.http.post("api/H5Tables/DeleteTable", { dbName: tenant, tableGuid: tableGuid, tableName: tableName });
    }
}
