<div class="dlg-select-feed-groups" *ngIf="visible">
    <div [@dialogOpacity]="animation" class="dlg-backdrop" (click)="onBackdropClick($event)"></div>
    <div [@dialogZoom]="animation" [@dialogOpacity]="animation" class="dlg-content-container">
        <div class="dlg-content">
            <div class="dlg-title">
                <div class="dlg-title-text">{{L('Limited_to_groups')}}</div>
                <div class="dlg-title-close icon icon-btn_close" (click)="onCloseClick()"></div>
            </div>
            <ng-scrollbar class="sagra-scrollbar" [autoHeightDisabled]="true" [track]="'vertical'">
                <div class="dlg-content-scroll">
                    <div class="dlg-group-list-row" *ngFor="let group of options.feedGroups">
                        <sagra-checkbox [state]="isGroupSelected(group)"
                                        [value]="group.id"
                                        [labelText]="group.name"
                                        [labelPosition]="checkboxOptions.labelPosition"
                                        [checkboxsize]="checkboxOptions.checkboxsize"
                                        [clickable]="checkboxOptions.clickable"
                                        [singleLineLabel]="checkboxOptions.singleLineLabel"
                                        (update)="selectGroup(group, $event)">
                        </sagra-checkbox>
                    </div>
                </div>
            </ng-scrollbar>
            <div class="dlg-footer">
                <button class="sgf-btn-primary dlg-btn-publish" (click)="onPublishClick($event)" [disabled]="selectedFeedGroups == null || selectedFeedGroups.length == 0">{{L('Publish')}}</button>
                <button class="sgf-btn-secondary dlg-btn-cancel" (click)="onCancelClick($event)">{{L('Cancel')}}</button>
            </div>
        </div>
    </div>
</div>
