import { Injectable } from '@angular/core';
import { NotificationService } from '../notificationService/notification.service';
import { MessageBusService } from '../messageBusService/messageBusService';
import { ConfigurationService } from '../configurationService/configuration.service';
import * as signalR from "@microsoft/signalr";
@Injectable({
    providedIn: 'root'
})
export class SignalRService {

    public hubConnectionProd: signalR.HubConnection;
    public hubConnectionDev: signalR.HubConnection;
    constructor(private notificationService: NotificationService,
        private messageService: MessageBusService,
        private configurationService: ConfigurationService) {

    }

    public startConnection = (username) => {

        this.hubConnectionProd = new signalR.HubConnectionBuilder()
            .withUrl('https://sgr-api-prod-emigoweb-centralsignalrhub.azurewebsites.net/AutoDeployHub?username=' + username)
            //.withUrl('https://localhost:44392/AutoDeployHub?username=' + username)
            .withAutomaticReconnect()
            .build();

        this.hubConnectionDev = new signalR.HubConnectionBuilder()
            .withUrl('https://sgr-api-dev-emigoweb-centralsignalrhub.azurewebsites.net/AutoDeployHub?username=' + username)
            //.withUrl('https://localhost:44392/AutoDeployHub?username=' + username)
            .withAutomaticReconnect()
            .build();

        this.hubConnectionProd
            .start()
            .then(() => console.log('Connection started Prod'))
            .catch(err => console.log('Error while starting connection Prod: ' + err))

        this.hubConnectionDev
            .start()
            .then(() => console.log('Connection started Dev'))
            .catch(err => console.log('Error while starting connection Dev: ' + err))
    }

    public initializeEvents(hubConnection: signalR.HubConnection) {

        hubConnection.on("RefreshDatabaseList", (data) => {
            console.log("SignalR: RefreshDatabaseList data:" + data);
            this.messageService.refreshDatabaseList();
        });

        hubConnection.on("JobInfo", (data) => {
            console.log("SignalR: Monitorign JobInfo:" + data);
            this.messageService.setMonitoringRefresh(data);
        });
        hubConnection.on("ReciveJobStatus", (data) => {
            console.log("SignalR: Monitorign JobInfo:" + data);
            this.messageService.setMonitoringRefresh(data);
        });

        hubConnection.on("RefreshNotificationCenter", (data) => {
            console.log("SignalR: RefreshNotificationCenter data: " + data);
            this.notificationService.refreshNotifications(data);
        });

        hubConnection.on("RefreshList", (data) => {
            const list = JSON.parse(data);
            console.log("SignalR: RefreshList code: " + list.RefreshList + "   data:" + data);
            let tenant: string = list.Tenant;
            if (tenant != null)
                tenant = tenant.toLowerCase();
            switch (list.RefreshList) {
                case 'Draft': {
                    this.messageService.refreshDatabaseList();
                    break;
                }
                case 'EmigoRole': {
                    this.messageService.refreshRolesList({ tenant: tenant });
                    break;
                }
                case 'EmigoDataSheet': {
                    this.messageService.refreshRepositoryList({ tenant: tenant, module: 'Sheets' });
                    break;
                }
                case 'Processes': {
                    this.messageService.refreshRepositoryList({ tenant: tenant, module: 'Processes' });
                    break;
                }
                case 'Activity': {
                    this.messageService.refreshRepositoryList({ tenant: tenant, module: 'Activities' });
                    break;
                }
                case 'TWR': {
                    this.messageService.refreshRepositoryList({ tenant: tenant, module: 'TWR' });
                    break;
                }
                case 'Extract': {
                    this.messageService.refreshRepositoryList({ tenant: tenant, module: 'Extracts'});
                    break;
                }
                case 'Districts': {
                    this.messageService.refreshDistrictList({ tenant: tenant });
                    break;
                }
                case 'ActivateRegister': {
                    this.messageService.refreshRegisterList({ tenant: tenant });
                    break;
                }
                case 'BazaBaz': {
                    this.messageService.refreshWinpoint({tenant: tenant});
                    break;
                }
                case 'BiqPackage': {
                    this.messageService.refreshBiqPackage({ tenant: tenant });
                    break;
                }
                case 'AADPermissions': {
                    this.messageService.refreshAADAgreements();
                    break;
                }
                case 'NewWorld': {
                    this.messageService.refreshNewWorld();
                    break;
                }
                case 'EnableApplication': {
                    this.messageService.refreshApplications();
                    break;
                }
            }
            //Manual,
            //AW2,
            //ActivateRegister,
            //Activity,
            //API,
            //Districts,
            //DomainRegister,
            //KeyBuilder,
            //Processes,
            //EmigoDataSheet,
            //EmigoRole,
            //SqlScripts,
            //TWR,
            //PowerShell,
            //Terraform,
            //test_service1 = 1000,
            //test_service2 = 1001
            //PortalLogo
        });

        hubConnection.on('SharedTask', (data) => {
            alert(data);
        });
    }
}
