<div class="main-container">
    <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
    <div class="filters">
        <div class="leftfilters">
            <div>
                <sagra-input [(model)]="searchText" [options]="searchInputOptions" (onClear)="filterHandler()" (onKeyupEnter)="filterHandler()" (update)="filterGridHandler()"></sagra-input>
            </div>
            <div class="col-desc">
                {{L('Records')}}: {{jobsCount}}
            </div>
            <sagra-grid-sort [id]="'grid-sort'"
                             [gridId]="'demo-grid'"
                             [(show)]="showColumnsFilter"
                             [(columnsDefinition)]="gridOptions.columnsDefinition"
                             [stickyFirstColumn]="gridOptions.stickyFirstColumn"
                             [stickyLastColumn]="gridOptions.stickyLastColumn"
                             [zIndex]="4000">
                <button class="sgf-btn-white-border" [sagraGridSortButton]="'grid-sort'">
                    <span class="icon icon-view_column" style="margin-right: 5px;"></span>
                    <span>Zarządzaj kolumnami</span>
                </button>
            </sagra-grid-sort>
            <div>
                <sagra-filters #filtersCtrl
                               [options]="filtersOptions"
                               [filterId]="'page-filters-files'"
                               [(filters)]="filters"
                               (filtersChange)="filterHandler()">
                </sagra-filters>
            </div>
        </div>
        <div class="right-filter">
        </div>
    </div>

    <sagra-placeholder *ngIf="!searchResult" [title]="filterNoResultOptions.title"
                       [image]="filterNoResultOptions.image"></sagra-placeholder>

    <div *ngIf="list.length>0 && searchResult" class="gridContainer">
        <sagra-grid [(list)]="list"
                    [(columnsDefinition)]="gridOptions.columnsDefinition"
                    [rowIdColumn]="gridOptions.rowIdColumn"
                    [searchText]="searchText?.toLowerCase()"
                    [searchByColumns]="gridOptions.searchByColumns"
                    [markNotIncluded]="gridOptions.markNotIncluded"
                    [disabledColumn]="'disabled'"
                    [stickyHeader]="gridOptions.stickyHeader"
                    [clickRowHandler]="gridOptions.clickRowHandler"
                    [selectionColumn]="gridOptions.selectionColumn"
                    [(sortingColumn)]="gridOptions.sortingColumn"
                    [(sortingAsc)]="gridOptions.sortingAsc"
                    [tableMinWidth]="gridOptions.tableMinWidth"
                    [scroll]="gridOptions.scroll"
                    [rowClassColumn]="gridOptions.rowClassColumn"
                    [rowAlignment]="gridOptions.rowAlignment"
                    [enableRowSelectionMode]="gridOptions.enableRowSelectionMode"
                    PL>
        </sagra-grid>
    </div>
</div>
