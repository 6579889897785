import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Subscription, Subject } from "rxjs";
import { H5TablesService } from "../../services/h5tablesService/h5tables.service";
import { SagraDialogType, SagraDialogService } from "@sagra/sagra-dialog";
import { H5TableListData } from "../../models/h5Tables/h5TableListData.model";
import { Utils } from "../../utils/utils";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { map } from "rxjs/operators";
import { H5TableData } from "../../models/h5Tables/h5TableData.model";
import { H5TableUsedPlaces } from "../../models/h5Tables/h5TableUsedPlaces.model";
import { NavigationItemEnum } from "../../models/navigationItem.model";
import { OperationsHistoryService } from "../../services/operationsHistoryService/operationsHistory.service";
import { SagraPlaceholderImage, SagraPlaceholderOptionsInterface } from "@sagra/sagra-placeholder";
import { ResizeList, ShowPanel } from "../../animations/animations";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";

@Component({
    selector: 'h5-tables',
    templateUrl: './h5-tables.component.html',
    styleUrls: ['./h5-tables.component.scss'],
    animations: [ ShowPanel(), ResizeList() ]
})

export class H5TablesComponent extends SagraTranslatable implements OnInit, OnDestroy {
    @Input() tenant: string;
    @ViewChild('detailsPanel') detailsPanel: ElementRef;

    private subscriptions = new Subscription();
    public headerTitle = this.L('Remove_tables');
    public searchText: string;
    public sortingReverse: boolean;
    public sortingColumn: string = 'name';
    public tables: Array<H5TableListData>;
    public filteredTables: Array<H5TableListData>;
    public selectedTable: H5TableListData;
    public isSolutions: boolean;
    public showLoaderDetails: boolean;
    public showLoader: boolean;
    public tableUsedPlaces: Array<H5TableData>;
    public firstDownloadingData: boolean = true;
    public counterText: string;

    public detailsPanelVisible: boolean;
    private deleteSubject: Subject<any> = new Subject<any>();

    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    public placeholderOptions: SagraPlaceholderOptionsInterface;

    constructor(public translationService: SagraTranslationService,
        public tablesService: H5TablesService,
        private operationService: OperationsHistoryService,
        public dialogService: SagraDialogService) {
        super(translationService);

        this.subscriptions.add(this.deleteSubject.asObservable().subscribe(
            x => this.deleteTable()
        ));
    }

    ngOnInit() {
        this.isSolutions = Utils.isSolutions(this.tenant);

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 40,
                borderWidth: 5
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        };

        this.preparePlaceholderOptions();

        this.getTablesList();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onSearchTextChanged(event) {
        this.searchText = event;
        this.applyFilters();
    }

    onListItemClick(item) {
        if (this.selectedTable == null || this.selectedTable.guid != item.guid) {
            this.selectedTable = item;
            this.getTableDetails();
            this.detailsPanelVisible = true;
        }
        else if (this.selectedTable.guid == item.guid) {
            this.detailsPanelVisible = !this.detailsPanelVisible;
        }
    }

    onClosePanelClick(event) {
        this.detailsPanelVisible = false;
    }

    onDeleteButtonClick(event) {
        this.dialogService.showAlert({
            dialogType: SagraDialogType.confirm,
            message: this.L('Ask_delete_table'),
            backdropClose: true,
            buttons: [
                { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                { name: this.L('Delete_table'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.custom, className: 'sgf-btn-cherry-light', handler: this.deleteSubject }
            ]
        });
    }

    onHistoryButtonClicked(event) {
        this.operationService.openInNewTab(this.tenant, NavigationItemEnum.H5Tables, null, null);
    }

    onHistoryListItemClick(item: H5TableListData) {
        this.operationService.openInNewTab(this.tenant, NavigationItemEnum.H5Tables, null, item.guid);
    }

    getTablesList() {
        this.showLoader = true;
        this.subscriptions.add(this.tablesService.getTables(this.tenant).subscribe(data => {
            this.tables = data;
            this.applyFilters();
            this.firstDownloadingData = false;
            this.showLoader = false;
        },
            (error) => {
                this.firstDownloadingData = false;
                this.showLoader = false;
            }));
    }

    getTableDetails() {
        this.showLoaderDetails = true;
        this.subscriptions.add(this.tablesService.getTableDetails(this.tenant, this.selectedTable.name, this.selectedTable.guid)
            .pipe(map(items => {
                const places = new Array<H5TableData>();
                for (const item of items) {
                    let tenant: H5TableData;
                    for (let i = 0; i < places.length; i++) {
                        if (item.idTenant == places[i].tenantName) {
                            tenant = places[i];
                            break;
                        }
                    }
                    if (tenant == null) {
                        tenant = new H5TableData();
                        tenant.tableUsedPlaces = [];
                        tenant.tenantName = item.idTenant;
                        places.push(tenant);
                    }
                    let place: H5TableUsedPlaces;
                    for (let i = 0; i < tenant.tableUsedPlaces.length; i++) {
                        if (item.placeType == tenant.tableUsedPlaces[i].placeType) {
                            place = tenant.tableUsedPlaces[i];
                            break;
                        }
                    }
                    if (place == null) {
                        place = new H5TableUsedPlaces();
                        place.places = [];
                        place.placeType = item.placeType;
                        tenant.tableUsedPlaces.push(place);
                    }
                    place.places.push(item.name);
                }
                return places;
            })).subscribe(data => {
                this.tableUsedPlaces = data;
                this.showLoaderDetails = false;
            },
                (error) => {
                    this.showLoaderDetails = false;
                }));
    }

    deleteTable() {
        this.showLoader = true;
        this.subscriptions.add(this.tablesService.deleteTable(this.tenant, this.selectedTable.guid, this.selectedTable.name).subscribe(data => {
            this.detailsPanelVisible = false;
            this.getTablesList();
        },
            (error) => {
                this.showLoader = false;
            }));
    }

    sortColumn(column: string, changeSort: boolean = true) {
        if (changeSort) {
            if (column != this.sortingColumn) {
                this.sortingColumn = column;
                this.sortingReverse = false;
            }
            else {
                this.sortingReverse = !this.sortingReverse;
            }
        }
        this.filteredTables = this.filteredTables.sort((a, b) => {
            let item1 = String(a[this.sortingColumn]).toLowerCase();
            let item2 = String(b[this.sortingColumn]).toLowerCase();
            if (this.sortingReverse) {
                if (item1 > item2)
                    return -1;
                if (item1 < item2)
                    return 1;
            }
            else {
                if (item1 < item2)
                    return -1;
                if (item1 > item2)
                    return 1;
            }
            return 0;
        });

        this.filteredTables = [...this.filteredTables];
    }

    getPlaceTypeName(type: number) {
        switch (type) {
            case 1:
                return this.L('Actions');
            case 2:
                return this.L('Operations');
            case 3:
                return this.L('Processes');
            case 4:
                return this.L('Extracts');
            case 5:
                return this.L('Sheets');
        }
        return '';
    }

    preparePlaceholderOptions() {
        this.placeholderOptions = {
            title: this.L('Nothing_to_display'),
            image: {
                type: SagraPlaceholderImage.lightbulb
            }
        }

        if (this.searchText && (this.filteredTables == null || this.filteredTables.length == 0)) {
            this.placeholderOptions.title = this.L('Empty_search_holder');
            this.placeholderOptions.image = { type: SagraPlaceholderImage.search };
        }
    }

    applyFilters() {
        let tmpListItems: Array<H5TableListData> = this.tables;

        if (this.tables != null) {
            if (this.searchText) {
                tmpListItems = tmpListItems.filter(item => item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
            }
        }

        this.filteredTables = tmpListItems;

        this.sortColumn(this.sortingColumn, false);

        this.counterText = '(' + this.filteredTables.length + ')';

        this.preparePlaceholderOptions();
    }
}
