import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { DistrictsService } from "../../services/districtsService/districts.service";
import { DistrictData } from "../../models/districts/districtData";
import { SagraInputOptionsModelInterface, SagraInputType, SagraInputStyle } from "@sagra/sagra-input";
import { SagraDialogService, SagraDialogOptionsInterface, SagraDialogOptions, SagraDialogType } from "@sagra/sagra-dialog";
import { DistrictAssign } from "../../models/districts/districtAssign";
import { Subscription } from "rxjs";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";

@Component({
    selector: 'districts-list',
    templateUrl: './districts-list.component.html',
    styleUrls: ['./districts-list.component.scss']
})

export class DistrictsListComponent extends SagraTranslatable implements OnInit, OnDestroy, OnChanges {
    @ViewChild('districtContent') districtListElement: ElementRef;
    @Input() tenant: string;

    private subscriptions = new Subscription();
    public searchText: string;
    public districtsList: DistrictData[];
    public headerTitle: string = this.L('Districts');
    public headerSubTitle: string;// = this.L('TurnedOn');
    public actionButtonText: string = this.L('Save');
    public actionButtonVisible: boolean = true;
    public actionButtonDisabled: boolean;
    public showLoader: boolean;
    public inputOptions: SagraInputOptionsModelInterface;
    public inputOptionsDisabled: SagraInputOptionsModelInterface;
    public newInputOptions: SagraInputOptionsModelInterface;
    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    public newDistrictName: string;

    constructor(public translationService: SagraTranslationService,
        private messageService: MessageBusService,
        private dialogService: SagraDialogService,
        private districtsService: DistrictsService) {

        super(translationService);

        this.newDistrictName = "";

        this.inputOptions = {
            type: SagraInputType.text,
            styleType: SagraInputStyle.minimal,
            characterLimit: 50,
            placeholder: "",
            isRequired: true
        }

        this.inputOptionsDisabled = {
            type: SagraInputType.text,
            styleType: SagraInputStyle.minimal,
            characterLimit: 50,
            placeholder: "",
            isDisabled: true
        }

        this.newInputOptions = {
            type: SagraInputType.text,
            styleType: SagraInputStyle.standard,
            characterLimit: 50,
            placeholder: this.L("Add_position")
        }

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }
    }

    ngOnInit(): void {
        this.showLoader = true;
        this.messageService.getRefreshDistrictList().subscribe((next) => {
            if (next.tenant == this.tenant.toLowerCase()) {
                //console.log("SignalR: call RefreshDistrictsList next: " + JSON.stringify(next));
                this.getDistrictsList();
            }
        });

        this.getDistrictsList();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        let currentItem: SimpleChange = changes.tenant;
        if (currentItem.currentValue) {
            this.tenant = changes.tenant.currentValue;
            if (currentItem.previousValue) {
                this.showLoader = true;
                this.getDistrictsList();
            }
        }
    }

    onSearchTextChanged(event) {
        this.searchText = event;
    }

    onActionButtonClicked($event) {
        let districtToSend: DistrictAssign[] = [];
        for (let item of this.districtsList) {
            if (!item.new && item.name == item.originalName && !item.deleted) {
                continue;
            }
            let district = new DistrictAssign();
            if (!item.new) {
                district.id = item.id;
            }
            district.name = item.name;
            district.deleted = item.deleted;
            districtToSend.push(district);
        }
        if (districtToSend.length > 0) {
            this.showLoader = true;
            this.subscriptions.add(this.districtsService.saveDistricts(this.tenant, districtToSend).subscribe(data => {
                for (let district of this.districtsList) {
                    if (!district.new) {
                        district.originalName = district.name;
                    }
                }
                this.getDistrictsList();
            }, (error) => {
                this.showLoader = false;
            }));
        }
    }

    getDistrictsList() {
        this.subscriptions.add(this.districtsService.getBricksList(this.tenant).subscribe(data => {

            const tmpList = data;
            if (this.districtsList != null) {
                for (let district of this.districtsList) {
                    if (district.new) {
                        let arr = tmpList.filter((item) => { return item.name == district.name; });
                        if (arr == null || arr.length == 0) {
                            //district.status = 1;
                            tmpList.push(district);
                        }
                    }
                    else {
                        let arr = tmpList.filter((item) => { return item.id == district.id; });
                        if (arr != null && arr.length > 0) {
                            arr[0].name = district.name;
                            //arr[0].originalName = district.originalName;
                            arr[0].deleted = district.deleted;
                        }
                    }
                }
            }

            this.districtsList = tmpList;
            this.showLoader = false;
        }, (error) => {
            this.showLoader = false;
        }));
    }

    deleteDistrict(district: DistrictData) {
        if (district.new) {
            let index = this.districtsList.indexOf(district);
            if (index > -1) {
                this.districtsList.splice(index, 1);
            }
        }
        else {
            district.deleted = true;
        }
    }

    addNewPosition() {
        if (this.newDistrictName != null && this.newDistrictName.length > 0) {
            this.newDistrictName = this.newDistrictName.trim();
            if (!this.checkDistrictNameExists(this.newDistrictName)) {
                let dialogOptions: SagraDialogOptions = {
                    backdropClose: true,
                    dialogType: SagraDialogType.alert,
                    message: this.L("District_name_exists")
                };

                this.dialogService.showAlert(dialogOptions);
                return;
            }

            let district = new DistrictData();
            district.new = true;
            district.name = district.originalName = this.newDistrictName;
            this.districtsList.push(district);
            this.newDistrictName = "";

            setTimeout(() => {
                this.districtListElement.nativeElement.scrollTop = this.districtListElement.nativeElement.scrollHeight;
            }, 100);

        }
    }

    checkDistrictNameExists(newName: string) {
        let result = this.districtsList.filter(item => { return item.name == newName; });
        return result.length == 0;
    }

}
