import { Component, Output, EventEmitter, Input } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { SagraCheckboxComponentOptionsInterface, SagraCheckboxComponentOptions, SagraCheckboxComponentLabelPosition, SagraCheckboxComponentSize } from "@sagra/sagra-checkbox";
import { SelectedFilter } from "../../models/filters/selectedFilter";
import { FilterGroup } from "../../models/filters/filterGroup.model";
import { FilterItem } from "../../models/filters/filterItem.model";

@Component({
    selector: "database-list-filters",
    templateUrl: "./database-list-filters.component.html",
    styleUrls: ["./database-list-filters.component.scss"]
})

export class DatabaseListFiltersComponent extends SagraTranslatable {
    @Input() filters: FilterGroup[];
    @Output() filterChanged: EventEmitter<Array<SelectedFilter>> = new EventEmitter<Array<SelectedFilter>>();

    public checkboxSize: SagraCheckboxComponentSize = SagraCheckboxComponentSize.small;
    public checkboxLabelPosition: SagraCheckboxComponentLabelPosition = SagraCheckboxComponentLabelPosition.right;

    constructor(public translationService: SagraTranslationService) {
        super(translationService);

    }

    public clearFilters() {
        for (let group of this.filters) {
            group.clearSelection();
        }

        this.onFilterChange(false, null, null);
    }

    public onFilterChange(value, groupSel, itemSel) {
        if (groupSel != null) {
            groupSel.setFilterSelected(itemSel.key, value);
        }

        let selectedFilters: Array<SelectedFilter> = [];
        for (let group of this.filters) {
            let selFilter = group.getSelectedFilters();

            if (selFilter.items.length > 0) {
                selectedFilters.push(selFilter);
            }
        }
        if (this.filterChanged != null) {
            this.filterChanged.emit(selectedFilters);
        }
    }
}
