import { AfterViewInit, Component } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Router } from "@angular/router";
import { NavigationGroup, NavigationItemEnum } from "../../models/navigationItem.model";
import { TenantService } from "../../services/tenantService/tenant.service";
import { TenantConfiguration } from "../../models/tenant/tenantConfiguration.model";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { BreadcrumbsService } from "../breadcrumbs/breadcrumbs.service";
import { Breadcrumbs } from "../../models/breadcrumbs.model";
import { Subject } from "rxjs";

@Component ({
    selector: 'database-details',
    templateUrl: './database-details.component.html',
    styleUrls: ['./database-details.component.scss']
})

export class DatabaseDetailsComponent extends SagraTranslatable implements AfterViewInit {
    public selectedItem: NavigationItemEnum = NavigationItemEnum.Unset;
    public tenantId: string;
    public tenantConfiguration: TenantConfiguration = new TenantConfiguration();
    public navigationItems: NavigationGroup[];
    private breadcrumbs: Breadcrumbs;

    private bcCustomerClick = new Subject<any>();

    constructor(public translationService: SagraTranslationService,
        private tenantService: TenantService,
        private messageService: MessageBusService,
        private router: Router,
        private breadcrumbsService: BreadcrumbsService
    ) {
        super(translationService);

        this.bcCustomerClick.subscribe((data) => {
            this.router.navigate(["/"]);
        });
    }

    ngOnInit() {
        //this.route.paramMap.subscribe((params: ParamMap) => {
        //    const id = params.get('id');
        //    this.tenantId = id;
        //    this.refreshDatabaseDetails();
        //});

        this.router.routerState.root.queryParams.subscribe(params => {
            //this.urlParams = params;
            if (params.id && (params.id == 'undefined' || params.id == "null")) {
                console.log("redirect to '/' constructor");
                this.router.navigate(["/"]);
                return;
            }
            this.tenantId = params.id;

            if (params.nav && (params.id != 'undefined' && params.id != "null")) {
                this.selectedItem = parseInt(params.nav);
            }

        });

        this.breadcrumbs = {
            id: 1,
            items: [
                { id: "1", name: this.L('Clients'), handler: this.bcCustomerClick },
                { id: "2", name: this.tenantId, handler: null }]
        }

        this.messageService.getRefreshDatabaseData().subscribe((data) => {
            this.refreshDatabaseDetails();
        });

        this.getTenantConfiguration();

        this.prepareNavigationItems();
    }

    ngAfterViewInit() {
        this.breadcrumbsService.setBreadcrumbs(this.breadcrumbs);
    }

    onSelectedItemChanged(selectedItem: NavigationItemEnum) {
        this.router.navigate(['/dbinfo'], { queryParams: { id: this.tenantId, nav: selectedItem } });
        this.selectedItem = selectedItem;
        this.breadcrumbsService.setBreadcrumbs(this.breadcrumbs);
    }

    refreshDatabaseDetails() {
        this.getTenantConfiguration();
    }

    private getTenantConfiguration() {
        this.tenantService.getTenantConfiguration(this.tenantId).subscribe((data) => {
            this.tenantConfiguration = data;
            this.prepareNavigationItems();
        });
    }

    prepareNavigationItems() {
        this.navigationItems = [{
            id: 1,
            name: "",
            items: [{
                id: NavigationItemEnum.DatabaseInfo,
                name: this.L('Client_settings'),
                iconClass: "icon-settings"
            }]
        },
        {
            id: 2,
            name: this.L('Repositories'),
            items: [{
                id: NavigationItemEnum.ActivitiesOperations,
                name: this.L('Acts_Opers'),
                shortName: this.L('Activities'),
                iconClass: "icon-activities-catalog",
                tooltip: { tooltipItems: [{ content: this.L('Acts_Opers') }]}
                //disabled: !this.permission.canRead
            }, {
                id: NavigationItemEnum.Processes,
                name: this.L('Processes'),
                iconClass: "icon-loop-alt3"
                //disabled: !this.permission.canRead
            }, {
                id: NavigationItemEnum.Roles,
                name: this.L('Roles'),
                iconClass: "icon-business_center"
            }, {
                id: NavigationItemEnum.Sheets,
                name: this.L('Sheets'),
                    iconClass: "icon-zestawienia"
            }, {
                id: NavigationItemEnum.Extracts,
                name: this.L('Extracts'),
                iconClass: "icon-table"
            }, {
                id: NavigationItemEnum.TWR,
                name: this.L('TWR'),
                iconClass: "icon-addin-TWR"
                //disabled: !this.permission.canRead
            }]
        },
        {
            id: 3,
            name: this.L('Configuration'),
            items: [{
                id: NavigationItemEnum.Register,
                name: this.L('Register'),
                iconClass: "icon-assignment"
            }, {
                id: NavigationItemEnum.Districts,
                name: this.L('Districts'),
                iconClass: "icon-earth"
            }, {
                id: NavigationItemEnum.WinPoint,
                name: this.L('WinPoint'),
                hidden: this.tenantConfiguration == null || !this.tenantConfiguration.winPointEnabled,
                iconClass: "icon-winpoint-sygnet"
            }, {
                id: NavigationItemEnum.H5Tables,
                name: this.L('Remove_tables'),
                hidden: false,
                iconClass: "icon-trash-o"
            }, {
                id: NavigationItemEnum.Hierarchy,
                name: this.L('Hierarchy'),
                iconClass: "icon-flow-cascade"
                //disabled: !this.permission.canRead
            }, {
                id: NavigationItemEnum.Applications,
                name: this.L('Applications'),
                iconClass: "icon-grid_view"
            }, {
                id: NavigationItemEnum.Targeting,
                name: this.L('Targeting'),
                iconClass: "icon-track_changes"
            },
            ]
        }];
    }

}
