<div class="history-component" [ngClass]="{'no-filters': dataSource==101}">
    <div class="history-left-panel" *ngIf="dataSource!=101">
        <div class="history-filters">
            <div class="history-filters-header">
                <div class="history-filters-header-title">{{L('Filters')}}</div>
                <div class="history-filters-header-clear sgf-link-pacific" (click)="clearFilters()">{{L('Clear')}}</div>
            </div>
            <ng-scrollbar class="sagra-scrollbar" [autoHeightDisabled]="true" [track]="'vertical'">
                <div class="history-filters-content">
                    <div class="history-filters-group">
                        <div class="history-filters-group-title">{{L('Name')}}</div>
                        <sagra-input class="search-input"
                                     [(model)]="searchNameModel"
                                     [placeholder]="searchInputOptions.placeholder"
                                     [styleType]="searchInputOptions.styleType"
                                     [clearButton]="searchInputOptions.clearButton"
                                     [errorType]="searchInputOptions.errorType"
                                     [icon]="searchInputOptions.icon"></sagra-input>
                    </div>
                    <div class="history-filters-group">
                        <div class="history-filters-group-title">{{L('Period')}}</div>
                        <sagra-radio [(selectedValue)]="periodRadioModel" [list]="periodRadio.values"></sagra-radio>
                        <div class="history-filters-group">
                            <div class="history-filters-group-title">{{L('Date_range')}}</div>
                            <sagra-datetime-picker [(comparisonDates)]="periodDatePickerModel.comparisonDates"
                                                   [type]="periodDatePickerModel.type"
                                                   [disabled]="periodRadioModel != 4"></sagra-datetime-picker>
                        </div>
                    </div>
                    <div class="history-filters-group">
                        <div class="history-filters-group-title">{{L('Orderer')}}</div>
                        <sagra-input class="search-input"
                                     [(model)]="ordererModel"
                                     [placeholder]="searchInputOptions.placeholder"
                                     [styleType]="searchInputOptions.styleType"
                                     [clearButton]="searchInputOptions.clearButton"
                                     [errorType]="searchInputOptions.errorType"
                                     [icon]="searchInputOptions.icon"></sagra-input>
                    </div>
                    <div class="history-filters-group" *ngIf="dataSource==2 || dataSource==6">
                        <div class="history-filters-group-title">{{L('Type')}}</div>
                        <sagra-combobox-multiselect class="sample" [(selectedValue)]="typeComboListSelectedValue"
                                                    [(list)]="typeComboList"
                                                    [type]="typeComboOptions.type"
                                                    [emptyPosition]="typeComboOptions.emptyPosition"
                                                    [style]="typeComboOptions.style"
                                                    [confirmation]="typeComboOptions.confirmation"
                                                    [arrowStyle]="typeComboOptions.arrowStyle"></sagra-combobox-multiselect>
                    </div>
                    <div class="history-filters-group">
                        <div class="history-filters-group-title">{{L('Operation_2')}}</div>
                        <sagra-combobox-multiselect class="sample" [(selectedValue)]="operationComboListSelectedValue"
                                                    [(list)]="operationComboList"
                                                    [type]="operationComboOptions.type"
                                                    [emptyPosition]="operationComboOptions.emptyPosition"
                                                    [style]="operationComboOptions.style"
                                                    [confirmation]="operationComboOptions.confirmation"
                                                    [arrowStyle]="operationComboOptions.arrowStyle"></sagra-combobox-multiselect>
                    </div>
                    <div class="history-filters-group">
                        <div class="history-filters-group-title">{{L('Status')}}</div>
                        <sagra-radio [(selectedValue)]="statusRadioModel" [list]="statusRadio.values"></sagra-radio>
                    </div>
                </div>
            </ng-scrollbar>
            <div class="history-filters-footer">
                <button class="history-filters-filter-btn sgf-btn-dark" (click)="applyFilters()">{{L('Filter')}}</button>
            </div>
        </div>
    </div>
    <div class="history-right-panel">
        <tabpage-header [title]="headerTitle"
                        [subTitle]="headerSubTitle"
                        [searchEnabled]="false"
                        (searchTextChanged)="onSearchHeaderTextChanged($event)">
        </tabpage-header>
        <div class="history-list" *ngIf="listItems != null && listItems.length > 0">
            <div class="history-list-header">
                <div class="history-list-header-column date" [ngClass]="{'sort': allowSorting, 'sorting': sortingColumn=='DataZdarzenia'}" (click)="sortColumn('DataZdarzenia')">
                    {{L('Occurrence_date')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="history-list-header-column name" [ngClass]="{'sort': allowSorting, 'sorting': sortingColumn=='Nazwa', 'with-type': dataSource==2, 'with-type-and-version': dataSource==2}" (click)="sortColumn('Nazwa')">
                    {{L('Name')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="history-list-header-column type" [ngClass]="{'sort': allowSorting, 'sorting': sortingColumn=='IdTyp'}" *ngIf="dataSource==2" (click)="sortColumn('IdTyp')">
                    {{L('Type')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="history-list-header-column version" *ngIf="dataSource==2">
                    <span></span>
                    {{L('Version')}}
                </div>
                <div class="history-list-header-column ordering" [ngClass]="{'sort': allowSorting, 'sorting': sortingColumn=='Zlecajacy'}" (click)="sortColumn('Zlecajacy')">
                    {{L('Orderer')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="history-list-header-column operation" [ngClass]="{'sort': allowSorting, 'sorting': sortingColumn=='Operacja'}" (click)="sortColumn('Operacja')">
                    {{L('Operation_2')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="history-list-header-column status" [ngClass]="{'sort': allowSorting, 'sorting': sortingColumn=='Status'}" (click)="sortColumn('Status')">
                    {{L('Status')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
            </div>
            <div class="history-list-row"
                *ngFor="let item of listItems"
                sagraTooltip [sagraTooltipModel]="item.tooltip">
                <div class="history-list-column date">
                    <span class="">{{Utils.formatDate(item.occurenceDate)}}</span>
                </div>
                <div class="history-list-column name" [ngClass]="{'with-type': dataSource==2, 'with-type-and-version': dataSource==2}">
                    <span class="">{{item.name}}</span>
                </div>
                <div class="history-list-column type" *ngIf="dataSource==2">
                    <span class="">{{item.type}}</span>
                </div>
                <div class="history-list-column version" *ngIf="dataSource==2">
                    <span class="">{{item.versionNumber}}</span>
                </div>
                <div class="history-list-column ordering">
                    <span class="">{{item.orderer}}</span>
                </div>
                <div class="history-list-column operation">
                    <span class="">{{item.operationDescription}}</span>
                </div>
                <div class="history-list-column status">
                    <div>
                        <sagra-pill class="" [type]="item.status == 1 ? 2 : 3" [text]="item.statusDescription" [pillClass]="statusClass(item)"></sagra-pill>
                    </div>
                </div>
            </div>
        </div>
        <div class="history-list-placeholder" *ngIf="listItems == null || listItems.length == 0">
            <sagra-placeholder [title]="placeholderOptions.title"
                               [description]="placeholderOptions.description"
                               [button]="placeholderOptions.button"
                               [image]="placeholderOptions.image"
                               >
            </sagra-placeholder>
        </div>
        <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
    </div>
</div>
