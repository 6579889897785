import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share, catchError } from 'rxjs/operators';
import { DatabaseRole } from '../../models/databaseRole.model';

@Injectable()
export class RoleService {
  private apiUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.apiUrl = baseUrl;
  }

  getPortalRoles(tenantId: string): Observable<DatabaseRole[]> {
    let observable = this.http.post<DatabaseRole[]>(this.apiUrl + 'api/Database/GetPortalRoles', { dbName: tenantId }, { observe: 'response' })
      .pipe(map(response => {
        let roles = response.body;
          for (let i = 0; i < roles.length; i++) {
              roles[i].shared = (roles[i].status == 2 && roles[i].tenantId == tenantId);
        }
        return roles;
      }),
        share());
    return observable;
  }
}
