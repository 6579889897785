<div class="functionalities">
    <div class="funct-navigation">
        <database-details-navigation [navGroups]="navigationItems" [selectedItem]="selectedItem" (selectedItemChanged)="navigationItemChanged($event)"></database-details-navigation>
    </div>
    <div class="funct-list">
        <functionality-list [dataSource]="2" [hidden]="selectedItem != 2"></functionality-list>
        <functionality-list [dataSource]="3" [hidden]="selectedItem != 3"></functionality-list>
        <functionality-list [dataSource]="4" [hidden]="selectedItem != 4"></functionality-list>
        <functionality-list [dataSource]="6" [hidden]="selectedItem != 6"></functionality-list>
        <functionality-list [dataSource]="9" [hidden]="selectedItem != 9"></functionality-list>
        <functionality-list [dataSource]="11" [hidden]="selectedItem != 11"></functionality-list>
        <functionality-list [dataSource]="13" [hidden]="selectedItem != 13"></functionality-list>
    </div>
</div>
