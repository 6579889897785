import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Component, Input, OnInit, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from "@angular/core";
import * as moment from 'moment';
import { BIQPackage } from "../../../models/biq/biqPackage.model";
import { SelectedBIQPackage } from "../../../models/biq/selectedBIQPackage.model";
import { BIQService } from "../../../services/biqService/biq.service";
import { Subscription, Subject } from "rxjs";
import { BiqPackageRequest } from "../../../models/biq/biqPackageRequest.model";
import { map } from "rxjs/operators";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { TenantConfiguration } from "../../../models/tenant/tenantConfiguration.model";
import { SagraDialogService, SagraDialogType } from "@sagra/sagra-dialog";
import { MessageBusService } from "../../../services/messageBusService/messageBusService";
import { SagraDatetimePickerType } from "@sagra/sagra-datetime-picker";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";

const DATE_FORMAT = "DD.MM.YYYY";


@Component({
    selector: 'biq-package',
    templateUrl: './biq-package.component.html',
    styleUrls: ['./biq-package.component.scss']
})

export class BiqPackageComponent extends SagraTranslatable implements OnInit, OnChanges {
    @Input() tenant: string;
    @Input() portal: string;
    @Input() tenantConfiguration: TenantConfiguration;
    @Output() showLoaderChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    public demoEnabled: boolean;
    public demoDurationFromModel: moment.Moment;
    public demoDurationToModel: moment.Moment;
    public sagraLoaderOptions: SagraLoaderOptionsInterface;

    private packageItems: Array<BIQPackage>;
    public currentPackage: SelectedBIQPackage;
    public canSavePackage: boolean;
    public demoUsed: boolean = false; // wykorzystano już demo

    public datePickerType: SagraDatetimePickerType = SagraDatetimePickerType.date;

    private subscriptions = new Subscription();
    private switchOffPackageSubject: Subject<any> = new Subject<any>();

    constructor(public translationService: SagraTranslationService,
        public biqService: BIQService,
        private dialogService: SagraDialogService,
        private messageService: MessageBusService) {

        super(translationService);

        this.subscriptions.add(this.switchOffPackageSubject.asObservable().subscribe(
            x => this.switchOffPackage()
        ));
    }

    ngOnInit(): void {
        const ctrl = this;

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 40,
                borderWidth: 5
            }
        };

        this.messageService.getRefreshBiqPackage().subscribe(data => {
            if (data.tenant == null || data.tenant.toLowerCase() == ctrl.tenant.toLowerCase()) {
                this.messageService.refreshDatabaseData();
            }
        });

        this.getPackagesList();
    }

    ngOnChanges(changes: SimpleChanges): void {
        let currentItem: SimpleChange = changes.tenantConfiguration;
        if (currentItem && currentItem.currentValue) {
            this.tenantConfiguration = currentItem.currentValue;

            this.prepareDataChanges();
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }


    onTurnOffPackageClick() {

        this.dialogService.showAlert(
            {
                dialogType: SagraDialogType.confirm,
                backdropClose: true,
                title: this.L('Want_turn_off_package'),
                buttons: [
                    { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                    { name: this.L('Save'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.switchOffPackageSubject }
                ]
            }
        );
    }

    onCancelPackageClick() {
        this.clearParameters();
        this.checkCanSavePackage();
        this.canSavePackage = false;
    }

    onBiqPackageFromDateChanged(event) {
        this.demoDurationFromModel = event;

        this.checkPackageDates();
        this.checkCanSavePackage();
    }

    onBiqPackageToDateChanged(event) {
        this.demoDurationToModel = event;

        this.checkPackageDates();
        this.checkCanSavePackage();
    }

    onSavePackageClick() {
        this.updateLoader(true, "onSavePackageClick");

        if (!this.demoEnabled && this.currentPackage != null) {
            //wybór nowego
            const biqRequest = new BiqPackageRequest();
            biqRequest.dbName = this.tenant;
            biqRequest.portalName = this.portal;
            biqRequest.newPackage = this.currentPackage;

            this.preparePackageParameters(biqRequest);

            this.biqService.switchOnPackage(biqRequest).subscribe((data) => {
            },
                (error) => {
                    this.updateLoader(false, "onSavePackageClick switchOn-error");
                });
        }
        else {
            // zmiana parametrów
            const biqRequest = new BiqPackageRequest();
            biqRequest.dbName = this.tenant;
            biqRequest.portalName = this.portal;
            biqRequest.currentPackage = this.currentPackage;

            this.preparePackageParameters(biqRequest);

            this.biqService.changePackageParameters(biqRequest).subscribe((data) => {
            },
                (error) => {
                    this.updateLoader(false, "onSavePackageClick changePackageParameters-error");
                });
        }
    }

    prepareDataChanges() {
        if (this.tenantConfiguration.cacheLicense != null && this.tenantConfiguration.cacheLicense.value.length > 0) {
            this.currentPackage = new SelectedBIQPackage();
            this.currentPackage.id = this.tenantConfiguration.cacheLicense.value;
            if (this.tenantConfiguration.biqPackagePlannedEnd != null) {
                this.currentPackage.demoDurationStop = moment(this.tenantConfiguration.biqPackagePlannedEnd.value, DATE_FORMAT);
                this.demoDurationToModel = this.currentPackage.demoDurationStop;
            }

            if (this.packageItems != null) {
                const result = this.packageItems.filter(item => { return item.id == this.currentPackage.id; })
                if (result != null && result.length > 0 && result[0].isDemo) {
                    this.currentPackage.name = result[0].name;
                    this.currentPackage.isDemo = result[0].isDemo;
                    this.demoEnabled = this.currentPackage.isDemo
                }
            }

            if (this.tenantConfiguration.demoStart != null && this.tenantConfiguration.demoStart.value.length > 0) {
                this.currentPackage.demoDurationStart = moment(this.tenantConfiguration.demoStart.value, DATE_FORMAT);
                this.demoDurationFromModel = this.currentPackage.demoDurationStart;
            }
        }
        else {
            this.prepareDefaultPackage();
        }

        this.demoUsed = false;
        if ((this.tenantConfiguration.demoEnd != null && this.tenantConfiguration.demoEnd.value.length > 0)
            || (this.currentPackage != null && !this.currentPackage.isDemo)) {
            this.demoUsed = true;
        }

        this.updateLoader(this.tenantConfiguration.biqInProgress && !this.demoUsed, "ngOnChanges");
    }

    switchOffPackage() {

        if (this.currentPackage != null) {
            //this.showLoader = true;
            this.updateLoader(true, "switchOffPackage");

            const biqRequest = new BiqPackageRequest();
            biqRequest.dbName = this.tenant;
            biqRequest.portalName = this.portal;
            biqRequest.currentPackage = this.currentPackage;

            this.biqService.removeCache(biqRequest).subscribe((data) => {
            },
                (error) => {
                    this.updateLoader(false, "switchOffPackage-error");
                });
        }
        
    }

    preparePackageParameters(biqRequest: BiqPackageRequest) {
        if (this.demoDurationFromModel != null) {
            biqRequest.startBiqDemoDate = this.demoDurationFromModel.format(DATE_FORMAT);
        }
        if (this.demoDurationToModel != null) {
            biqRequest.endBiqDemoDate = this.demoDurationToModel.format(DATE_FORMAT);
        }
    }

    getPackagesList() {
        this.updateLoader(true, "getPackageList");
        this.subscriptions.add(this.biqService.getPackagesList().pipe(map(data => {
            let items: Array<BIQPackage> = [];
            for (let item of data) {
                if (item.isDemo) {
                    item.value = item.id;
                    items.push(item);
                }
            }
            return items;
        })).subscribe(data => {
            this.packageItems = data;

            if (this.currentPackage != null) {
                const list = this.packageItems.filter(item => { return item.id == this.currentPackage.id; });
                if (list != null && list.length > 0) {
                    this.currentPackage.name = list[0].name;
                    this.currentPackage.isDemo = list[0].isDemo;
                    this.demoEnabled = this.currentPackage.isDemo;
                }
            }
            else {
                this.prepareDefaultPackage();
            }

            if (this.tenantConfiguration != null) {
                this.prepareDataChanges();
            }

            this.updateLoader(false, "getPackageList");
        },
            (error) => {
                this.updateLoader(false, "getPackageList-error");
            }
        ));
    }

    checkPackageDates() {
        if (this.demoDurationFromModel != null && this.demoDurationToModel) {
            if (this.demoDurationFromModel.isAfter(this.demoDurationToModel)) {
                console.log("Nieprawidłowe daty");
            }
        }
    }

    checkCanSavePackage() {
        let canSave = true;

        if (this.currentPackage == null || (this.currentPackage.isDemo && this.demoDurationToModel == null)) {
            canSave = false;
        }

        this.canSavePackage = canSave;
    }

    updateLoader(value: boolean, source: string) {
        value = value || (this.tenantConfiguration != null && this.tenantConfiguration.biqInProgress && !this.demoUsed);
        //console.log("showLoader - emit: " + source + " wartość: " + value);
        this.showLoaderChange.emit(value);
    }

    prepareDefaultPackage() {
        if (this.packageItems != null && this.packageItems.length > 0) {
            this.currentPackage = new SelectedBIQPackage();
            this.currentPackage.id = this.packageItems[0].id;
            this.currentPackage.name = this.packageItems[0].name;
            this.currentPackage.isDemo = this.packageItems[0].isDemo;
            this.demoDurationFromModel = moment();
        }
    }

    clearParameters() {
        if (this.currentPackage != null) {
            this.demoDurationFromModel = this.currentPackage.demoDurationStart;
            this.demoDurationToModel = this.currentPackage.demoDurationStop;
        }
        else {
            this.prepareDefaultPackage();
        }
    }
}
