import { TargetingGroupDescription, TargetingGroup, UpdateTargetingGroupRequest } from './../../models/targeting/targetingGroup.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TargetingService {

    private apiUrl: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    //Pobranie listy grup targetowych
    getTargetingGroups(tenant: string): Observable<TargetingGroup[]> {
        let httpParams = new HttpParams().set("dbName", tenant);
        return this.http.get<TargetingGroup[]>(this.apiUrl + "api/Targeting/GetTargetingGroups", { params: httpParams });
    }

    //Pobranie opisu dla grupy targetowej
    getTargetingGroupDescription(dbName: string): Observable<TargetingGroupDescription> {
        let httpParams = new HttpParams().set("dbName", dbName);
        return this.http.get<TargetingGroupDescription>(this.apiUrl + "api/Targeting/GetTargetingGroupDescription", { params: httpParams });
    }

    //Aktualizacja grupy targetowej
    updateTargetingGroup(request: UpdateTargetingGroupRequest): Observable<number> {
        return this.http.post<number>(this.apiUrl + "api/Targeting/UpdateTargetingGroup", request);
    }

    //Aktualizacja nazwy grupy targetowej
    updateTargetingGroupDescription(dbName: string, descriptionId: string): Observable<any> {
        return this.http.post(this.apiUrl + "api/Targeting/UpdateTargetingGroupDescription", { dbName: dbName, descriptionId: descriptionId });
    }

    //Aktywacja konsoli dla grupy targetowej
    activateTargetConsole(dbName: string): Observable<any> {
        return this.http.post<any>(this.apiUrl + "api/Targeting/ActivateTargetConsole", { dbName: dbName });
    }

    //Sprawdzenie czy tenant ma możliwość tworzenia grup targetowych dla personelu medycznego
    areTargetingClientsActive(dbName: string): Observable<boolean> {
        let httpParams = new HttpParams().set('dbName', dbName);
        return this.http.get<boolean>(this.apiUrl + "api/Targeting/AreTargetingClientsActive", { params: httpParams});
    }

}

