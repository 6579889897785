import { Component, ElementRef, ViewChild, Renderer2, Output, EventEmitter, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, SimpleChange } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Router } from "@angular/router";
import { NavigationItemEnum, NavigationItem, NavigationGroup } from "../../models/navigationItem.model";
import { ContextService } from "../../services/contextService/context.service";
import { Permission } from "../../models/permission.model";
import { TenantConfiguration } from "../../models/tenant/tenantConfiguration.model";
import { FilterGroup } from "../../models/filters/filterGroup.model";
import { SelectedFilter } from "../../models/filters/selectedFilter";

@Component({
    selector: 'database-details-navigation',
    templateUrl: './database-details-navigation.component.html',
    styleUrls: ['./database-details-navigation.component.scss']
})

export class DatabaseDetailsNavigationComponent extends SagraTranslatable implements OnInit, OnChanges {
    @Input() navGroups: Array<NavigationGroup>;
    @Input() selectedItem: NavigationItemEnum;
    @Input() filters: Array<FilterGroup>;
    @Input() showFilters: boolean;
    @Output() selectedItemChanged: EventEmitter<NavigationItemEnum> = new EventEmitter<NavigationItemEnum>();
    @Output() filterChanged: EventEmitter<Array<SelectedFilter>> = new EventEmitter<Array<SelectedFilter>>();

    public permission: Permission;
    public databaseName: string;


    constructor(public translationService: SagraTranslationService,
        private contextService: ContextService,
        private router: Router, private renderer: Renderer2) {

        super(translationService);

        //this.permission = contextService.getCurrentGRRPermission("GRR");
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (this.navGroups) {
            for (let group of this.navGroups) {
                for (let item of group.items) {
                    if (!item.tooltip) {
                        item.tooltip = { tooltipItems: [{ content: item.name }] };
                    }
                }
            }
        }
    }

    onFilterChanged(event) {
        if (this.filterChanged != null) {
            this.filterChanged.emit(event);
        }
    }

    /**
     *  Powrót do listy baz danych
     */
    public returnToList() {
        this.router.navigate(['/']);
    }

    public navigationItemClick(event: any, item: NavigationItem) {
        if (item.disabled) {
            return;
        }

        if (this.selectedItem != item.id) {
            this.selectedItemChanged.emit(item.id);
        }
    }
}
