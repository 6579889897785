<div class="cd-component">
    <sagra-collapse-expand [(expanded)]="expanded" [animationDuration]="colExpOptions.duration" [animationEasing]="colExpOptions.easing" [expandId]="cacheDetails.address">
        <div class="cd-expand-button" [sagraCollapseExpandButton]="cacheDetails.address">
            <div class="cd-expand-button-icon" [ngClass]="{'collapsed': !expanded}"><span class="icon icon-keyboard_arrow_down"></span></div>
            <div class="cd-expand-button-name">{{cacheFullName}}</div>
            <div class="cd-expand-button-date" *ngIf="cacheDetails.inProgressInfo==null">{{cacheDetails.timestampText}}</div>
            <div class="cd-expand-button-date" *ngIf="cacheDetails.inProgressInfo!=null">
                <sagra-pill [type]="2" [text]="inProgressText"></sagra-pill>
            </div>
        </div>
        <div [sagraCollapseExpandContent]="cacheDetails.address">
            <div class="cd-expand-container">
                <div class="cd-field-caption">{{L('Cache_version')}}</div>
                <div class="cd-field-value">
                    <sagra-combobox-multiselect class="cm-combo-packages"
                                                [(selectedValue)]="versionModel"
                                                [list]="versionsList"
                                                [disabled]="disableVersion"
                                                [multiselect]="false"
                                                [clearButton]="false"
                                                (selectedValueChange)="onVersionModelChange($event)">
                    </sagra-combobox-multiselect>
                </div>
                <div class="cd-field-caption">{{L('Chosen_package_Biqsens')}}</div>
                <div class="cd-field-value">
                    <sagra-combobox-multiselect class="cm-combo-packages"
                                                [(selectedValue)]="packageModel"
                                                [list]="packagesList"
                                                [disabled]="disablePackage"
                                                [multiselect]="false"
                                                [clearButton]="false"
                                                (selectedValueChange)="onPackageModelChange($event)">
                    </sagra-combobox-multiselect>
                </div>
                <div class="cd-field-caption">{{L('Server_address')}}</div>
                <div class="cd-field-value">{{cacheDetails.address}}</div>
                <div class="cd-field-caption">{{L('Region')}}</div>
                <div class="cd-field-value">{{cacheDetails.region}}</div>
                <button class="cd-action-button sgf-link-primary" [disabled]="!canUpdate" (click)="onUpdateButtonClick()">{{L('Update_2')}}</button>
                <button class="cd-action-button sgf-link-cherry" [disabled]="!allowDelete" (click)="onDeleteButtonClick()">{{L('Delete')}}</button>
            </div>
        </div>
    </sagra-collapse-expand>
</div>
