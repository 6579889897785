import { Component, ViewChild, ElementRef, HostListener, OnInit, Renderer2, OnDestroy, AfterViewInit, Input } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { TenantService } from "../../services/tenantService/tenant.service";
import { HttpEventType } from "@angular/common/http";
import { BIQService } from "../../services/biqService/biq.service";
import { Subscription } from "rxjs";
import { TenantConfiguration } from "../../models/tenant/tenantConfiguration.model";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";

@Component({
    selector: 'database-info',
    templateUrl: './database-info.component.html',
    styleUrls: ['./database-info.component.scss']
})

export class DatabaseInfoComponent extends SagraTranslatable implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('fileUpload') fileUpload: ElementRef;
    @ViewChild('imagePreview') imagePreview: ElementRef;
    @ViewChild('dropFileBox') dropFileBox: ElementRef;
    @Input() tenant: string;
    @Input() tenantConfiguration: TenantConfiguration;

    public showLoader: boolean;
    public showLoaderBiqPackage: boolean = true;
    public showLoaderNewWorld: boolean = true;
    public uploadedFile: File;
    public imagePreviewURL: any;
    public uploadingTextProgress: string;
    public uploadingFile: boolean;
    public portalName: string;
    public portalLogoUrl: string;
    public loadingPortalLogoStart = true;

    private subscriptions = new Subscription();

    public loaderOptions: SagraLoaderOptionsInterface;

    constructor(public translationService: SagraTranslationService,
        public tenantService: TenantService,
        public biqService: BIQService,
        public renderer: Renderer2) {

        super(translationService);

        this.loaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                borderWidth: 8,
                color: '#FFFFFF',
                size: 60
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }
    }

    ngOnInit() {
        //if (validationArray.length > 0) {
        //    combineLatest(validationArray).pipe(map(([validDBName, validPortalName, validPrefix]) => ({ validDBName, validPortalName, validPrefix }))).subscribe(pair => {

        this.getPortalName();
    }

    ngAfterViewInit() {
        this.previewImage();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onDrop(event) {
        let file = event.dataTransfer.files[0];
        this.processUploadedFile(file);
        event.preventDefault();
        event.stopPropagation();
    }
    onDragover(event) {
        this.renderer.addClass(this.dropFileBox.nativeElement, 'dragging');
        event.preventDefault();
        event.stopPropagation();
    }
    onDragleave(event) {
        this.renderer.removeClass(this.dropFileBox.nativeElement, 'dragging');
        event.preventDefault();
        event.stopPropagation();
    }

    onBiqShowLoaderChange(event) {
        this.showLoaderBiqPackage = event;
    }

    onNewWorldShowLoaderChange(event) {
        this.showLoaderNewWorld = event;
    }

    uploadFile($event) {
        this.processUploadedFile($event.target.files[0]);
    }

    processUploadedFile(file: File) {
        if (file.type != "image/png") {
            return;
        }
        this.sendLogo(file);
    }

    previewImage() {
        if (this.uploadedFile != null) {
            var mimeType = this.uploadedFile.type;
            if (mimeType.match(/image\/*/) == null) {
                //this.message = "Only images are supported.";
                return;
            }

            var reader = new FileReader();
            reader.readAsDataURL(this.uploadedFile);
            reader.onload = (_event) => {
                this.imagePreviewURL = reader.result;
            }
        }
        else {
            this.imagePreviewURL = this.portalLogoUrl;
        }
    }

    private sendLogo(file: File) {
        this.uploadingFile = true;
        this.tenantService.uploadLogo(file, this.tenant).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
                let progress = Math.round(100 * event.loaded / event.total);
                if (progress == 100) {
                    this.uploadingTextProgress = this.L('Running_job');
                }
                else {
                    this.uploadingTextProgress = this.L('Uploading_file') + "   " + progress + "%";
                }
            }
            else if (event.type === HttpEventType.Response) {
                //this.message = 'Upload success.';
                //this.onUploadFinished.emit(event.body);
                this.uploadingFile = false;
                this.uploadedFile = file;
                this.previewImage();
            }
        }, (error) => {
            this.uploadingFile = false;
        });
    }

    private getPortalName() {
        this.tenantService.getPortalName(this.tenant).subscribe(result => {
            this.portalName = result.body.portalName;
            this.buildPortalLogoUrl();
        });
    }

    private buildPortalLogoUrl() {
        //this.portalLogoUrl = "https://" + this.portalName + ".emigo.biz/portals/" + this.portalName + "/logofirma.png?v" + Math.random();
        this.portalLogoUrl = "https://static.emigo.biz/Portals/" + this.portalName + "/logoFirma.png?v" + Math.random();
        this.checkPortalLogoExists();
    }

    private checkPortalLogoExists() {
        const imageData = new Image();
        imageData.onload = () => {
            this.loadingPortalLogoStart = false;
            if (this.imagePreviewURL == null || this.imagePreviewURL.length == 0)
                this.imagePreviewURL = this.portalLogoUrl;
        };
        imageData.onerror = () => {
            this.loadingPortalLogoStart = false;
        }
        imageData.src = this.portalLogoUrl;
    }
}
