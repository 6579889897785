<div class="tabpage-header-panel" [ngClass]="{'short': shortMode, 'breadcrumb': showBreadcrumb}">
    <breadcrumbs [breadcrumbsId]="1" *ngIf="showBreadcrumb"></breadcrumbs>
    <div class="tabpage-header-navigation">
        <div class="tabpage-header-title">
            <div class="mainText sgf-font-h1">{{title}}</div>
            <div class="counterText">{{counterText}}</div>
            <div class="subText">{{subTitle}}</div>
        </div>
        <div class="tabpage-header-btn-share">
            <button class="sgf-btn-primary" *ngIf="actionButtonVisible" [ngClass]="{'disabled': actionButtonDisabled}" (click)="actionButtonClick()">{{actionButtonTitle}}</button>
        </div>
        <div class="tabpage-header-btn-history">
            <button class="sgf-btn-white-border" *ngIf="historyButtonVisible" (click)="historyButtonClick()">
                <span class="icon icon-restore"></span><span class="tabpage-header-btn-history-text">{{historyButtonTitle}}</span>
            </button>
        </div>
        <div class="tabpage-header-list-search">
            <sagra-input class="search-input" *ngIf="searchEnabled" [(model)]="searchText" [options]="searchListInputOptions" (modelChange)="searchChanged($event)"></sagra-input>
        </div>
    </div>
</div>
