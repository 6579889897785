<div class="database-list">
  <div class="database-list-left-panel">
    <div class="database-list-new">
      <button class="sgf-btn-primary" (click)="newDatabaseButtonClick()">
        <span class="icon icon-add"></span><span>{{L('NewDatabase')}}</span>
      </button>
    </div>
    <div class="database-list-filters">
      <database-list-filters [filters]="filters" (filterChanged)="onFilterChanged($event)"></database-list-filters>
    </div>
  </div>
  <div class="database-list-list">
    <database-list-list (databaseSelected)="onDatabaseSelected($event)" [filters]="selectedFilters"></database-list-list>
  </div>
</div>

