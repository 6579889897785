import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { HierarchyData } from "../../../models/hierarchy/hierarchyData.model";
import { SagraCheckboxComponentOptionsInterface, SagraCheckboxComponentSize, SagraCheckboxComponentCilckableArea, SagraCheckboxComponentLabelPosition, SagraCheckboxComponentListItemInterface } from "@sagra/sagra-checkbox";
import { trigger, state, transition, animate, style } from "@angular/animations";
import { MessageBusService } from "../../../services/messageBusService/messageBusService";
import { SagraDialogOptionsInterface, SagraDialogType, SagraDialogService } from "@sagra/sagra-dialog";
import { Subject, Subscription } from "rxjs";
import { HierarchyService } from "../../../services/hierarchyService/hierarchy.service";
import { CustomTooltipOptions } from "../../../directives/custom-tooltip/custom-tooltip.directive";
import { SagraLoaderType, SagraLoaderOptions } from "@sagra/sagra-loader";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";

@Component({
    selector: 'hierarchy-tree',
    templateUrl: './hierarchy-tree.component.html',
    styleUrls: ['./hierarchy-tree.component.scss'],
    animations: [trigger('hidegroup', [
        state('open', style({})),
        state('closed', style({ height: '0px' })),
        transition('open => closed', [animate('0.2s ease-in-out')]),
        transition('closed => open', [animate('0.2s ease-in-out')])
    ])]
})

export class HierarchyTreeComponent extends SagraTranslatable implements OnInit, OnDestroy, OnChanges {
    @Input() tenant: string;
    @Input("hierarchyRoot") rootHierarchyItem: HierarchyData;
    @Input("hierarchyItem") currentHierarchyItem: HierarchyData;
    @Output() hierarchyAdded: EventEmitter<HierarchyData> = new EventEmitter<HierarchyData>()

    private subscriptions = new Subscription();
    public checkboxOptions: SagraCheckboxComponentOptionsInterface;
    public checkboxItem: SagraCheckboxComponentListItemInterface;
    public tooltipOptions: CustomTooltipOptions;
    public loaderOptions: SagraLoaderOptions;
    public expanded: boolean;
    public showLoader: boolean;
    public activateCheckModel: boolean;
    public hideAdding: boolean;

    private addLevelNameSubject = new Subject<string>();
    private saveHierarchySubject: Subject<any> = new Subject<any>();
    private activateHierarchySubject: Subject<any> = new Subject<any>();
    private cancelActivateHierarchySubject: Subject<any> = new Subject<any>();

    constructor(public translationService: SagraTranslationService,
        public dialogService: SagraDialogService,
        public hierarchyService: HierarchyService,
        public messageService: MessageBusService) {
        super(translationService);

        this.subscriptions.add(this.addLevelNameSubject.asObservable().subscribe(
            x => this.showAddHierarchyDialog(x)
        ));

        let self = this;

        this.subscriptions.add(this.saveHierarchySubject.asObservable().subscribe(
            x => {
                if (x.edition) {
                    self.currentHierarchyItem = x.hierarchy;
                    self.hierarchyService.editHierarchy(self.rootHierarchyItem, self.currentHierarchyItem);
                }
                else {
                    //if (self.currentHierarchyItem.subHierarchies == null) {
                    //    self.currentHierarchyItem.subHierarchies = [];
                    //}
                    //self.currentHierarchyItem.subHierarchies.push(x.hierarchy);

                    self.hierarchyService.addHierarchy(self.currentHierarchyItem, x.hierarchy);
                    self.expanded = true;
                    self.currentHierarchyItem.expanded = true;
                }

                self.prepareTooltipOptions();
            }
        ));

        this.subscriptions.add(this.activateHierarchySubject.asObservable().subscribe(
            x => this.activateHierarchy()
        ));
        this.subscriptions.add(this.cancelActivateHierarchySubject.asObservable().subscribe(
            x => this.activateCheckModel = !this.currentHierarchyItem.deleted
        ));

        this.checkboxOptions = {
            checkboxsize: SagraCheckboxComponentSize.medium,
            clickable: SagraCheckboxComponentCilckableArea.wholeItem,
            labelPosition: SagraCheckboxComponentLabelPosition.right,
            singleLineLabel: true
        }
    }

    ngOnInit(): void {
        this.setActivateCheckboxItem();

        this.activateCheckModel = !this.currentHierarchyItem.deleted;

        this.messageService.getExpandAllHierarchies().subscribe((data) => {
            this.expanded = data;
        })

        this.messageService.getRefreshHierarchyItem().subscribe((data) => {
            this.setActivateCheckboxItem();

            this.activateCheckModel = !this.currentHierarchyItem.deleted;
        });

        this.loaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            }
        }

        if (this.currentHierarchyItem.deleted || this.currentHierarchyItem.levelId >= 10) {
            this.hideAdding = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.prepareTooltipOptions();
        let currentItem: SimpleChange = changes.currentHierarchyItem;
        if (currentItem && currentItem.currentValue) {
            this.expanded = changes.currentHierarchyItem.currentValue.expanded;
        }
    }


    onAddHierarchyClick() {
        let levelName = null;
        if (this.currentHierarchyItem != null) {
            levelName = this.hierarchyService.findLevelName(this.rootHierarchyItem, this.currentHierarchyItem.levelId+1);
        }
        if (levelName == null) {
            let dialogOptions: SagraDialogOptionsInterface = {
                dialogType: SagraDialogType.prompt,
                title: this.L('Set_level_name'),
                message: this.L('Level_name_text'),
                buttons: [
                    { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                    { name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.addLevelNameSubject }
                ]
            };
            this.dialogService.showAlert(dialogOptions);
        }
        else {
            this.showAddHierarchyDialog(levelName);
        }
    }

    onActivateCheckboxClick(event) {
        this.activateCheckModel = event;
        if (event) {
            this.activateHierarchy();
        }
        else {
            let dialogOptions: SagraDialogOptionsInterface = {
                dialogType: SagraDialogType.confirm,
                title: this.L('Deactivate_unit'),
                message: this.L('Deactivate_unit_text'),
                buttons: [
                    { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary, handler: this.cancelActivateHierarchySubject },
                    { name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.activateHierarchySubject }
                ]
            };
            this.dialogService.showAlert(dialogOptions);
        }
    }

    onEditHierarchyClick() {
        this.messageService.showDialogEditHierarchy({
            tenant: this.tenant,
            levelName: this.currentHierarchyItem.levelName,
            rootHierarchyItem: this.rootHierarchyItem,
            hierarchyItem: this.currentHierarchyItem,
            parentHierarchyCode: null,
            cancelHandler: null,
            saveHandler: this.saveHierarchySubject,
        });

    }

    activateHierarchy() {
        let self = this;
        this.showLoader = true;
        let item = Object.create(this.currentHierarchyItem);
        item.deleted = !this.activateCheckModel;
        this.hierarchyService.saveHierarchyItem(this.tenant, item).subscribe(data => {
            self.currentHierarchyItem.deleted = !self.activateCheckModel;

            if (!self.activateCheckModel) {
                self.markDeactivation(self.currentHierarchyItem, self.currentHierarchyItem.hierarchyCode, !self.activateCheckModel);
            }
            self.messageService.refreshHierarchyItem();

            self.showLoader = false;
        },
            error => {
               
                self.activateCheckModel = !self.currentHierarchyItem.deleted;
                self.showLoader = false;
            });
    }

    checkCanActivate(rootItem: HierarchyData, item: HierarchyData) {
        if (rootItem != null && rootItem.subHierarchies != null) {
            for (let hier of rootItem.subHierarchies) {
                if (item.hierarchyCode.startsWith(hier.hierarchyCode)) {
                    if (rootItem.endLevel || rootItem.deleted) { //rodzic hierarchii jest oznaczony jako ostani poziom
                        return false;
                    }
                    if (hier.hierarchyCode == item.hierarchyCode) {
                        return true;
                    }
                    return this.checkCanActivate(hier, item);
                }
            }
        }
        return true;
    }


    showAddHierarchyDialog(levelName: string) {
        //if (levelName == null || levelName.length == 0) {
        //    return;
        //}
        this.messageService.showDialogEditHierarchy({
            tenant: this.tenant,
            levelName: levelName,
            rootHierarchyItem: this.rootHierarchyItem,
            hierarchyItem: null,
            parentHierarchyCode: this.currentHierarchyItem.hierarchyCode,
            cancelHandler: this.cancelActivateHierarchySubject,
            saveHandler: this.saveHierarchySubject,
        });
    }

    setActivateCheckboxItem() {
        this.checkboxItem = {
            labelText: this.L('Active_2'),
            state: false,
            disabled: !this.checkCanActivate(this.rootHierarchyItem, this.currentHierarchyItem),
        }
    }

    expandItem() {
        this.expanded = !this.expanded;
        this.currentHierarchyItem.expanded = this.expanded;
    }

    prepareTooltipOptions() {
        this.tooltipOptions = new CustomTooltipOptions();
        if (this.currentHierarchyItem.endLevel) {
            this.tooltipOptions.tooltipItems.push({ title: null, content: this.L('Add_hierarchy_end_info') });
        }
        else {
            this.tooltipOptions.tooltipItems.push({ title: null, content: this.L('Add_hierarchy_info') });
        }
    }

    markDeactivation(hierarchy: HierarchyData, code: string, state: boolean) {
        if (hierarchy.subHierarchies != null) {
            for (let item of hierarchy.subHierarchies) {
                if (item.hierarchyCode.startsWith(code)) {
                    item.deleted = state;
                    this.markDeactivation(item, code, state);
                }
            }
        }
    }
}
