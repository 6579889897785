<div class="applications-list-component">
    <div class="apps-list-content" [@resizeList]="detailsPanelVisible ? 'end' : 'begin'">
        <tabpage-header [title]="headerTitle"
                        [actionButtonVisible]="false"
                        [shortMode]="true"
                        [searchEnabled]="true"
                        [historyButtonVisible]="true"
                        [showBreadcrumb]="true"
                        (searchTextChanged)="onSearchTextChanged($event)"
                        (historyButtonClicked)="onHistoryButtonClicked($event)">
        </tabpage-header>
        <div class="apps-list-description">{{L('Emigo_Applications_description')}}</div>
        <div class="apps-list" *ngIf="firstDownloadingData || (filteredApplicationsList != null && filteredApplicationsList.length > 0)">
            <div class="apps-list-header">
                <div class="apps-list-header-column sort name" [ngClass]="{'sorting': sortingColumn=='name'}" (click)="sortColumn('name')">
                    {{L('Name')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="apps-list-header-column availability">
                    {{L('Availability')}}
                </div>
                <div class="apps-list-header-column sort type" [ngClass]="{'sorting': sortingColumn=='businessParentIDAPP'}" (click)="sortColumn('businessParentIDAPP')">
                    {{L('App_type')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="apps-list-header-column sort status" [ngClass]="{'sorting': sortingColumn=='sortStatus'}" (click)="sortColumn('sortStatus')">
                    {{L('Status')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="apps-list-header-column history">
                </div>
            </div>
            <div class="apps-list-row" *ngFor="let item of filteredApplicationsList" [ngClass]="{'selected': selectedApp != null && item.id==selectedApp.id}" (click)="onListItemClick(item)">
                <div class="apps-list-column name" [ngClass]="{'bold': item.businessSuiteAPP}"><span class="center-vert">{{item.name}}</span></div>
                <div class="apps-list-column availability"><span class="center-vert">{{getDatesValue(item)}}</span></div>
                <div class="apps-list-column type"><div *ngIf="item.businessParentIDAPP != null" sagraTooltip [sagraTooltipModel]="appTooltipModel"></div></div>
                <div class="apps-list-column status">
                    <sagra-pill class="center-vert" [type]="getCurrentStatus(item) == 1 ? 2 : 3" [text]="getStatusText(item)" [pillClass]="getStatusesClass(item)"></sagra-pill>
                </div>
                <div class="apps-list-column history">
                    <span class="center-vert icon icon-restore sgf-link-light" title="{{L('Operation_history')}}" (click)="onHistoryListItemClick(item)"></span>
                </div>
            </div>
        </div>
        <div class="container-for-placeholder" *ngIf="!firstDownloadingData && (filteredApplicationsList == null || filteredApplicationsList.length == 0)">
            <sagra-placeholder [title]="placeholderOptions.title"
                               [description]="placeholderOptions.description"
                               [button]="placeholderOptions.button"
                               [image]="placeholderOptions.image">
            </sagra-placeholder>
        </div>
    </div>
    <div class="app-details-panel" [@showPanel]="detailsPanelVisible ? 'end' : 'begin'" *ngIf="detailsPanelVisible">
        <div class="app-details-container">
            <div class="app-details-header">
                <div class="app-details-header-title">{{selectedApp?.name}}</div>
                <div class="app-details-header-close icon icon-btn_close sgf-link-secondary" (click)="onClosePanelClick($event)"></div>
            </div>
            <ng-scrollbar class="sagra-scrollbar" [autoHeightDisabled]="true" [track]="'vertical'" #perfectScroll>
                <div class="app-details-content">
                    <div class="app-details-description">
                        <!--<div>{{L('Application_description_1')}}</div>
                            <div>{{L('Application_description_2')}}</div>-->
                        <div>{{selectedApp?.description}}</div>
                    </div>
                    <sagra-switch class="app-details-switch"
                                  [state]="permissionsModel"
                                  [options]="permissionsSwitchOptions"
                                  [disabled]="readOnlyPanel || disablePermissionsSwitch || (selectedApp?.checkCanBeOn == 1 && !selectedApp?.canBeActivated)"
                                  (stateChange)="onPermissionsSwitchChange($event)"
                                  [sagraTooltip]="tooltipActivationOptions"
                                  [sagraTooltipModel]="tooltipActivationModel"></sagra-switch>
                    <ng-container *ngIf="permissionsModel">
                        <div class="app-details-switch-descr">{{L('Application_link_description')}}</div>
                        <div class="app-details-agreement-link">{{aadLink}}</div>
                        <div class="app-details-agreement-link-action sgf-link-primary" (click)="onCopyLinkClick(aadLink)">{{L('Copy_link_clipboard')}}</div>
                        <sagra-switch class="app-details-switch"
                                      [state]="temporalLimitationsModel"
                                      [options]="tempLimitationsSwitchOptions"
                                      [disabled]="readOnlyPanel"
                                      (stateChange)="onTempLimitationsSwitchChange($event)"></sagra-switch>
                        <div class="app-details-switch-descr" *ngIf="temporalLimitationsModel">{{L('Temporal_limitations_description')}}</div>
                        <div class="app-details-date left" *ngIf="temporalLimitationsModel">
                            <div class="app-details-date-caption">{{L('Run_from_date')}}</div>
                            <div class="app-details-date-control">
                                <sagra-datetime-picker [date]="tempLimitationsStartModel"
                                                       [type]="datePickerType"
                                                       [disabled]="readOnlyPanel || readOnlyStart"
                                                       [minDate]="startMinDate"
                                                       (dateChange)="onTempLimitationsStartDateChanged($event)"></sagra-datetime-picker>
                            </div>
                            <div class="app-details-date-clear icon icon-btn_delete_small" *ngIf="tempLimitationsStartModel != null && !readOnlyPanel && !readOnlyStart" (click)="onClearTempLimitationsStartDate()"></div>
                        </div>
                        <div class="app-details-date right" *ngIf="temporalLimitationsModel">
                            <div class="app-details-date-caption">{{L('Stop_on_the_day')}}</div>
                            <div class="app-details-date-control">
                                <sagra-datetime-picker [date]="tempLimitationsEndModel"
                                                       [type]="datePickerType"
                                                       [disabled]="readOnlyPanel"
                                                       [minDate]="endMinDate"
                                                       (dateChange)="onTempLimitationsEndDateChanged($event)"></sagra-datetime-picker>
                            </div>
                            <div class="app-details-date-clear icon icon-btn_delete_small" *ngIf="tempLimitationsEndModel != null" (click)="onClearTempLimitationsEndDate()"></div>
                        </div>
                        <ng-container *ngIf="selectedApp?.businessParentIDAPP">
                            <div class="app-details-separator">
                                <div class="app-details-separator-line"></div>
                                <span class="app-details-separator-text">{{L('Linked_applications')}}</span>
                            </div>
                            <div class="app-details-switch-descr">{{L('Linked_application_description')}}</div>
                            <div class="app-details-linked-app">
                                <div class="app-details-linked-app-data">
                                    <div class="app-details-linked-app-name">{{parentApp?.name}}</div>
                                    <div class="app-details-linked-app-status">
                                        <sagra-switch *ngIf="getCurrentStatus(parentApp) == 4"
                                                      class="app-details-switch"
                                                      [state]="parentAppSwitchModel"
                                                      [disabled]="readOnlyPanel"
                                                      (stateChange)="onParentAppSwitchChange($event)"></sagra-switch>
                                        <sagra-pill [type]="1" [text]="L('PLANNED')" [pillClass]="'mint'" *ngIf="parentApp.status == 5"></sagra-pill>
                                        <sagra-pill [type]="1" [text]="L('ACTIVE_2')" [pillClass]="'mint'" *ngIf="getCurrentStatus(parentApp) == 2"></sagra-pill>
                                    </div>
                                </div>
                                <div class="app-details-linked-app-link" *ngIf="parentAppSwitchModel || this.getCurrentStatus(this.parentApp) != 4">
                                    <div class="app-details-switch-descr">{{L('Application_link_description')}}</div>
                                    <div class="app-details-agreement-link">{{parentAppAADLink}}</div>
                                    <div class="app-details-agreement-link-action sgf-link-primary" (click)="onCopyLinkClick(parentAppAADLink)">{{L('Copy_link_clipboard')}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="selectedApp?.businessSuiteAPP">
                        <div class="app-details-separator">
                            <div class="app-details-separator-line"></div>
                            <span class="app-details-separator-text">{{L('Linked_applications')}}</span>
                        </div>
                        <div class="app-details-switch-descr">
                            {{L('Secondary_apps_info')}}
                        </div>
                        <div class="app-secondary-item" *ngFor="let item of secondaryApps">
                            <div class="app-secondary-item-name sgf-link-light" (click)="onLinkedApplicationClick(item)">{{item.name}}<span class="icon icon-keyboard_arrow_right"></span></div>
                            <div class="app-secondary-item-status">
                                <sagra-pill [type]="getCurrentStatus(item) == 1 ? 2 : 3" [text]="getStatusText(item)" [pillClass]="getStatusesClass(item)"></sagra-pill>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-scrollbar>
            <div class="apps-list-footer">
                <button class="apps-list-btn-delete sgf-btn-primary" [ngClass]="{'disabled': !dataChanged}" (click)="onSaveButtonClick()">{{L('Save')}}</button>
            </div>
        </div>
    </div>
    <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
</div>
