export class WinPointConfiguration {
    public medicalStaffDB: boolean;
    public localizationsDB: boolean;
    public demo: boolean;
    public getProposals: boolean;
    public sendLocalizations: boolean;
    public sendCategories: boolean;
    public sendProposals: boolean;
    public getClientBB: boolean;
    public sendPayers: boolean;
    public sendHistCecha1: boolean;
    public sendHistCecha2: boolean;
    public sendDictionaries: boolean;
    public nipRequired: boolean;
    public sendErrands: boolean;
    public sendClasses: boolean;
    public addMissingLocalizations: boolean;
    public getRelations: boolean;
    public sendRelations: boolean;
    public clientDeleted: boolean;
    public status: number;
    public errorInfo: string;

    constructor() { }

    setDefault() {
        this.medicalStaffDB = false;
        this.demo = false;
        this.getProposals = true;
        this.sendLocalizations = true;
        this.sendCategories = true;
        this.sendProposals = true;
        this.getClientBB = false;
        this.sendPayers = false;
        this.sendHistCecha1 = false;
        this.sendHistCecha2 = false;
        this.nipRequired = true;
        //this.sendDictionaries = true;
    }
}
