import { SagraTooltipInterface, SagraTooltipOptionsInterface } from "@sagra/sagra-tooltip";
import { Moment } from "moment";

export class ApplicationAAD {
    id: string;
    name: string;
    description: string;
    status: number;
    statusInfo: string;
    link: string;
    startDate: Moment;
    endDate: Moment;
    aadClientAPP: boolean;
    businessSuiteAPP: boolean;
    businessParentIDAPP: string;
    canBeDelete: boolean;
    checkCanBeOn: number;

    canBeActivated?: boolean;
    activationInfo?: string;
    tooltipModel?: SagraTooltipInterface;
    sortStatus: number;

    constructor() {

    }
}
