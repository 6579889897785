import { Component, OnInit } from '@angular/core';
import { SagraFilterInterface, SagraFiltersListIconType, SagraFiltersOptionsInterface, SagraFiltersType } from '@sagra/sagra-filters';
import { SagraInputIconPosition, SagraInputOptionsModelInterface } from '@sagra/sagra-input';
import { SagraTranslatable, SagraTranslationService } from '@sagra/sagra-translation';
import { catchError, combineLatest, filter, Subject, takeUntil, throwError } from 'rxjs';
import { MonitoringService } from '../../services/monitoring/monitoring.service';
import { SagraGridColumnDefinitionInteface, SagraGridColumnType, SagraGridOptionsInterface, TooltipTarget } from '@sagra/sagra-grid';
import { MonitorignFilters } from '../../models/monitoring/monitorignFilters.model';
import moment from 'moment';
import { JobOperationType } from '../../models/monitoring/jobStatus.model';
import { SagraLoaderOptionsInterface, SagraLoaderType } from '@sagra/sagra-loader';
import { Router } from '@angular/router';
import { MessageBusService } from '../../services/messageBusService/messageBusService';
import { SagraPlaceholdeImageInterface, SagraPlaceholderButtonType, SagraPlaceholderImage, SagraPlaceholderOptionsInterface } from '@sagra/sagra-placeholder';

@Component({
    selector: 'app-monitoring',
    templateUrl: './monitoring.component.html',
    styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent extends SagraTranslatable implements OnInit {
    private readonly _destroying$ = new Subject<void>();
    ngOnDestroy() {
        this._destroying$.next();
        this._destroying$.complete();
    }

    public searchResult: boolean = true;
    public showLoader: boolean = false;
    public jobsCount: number = 0
    showColumnsFilter: any[] = [];

    public filtersOptions: SagraFiltersOptionsInterface;
    public filters = {
        onOff: null,
        status: null,
        author: null,
        dbName: null,
        date: { start: null, end: null },
        jobType: null
    }

    public searchInputOptions: SagraInputOptionsModelInterface;
    public searchText: string;

    public gridOptions: SagraGridOptionsInterface;
    public list: any[] = [];

    public sagraLoaderOptions: SagraLoaderOptionsInterface;

    public placeholderOptions: SagraPlaceholderOptionsInterface;
    public listNoResultImage: SagraPlaceholdeImageInterface;
    public filterNoResultOptions: SagraPlaceholderOptionsInterface;

    constructor(private monitoringService: MonitoringService,
        private messageService: MessageBusService,
        private router: Router,
        translationService: SagraTranslationService) {
        super(translationService);
        this.showLoader = true;
        this.setDataToGrid();

        //#region Placeholder Options
        this.placeholderOptions = {
            button: { className: 'sgf-link', name: this.L('CreateNewProduct'), type: SagraPlaceholderButtonType.link, handler: null },
            image: { type: SagraPlaceholderImage.file }
        }

        this.listNoResultImage = { type: SagraPlaceholderImage.file };

        this.filterNoResultOptions = {
            image: {
                type: SagraPlaceholderImage.search
            },
            title: this.L('NoSearchResultLabel'),
        }

        //#endregion

        messageService.getMonitoringRefresh()
            .pipe(takeUntil(this._destroying$))
            .subscribe((jobInfo) => {
                if (this.list) {
                    let job = this.list.find(x => x.jobID == jobInfo.job.id)
                    job.timestamp = jobInfo.status.timestamp;
                    job.status = [{ type: 1, text: this.monitoringService.getJobStateName(jobInfo.status.jobState), pillClass: this.monitoringService.getJobStatePillsClass(jobInfo.status.jobState) }];
                    job.tasks = `${jobInfo.status.tasksCompleted} / ${jobInfo.status.tasks}`;
                }
            });

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }

        //Opcje szukajki
        this.searchInputOptions = {
            icon:
            {
                className: "icon-search2",
                position: SagraInputIconPosition.left,
                size: 20,
                margin: 5
            },
            placeholder: this.L('Search'),
            clearButton: true
        }

        let filterList: SagraFilterInterface[] = [
            {
                name: this.L('Status'),
                fieldName: 'status',
                type: SagraFiltersType.list,
                selectList: [
                    { id: 'Running', name: this.L('Running'), iconClass: 'icon-circle_2', iconColor: '#0084f5' },
                    { id: 'Manual', name: this.L('Manual'), iconClass: 'icon-circle_2', iconColor: '#0084f5' },
                    { id: 'Retrying', name: this.L('Retrying'), iconClass: 'icon-circle_2', iconColor: '#d2e600' },
                    { id: 'Suspended', name: this.L('Suspended'), iconClass: 'icon-circle_2', iconColor: '#969696' },
                    { id: 'Failed', name: this.L('Failed'), iconClass: 'icon-circle_2', iconColor: '#f21843' },
                    { id: 'Completed', name: this.L('Completed'), iconClass: 'icon-circle_2', iconColor: '#38ba6f' },
                    { id: 'Stopped', name: this.L('Stoped'), iconClass: 'icon-circle_2', iconColor: '#969696' },
                ],
                labelIconClass: 'icon-fast_forward',
                listIconType: SagraFiltersListIconType.circle,
            },
            {
                name: this.L('JobType'),
                fieldName: 'jobType',
                type: SagraFiltersType.list,
                selectList: [
                    { id: JobOperationType.ActivateRegister, name: this.L(JobOperationType[JobOperationType.ActivateRegister]) },
                    { id: JobOperationType.ActivitiesActions, name: this.L(JobOperationType[JobOperationType.ActivitiesActions]) },
                    { id: JobOperationType.Processes, name: this.L(JobOperationType[JobOperationType.Processes]) },
                    { id: JobOperationType.EmigoRole, name: this.L(JobOperationType[JobOperationType.EmigoRole]) },
                    { id: JobOperationType.EmigoDataSheet, name: this.L(JobOperationType[JobOperationType.EmigoDataSheet]) },
                    { id: JobOperationType.Extract, name: this.L(JobOperationType[JobOperationType.Extract]) },
                    { id: JobOperationType.TWR, name: this.L(JobOperationType[JobOperationType.TWR]) },
                    { id: JobOperationType.Districts, name: this.L(JobOperationType[JobOperationType.Districts]) },
                    { id: JobOperationType.BazaBaz, name: this.L(JobOperationType[JobOperationType.BazaBaz]) },
                    { id: JobOperationType.Hierarchies, name: this.L(JobOperationType[JobOperationType.Hierarchies]) },
                    { id: JobOperationType.Applications, name: this.L(JobOperationType[JobOperationType.Applications]) },
                    { id: JobOperationType.Targets, name: this.L(JobOperationType[JobOperationType.Targets]) },
                    { id: JobOperationType.PortalLogo, name: this.L(JobOperationType[JobOperationType.PortalLogo]) },
                    { id: JobOperationType.Cache, name: this.L(JobOperationType[JobOperationType.Cache]) },
                    { id: JobOperationType.AADConsents, name: this.L(JobOperationType[JobOperationType.AADConsents]) },
                    { id: JobOperationType.NewEmigo, name: this.L(JobOperationType[JobOperationType.NewEmigo]) },
                    { id: JobOperationType.NewF1, name: this.L(JobOperationType[JobOperationType.NewF1]) },
                ],
                labelIconClass: 'icon-fast_forward',
                listIconType: SagraFiltersListIconType.circle,
            }

        ];

        monitoringService.getFilters().pipe(takeUntil(this._destroying$)).subscribe(response => {
            filterList.push({
                name: this.L('Author'),
                fieldName: 'author',
                type: SagraFiltersType.list,
                labelIconClass: 'icon-person',
                selectList: response.authors.map(x => ({ id: x, name: x })),
                listIconType: SagraFiltersListIconType.circleWithFirstLetter,
                sortByName: true,
                searchable: true,
            });
            filterList.push({
                name: this.L('DbName'),
                fieldName: 'dbName',
                type: SagraFiltersType.list,
                labelIconClass: 'icon-table',
                selectList: response.dbNames.map(x => ({ id: x, name: x })),
                sortByName: true,
                searchable: true,
            });

            filterList.push({
                name: this.L('Timestamp'),
                fieldName: 'date',
                type: SagraFiltersType.dateRange,
                labelIconClass: 'icon-btn_datepicker',
                selectList: [],
            });

            this.filtersOptions = {
                filtersList: filterList,
                zIndex: 4000,
            }
        });

        const subjectRowClick: Subject<any> = new Subject<any>();
        subjectRowClick.subscribe(id => {
            this.router.navigate(['/jobView'], { queryParams: { jobId: id, tab: 3 } })
        });


        this.gridOptions = {
            columnsDefinition: [],
            rowIdColumn: "jobID",  // wymagane do działania 'click handlerów'
            stickyHeader: true,
            clickRowHandler: subjectRowClick,
            searchText: this.searchText?.toLowerCase(),
            searchByColumns: ["name"],
            selectionColumn: 'timestamp',
            sortingColumn: 'timestamp',
            sortingAsc: false,
            tableMinWidth: 800,
            rowClassColumn: 'rowClass',
            scroll: {
                virtualScroll: {
                    itemSize: 60,
                    minBufferPx: 100,
                    maxBufferPx: 200
                },
                scrollOptions: {
                    autoHeightDisabled: true
                }
            }
        }

        this.clearFilters();
        this.setGridColumns();

    }

    public setGridColumns() {
        let columsDef: SagraGridColumnDefinitionInteface[] = [];
        //columsDef.push({
        //    firstLineOrValueColumn: "lp",
        //    description: this.L("lp"),
        //    columnBold: false,
        //    sortable: false,
        //    include: false,
        //    textNoWrap: true,
        //    type: SagraGridColumnType.text,
        //    width: 140
        //});
        columsDef.push({
            firstLineOrValueColumn: "jobID",
            description: this.L("JobID"),
            tooltipColumn: 'jobIDTT',
            tooltipTarget: TooltipTarget.cell,
            columnBold: false,
            sortable: false,
            include: false,
            textNoWrap: true,
            type: SagraGridColumnType.text,
            width: 140
        });
        columsDef.push({
            firstLineOrValueColumn: "timestamp",
            description: this.L("Timestamp"),
            tooltipColumn: 'timestampTT',
            tooltipTarget: TooltipTarget.cell,
            columnBold: false,
            sortable: true,
            include: true,
            textNoWrap: true,
            type: SagraGridColumnType.text,
            width: 80
        });
        columsDef.push({
            firstLineOrValueColumn: "dbName",
            description: this.L('DbName'),
            tooltipColumn: 'dbNameTT',
            tooltipTarget: TooltipTarget.cell,
            columnBold: true,
            sortable: true,
            include: true,
            textNoWrap: true,
            type: SagraGridColumnType.text,
            width: 100,
        });
        columsDef.push({
            firstLineOrValueColumn: "name",
            tooltipColumn: 'nameTT',
            tooltipTarget: TooltipTarget.cell,
            description: this.L('Name'),
            columnBold: false,
            sortable: true,
            include: true,
            textNoWrap: true,
            type: SagraGridColumnType.text,
            width: 250,
        });
        columsDef.push({
            firstLineOrValueColumn: "jobType",
            description: this.L('JobType'),
            tooltipColumn: 'jobTypeTT',
            tooltipTarget: TooltipTarget.cell,
            columnBold: false,
            sortable: true,
            include: true,
            type: SagraGridColumnType.text,
            width: 80,
        });
        columsDef.push({
            firstLineOrValueColumn: "author",
            description: this.L('Author'),
            tooltipColumn: 'authorTT',
            tooltipTarget: TooltipTarget.cell,
            sortable: true,
            include: true,
            textNoWrap: true,
            type: SagraGridColumnType.text,
            width: 100,
        });
        columsDef.push({
            firstLineOrValueColumn: "status",
            description: "Status",
            tooltipColumn: 'statusTT',
            tooltipTarget: TooltipTarget.cell,
            columnBold: false,
            sortable: true,
            include: true,
            type: SagraGridColumnType.pills,
            width: 70,
        });
        columsDef.push({
            firstLineOrValueColumn: "tasks",
            description: "Tasks",
            tooltipColumn: 'tasksTT',
            tooltipTarget: TooltipTarget.cell,
            columnBold: false,
            sortable: true,
            include: true,
            textNoWrap: true,
            type: SagraGridColumnType.text,
            width: 50,
        });
        columsDef.push({
            firstLineOrValueColumn: "env",
            tooltipColumn: 'envTT',
            tooltipTarget: TooltipTarget.cell,
            description: "Env",
            columnBold: false,
            sortable: false,
            include: true,
            type: SagraGridColumnType.pills,
            width: 50,
        });

        this.gridOptions.columnsDefinition = columsDef;
    }


    filterHandler() {

        this.showLoader = true;
        this.setDataToGrid();
    }

    filterGridHandler() {
        if (this.searchText) {
            let toSearch = this.list?.filter(x => x.name.toLowerCase().includes(this.searchText?.toLowerCase()));
            this.searchResult = toSearch?.length > 0
        } else {
            this.searchResult = this.list.length > 0
        }


    }

    setDataToGrid() {
        this.list = [];
        let mf: MonitorignFilters = {
            page: 0,
            take: 0,
            jobState: this.filters.status,
            dbName: this.filters.dbName,
            author: this.filters.author,
            description: this.searchText,
            jobOperationType: this.filters.jobType
        }
        if (this.filters?.date?.start) {
            let startDate = moment.utc(this.filters.date.start);
            if (startDate.isValid())
                mf.fromDate = startDate.format("yyyy-MM-DD");
            else
                mf.fromDate = null;
        } else
            mf.fromDate = null;
        if (this.filters?.date?.end) {
            let endDate = moment.utc(this.filters.date.end);

            if (endDate.isValid())
                mf.toDate = endDate.format("yyyy-MM-DD");
            else
                mf.toDate = null;
        } else
            mf.toDate = null;

        let getJobStatuses$ = this.monitoringService.getStatuses(mf).pipe(takeUntil(this._destroying$)).pipe(catchError(error => {
            return throwError(() => error)
        }));

        let getJobStatusesCount$ = this.monitoringService.getCountStatuses(mf).pipe(takeUntil(this._destroying$)).pipe(catchError(error => {
            return throwError(() => error)
        }));

        combineLatest([getJobStatuses$, getJobStatusesCount$]).pipe(
            takeUntil(this._destroying$)
        ).subscribe({
            next: ([jobs, jobsCount]) => {
                this.jobsCount = jobsCount;
                this.list = [];
                for (let i = 0; i < jobs.length; i++) {
                    let job = jobs[i];
                    let row = {
                        lp: i + 1,
                        jobID: job.id,
                        jobIDTT: {
                            tooltipItems: [{ title: this.L('JobId'), content: job.id }]
                        },
                        timestamp: moment(job.timestamp).format('YYYY-MM-DD HH:MM:SS'),
                        timestampTT: {
                            tooltipItems: [{ title: this.L('Timestamp'), content: moment(job.timestamp).format('YYYY-MM-DD HH:mm:ss') }]
                        },
                        dbName: job.tenant,
                        dbNameTT: {
                            tooltipItems: [{ title: this.L('DbName'), content: job.tenant }]
                        },
                        author: job.author,
                        authorTT: {
                            tooltipItems: [{ title: this.L('Author'), content: job.author }]
                        },
                        name: job.description,
                        nameTT: {
                            tooltipItems: [{ title: this.L('Name'), content: job.description }]
                        },
                        status: [{ type: 1, text: this.monitoringService.getJobStateName(job.jobState), pillClass: this.monitoringService.getJobStatePillsClass(job.jobState) }],
                        statusTT: {
                            tooltipItems: [{ title: this.L('Status'), content: this.monitoringService.getJobStateName(job.jobState) }]
                        },
                        tasks: `${job.tasksCompleted} / ${job.tasks}`,
                        tasksTT: {
                            tooltipItems: [{ title: this.L('Tasks'), content: `${job.tasksCompleted} / ${job.tasks}` }]
                        },
                        jobType: JobOperationType[job.jobOperationType],
                        jobTypeTT: {
                            tooltipItems: [{ title: this.L('JobType'), content: JobOperationType[job.jobOperationType] }]
                        },
                        env: [{ type: 1, text: job.environment, pillClass: this.getEnvPillClass(job.environment) }],
                        envTT: {
                            tooltipItems: [{ title: this.L('Env'), content: job.environment }]
                        },
                        rowClass: 'rowW'
                    };
                    this.list.push(row);
                };
                this.filterGridHandler();
                this.showLoader = false;
            }
        });
    }

    getEnvPillClass(env: string) {
        switch (env) {
            case 'Production': return 'mint'
            default: return 'violet'
        }
    }

    //Czyszczenie filtrów
    public clearFilters() {
    }

    ngOnInit() {

    }

}
