<div class="main">
    <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
    <div *ngIf="jobInfo" class="header">
        <div class="header-first">
            <a class="sgf-btn-white-border" [routerLink]="['/monitoring']" [queryParams]="{tab: 3,}"><span class="icon icon-arrow_back"></span></a>
            <sagra-pill [type]="1" [text]="getJobStateName(jobInfo.status.jobState)" [pillClass]="getJobStatePillsClass(jobInfo.status.jobState)"></sagra-pill>
            {{jobInfo.status.description}}
        </div>
        <div class="header-buttons" *ngIf="userContext.canRestart">
            <!--<button class="sgf-btn-white-border" (click)="showJob()"><span class="icon icon-file-code-o"></span> {{L('ShowJob')}}</button>-->
            <button class="sgf-btn-white-border" (click)="recalculateJob()"><span class="icon icon-refresh"></span> {{L('RestartJob')}}</button>
            <!--<button class="sgf-btn-white-border" (click)=""><span class="icon icon-block"></span> {{L('StopJob')}}</button>
            <button class="sgf-btn-white-border" (click)=""><span class="icon icon-block"></span> {{L('StopRetry')}}</button>-->
        </div>
    </div>
    <div *ngIf="jobInfo" class="job">
        <div class="job-left">
            <div class="job-header">
                <div class="title">{{L('CurrentTaskNumber')}} <div class="task-number">{{getTaskNumber()}}</div></div>
                <div class="task">
                    <div class="desc">
                        <div class="col-desc">{{L('TaskDescription')}}:</div>
                        <div>
                            {{jobInfo.currentTask.description}}
                        </div>
                    </div>
                    <div class="desc">
                        <div class="col-desc">{{L('TaskType')}}:</div>
                        <div>
                            {{jobInfo.currentTask.type}}
                        </div>
                    </div>
                    <div class="desc">
                        <div class="col-desc">{{L('CurrentTaskId')}}:</div>
                        <div>
                            {{jobInfo.status.currentTaskId ?? jobInfo.status.lastTaskId}}
                        </div>
                    </div>
                    <div class="desc">
                        <div class="col-desc">{{L('Timestamp')}}:</div>
                        <div>
                            {{getTimestampFormated()}}
                        </div>
                    </div>
                    <div class="desc">
                        <div class="col-desc">{{L('Retrays')}}:</div>
                        <div>{{jobInfo.status.retryCount}} </div>
                    </div>
                </div>
                <div>
                    <div *ngIf="jobInfo.status.errorDescription" class="desc">
                        <div class="col-desc">{{L('Error Message')}}:</div>
                        <div>{{jobInfo.status.errorDescription}} </div>
                    </div>
                </div>
                <div class="task-buttons" >
                    <button *ngIf="jobInfo.currentTask?.manualTaskParameters" class="sgf-btn-mint" (click)="openManualtTaskModal()"><span class="icon icon-check_circle"></span> {{L('CompleteManualTask')}}</button>
                    <button *ngIf="userContext.canRestart" class="sgf-btn-cherry" (click)="restartTask()"><span class="icon icon-check_circle"></span> Restart task</button>
                </div>
            </div>
            <div *ngIf="jobInfo.jiraTask" class="jira">
                <div class="left">
                    <div class="jira-icon"></div>
                    <a class="sgf-link-primary" href="https://sagragile.atlassian.net/browse/{{jobInfo.jiraTask.key}}" target="_blank">{{jobInfo.jiraTask.key}}</a>
                    <div>{{jobInfo.jiraTask.fields.summary}}</div>
                </div>
                <div class="right">
                    <div style="align-items: center; display: flex; gap:5px"><img src="{{getAvatar(jobInfo.jiraTask.fields.assignee.avatarUrls)}}" width="24" height="24" /> {{jobInfo.jiraTask.fields.assignee.displayName}}</div>
                    <sagra-pill [type]="1" [text]="L(jobInfo.jiraTask.fields.status.name)" [pillClass]="getJiraPillsClass(jobInfo.jiraTask.fields.status.name)"></sagra-pill>
                    <!--<div >Status</div>-->
                </div>
            </div>
            <div *ngIf="list" class="job-tasks">
                <div class="title">{{L('Tasks')}} <div class="task-number">{{jobInfo.status.tasks}}</div></div>
                <div class="grid" [ngStyle]="{'max-height': jobInfo.jiraTask ? 'calc(100vh - 550px)' : 'calc(100vh - 450px)'}">
                        <sagra-grid [(list)]="list"
                                    [(columnsDefinition)]="gridOptions.columnsDefinition"
                                    [rowIdColumn]="gridOptions.rowIdColumn"
                                    [searchText]="gridOptions.searchText"
                                    [searchByColumns]="gridOptions.searchByColumns"
                                    [markNotIncluded]="gridOptions.markNotIncluded"
                                    [disabledColumn]="'disabled'"
                                    [stickyHeader]="gridOptions.stickyHeader"
                                    [clickRowHandler]="gridOptions.clickRowHandler"
                                    [selectionColumn]="gridOptions.selectionColumn"
                                    [(sortingColumn)]="gridOptions.sortingColumn"
                                    [(sortingAsc)]="gridOptions.sortingAsc"
                                    [tableMinWidth]="gridOptions.tableMinWidth"
                                    [scroll]="gridOptions.scroll"
                                    [rowClassColumn]="gridOptions.rowClassColumn"
                                    [rowAlignment]="gridOptions.rowAlignment"
                                    [enableRowSelectionMode]="gridOptions.enableRowSelectionMode">
                        </sagra-grid>
                </div>
            </div>
        </div>
        <div class="job-right">
            <div class="job-details">
                <div class="title">{{L('JobDetails')}}</div>
                <div class="desc">
                    <div class="col-desc">{{L('JobId')}}:</div>
                    <div>
                        {{jobInfo.status.id}}
                    </div>
                </div>
                <div class="desc" *ngIf="jobInfo.status.jobOperationType">
                    <div class="col-desc">{{L('JobType')}}:</div>
                    <div>
                        {{getJobOperationTypeName(jobInfo.status.jobOperationType)}}
                    </div>
                </div>
                <div class="desc">
                    <div class="col-desc">{{L('Name')}}:</div>
                    <div>
                        {{jobInfo.status.description}}
                    </div>
                </div>
                <div class="desc">
                    <div class="col-desc">{{L('Created')}}:</div>
                    <div>
                        {{getCreateDateFormated()}}
                    </div>
                </div>
                <div class="desc">
                    <div class="col-desc">{{L('Timestamp')}}:</div>
                    <div>
                        {{getTimestampFormated()}}
                    </div>
                </div>
                <div class="desc">
                    <div class="col-desc">{{L('Tenant')}}:</div>
                    <div>
                        {{jobInfo.status.tenant}}
                    </div>
                </div>
                <div class="desc">
                    <div class="col-desc">{{L('Author')}}:</div>
                    <div>
                        {{jobInfo.status.author}}
                    </div>
                </div>
                <div class="desc" style="">
                    <div class="col-desc">{{L('Env')}}:</div>
                    <div>
                        <sagra-pill [type]="1" [text]="L(jobInfo.status.environment)" [pillClass]="getEnvPillClass(jobInfo.status.jobState)"></sagra-pill>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-manualTaskMonitoring *ngIf="showModal" [(showModal)]="showModal" [manualTask]="jobInfo.currentTask"></app-manualTaskMonitoring>
