import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";

@Component({
    selector: 'status-pill',
    templateUrl: './status-pill.component.html',
    styleUrls: ['./status-pill.component.scss']
})

export class StatusPillComponent implements OnChanges {
    @Input() statuses: Array<StatusDefinition>;
    @Input() statusId: number;

    public status: StatusDefinition = {id: 0, name: '', type: StatusType.hidden};
    public sagraRowLoaderOptions: SagraLoaderOptionsInterface;


    constructor() {
        this.sagraRowLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            backgroundSetings: {
                backgroundColor: 'transparent'
            },
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.statusId) {
            this.setStatus();
        }
    }

    setStatus() {
        if (this.statuses != null && this.statusId != null) {
            const result = this.statuses.filter(item => { return item.id == this.statusId; });
            if (result != null && result.length > 0) {
                this.status = result[0];
                return;
            }
        }
        this.status = null;
    }

    getStatusClass() {
        switch (this.status.type) {
            case 0:
                return '';
            case 1:
                return 'in-progress';
            case 2:
                return 'green';
            case 3:
                return 'red';
            case 4:
                return 'blue';
            case 5:
                return 'orange';
            case 6:
                return 'grey';
        }
    }

}

export class StatusDefinition {
    id: number;
    name: string;
    type: StatusType;
}

export enum StatusType {
    hidden = 0,
    inProgress = 1,
    green = 2,
    red = 3,
    blue = 4,
    orange = 5,
    grey = 6,
}
