<div class="main-content">
    <div class="body-content" [ngClass]="{'grid': !hideHeader}">
        <header-layout [appName]="appName" *ngIf="!showLoaderOnStart && !hideHeader"></header-layout>
        <div class="container" *ngIf="!showLoaderOnStart">
            <router-outlet></router-outlet>
        </div>
        <sagra-loader *ngIf="showLoader || showLoaderOnStart" [backgroundSetings]="loaderOptions.backgroundSetings"></sagra-loader>
        <iframe #iframeTokenRefresh *ngIf="refreshToken" (load)="finish()" src="/Account/SignIn"></iframe>
        <sagra-dialog [options]="dialogOptions"></sagra-dialog>
        <sagra-toasts [options]="toastsOptions"></sagra-toasts>
    </div>
    <div #notificationPanel class="notification-content" [@hidenotifications]="showNotification ? 'open' : 'closed'">
        <notification-panel [showNotification]="showNotification"></notification-panel>
    </div>
</div>
<dialog-new-database></dialog-new-database>
<dialog-select-feed-groups [options]="dialogFeedGroupsOptions"></dialog-select-feed-groups>
<dialog-agreements></dialog-agreements>
<dialog-edit-hierarchy [options]="dialogEditHierarchyOptions"></dialog-edit-hierarchy>
<dialog-select-cache-version *ngIf="showSelectVersion" [dialogData]="selectVersionDialogData"></dialog-select-cache-version>
<manage-targeting-modal></manage-targeting-modal>
