<sagra-modal [showModal]="show" [options]="options" [title]="title">
    <!--Body-->
    <div class="sgf-modal-body">
        <div class="input-information-container">
            <div class="input-name">{{L('Name')}} *</div>
            <div class="input-characters-left">{{L('CharactersLeft')}}: {{targetingGroupNameMaxLength - (inputValue.length > targetingGroupNameMaxLength ? targetingGroupNameMaxLength : inputValue.length)}}</div>
        </div>

        <sagra-input #input [(model)]="inputValue" [clearButton]="inputOptions.clearButton" [characterLimit]="inputOptions.characterLimit" [validationRegex]="inputOptions.validationRegex" [invalidText]="inputOptions.invalidText" [isRequired]="inputOptions.isRequired" [returnInvalidValue]="inputOptions.returnInvalidValue"></sagra-input>

    </div>

    <!--Footer-->
    <div class="sgf-modal-footer">
        <button *ngIf="modalState?.targetingGroup" class="sgf-btn-cherry" (click)="delete()">
            <span class="icon icon-btn_delete"></span><span>{{L('Delete')}}</span>
        </button>
        <button class="sgf-btn-light" (click)="closeModal()">{{L('Cancel')}}</button>
        <button class="sgf-btn-primary" (click)="save()">
            <span class="icon icon-cloud_upload"></span><span>{{L('Save')}}</span>
        </button>
    </div>

</sagra-modal>
