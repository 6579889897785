import { Pipe, PipeTransform } from "@angular/core";
import { TargetingGroup } from "../../models/targeting/targetingGroup.model";

@Pipe({
    name: 'targetingListPipe'
})

export class TargetingListPipe implements PipeTransform {

    transform(items: TargetingGroup[], searchText: string): any[] {

        if (!items || items.length == 0) {
            return items;
        }

        let targetingGroups: TargetingGroup[] = items;

        if (searchText)
            targetingGroups = targetingGroups.filter(x => x.name.toLowerCase().includes(searchText));

        return targetingGroups;
    }
}
