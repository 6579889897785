import { FilterItem } from "./filterItem.model";
import { SelectedFilter } from "./selectedFilter";

export class FilterGroup {
    key: string;
    caption: string;
    items: FilterItem[];

    constructor(key: string, caption: string) {
        this.key = key;
        this.caption = caption;
        this.items = [];
    }

    clearSelection() {
        for (let item of this.items) {
            item.selected = false;
        }
    }

    setFilterSelected(key: string, state: boolean) {
        let itemToSelect = this.items.filter(item => {
            return item.key == key;
        });
        itemToSelect[0].selected = state;
    }
    getSelectedFilters(): SelectedFilter {
        let selFilter = new SelectedFilter();
        selFilter.idGroup = this.key;
        for (let item of this.items) {
            if (item.selected) {
                selFilter.items.push({ id: item.key, state: item.selected });
            }
        }
        return selFilter;
    }
}
