import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, Configuration, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

// this checks if the app is running on IE
export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: '32934c34-f29d-49d8-95e4-c9332ad9c975',
            authority: 'https://login.microsoftonline.com/58e8ee6a-493f-4952-beff-df85c861d764',
            redirectUri: '/',
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Error,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.api, ['https://sagra.pl/TenantConfiguratorAPI/Access']);
    protectedResourceMap.set(environment.apiProd, ['https://sagra.pl/TenantConfiguratorAPI/Access']);
    protectedResourceMap.set(environment.apiDNS, ['https://sagra.pl/TenantConfiguratorAPI/Access']);
    protectedResourceMap.set(environment.apiStaviator, ['https://sagra.pl/StaviatorAPI/Access']);
    protectedResourceMap.set(environment.apiStaviatorProd, ['https://sagra.pl/StaviatorAPI/Access']);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['openid', 'profile']
        }
    };
}


export function loggerCallback(logLevel: LogLevel, message: string) {
    if (logLevel == LogLevel.Error) {
        console.error(message)
    }
    else {
        console.log(message);
    }

    
}

