import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, AfterViewInit } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { DialogFeedGroupsOptionsInterface, DialogFeedGroupsOptions } from "../../logic/dialogFeedGroupsOptions.interface";
import { FeedGroup } from "../../models/repository/feedGroup.model";
import { SagraCheckboxComponentCilckableArea, SagraCheckboxComponentLabelPosition, SagraCheckboxComponentOptionsInterface, SagraCheckboxComponentSize } from "@sagra/sagra-checkbox";
import { DialogOpacity, DialogZoom } from "../../animations/animations";

const animationDuration: number = 200;

@Component({
    selector: 'dialog-select-feed-groups',
    templateUrl: './dialog-select-feed-groups.component.html',
    styleUrls: ['./dialog-select-feed-groups.component.scss'],
    animations: [ DialogZoom(animationDuration), DialogOpacity(animationDuration) ]
})

export class DialogSelectFeedGroupsComponent extends SagraTranslatable implements OnInit, AfterViewInit {
    @Input() options: DialogFeedGroupsOptionsInterface;

    public visible: boolean;
    public animation: string;
    public selectedFeedGroups: Array<number>;
    public feedGroups: Array<FeedGroup>;

    public checkboxOptions: SagraCheckboxComponentOptionsInterface;

    constructor(public translationService: SagraTranslationService
    ) {
        super(translationService);

        this.checkboxOptions = {
            labelPosition: SagraCheckboxComponentLabelPosition.right,
            singleLineLabel: true,
            checkboxsize: SagraCheckboxComponentSize.small,
            clickable: SagraCheckboxComponentCilckableArea.wholeItem
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            if (this.options) {
                this.options = new DialogFeedGroupsOptions(
                    this.options.feedGroups,
                    this.options.selectedFeedGroups,
                    this.options.cancelHandler,
                    this.options.publishHandler
                );
                this.feedGroups = this.options.feedGroups;
                this.selectedFeedGroups = this.options.selectedFeedGroups;
                this.visible = true;
                this.animation = 'in';

                this.selectDefaultGroups();
            }
        }
    }

    onCloseClick() {
        setTimeout(() => {
            this.visible = false;
        }, animationDuration);

        this.animation = 'out';
    }

    onCancelClick() {
        setTimeout(() => {
            this.visible = false;
        }, animationDuration);

        this.animation = 'out';
    }

    onPublishClick() {
        setTimeout(() => {
            this.visible = false;
        }, animationDuration);

        this.animation = 'out';
        if (this.options.publishHandler != null) {
            this.options.publishHandler.next(this.selectedFeedGroups);
        }
    }

    public selectGroup(group, value) {
        if (this.selectedFeedGroups == null) {
            this.selectedFeedGroups = [];
        }
        let bFound = false;
        for (let i = 0; i < this.selectedFeedGroups.length; i++) {
            if (this.selectedFeedGroups[i] == group.id) {
                bFound = true;
                this.selectedFeedGroups.splice(i, 1);
                break;
            }
        }
        if (!bFound) {
            this.selectedFeedGroups.push(group.id);
        }
    }

    public isGroupSelected(group) {
        if (this.selectedFeedGroups != null) {
            let result = this.selectedFeedGroups.filter(item => { return item == group.id; });
            if (result != null && result.length > 0) {
                return true;
            }
        }
        return false;
    }

    public selectDefaultGroups() {
        if (this.selectedFeedGroups == null || this.selectedFeedGroups.length == 0) {
            this.selectedFeedGroups = [];
            for (var i = 0; i < this.feedGroups.length; i++) {
                if (this.feedGroups[i].defaultGroup) {
                    this.selectedFeedGroups.push(this.feedGroups[i].id);
                }
            }
        }
    }

}
