import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

import { SagraCheckboxModule } from '@sagra/sagra-checkbox';
import { SagraComboboxMultiSelectModule } from '@sagra/sagra-combobox-multiselect';
import { SagraDialogModule } from '@sagra/sagra-dialog';
import { SagraInputModule } from '@sagra/sagra-input';
import { SagraLoaderModule } from '@sagra/sagra-loader';
import { SagraProfileDropdownModule } from '@sagra/sagra-profile-dropdown';
import { SagraRadioModule } from '@sagra/sagra-radio';
import { SagraSwitchModule } from '@sagra/sagra-switch';
import { SagraToastsModule, SagraToastsService } from '@sagra/sagra-toasts';
import { SagraTranslationModule, SagraTranslationService, SagraTranslationServiceOptionsInterface, SAGRA_TRANSLATION_SERVICE_CONFIG } from '@sagra/sagra-translation';
import { HeaderLayoutComponent } from './components/header-layout/header-layout.component';
import { ContextService } from './services/contextService/context.service';
import { MessageBusService } from './services/messageBusService/messageBusService';
import { DatabaseListComponent } from './components/database-list/database-list.component';
import { DatabaseService } from './services/databaseService/database.service';
import { DatabaseDetailsComponent } from './components/database-details/database-details.component';
import { DatabaseInfoComponent } from './components/database-info/database-info.component';
import { RolesListComponent } from './components/roles-list/roles-list.component';
import { DatabaseListFiltersComponent } from './components/database-list-filters/database-list-filters.component';
import { DatabaseListListComponent } from './components/database-list-list/database-list-list.component';
import { CookieService } from 'ngx-cookie-service';
import { DatabaseDetailsNavigationComponent } from './components/database-details-navigation/database-details-navigation.component';
import { RepositoryService } from './services/repositoryService/repository.service';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { TabPageHeaderComponent } from './components/tabpage-header/tabpage-header.component';
import { RolesListSearchFilter } from './filters/rolesListSearch.filter';
import { DatabaseListSearchFilter } from './filters/databaseListSearch.filter';
import { RepositoryListSearchFilter } from './filters/repositoryListSearch.filter';
import { RepositoryListComponent } from './components/repository-list/repository-list.component';
import { RegisterListComponent } from './components/register-list/register-list.component';
import { RegisterService } from './services/registerService/register.service';
import { TargetingService } from './services/targetingService/targeting.service';
import { RegisterActivitySearchFilter, RegisterGroupSearchFilter } from './filters/registerListSearch.filter';
import { DistrictsListComponent } from './components/districts-list/districts-list.component';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { NotificationService } from './services/notificationService/notification.service';
import { DistrictsListSearchFilter } from './filters/districtsListSearch.filter';
import { DialogNewDatabaseComponent } from './components/dialog-new-database/dialog-new-database.component';
import { RoleService } from './services/roleService/role.service';
import { CustomTooltipDirective } from './directives/custom-tooltip/custom-tooltip.directive';
import { SafeHtmlPipe } from './filters/safeHtml.filter';
import { TenantService } from './services/tenantService/tenant.service';
import { DialogNewDatabaseWinpointComponent } from './components/dialog-new-database-winpoint/dialog-new-database-winpoint.component';
import { WinPointComponent } from './components/winpoint/winpoint.component';
import { H5TablesComponent } from './components/h5-tables/h5-tables.component';
import { H5TablesListSearchFilter } from './filters/h5TablesListSearch.filter';
import { H5TablesService } from './services/h5tablesService/h5tables.service';
import { BIQService } from './services/biqService/biq.service';
import * as moment from 'moment';
import { DialogSelectFeedGroupsComponent } from './components/dialog-select-feed-groups/dialog-select-feed-groups.component';
import { BiqPackageComponent } from './components/database-info/biq-package/biq-package.component';
import { NewWorldComponent } from './components/database-info/new-world/new-world.component';
import { DialogAgreementsComponent } from './components/dialog-agreements/dialog-agreements.component';
import { ConfigurationService } from "./services/configurationService/configuration.service";
import { HierarchyListComponent } from './components/hierarchy-list/hierarchy-list.component';
import { HierarchyService } from './services/hierarchyService/hierarchy.service';
import { HierarchyTreeComponent } from './components/hierarchy-list/hierarchy-tree/hierarchy-tree.component';
import { DialogEditHierarchyComponent } from './components/hierarchy-list/dialog-edit-hierarchy/dialog-edit-hierarchy.component';
import { ApplicationsListComponent } from './components/applications-list/applications-list.component';
import { SagraDatetimePickerModule } from '@sagra/sagra-datetime-picker';
import { StatusPillComponent } from './components/status-pill/status-pill.component';
import { SagraPillModule } from '@sagra/sagra-pill';
import { ApplicationsListSearchFilter } from './filters/applicationsListSearch.filter';
import { SagraTooltipModule } from '@sagra/sagra-tooltip';
import { TargetingListComponent } from './components/targeting-list/targeting-list.component';
import { SagraPlaceholderModule } from '@sagra/sagra-placeholder';
import { ManageTargetingModalComponent } from './components/targeting-list/manage-targeting-modal/manage-targeting-modal.component';
import { TargetingListPipe } from './components/targeting-list/targeting-list.pipe';
import { SagraTabsModule } from '@sagra/sagra-tabs';
import { FunctionalitiesComponent } from './components/functionalities/functionalities.component';
import { SagraDomService, SagraDomServiceModule, SagraDomServiceOptionsInterface, SAGRA_DOM_SERVICE_CONFIG } from '@sagra/sagra-dom-service';
import { FunctionalityListComponent } from './components/functionality-list/functionality-list.component';
import { FunctionalitiesService } from './services/functionalitiesService/functionalities.service';
import { SagraSegmentedControlModule } from '@sagra/sagra-segmented-control';
import { FunctionalitiesListSearchFilter, FunctionalityDetailSearchFilter } from './filters/functionalitiesSearch.filter';
import { OperationsHistoryComponent } from './components/operations-history/operations-history.component';
import { OperationsHistoryService } from './services/operationsHistoryService/operationsHistory.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SagraCollapseExpandModule } from '@sagra/sagra-collapse-expand';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { BreadcrumbsService } from './components/breadcrumbs/breadcrumbs.service';
import { SagraToggleButtonModule } from '@sagra/sagra-toggle-button';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { CacheManagingComponent } from './components/cache-managing/cache-managing.component';
import { DialogSelectCacheVersionComponent } from './components/dialog-select-cache-version/dialog-select-cache-version.component';
import { SagraModalModule } from '@sagra/sagra-modal';
import { CacheDetailsComponent } from './components/cache-managing/cache-details/cache-details.component';
import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { SagraFiltersModule } from '@sagra/sagra-filters';
import { SagraGridModule } from '@sagra/sagra-grid';
import { JobViewComponent } from './components/monitoring/jobView/jobView.component';
import { ManualTaskMonitoringComponent } from './components/monitoring/jobView/manualTaskMonitoring/manualTaskMonitoring.component';


// locale
import localeBG from '@angular/common/locales/bg';
import localeBGExtra from '@angular/common/locales/extra/bg';
registerLocaleData(localeBG, 'bg', localeBGExtra);
import localCS from '@angular/common/locales/cs';
import localeCSExtra from '@angular/common/locales/extra/cs';
registerLocaleData(localCS, 'cs', localeCSExtra);
import localDE from '@angular/common/locales/de';
import localeDEExtra from '@angular/common/locales/extra/de';
registerLocaleData(localDE, 'de', localeDEExtra);
import localeEN from '@angular/common/locales/en-GB';
import localeENExtra from '@angular/common/locales/extra/en-GB';
registerLocaleData(localeEN, 'en-gb', localeENExtra);
import localFR from '@angular/common/locales/fr';
import localeFRExtra from '@angular/common/locales/extra/fr';
registerLocaleData(localFR, 'fr', localeFRExtra);
import localHU from '@angular/common/locales/hu';
import localeHUExtra from '@angular/common/locales/extra/hu';
registerLocaleData(localHU, 'hu', localeHUExtra);
import localIT from '@angular/common/locales/it';
import localeITExtra from '@angular/common/locales/extra/it';
registerLocaleData(localIT, 'it', localeITExtra);
import localLT from '@angular/common/locales/lt';
import localeLTExtra from '@angular/common/locales/extra/lt';
registerLocaleData(localLT, 'lt', localeLTExtra);
import localLV from '@angular/common/locales/lv';
import localeLVExtra from '@angular/common/locales/extra/lv';
registerLocaleData(localLV, 'lv', localeLVExtra);
import localePL from '@angular/common/locales/pl';
import localePLExtra from '@angular/common/locales/extra/pl';
registerLocaleData(localePL, 'pl', localePLExtra);
import localeRO from '@angular/common/locales/ro';
import localeROExtra from '@angular/common/locales/extra/ro';
registerLocaleData(localeRO, 'ro', localeROExtra);
import localeRU from '@angular/common/locales/ru';
import localeRUExtra from '@angular/common/locales/extra/ru';
registerLocaleData(localeRU, 'ru', localeRUExtra);
import localeSK from '@angular/common/locales/sk';
import localeSKExtra from '@angular/common/locales/extra/sk';
registerLocaleData(localeSK, 'sk', localeSKExtra);
import localeUK from '@angular/common/locales/uk';
import localeUKExtra from '@angular/common/locales/extra/uk';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
registerLocaleData(localeUK, 'uk', localeUKExtra);
//locale

const DEFAULT_SAGRA_TRANSLATION_SERVICE_CONFIG: SagraTranslationServiceOptionsInterface = {
    languages: [
        //require('./translations/translation.bg-bg.json'),
        //require('./translations/translation.cs-cz.json'),
        //require('./translations/translation.de-de.json'),
        require('./translations/translation.en.json'),
        //require('./translations/translation.fr-fr.json'),
        //require('./translations/translation.hu-hu.json'),
        //require('./translations/translation.it-it.json'),
        //require('./translations/translation.lt-lt.json'),
        //require('./translations/translation.lv-lv.json'),
        require('./translations/translation.pl.json'),
    //    require('./translations/translation.ro-ro.json'),
    //    require('./translations/translation.ru-ru.json'),
    //    require('./translations/translation.sk-sk.json'),
    //    require('./translations/translation.uk-ua.json')
    ],
    defaultLanguage: 'en-gb',
    useOnlyBrowserLanguage: false,
    useLocalStorage: true
};


const appInitializerFn = (appConfig: ConfigurationService) => {
    return (): Promise<any> => {
        return appConfig.loadConfig();
    };
};

const DEFAULT_SAGRA_DOM_SERVICE_CONFIG: SagraDomServiceOptionsInterface = {
    disabled: false,
    manualDomElementsRefresh: false
}

// formaty dat dla PL
export const MY_MOMENT_FORMATS_PL_ENGB = {
    parseInput: 'L LT',
    fullPickerInput: 'L LT',
    datePickerInput: 'DD.MM.YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
    declarations: [
        AppComponent,
        HeaderLayoutComponent,
        DatabaseListComponent,
        DatabaseListListComponent,
        DatabaseDetailsComponent,
        DatabaseInfoComponent,
        RolesListComponent,
        DatabaseListSearchFilter,
        RepositoryListSearchFilter,
        RegisterGroupSearchFilter,
        RegisterActivitySearchFilter,
        RolesListSearchFilter,
        DatabaseListFiltersComponent,
        DatabaseDetailsNavigationComponent,
        RepositoryListComponent,
        NoAccessComponent,
        TabPageHeaderComponent,
        RegisterListComponent,
        DistrictsListComponent,
        DistrictsListSearchFilter,
        NotificationPanelComponent,
        DialogNewDatabaseComponent,
        DialogNewDatabaseWinpointComponent,
        CustomTooltipDirective,
        SafeHtmlPipe,
        WinPointComponent,
        H5TablesComponent,
        H5TablesListSearchFilter,
        DialogSelectFeedGroupsComponent,
        BiqPackageComponent,
        NewWorldComponent,
        DialogAgreementsComponent,
        HierarchyListComponent,
        HierarchyTreeComponent,
        DialogEditHierarchyComponent,
        ApplicationsListComponent,
        StatusPillComponent,
        ApplicationsListSearchFilter,
        TargetingListComponent,
        ManageTargetingModalComponent,
        TargetingListPipe,
        FunctionalitiesComponent,
        FunctionalityListComponent,
        FunctionalitiesListSearchFilter,
        FunctionalityDetailSearchFilter,
        OperationsHistoryComponent,
        BreadcrumbsComponent,
        CacheManagingComponent,
        DialogSelectCacheVersionComponent,
        CacheDetailsComponent,
        MonitoringComponent,
        JobViewComponent,
        ManualTaskMonitoringComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        NgScrollbarModule,
        ScrollingModule,
        AppRoutingModule,
        SagraCheckboxModule,
        SagraCollapseExpandModule,
        SagraComboboxMultiSelectModule,
        SagraDatetimePickerModule,
        SagraDialogModule,
        SagraDomServiceModule,
        SagraInputModule,
        SagraLoaderModule,
        SagraModalModule,
        SagraPillModule,
        SagraPlaceholderModule,
        SagraProfileDropdownModule,
        SagraRadioModule,
        SagraSwitchModule,
        SagraTabsModule,
        SagraToastsModule,
        SagraTooltipModule,
        SagraTranslationModule,
        SagraSegmentedControlModule,
        SagraToggleButtonModule,
        SagraFiltersModule,
        SagraGridModule
    ],
    providers: [
        ContextService,
        CookieService,
        DatabaseService,
        MessageBusService,
        RepositoryService,
        RegisterService,
        NotificationService,
        RoleService,
        TenantService,
        SagraTranslationService,
        H5TablesService,
        BIQService,
        SagraToastsService,
        HierarchyService,
        ConfigurationService,
        TargetingService,
        { provide: SAGRA_TRANSLATION_SERVICE_CONFIG, useValue: DEFAULT_SAGRA_TRANSLATION_SERVICE_CONFIG },
        { provide: 'moment', useFactory: (): any => moment },
        SagraDomService,
        { provide: SAGRA_DOM_SERVICE_CONFIG, useValue: DEFAULT_SAGRA_DOM_SERVICE_CONFIG },
        FunctionalitiesService,
        OperationsHistoryService,
        BreadcrumbsService,
        {
            provide: NG_SCROLLBAR_OPTIONS,
            useValue: { // sugerowane domyślne ustawienia
                track: 'all',
                visibility: 'hover',
                pointerEventsMethod: 'scrollbar',
                autoHeightDisabled: false
            } as Partial<ScrollOptions>
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
