import { Pipe, PipeTransform } from "@angular/core";
import { RegisterData } from "../models/register/registerData.model";
import { RegisterActivityType } from "../models/register/registerActivityType.model";

@Pipe({
    name: 'registerGroupSearchFilter',
    pure: false
})
export class RegisterGroupSearchFilter implements PipeTransform {
    transform(items: RegisterData[], search: string): RegisterData[] {
        if (!items || !search) {
            return items;
        }
        search = search.toLowerCase();

        let filteredItems: RegisterData[] = [];
        for (let group of items) {
            if (group.name.toLowerCase().indexOf(search) !== -1 || group.activityTypes.filter(item => item.activityTypeName.toLowerCase().indexOf(search) !== -1).length > 0) {
                filteredItems.push(group);
            }
        }

        return filteredItems;
    }
}

@Pipe({
    name: 'registerActivitySearchFilter',
    pure: false
})
export class RegisterActivitySearchFilter implements PipeTransform {
    transform(items: RegisterActivityType[], search: string): RegisterActivityType[] {
        if (!items || !search) {
            return items;
        }

        return items.filter(item => item.activityTypeName.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
}
