<div class="targeting-list-container">
    <div class="targeting-list-content">

        <!--Nagłówek-->
        <tabpage-header [title]="headerTitle"
                        [actionButtonVisible]="false"
                        [shortMode]="true"
                        [searchEnabled]="false"
                        [showBreadcrumb]="true">
        </tabpage-header>

        <!--Opis-->
        <div class="targeting-description-container">
            <div class="targeting-description-text">{{L('TargetingDescriptionInfo')}}</div>
            <sagra-radio class="targeting-description-radio" [(selectedValue)]="targetingDescriptionRadioValue" [list]="targetingDescriptionRadioList" (selectedValueChange)="targetingDescriptionChanged($event)"></sagra-radio>
        </div>

        <!--Info-->
        <div *ngIf="consoleVisible" class="targeting-info-container">
            <div class="targeting-info-text">{{L('TargetingConsolesInfo')}} ({{L('TargetingConsolesList')}})</div>
            <span class="sgf-link-primary" (click)="enableConsoles()">{{L('RevealConsoles')}}</span>
        </div>

        <!--Menu-->
        <div class="targeting-menu-container">
            <div sagraTabs class="targeting-menu-select" [tabs]="menuTabs" [(activeTab)]="selectedMenuItem" (activeTabChange)="updateTab()"></div>
            <button class="sgf-btn-primary" (click)="showTargetingModal(null)"> + {{L( selectedMenuItem == 0 ? 'AddForLocation' : 'AddForStaff')}}</button>
            <sagra-input [(model)]="searchText" [options]="searchInputOptions"></sagra-input>
        </div>

        <!--Lista grup targetowych-->
        <div class="targeting-list" *ngIf="(selectedMenuItem == 0  && locationsTargetingGroupList.length > 0) || (selectedMenuItem == 1 && clientsTargetingGroupList.length > 0) ">
            <div class="targeting-list-header">{{L('Name')}}</div>
            <div *ngFor="let targetingGroup of (selectedMenuItem == 0 ? locationsTargetingGroupList : clientsTargetingGroupList) | targetingListPipe: searchText">
                <div class=" targeting-list-row" (click)="showTargetingModal(targetingGroup)">
                    <div class="targeting-group-name">{{targetingGroup.name}}</div>
                    <span class="targeting-group-delete" (click)="showDeleteTargetingGroupDialog(targetingGroup);$event.stopPropagation()"><i class="icon icon-btn_delete"></i></span>
                </div>
            </div>
        </div>

        <!--Pusta lista-->
        <sagra-placeholder class="empty-list" *ngIf="(selectedMenuItem == 0 && locationsTargetingGroupList.length == 0) || (selectedMenuItem == 1 && clientsTargetingGroupList.length == 0)" [title]="emptyListPlaceholderOptions.title" [button]="emptyListPlaceholderOptions.button" [image]="emptyListPlaceholderOptions.image"></sagra-placeholder>

    </div>
</div>
<sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>



