import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { SagraButtonActionType } from '@sagra/sagra-common';
import { SagraInputComponent, SagraInputOptionsModelInterface } from '@sagra/sagra-input';
import { SagraModalOptionsInterface } from '@sagra/sagra-modal';
import { SagraTranslatable, SagraTranslationService } from '@sagra/sagra-translation';
import { Subject, takeUntil } from 'rxjs';
import { JobTaskDTO, JobTaskType } from '../../../../models/monitoring/jobTask.model';
import { Field } from '../../../../models/monitoring/manualTaskParameters.model';
import { SagraComboboxMultiselectGroupInterface, SagraComboboxMultiselectOptionsInterface, SagraComboboxMultiselectType } from '@sagra/sagra-combobox-multiselect';
import { MonitoringService } from '../../../../services/monitoring/monitoring.service';
import { SagraToastsService, SagraToastsStyle } from '@sagra/sagra-toasts';
import { SagraLoaderOptionsInterface, SagraLoaderType } from '@sagra/sagra-loader';

@Component({
    selector: 'app-manualTaskMonitoring',
    templateUrl: './manualTaskMonitoring.component.html',
    styleUrls: ['./manualTaskMonitoring.component.scss']
})
export class ManualTaskMonitoringComponent extends SagraTranslatable implements OnInit {
    private readonly _destroying$ = new Subject<void>();
    ngOnDestroy() {
        this._destroying$.next();
        this._destroying$.complete();
    }

    public showLoader: boolean = false;
    public sagraLoaderOptions: SagraLoaderOptionsInterface;

    @Input() showModal: boolean = false;
    @Output() showModalChange = new EventEmitter<boolean>();

    @Input() manualTask: JobTaskDTO;

    @ViewChildren(SagraInputComponent) inputsList: QueryList<SagraInputComponent>;

    public validateFunction() {
        return this.inputsList.map(x => x.validate()).every(x => x);
    }

    modalOptions: SagraModalOptionsInterface;

    subjectclose = new Subject<any>();
    subjectSave = new Subject<any>();
    showSaveModal: boolean;

    inputOptions: SagraInputOptionsModelInterface;

    regionComboboxList: SagraComboboxMultiselectGroupInterface[];
    regionOptions: SagraComboboxMultiselectOptionsInterface;

    constructor(private sagraToastsService: SagraToastsService,
        private monitoringService: MonitoringService,
        translationSrv: SagraTranslationService) {
        super(translationSrv)

        this.setModalOptions();

        this.regionOptions = {
            groupedList: false,
            type: SagraComboboxMultiselectType.default,
            zIndex: 4010,
            required: true
        }

        this.regionComboboxList = [{
            name: 'regions',
            list: [
                { name: 'North Europe (ne)', value: 'ne' },
                { name: 'West Europe (we)', value: 'we' },]
        }];

        this.subjectclose.subscribe(() => {
            this.showModal = false;
            this.showModalChange.emit(false);
        });

        this.subjectSave.subscribe(() => {
            this.showLoader = true;
            if (this.validateFunction())
                monitoringService.completeManualTask(this.manualTask)
                    .pipe(takeUntil(this._destroying$))
                    .subscribe(() => {
                        this.showModal = false;
                        this.showModalChange.emit(false);
                        this.showLoader = false;
                    });
            else {
                this.sagraToastsService.pushToast({
                    moduleId: 1,
                    message: 'Sprawdź poprawność wypełnionych pól',
                    style: SagraToastsStyle.danger,
                });
                this.showLoader = false;
            }
        });

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }
    }


    ngOnInit() {
    }

    setModalOptions() {

        this.modalOptions = {
            buttons: [
                {
                    id: "cancel",
                    name: this.L("Cancel"),
                    actionType: SagraButtonActionType.cancel,
                    handler: this.subjectclose,
                    closeOnHandler: true,
                    className: 'sgf-btn-white class-1 class-2'
                }, {
                    id: "save",
                    name: this.L('CompleteTask'),
                    actionType: SagraButtonActionType.accept,
                    handler: this.subjectSave,
                    className: 'sgf-btn-primary',
                    closeOnHandler: false,
                }
            ],
            title: this.L("ManualTask"),
            backdropCloseHandler: this.subjectclose,
            zIndex: 4000
        }
    }

    setInputToTransferParameters(value: string, field: Field) {
        if (!this.manualTask.transferParameters)
            this.manualTask.transferParameters = {};

        this.manualTask.transferParameters[field.name] = value;

    }
}
