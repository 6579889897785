<div class="hierarchy-list-component">
    <div class="hierarchy-list-content">
        <tabpage-header [title]="headerTitle"
                        [actionButtonVisible]="false"
                        [searchEnabled]="false"
                        [shortMode]="true"
                        [showBreadcrumb]="true"
                        >
        </tabpage-header>
        <div class="hl-start-page" *ngIf="hierarchy==null || hierarchy.subHierarchies==null || hierarchy.subHierarchies.length==0">
            <div class="hl-start-page-image"></div>
            <div class="hl-start-page-info">{{L('No_hierarchy_yet')}}</div>
            <div class="hl-start-page-add sgf-btn-primary" (click)="onAddHierarchyClick()">{{L('Add_unit')}}</div>
        </div>
        <div class="hl-content" *ngIf="hierarchy != null && hierarchy.subHierarchies!=null && hierarchy.subHierarchies.length > 0">
            <div class="hl-content-info">
                <span class="hl-content-info-icon icon icon-tootltip_info_outline"></span>
                <span class="hl-content-info-text">{{L('Hierarchy_list_info')}}</span>
            </div>
            <div class="hl-list-header">
                <div class="hl-list-header-tenant">{{tenant}}</div>
                <div class="hl-list-header-add"><span class="sgf-link-primary" (click)="onAddHierarchyClick()"><span class="icon icon-flow-cascade"></span>{{L('Add')}}</span></div>
                <div class="hl-list-header-collapse sgf-link-secondary" (click)="onExpandAllClick()"><span class="icon icon-group_less" [ngClass]="{'collapsed': !expandedAll}"></span>{{expandAllText}}</div>
            </div>
            <div class="hl-list">
                <ng-scrollbar class="sagra-scrollbar">
                    <div *ngIf="hierarchy != null">
                        <hierarchy-tree [tenant]="tenant" [hierarchyItem]="item" [hierarchyRoot]="hierarchy" *ngFor="let item of hierarchy.subHierarchies" (hierarchyAdded)="onHierarchyAdded($event)"></hierarchy-tree>
                    </div>
                </ng-scrollbar>
            </div>
        </div>
        <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
    </div>
</div>
