<div class="funct-list-component">
    <div class="funct-list-content" [ngClass]="{'showFilters': showFilters}" [@resizeList]="detailsPanelVisible ? 'end' : 'begin'">
        <!--[@.disabled]="isDisabled"-->
        <tabpage-header [title]="headerTitle"
                        [subTitle]="headerSubTitle"
                        [showBreadcrumb]="false"
                        [searchEnabled]="true"
                        (searchTextChanged)="onSearchTextChanged($event)"
                        (actionButtonClicked)="onActionButtonClicked($event)">
        </tabpage-header>
        <div class="funct-list-filters" *ngIf="showFilters">
            <div class="funct-list-filter">
                <span class="funct-list-filter-caption">{{L('Typ')}}</span>
                <sagra-toggle-button [style]="h5ToggleBtnOptions.style"
                                     [normalState]="h5ToggleBtnOptions.normalState"
                                     [activeState]="h5ToggleBtnOptions.activeState"
                                     (click)="applyTypeFilters('H5')">
                </sagra-toggle-button>
                <sagra-toggle-button [style]="operationToggleBtnOptions.style"
                                     [normalState]="operationToggleBtnOptions.normalState"
                                     [activeState]="operationToggleBtnOptions.activeState"
                                     (click)="applyTypeFilters('OPERATIONS')">
                </sagra-toggle-button>
            </div>
        </div>
        <div class="funct-list">
            <div class="funct-list-header" *ngIf="firstDownloadingData || (filteredListFunctionalitiesItems != null && filteredListFunctionalitiesItems.length > 0)">
                <div class="funct-list-header-column sort name" [ngClass]="{'sorting': sortingColumn=='name', 'with-type': dataSource==2}" (click)="sortFunctionalitiesColumn('name')">
                    <span class="column-text">{{L('Name')}}</span>
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="funct-list-header-column sort type" *ngIf="dataSource==2" [ngClass]="{'sorting': sortingColumn=='baseTypeDescription'}" (click)="sortFunctionalitiesColumn('baseTypeDescription')">
                    <span class="column-text">{{L('Type')}}</span>
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="funct-list-header-column sort sharedCount" [ngClass]="{'sorting': sortingColumn=='shareCount'}" (click)="sortFunctionalitiesColumn('shareCount')">
                    <span class="column-text">{{L('Funct_shared_count')}}</span>
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
            </div>
            <div class="funct-list-row"
                 [ngClass]="{'selected': item.id==selectedFunctionalityItem?.id && item.guid==selectedFunctionalityItem?.guid}"
                 *ngFor="let item of filteredListFunctionalitiesItems"
                 (click)="onFunctionalityListItemClick(item)"
                 sagraTooltip [sagraTooltipModel]="item.tooltipModel">
                <div class="funct-list-column name" [ngClass]="{'with-type': dataSource==2}">
                    <span class="center-vert">{{item.name}}</span>
                </div>
                <div class="funct-list-column type" *ngIf="dataSource==2">
                    <span class="center-vert">{{item.baseTypeDescription}}</span>
                </div>
                <div class="funct-list-column sharedCount">
                    <span class="center-vert">{{item.shareCount}}</span>
                </div>
            </div>
            <sagra-placeholder class="list-placeholder" *ngIf="!firstDownloadingData && (filteredListFunctionalitiesItems == null || filteredListFunctionalitiesItems.length == 0)"
                               [title]="placeholderListOptions.title"
                               [description]="placeholderListOptions.description"
                               [button]="placeholderListOptions.button"
                               [image]="placeholderListOptions.image">
            </sagra-placeholder>
        </div>
        <sagra-loader *ngIf="showFunctionalitiesLoader" [loaderType]="loaderOptions.loaderType" [backgroundSetings]="loaderOptions.backgroundSetings"></sagra-loader>
    </div>
    <div class="funct-details-panel" [@showPanel]="detailsPanelVisible ? 'end' : 'begin'" *ngIf="detailsPanelVisible">
        <div class="funct-details-container">
            <div class="funct-details-header">
                <div class="funct-details-header-title">
                    <div class="main-title">{{selectedFunctionalityItem.name}}</div>
                    <div class="sub-title">{{selectedFunctionalityItem.baseTypeDescription}}</div>
                </div>
                <div class="funct-details-header-close icon icon-btn_close sgf-link-secondary" (click)="onClosePanelClick($event)"></div>
            </div>
            <div class="funct-details-content">
                <div class="funct-details-search">
                    <sagra-input [(model)]="tenantSearchText"
                                 [placeholder]="tenantSearchListInputOptions.placeholder"
                                 [styleType]="tenantSearchListInputOptions.styleType"
                                 [clearButton]="tenantSearchListInputOptions.clearButton"
                                 [errorType]="tenantSearchListInputOptions.errorType"
                                 [icon]="tenantSearchListInputOptions.icon"
                                 (modelChange)="tenantSearchChanged($event)"></sagra-input>
                </div>
                <div class="funct-details-segments" *ngIf="!tenantSearchText">
                    <sagra-segmented-control [items]="segmentedControlOptions.items"
                                             [selected]="selectedTabId"
                                             (update)="onTabChanged($event)">
                    </sagra-segmented-control>
                </div>
                <div class="funct-details-list" #detailsList *ngIf="downloadingDetailsData || (!tenantSearchText && filteredListTenantsItems != null && filteredListTenantsItems.length > 0) || filteredDetailsListLength > 0">
                    <div class="funct-details-list-header">
                        <div class="funct-details-list-header-column sort name" [ngClass]="{'sorting': sortingDetailsColumn=='tenant'}" (click)="sortDetailsColumn('tenant')">
                            {{L('Tenant')}}
                            <span class="icon" [ngClass]="(sortingDetailsReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                        </div>
                        <div class="funct-details-list-header-column sort status" [ngClass]="{'sorting': sortingDetailsColumn=='sortStatus'}" (click)="sortDetailsColumn('sortStatus')">
                            {{L('Status')}}
                            <span class="icon" [ngClass]="(sortingDetailsReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                        </div>
                        <div class="funct-details-list-header-column history">
                        </div>
                        <div class="funct-details-list-header-column iconGo">
                        </div>
                    </div>
                    <div class="funct-details-list-row" *ngFor="let item of filteredListTenantsItems | functionalityDetailSearchFilter: tenantSearchText:filteredDetailsCount">
                        <div class="funct-details-list-column name" [ngClass]="{'with-type': dataSource==1}">
                            <span class="center-vert" title="{{item.tenant}}">{{item.tenant}}</span>
                        </div>
                        <div class="funct-details-list-column status center-vert">
                            <ng-container *ngIf="dataSource != 11 && dataSource != 13">
                                <span class="center-vert sgf-link-pacific" *ngIf="item.status==0" (click)="onShareItemClick(item); $event.stopPropagation();">{{L('Share')}}</span>
                                <sagra-pill class="center-vert" [type]="2" [text]="L('PROCESSING') + '...'" *ngIf="item.status==1"></sagra-pill>
                                <sagra-pill class="center-vert" [type]="3" [text]="L('SHARED')" [pillClass]="'mint'" *ngIf="item.status==2"></sagra-pill>
                                <sagra-pill class="center-vert" [type]="3" [text]="L('ERRORSHARING')" [pillClass]="'cherry'" *ngIf="item.status==3"></sagra-pill>
                            </ng-container>
                            <ng-container *ngIf="dataSource == 11">
                                <sagra-pill class="center-vert" [type]="3" [text]="L('NOT_SHARED')" [pillClass]="'ink'" *ngIf="item.status==0"></sagra-pill>
                                <sagra-pill class="center-vert" [type]="3" [text]="L('SHARED')" [pillClass]="'mint'" *ngIf="item.status==2"></sagra-pill>
                            </ng-container>
                            <ng-container *ngIf="dataSource == 13">
                                <sagra-pill class="center-vert" [type]="3" [text]="L('ACTIVE_2')" [pillClass]="'mint'" *ngIf="item.status==2"></sagra-pill>
                                <sagra-pill class="center-vert" [type]="3" [text]="L('PLANNED')" [pillClass]="'mint'" *ngIf="item.status==5"></sagra-pill>
                                <sagra-pill class="center-vert" [type]="2" [text]="L('PROCESSING') + '...'" *ngIf="item.status==1"></sagra-pill>
                                <sagra-pill class="center-vert" [type]="3" [text]="L('TURNED_OFF')" [pillClass]="'ink'" *ngIf="item.status==0"></sagra-pill>
                            </ng-container>
                        </div>
                        <div class="funct-details-list-column history">
                            <span class="center-vert icon icon-restore sgf-link-light" title="{{L('Operation_history')}}" (click)="onHistoryDetailsListItemClick(item)"></span>
                        </div>
                        <div class="funct-details-list-column iconGo">
                            <span class="center-vert icon icon-keyboard_arrow_right sgf-btn-white" title="{{L('Go_to_client')}}" (click)="onDetailsListItemClick(item)"></span>
                        </div>
                    </div>
                </div>
                <div class="funct-details-list placeholder" *ngIf="filteredDetailsListLength == 0">
                    <sagra-placeholder class="list-placeholder"
                                       [title]="placeholderDetailsListOptions.title"
                                       [description]="placeholderDetailsListOptions.description"
                                       [button]="placeholderDetailsListOptions.button"
                                       [image]="placeholderDetailsListOptions.image">
                    </sagra-placeholder>
                </div>
            </div>
        </div>
        <sagra-loader *ngIf="showDetailsLoader" [loaderType]="loaderOptions.loaderType" [backgroundSetings]="loaderOptions.backgroundSetings"></sagra-loader>
    </div>
    <sagra-loader *ngIf="showLoader"></sagra-loader>
</div>
