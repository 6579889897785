import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ConfigurationService } from '../configurationService/configuration.service';
import { MonitorignFilters } from '../../models/monitoring/monitorignFilters.model';
import { lastValueFrom, map, Observable, share } from 'rxjs';
import { JobState, JobStatus } from '../../models/monitoring/jobStatus.model';
import { JobInfo } from '../../models/monitoring/jobInfo.model';
import { Filters } from '../../models/monitoring/job.model';
import { SagraTranslatable, SagraTranslationService } from '@sagra/sagra-translation';
import { JobTaskDTO } from '../../models/monitoring/jobTask.model';
import { StaviatorUserContext } from '../../models/staviatorUserContext.model';
import { RunTask } from '../../models/monitoring/staviatorContext.model';

@Injectable({
    providedIn: 'root'
})
export class MonitoringService extends SagraTranslatable {
    private apiUrl: string;
    private userContext: StaviatorUserContext;
    constructor(private http: HttpClient, private appConfig: ConfigurationService,
    translationService: SagraTranslationService) {
    super(translationService);
        this.apiUrl = appConfig.staviatorApiUrl;
    }

    async getUserContext() {
        if (!this.userContext) {
            this.userContext = await lastValueFrom(
                this.http.get<StaviatorUserContext>(this.apiUrl + '/api/Jobs/GetUserContext')
                    .pipe(
                        map(result => {
                            return result;
                        })
                    )
            );
        }
        return this.userContext;
    }

    getStatuses(filters: MonitorignFilters) {
        let httpParams = new HttpParams()
            .set("page", filters.page)
            .set("take", filters.take)
        if (filters.fromDate)    
            httpParams = httpParams.set("fromDate", filters.fromDate);
        if (filters.toDate)    
            httpParams = httpParams.set("toDate", filters.toDate);
        if (filters.description)    
            httpParams = httpParams.set("description", filters.description);
        if (filters.dbName)    
            httpParams = httpParams.set("dbName", filters.dbName);
        if (filters.author)    
            httpParams = httpParams.set("author", filters.author);
        if (filters.jobState)    
            httpParams = httpParams.set("jobState", filters.jobState);
        if (filters.jobOperationType)    
        httpParams = httpParams.set("jobOperationType", filters.jobOperationType);

        return this.http.get<JobStatus[]>(this.apiUrl + "/api/Jobs/ListJobsStatuses", { params: httpParams }).pipe(
            map(response => {
                return response;
            }), share());
    }

    getCountStatuses(filters: MonitorignFilters) {
        let httpParams = new HttpParams()
            .set("page", filters.page)
            .set("take", filters.take)
        if (filters.fromDate)
            httpParams = httpParams.set("fromDate", filters.fromDate);
        if (filters.toDate)
            httpParams = httpParams.set("toDate", filters.toDate);
        if (filters.description)
            httpParams = httpParams.set("description", filters.description);
        if (filters.dbName)
            httpParams = httpParams.set("dbName", filters.dbName);
        if (filters.author)
            httpParams = httpParams.set("author", filters.author);
        if (filters.jobState)
            httpParams = httpParams.set("jobState", filters.jobState);
        if (filters.jobOperationType)
            httpParams = httpParams.set("jobOperationType", filters.jobOperationType);

        return this.http.get<number>(this.apiUrl + "/api/Jobs/CountJobStatuses", { params: httpParams }).pipe(
            map(response => {
                return response;
            }), share());
    }

    getInfo(jobId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("jobId", jobId);
        return this.http.get<JobInfo>(this.apiUrl + "/api/Jobs/JobInfo", { params: httpParams }).pipe(
            map(response => {
                return response;
            }), share());
    }

    getFilters() {
        return this.http.get<Filters>(this.apiUrl + "/api/Jobs/GetFilters").pipe(
            map(response => {
                return response;
            }), share());
    }

    completeManualTask(task: JobTaskDTO) {
        return this.http.post<JobTaskDTO>(this.apiUrl + "/api/Jobs/NotifyTaskCompleted", task);
    }

    recalculateJob(jobId: string) {
        return this.http.post<JobTaskDTO>(this.apiUrl + "/api/Jobs/Recalculate", { jobId: jobId });
    }

    restartCurrentTask(task: RunTask) {
        return this.http.post<JobTaskDTO>(this.apiUrl + "/api/Jobs/RunFromTask", task);
    }

    getJobStatePillsClass(jobState: JobState) {
        switch (jobState) {
            case JobState.Completed: return 'mint';
            case JobState.Failed: return 'cherry';
            case JobState.Manual: return 'pacific';
            case JobState.Retrying: return 'citrus';
            case JobState.Running: return 'pacific';
            case JobState.Stopped: return 'Grey';
            case JobState.Stopping: return 'pacific';
            case JobState.Suspended: return 'Grey';
            //$color-pacific-light4: #d5e8fa
        }
    }

    getJobStateName(jobState: JobState) {
        switch (jobState) {
            case JobState.Completed: return this.L(JobState[JobState.Completed]);
            case JobState.Failed: return this.L(JobState[JobState.Failed]);
            case JobState.Manual: return this.L(JobState[JobState.Manual]);
            case JobState.Retrying: return this.L(JobState[JobState.Retrying]);
            case JobState.Running: return this.L(JobState[JobState.Running]);
            case JobState.Stopped: return this.L(JobState[JobState.Stopped]);
            case JobState.Stopping: return this.L(JobState[JobState.Stopping]);
            case JobState.Suspended: return this.L(JobState[JobState.Suspended]);
        }
    }
}
