import { Component, Input, OnInit, OnDestroy, SimpleChanges, SimpleChange, OnChanges, ViewChild, ElementRef } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { RepositoryService } from "../../services/repositoryService/repository.service";
import { RepositoryListData } from "../../models/repository/repositoryListData.model";
import { SagraCheckboxComponentOptions, SagraCheckboxComponentLabelPosition, SagraCheckboxComponentCilckableArea } from "@sagra/sagra-checkbox";
import { SagraInputStyle, SagraInputOptionsModelInterface, SagraInputIconPosition } from "@sagra/sagra-input";
import { SagraDialogType, SagraDialogService } from "@sagra/sagra-dialog";
import { Permission } from "../../models/permission.model";
import { Subscription, Subject } from "rxjs";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { map } from "rxjs/operators";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { SelectedItem } from "../../models/selectedItem.model";
import { FeedGroup } from "../../models/repository/feedGroup.model";
import { TenantConfiguration } from "../../models/tenant/tenantConfiguration.model";
import { NavigationItemEnum } from "../../models/navigationItem.model";
import { OperationsHistoryService } from "../../services/operationsHistoryService/operationsHistory.service";
import { SagraTooltipInterface } from "@sagra/sagra-tooltip";
import { SagraPlaceholderImage, SagraPlaceholderOptionsInterface } from "@sagra/sagra-placeholder";
import { Sorting } from "../../utils/sorting";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { SagraCollapseExpandOptions } from "@sagra/sagra-collapse-expand";
import { ActivityUse } from "src/app/models/repository/activityUse.model";
import { SagraToggleButtonOptions, SagraToggleButtonStyle } from "@sagra/sagra-toggle-button";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ShowPanel } from "../../animations/animations";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";

@Component({
    selector: "repository-list",
    templateUrl: "./repository-list.component.html",
    styleUrls: ["./repository-list.component.scss"],
    animations: [ ShowPanel(),
        trigger('blinkList', [
            state('show', style({ opacity: '1' })),
            state('hide', style({ opacity: '0.3' })),
            transition('hide => show', [animate('0.25s ease-in-out')])
        ]),
    ]
})
export class RepositoryListComponent extends SagraTranslatable implements OnInit, OnDestroy, OnChanges {
    @Input() tenant: string;
    @Input() dataSource: NavigationItemEnum;
    @Input() allowUnShare: boolean;
    @Input() tenantConfiguration: TenantConfiguration;

    @ViewChild("virtualScroll") virtualScroll: CdkVirtualScrollViewport;
    @ViewChild("list") repositoryList: ElementRef;

    private subscriptions = new Subscription();
    public searchText: string;
    public searchListInputOptions: SagraInputOptionsModelInterface;
    public checkboxListOptions: SagraCheckboxComponentOptions;
    public sortingReverse: boolean;
    public sortingColumn: string = 'sortStatus';
    public listItems: Array<RepositoryListData>;
    public filteredListItems: Array<RepositoryListData>;
    public allowEdit: boolean = true;
    public permission: Permission;
    public repositoryConfiguration: RepositoryConfig;
    public headerTitle: string;
    public headerSubTitle;
    public actionButtonText: string;
    public showLoader: boolean;
    public showDetailsLoader: boolean;
    public allowItemHistory: boolean;
    public showCheckColumn: boolean;
    public selectSharedItems: boolean;
    public selectUnsharedItems: boolean;
    public detailsPanelVisible: boolean = false;
    public detailsRepoItem: RepositoryListData;
    public detailItemsList: Array<any>;
    public expandedOperations: boolean;
    public expandedTasks: boolean;
    public expandedProcesses: boolean;
    public allowDetailsPanel: boolean;
    public bindingGroups: Array<BindGroup>;
    public allItemsSelected: boolean;
    public showFilters: boolean;

    public statusFilters: StatusFilter = new StatusFilter();
    public typeFilters: TypeFilter = new TypeFilter();

    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    private saveChangesSubject: Subject<any> = new Subject<any>();
    private publishGroupsSubject: Subject<any> = new Subject<any>();

    public selectedItems: Array<SelectedItem> = [];
    public itemsToShare: Array<SelectedItem> = [];

    public itemsCount: number = 0;
    public toShareCounter: number = 0;
    public firstDownloadingData: boolean = true; // pierwsze pobranie listy, aby nie pokazywał się placeholder
    public counterText: string = '';
    public allowUnShareOnList: boolean; // czy można zaznaczać na liście lementy do odudostępnienia
    public blinkList: boolean; // używana do odpalenia animacji mrygania listy

    public sharingItems: boolean;

    public feedGroupsList: Array<FeedGroup>;
    public selectedFeedGroups: Array<number>;

    public colExpOptions: SagraCollapseExpandOptions;
    public placeholderOptions: SagraPlaceholderOptionsInterface;
    public placeholderDetailsListOptions: SagraPlaceholderOptionsInterface;

    public shareToggleBtnOptions: SagraToggleButtonOptions;
    public unshareToggleBtnOptions: SagraToggleButtonOptions;
    public h5ToggleBtnOptions: SagraToggleButtonOptions;
    public operationToggleBtnOptions: SagraToggleButtonOptions;
    public otherToggleBtnOptions: SagraToggleButtonOptions;

    constructor(public translationService: SagraTranslationService,
        private dialogService: SagraDialogService,
        public repositoryService: RepositoryService,
        private messageService: MessageBusService,
        private operationService: OperationsHistoryService
    ) {
        super(translationService);

        this.searchListInputOptions = {
            placeholder: this.L("Search"),
            styleType: SagraInputStyle.underline,
            clearButton: true,
            icon: { className: 'icon-btn_search', position: SagraInputIconPosition.left }
        }

        this.checkboxListOptions = {
            labelPosition: SagraCheckboxComponentLabelPosition.right,
            clickable: SagraCheckboxComponentCilckableArea.checkboxOnly
        }

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }

        this.colExpOptions = {
            expanded: false,
            duration: 200,
            easing: 'linear'
        };

        this.bindingGroups = [{ id: 1, name: this.L('Tasks'), iconClass: 'taskCatalog', expanded: true },
            { id: 2, name: this.L('SR_groups'), iconClass: 'phGroups', expanded: true },
            { id: 3, name: this.L('Planned_tasks'), iconClass: 'planedTasks', expanded: true },
            { id: 4, name: this.L('Promotions'), iconClass: 'promotions', expanded: true },
            { id: 5, name: this.L('Automatic_customer_categorization'), iconClass: 'automaticCustomerCategorization', expanded: true },
            { id: 6, name: this.L('Processes'), iconClass: 'processes', expanded: true },
            { id: 7, name: this.L('Routines'), iconClass: 'routines', expanded: true },
            { id: 8, name: this.L('Operations'), iconClass: 'operations', expanded: true },
            { id: 9, name: this.L('Grouping_activities'), iconClass: 'groupingActivities', expanded: true },
            { id: 10, name: this.L('Actions'), iconClass: 'actions', expanded: true }
        ];
        //this.permission = contextService.getCurrentGRRPermission("GRR");
        this.preparePlaceholderOptions();
        this.prepareFilterToggleButtons();
    }

    ngOnInit() {
        this.showLoader = true;

        this.subscriptions.add(this.saveChangesSubject.asObservable().subscribe(
            x => this.saveChanges()
        ));
        this.subscriptions.add(this.publishGroupsSubject.asObservable().subscribe((data) => {
            this.selectedFeedGroups = data;
            this.saveChangesWithGroups();
        }
        ));


        this.repositoryConfiguration = this.getRepositoryConfiguration();

        this.headerTitle = this.repositoryConfiguration.title;

        this.messageService.getRefreshRepositoryList().subscribe((next) => {
            if (next.tenant == this.tenant.toLowerCase()) {
                const module = next.module;
                if (module == 'Sheets' && this.dataSource == NavigationItemEnum.Sheets
                    || module == 'Activities' && this.dataSource == NavigationItemEnum.ActivitiesOperations
                    || module == 'Processes' && this.dataSource == NavigationItemEnum.Processes
                    || module == 'TWR' && this.dataSource == NavigationItemEnum.TWR
                    || module == 'Extracts' && this.dataSource == NavigationItemEnum.Extracts) {
                    //console.log("SignalR: call RefreshRepositoryList: " + this.dataSource + " next: " + JSON.stringify(next));
                    this.getElementsList();
                }
            }
        });

        this.getElementsList();

        if (this.dataSource == NavigationItemEnum.TWR || this.dataSource == NavigationItemEnum.Sheets || this.dataSource == NavigationItemEnum.Extracts) {
            this.getGroupsList();
        }

        this.allowDetailsPanel = this.dataSource == NavigationItemEnum.ActivitiesOperations || this.dataSource == NavigationItemEnum.Processes;

        this.allowUnShareOnList = this.allowUnShare && (this.dataSource == NavigationItemEnum.Sheets || this.dataSource == NavigationItemEnum.Extracts);

        this.showFilters = (this.dataSource == NavigationItemEnum.ActivitiesOperations || this.dataSource == NavigationItemEnum.Processes);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        let currentItem: SimpleChange = changes.tenant;
        if (currentItem && currentItem.currentValue) {
            this.tenant = changes.tenant.currentValue;
            if (currentItem.previousValue) {
                this.getElementsList();
            }
        }
        currentItem = changes.tenantConfiguration;
        if (currentItem && currentItem.currentValue) {
            this.tenantConfiguration = changes.tenantConfiguration.currentValue;
        }


        this.allowItemHistory = this.dataSource == NavigationItemEnum.ActivitiesOperations || this.dataSource == NavigationItemEnum.Processes || this.dataSource == NavigationItemEnum.Extracts || this.dataSource == NavigationItemEnum.Sheets
            || this.dataSource == NavigationItemEnum.TWR;
    }

    onSearchTextChanged(event) {
        this.searchText = event;

        this.applyFilters();
    }

    onActionButtonClicked(event) {
        //if (!this.permission.canWrite) {
        //    this.dialogService.showAlert(
        //        {
        //            dialogType: SagraDialogType.alert,
        //            title: this.L('NoWritePermissions')
        //        }
        //    );
        //    return;
        //}

        this.itemsToShare = this.selectedItems;
        this.shareItems(!this.selectSharedItems, false);
    }

    onHistoryButtonClicked(event) {
        this.operationService.openInNewTab(this.tenant, this.dataSource, null, null);
    }

    onHistoryListItemClick(event: Event, item: RepositoryListData) {
        this.operationService.openInNewTab(this.tenant, this.dataSource, item.id, item.tableGUID);
        event.stopPropagation();
    }

    onClosePanelClick(event) {
        this.closePanel();
    }

    onItemClick(item: RepositoryListData) {
        if (this.showCheckColumn) return;

        if (this.detailsRepoItem != null && this.detailsRepoItem.id == item.id) {
            this.closePanel();
        }
        else {
            if (this.allowDetailsPanel) {
                this.detailsRepoItem = item;
            }
            this.openPanel();
        }
    }

    onDetailsShareBtnClick() {
        const newItem = new SelectedItem(this.detailsRepoItem.selected, this.detailsRepoItem.onlyEditBySagra);
        newItem.id = this.detailsRepoItem.id;
        newItem.guid = this.detailsRepoItem.tableGUID;
        newItem.status = this.detailsRepoItem.status;
        newItem.editable = this.detailsRepoItem.onlyEditBySagra;
        newItem.selected = true;
        newItem.baseType = this.detailsRepoItem.baseType;
        //this.selectedItems.push(newItem);

        this.itemsToShare = [newItem];
        this.shareItems(newItem.status != 2 && newItem.status != 13, true);
    }

    onEndBlinkAnimation() {
        this.blinkList = false;
    }

    private shareItems(sharing: boolean, fromDetails: boolean) {
        this.sharingItems = sharing;

        if (this.dataSource != NavigationItemEnum.Sheets) {
            if (this.allowUnShare && !this.sharingItems) {
                let showAdditionalText = false;
                if (this.dataSource == NavigationItemEnum.ActivitiesOperations) {
                    let res = this.itemsToShare.filter(item => item.baseType == 513);
                    if (res != null && res.length > 0) {
                        showAdditionalText = true;
                    }
                }
                else {
                    if (this.dataSource == NavigationItemEnum.Processes) {
                        showAdditionalText = true;
                    }
                }
                //let additionalText = '<div class="rdm-additional"><div class="icon icon-tootltip_info_outline" style="display:inline-block;vertical-align:top;"></div><div class="text" style="display:inline-block;width:calc(100%-20px);padding-left:8px;font-size:12px;">'
                //    + this.L('Unshare_message_warning')
                //    + '<div>';
                //let message = this.L('Unshare_message') + (showAdditionalText ? additionalText : '');

                let message = this.L('Unshare_message_2') + '<div>' + this.L('Unshare_message_question') + '</div>';

                this.dialogService.showAlert(
                    {
                        dialogType: SagraDialogType.confirm,
                        backdropClose: true,
                        title: this.L('Warning') + "!",
                        message: message,
                        buttons: [
                            { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                            { name: this.L('Stop_sharing') + (!fromDetails ? ' (' + this.itemsToShare.length + ')' : ''), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.custom, className: 'sgf-btn-cherry', iconClassName: 'icon-block', handler: this.saveChangesSubject }
                        ] 
                    }
                );
            }
            else {
                this.dialogService.showAlert(
                    {
                        dialogType: SagraDialogType.confirm,
                        backdropClose: true,
                        title: this.L('Sure_continue'),
                        buttons: [
                            { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                            { name: this.L('Share') + ' (' + this.itemsToShare.length + ')', actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.saveChangesSubject }
                        ]
                    }
                );
            }
        }
        else {
            this.saveChangesSubject.next(null);
        }
    }

    private saveChanges() {
        //sprawdzenie czy jakieś elementy są udostępniane
        const res = this.itemsToShare.filter(item => { return item.selected && item.selected != item.originalSelected; });

        if (res != null && res.length > 0 && this.tenantConfiguration != null && this.tenantConfiguration.oDataFeedRestrictions.value.toLowerCase() == "true" /*this.feedGroupsList != null && this.feedGroupsList.length > 0*/
            && (this.dataSource == NavigationItemEnum.TWR || this.dataSource == NavigationItemEnum.Sheets || this.dataSource == NavigationItemEnum.Extracts)) {

            this.selectedFeedGroups = null;

            this.messageService.showDialogFeedGroups({
                feedGroups: this.feedGroupsList,
                selectedFeedGroups: this.selectedFeedGroups,
                cancelHandler: null,
                publishHandler: this.publishGroupsSubject,
            });
        }
        else {
            this.saveChangesWithGroups();
        }
    }

    private saveChangesWithGroups() {
        this.showLoader = true;

        let method: string;
        if (this.sharingItems) {
            method = this.repositoryConfiguration.methodShare;
        }
        else {
            method = this.repositoryConfiguration.methodUnshare;
        }

        this.subscriptions.add(this.repositoryService.assignElements(method, this.tenant, this.itemsToShare, this.selectedFeedGroups).subscribe(result => {
            this.selectedItems = [];
            this.itemsToShare = [];
            //this.toShareCounter = this.selectedItems.length;
            this.setTitleText(this.toShareCounter);
            this.getElementsList();
            if (this.detailsRepoItem != null) {
                this.getActivityUseList();
            }
        },
            error => {
                this.dialogService.showAlert({ dialogType: SagraDialogType.alert, title: error.message });
                this.getElementsList();
            }));
    }

    getRepositoryConfiguration(): RepositoryConfig {
        if (this.dataSource == NavigationItemEnum.ActivitiesOperations) {
            return {
                title: this.L('Acts_Opers'),
                methodList: 'api/Repository/GetActivitiesList',
                methodShare: 'api/Repository/AssignActivities',
                methodUnshare: 'api/Repository/UnAssignActivities'
            }
        }
        else if (this.dataSource == NavigationItemEnum.Processes) {
            return {
                title: this.L('Processes'),
                methodList: 'api/Repository/GetProcessesList',
                methodShare: 'api/Repository/AssignProcesses',
                methodUnshare: 'api/Repository/UnAssignProcesses'
            }
        }
        else if (this.dataSource == NavigationItemEnum.TWR) {
            return {
                title: this.L('TWR'),
                methodList: 'api/Repository/GetTWRList',
                methodShare: 'api/Repository/AssignTWRs'
            }
        }
        else if (this.dataSource == NavigationItemEnum.Sheets) {
            return {
                title: this.L('Sheets'),
                methodList: 'api/Repository/GetSheetsList',
                methodShare: 'api/Repository/AssignSheets'
            }
        }
        else if (this.dataSource == NavigationItemEnum.Extracts) {
            return {
                title: this.L('Extracts'),
                methodList: 'api/Repository/GetExtractsList',
                methodShare: 'api/Repository/AssignExtracts'
            }
        }
    }

    sortColumn(column: string, changeSort: boolean = true) {
        this.blinkList = true;
        if (changeSort) {
            if (column != this.sortingColumn) {
                this.sortingColumn = column;
                this.sortingReverse = false;
            }
            else {
                this.sortingReverse = !this.sortingReverse;
            }
        }
        let secondColumn = null;
        if (this.sortingColumn != 'name') {
            secondColumn = 'name';
        }
        let sortColumnType = 0;
        if (this.sortingColumn == 'sortStatus') {
            sortColumnType = 1;
        }
        Sorting.sortByTwoProperties(this.filteredListItems, this.sortingColumn, secondColumn, !this.sortingReverse, sortColumnType);
        //potrzebne dla virtual scrolla
        this.filteredListItems = [...this.filteredListItems];
    }

    getElementsList() {
        this.showLoader = true;
        this.toShareCounter = 0;
        this.setTitleText(0);
        this.subscriptions.add(this.repositoryService.getElementsList(this.repositoryConfiguration.methodList, this.tenant)
            .pipe(map(array => {
                for (let item of array) {
                    if (item.status == null)
                        item.status = 0;
                    if (!this.allowUnShare && item.status == 2) {
                        item.selected = true;
                    }
                    else {
                        item.selected = false;
                    }
                    if (item.status == 0) {
                        //unshared
                        item.sortStatus = 4;
                    }
                    else if (item.status == 1) {
                        //inprogress
                        item.sortStatus = 1;
                    }
                    else if (item.status == 2) {
                        //shared
                        item.sortStatus = 3;
                    }
                    else {
                        //error
                        item.sortStatus = 2;
                    }

                    if (this.dataSource == NavigationItemEnum.ActivitiesOperations || this.dataSource == NavigationItemEnum.Processes) {
                        item.tooltip = this.prepareTooltipOptions(item);
                    }
                }
                return array;
            })).subscribe(items => {
                this.listItems = items;

                this.applyFilters();

                this.itemsCount = this.listItems.length;

                if (this.detailsRepoItem != null) {
                    let res = this.listItems.filter(item => item.id == this.detailsRepoItem.id);
                    if (res != null && res.length > 0) {
                        this.detailsRepoItem = res[0];
                    }
                    else {
                        this.closePanel();
                    }
                }

                this.checkSelectedItems();
                this.firstDownloadingData = false;
                this.showLoader = false;
            },
                () => {
                    this.firstDownloadingData = false;
                    this.showLoader = false;
                }));
    }

    getGroupsList() {
        this.subscriptions.add(this.repositoryService.getGroupsList(this.tenant).subscribe(data => {
            this.feedGroupsList = data;
        }));
    }

    getActivityUseList() {
        this.showDetailsLoader = true;
        this.detailItemsList = [];
        this.subscriptions.add(this.repositoryService.getActivityUseList(this.tenant, this.detailsRepoItem.primeTypeGUID).subscribe(data => {

            this.detailItemsList = data;
            for (let group of this.bindingGroups) {
                group.items = this.detailItemsList.filter(item => item.type == group.id);
                //group.items = data;
            }
            this.showDetailsLoader = false;
        }, (error) => {
            this.showDetailsLoader = false;
        }));
    }

    getProcessesUseList() {
        this.showDetailsLoader = true;
        this.subscriptions.add(this.repositoryService.getProcessesUseList(this.tenant, this.detailsRepoItem.guid).subscribe(data => {

            this.detailItemsList = data
            for (let group of this.bindingGroups) {
                group.items = this.detailItemsList.filter(item => item.type == group.id);
                //group.items = data;
            }
            this.showDetailsLoader = false;
        }, (error) => {
            this.showDetailsLoader = false;
        }));
    }

    selectItem(state: boolean, item: RepositoryListData) {

        this.setItemSelection(item, state, true);

        this.checkAllItemsSelected();

        this.setTitleText(this.toShareCounter);

        this.closePanel();
    }

    selectItemEditable(state: boolean, item: RepositoryListData) {
        this.setItemSelection(item, state, false);

        this.setTitleText(this.toShareCounter);
    }

    setItemSelection(item: RepositoryListData, state: boolean, selection: boolean) {
        const selArr = this.selectedItems.filter((elem) => { return this.identifyItem(item, elem); });
        if (selArr.length > 0) {
            const selItem = selArr[0];
            if (selection) {
                selItem.selected = state;
            }
            else {
                selItem.editable = state;
            }
            if (selItem.selected == selItem.originalSelected && selItem.editable == selItem.originalEditable) {
                for (let i = 0; i < this.selectedItems.length; i++) {
                    if ((this.dataSource == NavigationItemEnum.TWR && this.selectedItems[i].guid == selArr[0].guid) ||
                        (this.dataSource != NavigationItemEnum.TWR && this.selectedItems[i].id == selArr[0].id)) {
                        this.selectedItems.splice(i, 1);
                        break;
                    }
                }
            }
        }
        else {
            const newItem = new SelectedItem(item.selected, item.onlyEditBySagra);
            newItem.id = item.id;
            newItem.guid = item.tableGUID;
            newItem.status = item.status;
            newItem.selected = item.selected;
            newItem.editable = item.onlyEditBySagra;
            if (selection) {
                newItem.selected = state;
            }
            else {
                newItem.editable = state;
            }
            newItem.baseType = item.baseType;
            this.selectedItems.push(newItem);
        }

        if (this.selectedItems.length > 0) {
            if (this.selectedItems[0].status != 2 && this.selectedItems[0].status != 13) {
                this.selectUnsharedItems = true;
                this.showCheckColumn = true;
            }
            else {
                this.selectSharedItems = true;
                this.showCheckColumn = true;
            }
        }
        else {
            this.selectUnsharedItems = false;
            this.selectSharedItems = false;
            this.showCheckColumn = false;
        }

        this.toShareCounter = this.selectedItems.length;
    }

    isItemSelected(item: RepositoryListData) {
        const selArr = this.selectedItems.filter(elem => { return this.identifyItem(item, elem) && elem.selected; });
        if (selArr.length > 0 || item.selected || (!this.allowUnShare && item.shared)) {
            return true;
        }
        else {
            return false;
        }
    }

    isItemEditable(item: RepositoryListData) {
        const selArr = this.selectedItems.filter(elem => { return this.identifyItem(item, elem) && elem.editable; });
        if (selArr.length > 0 || item.onlyEditBySagra) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Sprawdza poprawość listy zaznaczonych elementów. Czy jeszcze istnieją i czy nie należy ich usunąć.
     */
    checkSelectedItems() {
        const tmpSelectedItems: SelectedItem[] = [];

        for (let item of this.selectedItems) {
            const items = this.listItems.filter(elem => { return this.identifyItem(elem, item); });
            if (items.length > 0) {
                const selItem = items[0];
                item.originalSelected = selItem.selected;
                item.originalEditable = selItem.onlyEditBySagra;
                if (item.originalSelected != selItem.selected || item.originalEditable != selItem.onlyEditBySagra) {
                    tmpSelectedItems.push(item);
                }
            }
        }

        this.selectedItems = tmpSelectedItems;
    }

    /**
     * Sprawdza czy element z listy zaznaczonych jest odpowiadającym mu elementem z ogólnej listy
     * @param item - element z ogólnej listy
     * @param selectedItem - element z listy zaznaczonych
     */
    identifyItem(item: RepositoryListData, selectedItem: SelectedItem): boolean {
        if (this.dataSource == NavigationItemEnum.TWR) {
            return item.tableGUID == selectedItem.guid;
        }
        else {
            return item.id == selectedItem.id;
        }
    }

    statusClass(item: RepositoryListData) {
        switch (item.status) {
            case 0:
                return 'ink';
            case 1:
            case 11:
                return 'in-progress';
            case 2:
                return 'mint';
            case 3:
            case 13:
                return 'cherry';
        }
    }

    statusTextForPills(item: RepositoryListData) {
        return this.statusText(item).toLocaleUpperCase();
    }

    statusText(item: RepositoryListData) {
        switch (item.status) {
            case 0:
                return this.L("Not_shared");
            case 1:
                return this.L("Sharing");
            case 11:
                return this.L("Unsharing");
            case 2:
                return this.L("Shared_2");
            case 13:
                return this.L("Shared_2");
            case 3:
            default:
                return this.L("Not_shared");
        }
    }

    setTitleText(shareCounter: number) {
        if (this.allowUnShare) {
            if (this.selectSharedItems)
                this.actionButtonText = this.L('Stop_sharing');
            else {
                this.actionButtonText = this.L('Share');
            }
        }
        else {
            this.actionButtonText = this.L('Share');
        }

        if (shareCounter > 0) {
            this.actionButtonText += ' (' + shareCounter + ')'
        }

        //this.headerSubTitle = this.filteredListItems ? '(' + this.filteredListItems.length + ')' : '( 0 )';
    }

    prepareTooltipOptions(item: RepositoryListData) {
        if (item != null) {
            var options: SagraTooltipInterface = {
                tooltipItems: []
            };

            if (item.status == 3 || item.status == 13) {
                options.tooltipItems.push({ title: this.L('Error'), content: (item.status == 3 ? this.L('Unsharing_error') : this.L('Sharing_error')) + '<br/>' + item.errorDescripton });
                options.tooltipItems.push({ title: this.L('Status'), content: item.status == 3 ? this.L('Not_shared') : this.L('Shared_2') });
            }
            else {
                options.tooltipItems.push({ title: this.L('Status'), content: this.statusText(item) });
            }
            options.tooltipItems.push({ title: this.L('Catalog_name'), content: item.name });
            options.tooltipItems.push({ title: this.L('Display_name'), content: item.displayName });
            options.tooltipItems.push({ title: this.L('Id'), content: item.id.toString() });
            options.tooltipItems.push({ title: this.L('Description'), content: item.description });
            options.tooltipItems.push({ title: this.L('Note'), content: item.note });
            return options;
        }
    }

    preparePlaceholderOptions() {
        this.placeholderOptions = {
            title: this.L('Nothing_to_display'),
            image: {
                type: SagraPlaceholderImage.lightbulb
            }
        }

        if (this.searchText && (this.filteredListItems == null || this.filteredListItems.length == 0)) {
            this.placeholderOptions.title = this.L('Empty_search_holder');
            this.placeholderOptions.image = { type: SagraPlaceholderImage.search };
        }

        this.placeholderDetailsListOptions = {
            title: this.L('Nothing_to_display'),
            image: {
                type: SagraPlaceholderImage.lightbulb
            }
        }

    }

    applyFilters() {
        this.blinkList = true;
        let tmpListItems: Array<RepositoryListData> = this.listItems;

        if (this.listItems != null) {
            if (this.searchText) {
                tmpListItems = tmpListItems.filter(item => {
                    return item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
                        || (this.dataSource == NavigationItemEnum.ActivitiesOperations && item.id.toString().indexOf(this.searchText.toLowerCase()) !== -1)
                });
            }

            if (this.statusFilters.shared != this.statusFilters.notshared) {
                tmpListItems = tmpListItems.filter(item => (
                    this.statusFilters.shared
                    && (item.status == 2 || item.status == 11 || item.status == 13)
                    || (this.statusFilters.notshared && item.status != 2 && item.status != 11 && item.status != 13)));
            }
            if (this.typeFilters.h5Activities || this.typeFilters.operations || this.typeFilters.others) {
                tmpListItems = tmpListItems.filter(item => (this.typeFilters.h5Activities && item.baseType == 32) || (this.typeFilters.operations && item.baseType == 513) || (this.typeFilters.others && item.baseType != 32 && item.baseType != 513));
            }
        }

        this.filteredListItems = tmpListItems;
        this.counterText = this.filteredListItems ? '(' + this.filteredListItems.length + ')' : '( 0 )';
        this.preparePlaceholderOptions();

        this.sortColumn(this.sortingColumn, false);

        this.preparePlaceholderOptions();
        if (this.virtualScroll) {
            this.virtualScroll.scrollTo({ top: 0 });
        }
        //this.blinkList = false;
    }

    applyStatusFilters(status: string) {
        if (status == "SHARED") {
            this.statusFilters.shared = !this.statusFilters.shared;
        }
        else if (status == "NOTSHARED") {
            this.statusFilters.notshared = !this.statusFilters.notshared;
        }

        this.applyFilters();
    }

    applyTypeFilters(type: string) {
        if (type == "H5") {
            this.typeFilters.h5Activities = !this.typeFilters.h5Activities;
        }
        else if (type == "OPERATIONS") {
            this.typeFilters.operations = !this.typeFilters.operations;
        }
        else if (type == "OTHERS") {
            this.typeFilters.others = !this.typeFilters.others;
        }
        this.applyFilters();
    }

    openPanel() {
        if (this.allowDetailsPanel) {
            this.detailsPanelVisible = true;
            if (this.dataSource == NavigationItemEnum.ActivitiesOperations) {
                this.getActivityUseList();
            }
            else if (this.dataSource == NavigationItemEnum.Processes) {
                this.getProcessesUseList();
            }
        }
    }

    closePanel() {
        this.detailsRepoItem = null;
        this.detailsPanelVisible = false;
    }

    selectAllItems(event) {
        this.allItemsSelected = event;

        if (this.allItemsSelected) {
            let itemsToSelect;
            if (this.selectUnsharedItems) {
                itemsToSelect = this.filteredListItems.filter(item => item.status != 2 && item.status != 13 && item.status != 1 && item.status != 11);
            }
            else {
                itemsToSelect = this.filteredListItems.filter(item => item.status == 2 || item.status == 13);
            }

            for (let item of itemsToSelect) {
                const selArr = this.selectedItems.filter((elem) => { return this.identifyItem(item, elem); });                
                if (selArr.length == 0) {
                    const newItem = new SelectedItem(item.selected, item.onlyEditBySagra);
                    newItem.id = item.id;
                    newItem.guid = item.tableGUID;
                    newItem.status = item.status;
                    newItem.editable = item.onlyEditBySagra;
                    newItem.selected = true;
                    this.selectedItems.push(newItem);
                }
            }
        }
        else {
            this.selectedItems = [];
            this.selectUnsharedItems = false;
            this.selectSharedItems = false;
            this.showCheckColumn = false;
        }
        this.toShareCounter = this.selectedItems.length;
        this.setTitleText(this.toShareCounter);
    }

    checkAllItemsSelected() {
        let itemsToSelect;
        if (this.selectUnsharedItems) {
            itemsToSelect = this.filteredListItems.filter(item => item.status != 2 && item.status != 13 && item.status != 1 && item.status != 11);
        }
        else {
            itemsToSelect = this.filteredListItems.filter(item => item.status == 2 || item.status == 13);
        }
        if (itemsToSelect.length == this.selectedItems.length) {
            this.allItemsSelected = true;
        }
        else {
            this.allItemsSelected = false;
        }
    }

    setGroupExpanded(event, group: BindGroup, id: number) {
        if(group.id == id) {
            group.expanded = event;
        }
    }
    
    groupExpandedChange(event, group: BindGroup, id: number) {
        if(group.id == id) {
            group.expanded = event;
        }
    }

    prepareFilterToggleButtons() {
        this.shareToggleBtnOptions = {
            style: SagraToggleButtonStyle.primary,
            normalState: {
                text: this.L('SHARED_2')
            },
            activeState: {
                text: this.L('SHARED_2'),
                iconClass: 'icon-check'
            }
        };
        this.unshareToggleBtnOptions = {
            style: SagraToggleButtonStyle.primary,
            normalState: {
                text: this.L('NOT_SHARED_2')
            },
            activeState: {
                text: this.L('NOT_SHARED_2'),
                iconClass: 'icon-check'
            }
        };
        this.h5ToggleBtnOptions = {
            style: SagraToggleButtonStyle.primary,
            normalState: {
                text: this.L('H5_ACTIVITY')
            },
            activeState: {
                text: this.L('H5_ACTIVITY'),
                iconClass: 'icon-check'
            }
        };
        this.operationToggleBtnOptions = {
            style: SagraToggleButtonStyle.primary,
            normalState: {
                text: this.L('OPERATIONS')
            },
            activeState: {
                text: this.L('OPERATIONS'),
                iconClass: 'icon-check'
            }
        };
        this.otherToggleBtnOptions = {
            style: SagraToggleButtonStyle.primary,
            normalState: {
                text: this.L('OTHERS')
            },
            activeState: {
                text: this.L('OTHERS'),
                iconClass: 'icon-check'
            }
        };
    }
}

class RepositoryConfig {
    title: string;
    methodList: string;
    methodShare: string;
    methodUnshare?: string;
}

class StatusFilter {
    shared: boolean = false;
    notshared: boolean = false;
}
class TypeFilter {
    h5Activities: boolean = false;
    operations: boolean = false;
    others: boolean = false;
}

class BindGroup {
    name: string;
    id: number;
    expanded: boolean;
    iconClass: string;
    items?: Array<ActivityUse>;
}


//1	KatalogZadan
//2	GrupyPH
//3	Zaplanowane zadania
//4	Promocje
//5	Automatyczna kategoryzacja klienta
//6	Procesy
//7	Rutyny
//8	Dzialania
//9	czynnosci grupujace
