import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { DistrictData } from "../../models/districts/districtData";
import { map } from "rxjs/operators";
import { DistrictAssign } from "../../models/districts/districtAssign";

@Injectable({
    providedIn: 'root'
})

export class DistrictsService {
    private apiUrl: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    getBricksList(tenant: string): Observable<DistrictData[]> {
        return this.http.post<DistrictData[]>("api/Districts/GetDistrictsList", { Context: tenant }).pipe(
            map(data => {
                for (let item of data) {
                    if (item.analytical) {
                        item.name = "[" + item.name + "]";
                    }
                    item.originalName = item.name;
                }

                return data;
            })

        );
    }

    saveDistricts(tenant: string, districtsToSend: DistrictAssign[]) {
        return this.http.post("api/Districts/SaveDistricts", { DbName: tenant, AssignItems: districtsToSend });
    }
}
