import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { SagraComboboxMultiselectGroupInterface, SagraComboboxMultiselectOptionsInterface } from "@sagra/sagra-combobox-multiselect";
import { SagraSwitchComponentModelInterface, SagraSwitchComponentLabelPosition } from "@sagra/sagra-switch";
import { WinPointConfiguration } from "../../models/tenant/winPointConfiguration.model";
import { trigger, state, transition, style, animate } from "@angular/animations";

@Component({
    selector: 'dialog-new-database-winpoint',
    templateUrl: './dialog-new-database-winpoint.component.html',
    styleUrls: ['./dialog-new-database-winpoint.component.scss'],
    animations: [
        trigger('animationOption2', [
            transition(':enter', [
                style({ right: 'calc(50% - 500px)' }),
                animate(200)
            ]),
            transition(':leave', [
                animate(200, style({ right: 'calc(50% - 500px)' }))
            ]),
            state('*', style({ right: '*' }))
        ])
    ]
})

export class DialogNewDatabaseWinpointComponent extends SagraTranslatable implements OnInit, OnChanges {
    @Input() readOnly: boolean;
    @Input() winpointData: WinPointConfiguration;
    @Input() showDialog: boolean;
    @Output() onCloseDialog: EventEmitter<WinPointConfiguration> = new EventEmitter<WinPointConfiguration>();

    public templateTenants: Array<SagraComboboxMultiselectGroupInterface> = [];
    public templateTenantsComboOptions: SagraComboboxMultiselectOptionsInterface;
    public controlSwitchOptions: SagraSwitchComponentModelInterface;
    public medicalStaffSwitchOptions: SagraSwitchComponentModelInterface;

    constructor(public translationService: SagraTranslationService
    ) {
        super(translationService);
    }

    ngOnInit(): void {
        this.prepareControlsOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.winpointData) {
            if (changes.winpointData.currentValue == null) {
                this.winpointData = new WinPointConfiguration();
                this.winpointData.setDefault();
            }
            else {
                const conf = changes.winpointData.currentValue as WinPointConfiguration;
                this.winpointData = conf;
            }
        }
    }

    public onSaveClicked(event) {
        this.showDialog = false;
        setTimeout(() => {
            this.onCloseDialog.emit(this.winpointData);
        }, 200);
    }
    /**
     * Kliknięcie na przycisk Anuluj
     * @param event
     */
    public onCancelClicked(event) {
        this.showDialog = false;
        setTimeout(() => {
            this.onCloseDialog.emit(null);
        }, 200);
    }

    public onDefaultClicked(event) {
        this.winpointData = new WinPointConfiguration();
        this.winpointData.setDefault();
    }

    medicalStaffSwitchChange(event) {
        this.winpointData.medicalStaffDB = event;
        this.winpointData.sendRelations = event; // ma mieć takie samo ustawienie jak Personel Medyczny

    }
    demoSwitchChange(event) {
        this.winpointData.demo = event;
    }
    getProposalsSwitchChange(event) {
        this.winpointData.getProposals = event;
    }
    sendLocalizationsSwitchChange(event) {
        this.winpointData.sendLocalizations = event;
    }
    sendCategoriesSwitchChange(event) {
        this.winpointData.sendCategories = event;
    }
    sendProposalsSwitchChange(event) {
        this.winpointData.sendProposals = event;
    }
    getClientBBSwitchChange(event) {
        this.winpointData.getClientBB = event;
    }
    sendPayersSwitchChange(event) {
        this.winpointData.sendPayers = event;
    }
    sendHistCecha1SwitchChange(event) {
        this.winpointData.sendHistCecha1 = event;
    }
    sendHistCecha2SwitchChange(event) {
        this.winpointData.sendHistCecha2 = event;
    }
    sendDictionariesSwitchChange(event) {
        this.winpointData.sendDictionaries = event;
    }

    /**
     * Przygotowanie obiektów do konfiguracji kontrolek
     * */
    private prepareControlsOptions() {

        this.templateTenantsComboOptions = {
            multiselect: false,
            clearButton: false,
            groupedList: false,
            required: false,
            disabled: this.readOnly
        };

        this.controlSwitchOptions = {
            labelPosition: SagraSwitchComponentLabelPosition.right
        };

        this.medicalStaffSwitchOptions = {
            labelPosition: SagraSwitchComponentLabelPosition.right,
            labelText: this.L('MS_database_connection')
        }
    }
}
