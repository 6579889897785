import { Inject, Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RegisterData } from "../../models/register/registerData.model";
import { RegisterAssignRequest } from "../../models/register/registerAssignRequest.model";

@Injectable()
export class RegisterService {
    private apiUrl: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    getRegisterList(tenant: string): Observable<RegisterData[]> {
        return this.http.post<RegisterData[]>("api/Register/GetRegisterList", { Context: tenant }).pipe(
            map(data => {
                for (let item of data) {
                    if (item.status == null) {
                        item.status = 0;
                    }
                    if (item.status == 0) {
                        item.collapsed = true;
                    }
                    for (let actType of item.activityTypes) {
                        actType.selected = actType.active;
                    }
                }
                return data;
            })
        );
    }

    saveRegisters(tenant: string, registersToSend: RegisterAssignRequest[]) {
        return this.http.post("api/Register/SaveRegisters", { DbName: tenant, AssignItems: registersToSend });
    }
}
