import { Subject } from "rxjs";
import { HierarchyData } from "../models/hierarchy/hierarchyData.model";

export class DialogEditHierarchyOptions implements DialogEditHierarchyOptionsInterface {
    tenant: string;
    levelName: string;
    parentHierarchyCode: string;
    rootHierarchyItem: HierarchyData;
    hierarchyItem: HierarchyData;
    saveHandler: Subject<any>;
    cancelHandler: Subject<any>;

    constructor(tenant: string, levelName: string, rootHierarchyItem: HierarchyData, hierarchyItem: HierarchyData, parentHierarchyCode: string, cancelHandler: Subject<any>, publishHandler: Subject<any>) {
        this.tenant = tenant;
        this.levelName = levelName;
        this.parentHierarchyCode = parentHierarchyCode;
        this.rootHierarchyItem = rootHierarchyItem;
        this.hierarchyItem = hierarchyItem;
        this.saveHandler = publishHandler;
        this.cancelHandler = cancelHandler;
    }
}

export interface DialogEditHierarchyOptionsInterface {
    tenant: string,
    levelName: string;
    rootHierarchyItem: HierarchyData,
    hierarchyItem: HierarchyData,
    parentHierarchyCode: string,
    saveHandler: Subject<any>;
    cancelHandler: Subject<any>;
}
