import { Pipe, PipeTransform } from "@angular/core";
import { RepositoryListData } from "../models/repository/repositoryListData.model";

@Pipe({
    name: 'repositoryListSearchFilter',
    pure: false
})
export class RepositoryListSearchFilter implements PipeTransform {
    transform(items: RepositoryListData[], search: string, filterMetadata): RepositoryListData[] {
        if (!items || !search) {
            const newVal = items != null ? items.length : 0;
            if (filterMetadata.count != newVal) {
                filterMetadata.count = newVal;
            }
            return items;
        }

        const retItems = items.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        const newVal = retItems != null ? retItems.length : 0;
        if (filterMetadata.count != newVal) {
            filterMetadata.count = newVal;
        }
        return retItems;
    }
}
