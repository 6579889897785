import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { UserModelInterface } from "../../models/user.model";
import { Draft } from "../../models/database/draft.model";
import { DialogFeedGroupsOptionsInterface } from "../../logic/dialogFeedGroupsOptions.interface";
import { DialogAgreementsOptionsInterface } from "../../logic/dialogAgreementsOptions.interface";
import { DialogEditHierarchyOptionsInterface } from "../../logic/dialogEditHierarchyOptions.interface";
import { DialogSelectCacheVersionOptionsInterface } from "../../logic/dialogSelectCacheVersionOptions.interface";
import { JobInfo } from "../../models/monitoring/jobInfo.model";

@Injectable({ providedIn: 'root' })
export class MessageBusService {
    private userProfileSubject: Subject<UserModelInterface> = new Subject<UserModelInterface>();
    private logoutSubject: Subject<any> = new Subject<any>();
    private refreshTokenSubject: Subject<any> = new Subject<any>();
    private closeContextMenu: Subject<any> = new Subject<any>();
    private showLoaderSubject: Subject<any> = new Subject<any>();
    private showNotificationPanelSubject: Subject<any> = new Subject<any>();
    private refreshNotificationsSubject: Subject<any> = new Subject<any>();
    private showNotificationBellSubject: Subject<any> = new Subject<any>();
    private showDialogNewDatabaseSubject: Subject<any> = new Subject<any>();
    private hideDialogNewDatabaseSubject: Subject<any> = new Subject<any>();
    private refreshDatabaseListSubject: Subject<any> = new Subject<any>();
    private refreshRolesListSubject: Subject<any> = new Subject<any>();
    private refreshRepositoryListSubject: Subject<any> = new Subject<any>();
    private refreshDistrictListSubject: Subject<any> = new Subject<any>();
    private refreshRegisterListSubject: Subject<any> = new Subject<any>();
    private refreshWinpointSubject: Subject<any> = new Subject<any>();
    private showDialogFeedGroupsSubject: Subject<DialogFeedGroupsOptionsInterface> = new Subject<DialogFeedGroupsOptionsInterface>();
    private refreshBiqPackageSubject: Subject<any> = new Subject<any>();
    private refreshDatabaseDataSubject: Subject<any> = new Subject<any>();
    //private showDialogAgreementsSubject: Subject<DialogAgreementsOptionsInterface> = new Subject<DialogAgreementsOptionsInterface>();
    private refreshAADAgreementsSubject: Subject<any> = new Subject<any>();
    private refreshNewWorldSubject: Subject<any> = new Subject<any>();
    private expandAllHierarchiesSubject: Subject<any> = new Subject<any>();
    private showDialogEditHierarchySubject: Subject<DialogEditHierarchyOptionsInterface> = new Subject<DialogEditHierarchyOptionsInterface>();
    private refreshHierarchyItemSubject: Subject<any> = new Subject<any>();
    private refreshApplicationsSubject: Subject<any> = new Subject<any>();
    private showDialogSelectCacheVersionSubject: Subject<any> = new Subject<any>();
    private monitoringRefresh: Subject<JobInfo> = new Subject<JobInfo>();

    getCloseContextMenuEvent(): Observable<any> {
        return this.closeContextMenu.asObservable();
    }

    closeContextMenuEvent() {
        this.closeContextMenu.next(null);
    }

    getUserProfile(): Observable<UserModelInterface> {
        return this.userProfileSubject.asObservable();
    }
    setUserProfile(message: UserModelInterface): void {
        return this.userProfileSubject.next(message);
    }

    getRefreshToken(): Observable<any> {
        return this.refreshTokenSubject.asObservable();
    }

    refreshToken(): void {
        this.refreshTokenSubject.next(null);
    }

    getLogoutUrl(): Observable<string> {
        return this.logoutSubject.asObservable();
    }

    setLogoutUrl(path: string): any {
        this.logoutSubject.next(path);
    }

    getShowLoader(): Observable<boolean> {
        return this.showLoaderSubject.asObservable();
    }
    showLoader(show: boolean): void {
        this.showLoaderSubject.next(show);
    }

    getNotificationPanel(): Observable<boolean> {
        return this.showNotificationPanelSubject.asObservable();
    }
    showNotificationPanel(show: boolean): void {
        this.showNotificationPanelSubject.next(show);
    }

    getNotificationBell(): Observable<boolean> {
        return this.showNotificationBellSubject.asObservable();
    }
    showNotificationBell(show: boolean): void {
        this.showNotificationBellSubject.next(show);
    }

    getRefreshNotifications(): Observable<any> {
        return this.refreshNotificationsSubject.asObservable();
    }
    refreshNotifications(data: any): void {
        this.refreshNotificationsSubject.next(data);
    }

    getShowDialogNewDatabase() {
        return this.showDialogNewDatabaseSubject.asObservable();
    }
    showDialogNewDatabase(draft: Draft) {
        this.showDialogNewDatabaseSubject.next(draft);
    }

    getHideDialogNewDatabase(): Observable<any> {
        return this.hideDialogNewDatabaseSubject.asObservable();
    }
    hideDialogNewDatabase() {
        this.hideDialogNewDatabaseSubject.next(null);
    }

    getRefreshDatabaseList(): Observable<any> {
      return this.refreshDatabaseListSubject.asObservable();
    }
    refreshDatabaseList() {
        this.refreshDatabaseListSubject.next(null);
    }

    getRefreshRolesList(): Observable<any> {
      return this.refreshRolesListSubject.asObservable();
    }

    refreshRolesList(data) {
        this.refreshRolesListSubject.next(data);
    }

    getRefreshRepositoryList(): Observable<any> {
      return this.refreshRepositoryListSubject.asObservable();
    }
    refreshRepositoryList(data) {
      this.refreshRepositoryListSubject.next(data);
    }

    getRefreshDistrictList(): Observable<any> {
        return this.refreshDistrictListSubject.asObservable();
    }
    refreshDistrictList(data) {
        this.refreshDistrictListSubject.next(data);
    }

    getRefreshRegisterList(): Observable<any> {
        return this.refreshRegisterListSubject.asObservable();
    }
    refreshRegisterList(data) {
        this.refreshRegisterListSubject.next(data);
    }

    getRefreshWinpoint(): Observable<any> {
        return this.refreshWinpointSubject.asObservable();
    }
    refreshWinpoint(data) {
        this.refreshWinpointSubject.next(data);
    }

    getShowDialogFeedGroups() {
        return this.showDialogFeedGroupsSubject.asObservable();
    }

    showDialogFeedGroups(dialogOptions: DialogFeedGroupsOptionsInterface) {
        this.showDialogFeedGroupsSubject.next(dialogOptions);
    }

    getRefreshBiqPackage(): Observable<any> {
        return this.refreshBiqPackageSubject.asObservable();
    }
    refreshBiqPackage(data) {
        this.refreshBiqPackageSubject.next(data);
    }

    getRefreshDatabaseData(): Observable<any> {
        return this.refreshDatabaseDataSubject.asObservable();
    }
    refreshDatabaseData() {
        this.refreshDatabaseDataSubject.next(null);
    }

    //getShowDialogAgreements() {
    //    return this.showDialogAgreementsSubject.asObservable();
    //}

    //showDialogAgreements(dialogOptions: DialogAgreementsOptionsInterface) {
    //    this.showDialogAgreementsSubject.next(dialogOptions);
    //}

    getRefreshAADAgreements(): Observable<any> {
        return this.refreshAADAgreementsSubject.asObservable();
    }
    refreshAADAgreements() {
        this.refreshAADAgreementsSubject.next(null);
    }

    getRefreshNewWorld(): Observable<any> {
        return this.refreshNewWorldSubject.asObservable();
    }
    refreshNewWorld() {
        this.refreshNewWorldSubject.next(null);
    }

    getExpandAllHierarchies(): Observable<any> {
        return this.expandAllHierarchiesSubject.asObservable();
    }
    expandAllHierarchies(expand: boolean) {
        this.expandAllHierarchiesSubject.next(expand);
    }

    getShowDialogEditHierarchy() {
        return this.showDialogEditHierarchySubject.asObservable();
    }
    showDialogEditHierarchy(dialogOptions: DialogEditHierarchyOptionsInterface) {
        this.showDialogEditHierarchySubject.next(dialogOptions);
    }

    getRefreshHierarchyItem(): Observable<any> {
        return this.refreshHierarchyItemSubject.asObservable();
    }
    refreshHierarchyItem() {
        this.refreshHierarchyItemSubject.next(null);
    }

    getRefreshApplications(): Observable<any> {
        return this.refreshApplicationsSubject.asObservable();
    }
    refreshApplications() {
        this.refreshApplicationsSubject.next(null);
    }

    getShowDialogSelectCacheVersion() {
        return this.showDialogSelectCacheVersionSubject.asObservable();
    }
    showDialogSelectCacheVersion(dialogOptions: DialogSelectCacheVersionOptionsInterface) {
        this.showDialogSelectCacheVersionSubject.next(dialogOptions);
    }

    getMonitoringRefresh() {
        return this.monitoringRefresh.asObservable();
    }
    setMonitoringRefresh(jobInfo: JobInfo) {
        this.monitoringRefresh.next(jobInfo);
    }
}
