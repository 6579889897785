import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { SagraCollapseExpandOptions } from "@sagra/sagra-collapse-expand";
import { SagraComboboxMultiselectGroupInterface, SagraComboboxMultiselectListPosition, SagraComboboxMultiselectValueInterface } from "@sagra/sagra-combobox-multiselect";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";
import { SagraDialogOptionsInterface, SagraDialogService, SagraDialogType } from "@sagra/sagra-dialog";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Subject, Subscription } from "rxjs";
import { BIQPackage } from "../../../models/biq/biqPackage.model";
import { BiqPackageRequest } from "../../../models/biq/biqPackageRequest.model";
import { CacheInfoDetails } from "../../../models/biq/cacheInfoDetails.model";
import { CacheLicenseType } from "../../../models/biq/cacheLicenseType.model";
import { CacheVersion } from "../../../models/biq/cacheVersion.model";
import { SelectedBIQPackage } from "../../../models/biq/selectedBIQPackage.model";
import { BIQService } from "../../../services/biqService/biq.service";

@Component({
    selector: 'cache-details',
    templateUrl: './cache-details.component.html',
    styleUrls: ['./cache-details.component.scss']
})

export class CacheDetailsComponent extends SagraTranslatable implements OnChanges {
    @Input() tenant: string;
    @Input() portal: string;
    @Input() allowDelete: boolean;
    @Input() cacheDetails: CacheInfoDetails;
    @Input() packages: Array<BIQPackage>;
    @Input() versions: Array<CacheVersion>;
    @Input() licenseTypes: Array<CacheLicenseType>;
    @Output() performingAction: EventEmitter<boolean> = new EventEmitter<boolean>();


    private readonly DB_PERCENTAGE: number = 75;

    private subscriptions: Subscription = new Subscription();

    public expanded: boolean = false;
    public disableVersion: boolean = false;
    public disablePackage: boolean = false;
    public versionModel: string;
    public packageModel: string;
    public cacheFullName: string;
    private currentPackage: SelectedBIQPackage;
    private currentVersion: string;
    public canUpdate: boolean = false;
    public inProgressText: string = this.L('Updating');

    public updateCacheSubject = new Subject<string>();
    public deleteCacheSubject = new Subject<string>();

    public versionsList: Array<SagraComboboxMultiselectGroupInterface> = [];
    public packagesList: Array<SagraComboboxMultiselectGroupInterface> = [];

    public colExpOptions: SagraCollapseExpandOptions;

    constructor(public translationService: SagraTranslationService,
        private biqService: BIQService,
        private dialogService: SagraDialogService) {
        super(translationService);

        this.colExpOptions = {
            expanded: false,
            duration: 200,
            easing: 'linear'
        }

        this.updateCacheSubject.subscribe({
            next: data => {
                this.updateCache();
            }
        });

        this.deleteCacheSubject.subscribe({
            next: data => {
                this.deleteCache();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.versions) {
            let list: Array<SagraComboboxMultiselectValueInterface> = [];
            for (let version of this.versions) {
                list.push({ name: version.cacheSeverVersionNameShort, value: version.cacheSeverVersionId });
            }
            this.versionsList = [{ list: list}];
        }

        if (this.packages) {

            this.currentPackage = new SelectedBIQPackage();
            this.currentPackage.id = this.cacheDetails.cacheLicenseType;
            const result = this.packages.filter(item => { return item.id == this.currentPackage.id; })
            this.currentPackage.name = result[0].name;
            this.currentPackage.isDemo = result[0].isDemo;
            this.currentPackage.address = result[0].address;
            //this.currentPackage.serverId = this.cacheDetails.

            let list: Array<SagraComboboxMultiselectValueInterface> = [];
            for (let packageItem of this.packages) {
                if ((this.currentPackage.isDemo && packageItem.isDemo && packageItem.id != this.currentPackage.id)
                    || (!this.currentPackage.isDemo && packageItem.isDemo)) {
                    continue;
                }
                list.push({ name: packageItem.name, value: packageItem.id });
            }
            this.packagesList = [{ list: list }];
        }

        if (this.cacheDetails) {
            this.versionModel = this.cacheDetails.version.cacheSeverVersionId;
            this.currentVersion = this.cacheDetails.version.cacheSeverVersionId;
            this.packageModel = this.cacheDetails.cacheLicenseType;

            let res = this.packages.filter(item => item.id == this.cacheDetails.cacheLicenseType);
            let packageName = '';
            if (res != null && res.length > 0) {
                packageName = res[0].name;
            }
            this.cacheFullName = this.cacheDetails.version.cacheSeverVersionName + " - " + packageName;
            if (this.cacheDetails.inProgressInfo != null) {
                this.inProgressText = this.L('Updating') + ' (' + (this.cacheDetails.inProgressInfo.taskCompleted + 1) + ' ' + this.L('of') + ' ' + this.cacheDetails.inProgressInfo.tasksCount + ')'
            }

            this.disablePackage = this.cacheDetails.inProgressInfo != null;
            this.disableVersion = this.cacheDetails.inProgressInfo != null;
        }

        if (this.currentPackage != null && this.cacheDetails) {
            this.currentPackage.serverId = this.cacheDetails.serverId;
        }

        this.allowDelete = this.allowDelete && this.cacheDetails.inProgressInfo == null;
    }

    onVersionModelChange(event) {
        this.checkCanUpdate();
    }

    onPackageModelChange(event) {
        const res = this.licenseTypes.filter(x => x.cacheLicenseType == event);
        if (res != null && res.length > 0) {
            if (this.cacheDetails.databaseVolumeGB > res[0].dataBaseSizeGB * (this.DB_PERCENTAGE / 100)) {
                const res2 = this.packages.filter(x => x.id == event);
                const dialogOptions: SagraDialogOptionsInterface = {
                    dialogType: SagraDialogType.alert,
                    title: this.L('Cant_change_package_size_title'),
                    message: this.L('Cant_change_package_size_message').formatString(res2[0].name, this.DB_PERCENTAGE),
                    buttons: [{ name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary }]
                }
                this.dialogService.showAlert(dialogOptions);
                setTimeout(() => {
                    this.packageModel = this.currentPackage.id;
                    this.checkCanUpdate();
                })

            }
        }
        this.checkCanUpdate();
    }

    onUpdateButtonClick() {
        let versionName = this.cacheDetails.version.cacheSeverVersionName;
        let packageName = this.cacheDetails.cacheLicenseType;

        if (this.versionModel != this.currentVersion) {
            let res = this.versions.filter(item => {
                return item.cacheSeverVersionId == this.versionModel;
            });
            if (res != null && res.length > 0) {
                versionName = res[0].cacheSeverVersionName;
            }
        }
        if (this.packageModel != this.currentPackage.id) {
            let res = this.packages.filter(item => item.id == this.packageModel);
            if (res != null && res.length > 0) {
                packageName = res[0].name;
            }
        }

        let cacheName = versionName + " - " + packageName;
        let message = this.L('Ask_change_package_biqsens_1').formatString(cacheName);
        if (this.packageModel != this.currentPackage.id) {
            message = message + this.L('Ask_change_package_biqsens_2');
        }

        const dialogOptions: SagraDialogOptionsInterface = {
            dialogType: SagraDialogType.confirm,
            message: message,
            buttons: [{ name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                { name: this.L('Change'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.updateCacheSubject }]
        }
        this.dialogService.showAlert(dialogOptions);

    }

    onDeleteButtonClick() {
        let dialogOptions: SagraDialogOptionsInterface = {
            dialogType: SagraDialogType.confirm,
            message: this.L('Ask_delete_cache').formatString(this.cacheFullName),
            buttons: [{ name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                { name: this.L('Delete'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.custom, className: 'sgf-btn-cherry', handler: this.deleteCacheSubject }]
        }
        this.dialogService.showAlert(dialogOptions);
    }

    updateCache() {
        this.signalPerformingAction(true);

        const biqRequest = new BiqPackageRequest();
        biqRequest.dbName = this.tenant;
        biqRequest.portalName = this.portal;
        biqRequest.currentPackage = this.currentPackage;

        if (this.currentPackage.id != this.packageModel) {
            const result = this.packages.filter(item => { return item.id == this.packageModel; })
            const newPackage = new SelectedBIQPackage();
            newPackage.id = result[0].id;
            newPackage.name = result[0].name;
            newPackage.isDemo = result[0].isDemo;
            biqRequest.newPackage = newPackage;
        }

        if (this.currentVersion != this.versionModel) {
            let version = this.versionModel;
            let res = this.versions.filter(item => item.cacheSeverVersionId == this.versionModel);
            if (res != null && res.length > 0) {
                version = res[0].cacheSeverVersionNameShort;
            }

            if (biqRequest.newPackage != null) {
                biqRequest.newPackage.version = version;
            }
            else {
                biqRequest.currentPackage.version = version;
            }
        }
        let observable = null;
        if (this.currentPackage.id != this.packageModel) {
            observable = this.biqService.changePackage(biqRequest);
        }
        else {
            observable = this.biqService.changePackageParameters(biqRequest);
        }

        this.subscriptions.add(observable.subscribe({
            next: data => {
                this.signalPerformingAction(false);
            },
            error: error => {
                this.signalPerformingAction(false);

            }
        }));
    }

    deleteCache() {
        this.signalPerformingAction(true);
        const biqRequest = new BiqPackageRequest();
        biqRequest.dbName = this.tenant;
        biqRequest.portalName = this.portal;
        biqRequest.currentPackage = this.currentPackage;

        this.subscriptions.add(this.biqService.removeCache(biqRequest).subscribe({
            next: data => {
                this.signalPerformingAction(false);
            },
            error: error => {
                this.signalPerformingAction(false);
            }
        }));
    }

    signalPerformingAction(state: boolean) {
        if (this.performingAction != null) {
            this.performingAction.emit(state);
        }
    }

    checkCanUpdate() {
        this.canUpdate = this.currentPackage.id != this.packageModel || this.currentVersion != this.versionModel;
    }
}
