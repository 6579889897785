export class UserModel implements UserModelInterface {
    public userName: string;
    public userEmail: string;
    public permissions: any;
}

export interface UserModelInterface {
    userName: string;
    userEmail: string;
    permissions: any;
}
