export class Permission {
    public permissionName: string;

    public canActivate: boolean;
    public canAssign: boolean;
    public canCreate: boolean;
    public canDelete: boolean;
    public canRead: boolean;
    public canWrite: boolean;
}
