<div class="roles-component">
    <tabpage-header [title]="headerTitle"
                    [actionButtonTitle]="actionButtonTitle"
                    [actionButtonVisible]="true"
                    [actionButtonDisabled]="toShareCounter == 0"
                    [searchEnabled]="true"
                    [showBreadcrumb]="true"
                    (searchTextChanged)="onSearchTextChanged($event)"
                    (actionButtonClicked)="onActionButtonClicked($event)">
    </tabpage-header>
    <div class="roles-list">
        <div class="roles-list-check-row" *ngFor="let role of roles | rolesListSearchFilter: searchText">
            <div class="roles-list-check-row-control">
                <sagra-checkbox [state]="isItemSelected(role)" [disabled]="role.shared" [checkboxsize]="checkboxSize" (update)="onCheckBoxUpdate($event, role)" *ngIf="role.status!=1"></sagra-checkbox>
                <sagra-loader [loaderType]="roleLoaderOptions.loaderType" [spinerSolidSetings]="roleLoaderOptions.spinerSolidSetings" [backgroundSetings]="roleLoaderOptions.backgroundSetings" *ngIf="role.status==1"></sagra-loader>
            </div>
            <div class="roles-list-check-row-text">
                <span>{{role.name}}</span><span class="roles-list-check-row-text-error" *ngIf="role.statusInfo">({{role.statusInfo}})</span>
            </div>
        </div>
    </div>
    <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
</div>
