import { Pipe, PipeTransform } from "@angular/core";
import { ApplicationAAD } from "../models/tenant/applicationAAD.model";

@Pipe({
    name: 'applicationsListSearchFilter',
    pure: false
})

export class ApplicationsListSearchFilter implements PipeTransform {
    transform(items: ApplicationAAD[], search: string): ApplicationAAD[] {
        if (!items || !search) {
            return items;
        }

        return items.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
}
