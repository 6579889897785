import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnDestroy, SimpleChange } from "@angular/core";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { TenantConfiguration } from "../../../models/tenant/tenantConfiguration.model";
import { MessageBusService } from "../../../services/messageBusService/messageBusService";
import { Subject, Subscription } from "rxjs";
import { SagraComboboxMultiselectGroupInterface, SagraComboboxMultiselectOptionsInterface } from "@sagra/sagra-combobox-multiselect";
import { BIQService } from "../../../services/biqService/biq.service";
import { BIQPackage } from "../../../models/biq/biqPackage.model";
import { map } from "rxjs/operators";
import { SelectedBIQPackage } from "../../../models/biq/selectedBIQPackage.model";
import { TenantService } from "../../../services/tenantService/tenant.service";
import { BiqPackageRequest } from "../../../models/biq/biqPackageRequest.model";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { SagraDialogService, SagraDialogOptionsInterface, SagraDialogType } from "@sagra/sagra-dialog";
import { ApplicationAAD } from "../../../models/tenant/applicationAAD.model";
import { OperationsHistoryService } from "../../../services/operationsHistoryService/operationsHistory.service";
import { SagraTooltipInterface, SagraTooltipOptionsInterface, SagraTooltipOrientation } from "@sagra/sagra-tooltip";
import moment from "moment";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";
import { SagraDatetimePickerType } from "@sagra/sagra-datetime-picker";
import { CacheLicenseType } from "../../../models/biq/cacheLicenseType.model";
import { CacheInfoDetails } from "../../../models/biq/cacheInfoDetails.model";
import { InProgressInfo } from "../../../models/biq/inProgressInfo.model";
import { Utils } from "../../../utils/utils";
import { DialogsService } from "../../../services/dialogsService/dialogs.service";

const DATE_FORMAT = "DD.MM.YYYY";

@Component({
    selector: 'new-world',
    templateUrl: './new-world.component.html',
    styleUrls: ['./new-world.component.scss']
})

export class NewWorldComponent extends SagraTranslatable implements OnInit, OnChanges, OnDestroy {
    @Input() tenant: string;
    @Input() portal: string;
    @Input() tenantConfiguration: TenantConfiguration;
    @Output() showLoaderChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    private readonly DB_PERCENTAGE: number = 75;

    private subscriptions = new Subscription();
//    private activateAgreementsSubject: Subject<any> = new Subject<any>();
//    private copyLinksSubject: Subject<any> = new Subject<any>();
    public demoPackageEndingInfo: string;
    public demoUsed: boolean;
    public packagesList: Array<SagraComboboxMultiselectGroupInterface> = [];
    private packageItems: Array<BIQPackage>;
    public currentPackage: SelectedBIQPackage;
    public packageModel: BIQPackage = new BIQPackage();
    public agreementsList: Array<ApplicationAAD>;
    public canSavePackage: boolean;
    public disablePackage: boolean;
    public cacheEnabled: boolean;
    public newWorldStatus: number = 0;
    public engineH5Status: any = {};
    public packageLoader: boolean = false;
    public packageLoaderText: string;
    public showLoaderBiqPackage: boolean = true;
    public cacheLicenseTypes: Array<CacheLicenseType>;
    public cacheDetailsItems: Array<CacheInfoDetails>;

    public demoEnabled: boolean;
    public demoDurationFromModel: moment.Moment;
    public demoDurationToModel: moment.Moment;
    public datePickerType: SagraDatetimePickerType = SagraDatetimePickerType.date;

    private switchOffPackageSubject: Subject<any> = new Subject<any>();

    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    public tooltipOptions: SagraTooltipOptionsInterface;
    public newWorldTooltipModel: SagraTooltipInterface;
    public demoTooltipOptions: SagraTooltipOptionsInterface;
    public demoTooltipModel: SagraTooltipInterface;

    private newWorldSubject = new Subject<string>();
    private engineH5Subject = new Subject<string>();
    private enableNewPackageSubject = new Subject<string>();
    private changePackageSubject = new Subject<string>();


    constructor(public translationService: SagraTranslationService,
        public messageService: MessageBusService,
        public dialogService: SagraDialogService,
        private dialogsService: DialogsService,
        public biqService: BIQService,
        public tenantService: TenantService,
        private operationService: OperationsHistoryService) {

        super(translationService);

        this.subscriptions.add(this.switchOffPackageSubject.asObservable().subscribe(
            x => this.switchOffPackage()
        ));

        this.tooltipOptions = {
            orientation: SagraTooltipOrientation.horizontal
        }
        this.demoTooltipOptions = {
            orientation: SagraTooltipOrientation.vertical,

        }
    }

    ngOnInit(): void {
        const self = this;

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            }
        }

        //this.subscriptions.add(self.activateAgreementsSubject.asObservable().subscribe((data) => {
        //    this.activateAgreements(data);
        //}));
        //this.subscriptions.add(self.copyLinksSubject.asObservable().subscribe((data) => {
        //}));
        this.subscriptions.add(self.newWorldSubject.asObservable().subscribe(
            x => {
                self.subscriptions.add(self.tenantService.activateNewWorld(self.tenant, self.portal).subscribe(data => {
                    self.newWorldStatus = 1; // w trakcie 
                    self.messageService.refreshDatabaseData();
                }));
            }
        ));
        this.subscriptions.add(self.engineH5Subject.asObservable().subscribe(
            x => {
                self.subscriptions.add(self.tenantService.startEngineH5(self.tenant, self.portal).subscribe(data => {
                    self.engineH5Status.status = 1;
                    self.getEngineH5Status();
                }));
            }
        ));
        this.subscriptions.add(self.enableNewPackageSubject.asObservable().subscribe(
            x => {
                this.enableNewPackage();
            }
        ));
        this.subscriptions.add(self.changePackageSubject.asObservable().subscribe(
            x => {
                this.changePackage();
            }
        ));

        this.messageService.getRefreshBiqPackage().subscribe(data => {
            if (data.tenant == null || data.tenant.toLowerCase() == self.tenant.toLowerCase()) {
                self.messageService.refreshDatabaseData();
            }
        });

        this.messageService.getRefreshNewWorld().subscribe(data => {
            self.messageService.refreshDatabaseData();
            self.getEngineH5Status(); // dla enginu H5 nie ma osobnego signalR 
        })

        this.getPackagesList();
        this.getCacheDetails();
        this.getCacheLicenseTypes();
        //this.getApplicationsAAD(); 
        this.getEngineH5Status();
        this.preparePackageModels();
    }

    ngOnChanges(changes: SimpleChanges): void {
        let currentItem: SimpleChange = changes.tenantConfiguration;
        if (currentItem && currentItem.currentValue) {
            this.tenantConfiguration = currentItem.currentValue;
            if (this.tenantConfiguration.cacheLicense != null && this.tenantConfiguration.cacheLicense.value.length > 0) {
                this.cacheEnabled = true;
            }
            else {
                this.cacheEnabled = false;
            }
            this.prepareCurrentPackage();

            if ((this.tenantConfiguration.demoEnd != null && this.tenantConfiguration.demoEnd.value.length > 0)
                || (this.currentPackage != null && !this.currentPackage.isDemo)) {
                this.demoUsed = true;
                this.preparePackageList();

                if (this.tenantConfiguration.demoEnd != null && this.tenantConfiguration.demoEnd.value.length > 0) {
                    let startDate = "";
                    let stopDate = "";
                    if (this.tenantConfiguration.demoStart != null && this.tenantConfiguration.demoStart.value.length > 0) {
                        startDate = this.tenantConfiguration.demoStart.value;
                    }
                    stopDate = this.tenantConfiguration.demoEnd.value;
                    this.demoPackageEndingInfo = this.L('Demo_package_ending_info') + " " + startDate + " - " + stopDate;
                }
            }

            this.newWorldStatus = 0;
            if (this.tenantConfiguration.newWorldInProgress) {
                this.newWorldStatus = 1;
            }
            else {
                this.newWorldStatus = this.tenantConfiguration.newWorld?.value == 'True' ? 2 : 0;
            }

            this.disablePackage = this.tenantConfiguration == null || (this.tenantConfiguration.biqInProgress != null && this.tenantConfiguration.biqInProgress.value == "1");// || (this.currentPackage != null && this.currentPackage.id.length > 0 && !this.currentPackage.isDemo); 
            this.packageLoader = (this.tenantConfiguration.biqInProgress != null && this.tenantConfiguration.biqInProgress.value == "1");// && ((this.currentPackage != null && this.currentPackage.id.length > 0 && !this.currentPackage.isDemo) || this.demoUsed);

            this.preparePackageLoaderText();

            this.prepareControlsOptions();

            this.newWorldTooltipModel = {
                tooltipItems: []
            }
            if (this.newWorldStatus != 0 && this.tenantConfiguration.newWorld) {
                this.newWorldTooltipModel.tooltipItems.push({ title: this.L('Activation_date'), content: moment(this.tenantConfiguration.newWorld.settingDate).format('DD.MM.YYYY') });
                this.newWorldTooltipModel.tooltipItems.push({ title: this.L('Orderer'), content: this.tenantConfiguration.newWorld.orderer });
            }
            this.demoTooltipModel = {
                tooltipItems: []
            }
            if (this.demoPackageEndingInfo) {
                this.demoTooltipModel.tooltipItems.push({ content: this.demoPackageEndingInfo });
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onPackageModelChange(event) {
        const res = this.cacheLicenseTypes.filter(x => x.cacheLicenseType == event);
        if (res != null && res.length > 0) {
            if (this.cacheDetailsItems != null && this.cacheDetailsItems.length > 0 &&  this.cacheDetailsItems[0].databaseVolumeGB > res[0].dataBaseSizeGB * (this.DB_PERCENTAGE / 100)) {
                const res2 = this.packageItems.filter(x => x.id == event);
                const dialogOptions: SagraDialogOptionsInterface = {
                    dialogType: SagraDialogType.alert,
                    title: this.L('Cant_change_package_size_title'),
                    message: this.L('Cant_change_package_size_message').formatString(res2[0].name, this.DB_PERCENTAGE),
                    buttons: [{ name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary }]
                }
                this.dialogService.showAlert(dialogOptions);
                setTimeout(() => {
                    this.packageModel.id = this.currentPackage.id;
                    this.checkCanSavePackage();
                })
                return;
            }
        }

        const result = this.packageItems.filter(item => { return item.id == event; })
        this.packageModel.name = result[0].name;
        this.packageModel.id = result[0].id;
        this.packageModel.isDemo = result[0].isDemo;

        if (this.currentPackage != null && this.currentPackage.id == this.packageModel.id) {
            this.preparePackageModels();
        }

        this.checkCanSavePackage();
    }

    onPackageSetClick() {
        //this.showLoader = true; 

        if (this.currentPackage == null) {
            //wybór nowego 
            let dialogOptions: SagraDialogOptionsInterface = {
                dialogType: SagraDialogType.confirm,
                message: this.L('Ask_enable_package_biqsens'),
                buttons: [
                    { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary, className: 'sgf-btn-light' },
                    { name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.enableNewPackageSubject }
                ]
            };
            this.dialogService.showAlert(dialogOptions);
        }
        else {
            // zmiana pakietu 
            if (this.currentPackage.isDemo) {
                let dialogOptions: SagraDialogOptionsInterface = {
                    dialogType: SagraDialogType.confirm,
                    message: this.L('Ask_enable_package_biqsens_with_demo'),
                    buttons: [
                        { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                        { name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.changePackageSubject }
                    ]
                };
                this.dialogService.showAlert(dialogOptions);
            }
            else {
                //zmiana pakietu, gdy włączony inny niż demo 
                let dialogOptions: SagraDialogOptionsInterface = {
                    dialogType: SagraDialogType.confirm,
                    message: this.L('Ask_change_package_biqsens').formatString(this.packageModel.name),
                    buttons: [
                        { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                        { name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.changePackageSubject }
                    ]
                };
                this.dialogService.showAlert(dialogOptions);
            }
        }
    }

    onNewWorldClick() {

        let dialogOptions: SagraDialogOptionsInterface = {
            dialogType: SagraDialogType.confirm,
            message: this.L('Ask_enable_new_world'),
            buttons: [
                { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                { name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.newWorldSubject }
            ]
        };
        this.dialogService.showAlert(dialogOptions);
    }

    onAgreementsSetClick() {
        //this.messageService.showDialogAgreements({
        //    agreements: this.agreementsList,
        //    cancelHandler: null,
        //    publishHandler: this.activateAgreementsSubject,
        //    copyLinksHandler: this.copyLinksSubject,
        //    tenant: this.tenant
        //});

        this.dialogsService.setAgreementsModalState({
            state: true,
            agreements: this.agreementsList,
            tenant: this.tenant,
            portal: this.portal
        })
    }

    onEngineH5Click() {
        let dialogOptions: SagraDialogOptionsInterface = {
            dialogType: SagraDialogType.confirm,
            message: this.L('Ask_engine_h5'),
            buttons: [
                { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                { name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.engineH5Subject }
            ]
        };
        this.dialogService.showAlert(dialogOptions);
    }

    onBiqShowLoaderChange(event) {
        this.showLoaderBiqPackage = event;
    }

    onBiqPackageFromDateChanged(event) {
        this.demoDurationFromModel = event;

        this.checkDemoPackageDates();
        this.checkCanSavePackage();
    }

    onBiqPackageToDateChanged(event) {
        this.demoDurationToModel = event;

        this.checkDemoPackageDates();
        this.checkCanSavePackage();
    }

    onTurnOffPackageClick() {

        this.dialogService.showAlert(
            {
                dialogType: SagraDialogType.confirm,
                backdropClose: true,
                title: this.L('Want_turn_off_package'),
                buttons: [
                    { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                    { name: this.L('Save'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.switchOffPackageSubject }
                ]
            }
        );
    }

    checkDemoPackageDates() {
        if (this.demoDurationFromModel != null && this.demoDurationToModel) {
            if (this.demoDurationFromModel.isAfter(this.demoDurationToModel)) {
                console.log("Nieprawidłowe daty");
            }
        }
    }

    cacheHistoryButtonClick() {
        this.operationService.openInNewTab(this.tenant, 101, null, null);
    }

    agreementsHistoryButtonClick() {
        this.operationService.openInNewTab(this.tenant, 100, null, null);
    }

    enableNewPackage() {
        this.updateLoader(true);

        const biqRequest = new BiqPackageRequest();
        biqRequest.dbName = this.tenant;
        biqRequest.portalName = this.portal;
        biqRequest.newPackage = this.packageModel;
        if (biqRequest.newPackage.isDemo) {
            if (this.demoDurationFromModel != null) {
                biqRequest.startBiqDemoDate = this.demoDurationFromModel.format(DATE_FORMAT);
            }
            if (this.demoDurationToModel != null) {
                biqRequest.endBiqDemoDate = this.demoDurationToModel.format(DATE_FORMAT);
            }
        }


        this.biqService.switchOnPackage(biqRequest).subscribe((data) => {
            this.updateLoader(false);
            this.packageLoader = true;
        },
            (error) => {
                this.updateLoader(false);
            });
    }

    changePackage() {
        this.updateLoader(true);
        const biqRequest = new BiqPackageRequest();
        biqRequest.dbName = this.tenant;
        biqRequest.portalName = this.portal;
        biqRequest.currentPackage = this.currentPackage;
        biqRequest.newPackage = this.packageModel;

        this.biqService.changePackage(biqRequest).subscribe((data) => {
            this.updateLoader(false);
            this.packageLoader = true;
        },
            (error) => {
                this.updateLoader(false);
            });
    }

    activateAgreements(agreements: Array<any>) {
        this.subscriptions.add(this.tenantService.activateAgreements(this.tenant, this.portal, agreements).subscribe(data => {

        }));
    }

    copyLinks() {

    }

    getPackagesList() {
        //this.showLoader = true; 
        this.updateLoader(true);
        this.subscriptions.add(this.biqService.getPackagesList().pipe(map(data => {
            for (let item of data) {
                item.value = item.id;
            }
            return data;
        })).subscribe(data => {
            this.packageItems = data;

            if (this.currentPackage != null) {
                const list = this.packageItems.filter(item => { return item.id == this.currentPackage.id; });
                if (list != null && list.length > 0) {
                    this.currentPackage.name = list[0].name;
                    this.currentPackage.isDemo = list[0].isDemo;
                }
            }

            this.preparePackageModels();

            this.preparePackageList();

            this.disablePackage = this.tenantConfiguration == null || (this.tenantConfiguration.biqInProgress != null && this.tenantConfiguration.biqInProgress.value == "1");// || (this.currentPackage != null && this.currentPackage.id.length > 0 && !this.currentPackage.isDemo); 
            this.prepareControlsOptions();

            this.updateLoader(false);
        },
            (error) => {
                //this.showLoader = false; 
                this.updateLoader(false);
            }
        ));
    }

    preparePackageModels() {
        if (this.currentPackage != null) {
            this.packageModel = new BIQPackage();
            this.packageModel.name = this.currentPackage.name;
            this.packageModel.id = this.currentPackage.id;
            this.packageModel.isDemo = this.currentPackage.isDemo;
        }
        else {
            this.packageModel = new BIQPackage();
        }
    }

    preparePackageList() {
        this.packagesList = [];
        if (this.packageItems == null) {
            return;
        }

        let list = this.packageItems;
        if (this.demoUsed) {
            list = list.filter(item => {
                return item.isDemo == null || item.isDemo == false || (this.currentPackage != null && item.id == this.currentPackage.id);
            })
        }
        // jeśli aktualny pakiet, którego już nie ma 
        if (this.currentPackage != null) {
            const tmpList = list.filter(item => { return item.id == this.currentPackage.id; })
            if (tmpList == null || tmpList.length == 0) {
                list.unshift({ id: '', name: this.currentPackage.name, value: this.currentPackage.id, isDemo: this.currentPackage.isDemo });
            }
        }
        this.packagesList.push({
            list: list
        });
    }

    updateLoader(value: boolean) {
        value = value;
        this.showLoaderChange.emit(value);
    }

    getApplicationsAAD() {
        this.subscriptions.add(this.tenantService.getApplicationsAAD(this.tenant, 1)
            .pipe(map(items => {
                for (let i = 0; i < items.length; i++) {
                    items[i].link = Utils.getAADLink(items[i].id);
                }
                return items;
            }))
            .subscribe(data => {
                this.agreementsList = data;
            }));
    }

    getEngineH5Status() {
        this.subscriptions.add(this.tenantService.getEngineH5Status(this.tenant).subscribe(data => {
            this.engineH5Status = data;
        }));
    }

    checkCanSavePackage() {
        let canSave = true;

        if (this.packageModel.id == null || (this.currentPackage != null && this.packageModel.id == this.currentPackage.id)) {
            canSave = false;
        }

        if (this.packageModel == null || (this.packageModel.isDemo && this.demoDurationToModel == null)) {
            canSave = false;
        }


        this.canSavePackage = canSave;
    }

    prepareControlsOptions() {
    }

    switchOffPackage() {

        if (this.currentPackage != null) {
            //this.showLoader = true; 
            this.updateLoader(true);

            const biqRequest = new BiqPackageRequest();
            biqRequest.dbName = this.tenant;
            biqRequest.portalName = this.portal;
            biqRequest.currentPackage = this.currentPackage;

            this.subscriptions.add(this.biqService.removeCache(biqRequest).subscribe({
                next: data => {
                    this.updateLoader(false);
                },
                error: error => {
                    this.updateLoader(false);
                }
            }));
        }
    }

    getCacheDetails() {
        this.subscriptions.add(this.biqService.getCacheDetails(this.tenant).subscribe({
            next: data => {
                this.cacheDetailsItems = data;
                this.prepareCurrentPackage();
                this.preparePackageLoaderText();
            },
            error: error => {

            }
        }));
    }

    getCacheLicenseTypes() {
        this.subscriptions.add(this.biqService.getCacheLicenseTypes().subscribe({
            next: data => {
                this.cacheLicenseTypes = data;
            },
            error: error => {
            }
        }));
    }

    preparePackageLoaderText() {
        if (this.cacheDetailsItems != null && this.cacheDetailsItems.length > 0) {
            let cacheDetails: CacheInfoDetails = null;
            for (let item of this.cacheDetailsItems) {
                if (item.inProgressInfo != null) {
                    cacheDetails = item;
                    break;
                }
            }
            if (cacheDetails != null) {
                this.packageLoaderText = this.L('Updating');
                const progressInfo: InProgressInfo = cacheDetails.inProgressInfo;
                this.packageLoaderText += ` (${progressInfo.taskCompleted + 1} ${this.L('of')} ${progressInfo.tasksCount})`;
            }
        }
    }

    prepareCurrentPackage() {
        this.currentPackage = null;
        let cacheDetailsInProgress: CacheInfoDetails = null;
        if (this.cacheDetailsItems != null) {
            for (let item of this.cacheDetailsItems) {
                if (item.inProgressInfo != null) {
                    cacheDetailsInProgress = item;
                    break;
                }
            }
        }

        if (this.tenantConfiguration != null && this.tenantConfiguration.cacheLicense != null && this.tenantConfiguration.cacheLicense.value.length > 0 && cacheDetailsInProgress == null) {
            this.currentPackage = new SelectedBIQPackage();
            this.currentPackage.id = this.tenantConfiguration.cacheLicense.value;
        }
        else if (this.cacheDetailsItems != null && this.cacheDetailsItems.length > 0 && cacheDetailsInProgress != null) {
            this.currentPackage = new SelectedBIQPackage();
            this.currentPackage.id = cacheDetailsInProgress.cacheLicenseType;
        }

        if (this.packageItems != null && this.currentPackage != null) {
            const result = this.packageItems.filter(item => { return item.id == this.currentPackage.id; })
            this.currentPackage.name = result[0].name;
            this.currentPackage.isDemo = result[0].isDemo;
        }
        this.preparePackageModels();
    }
} 
