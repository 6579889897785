import { SagraTooltipInterface } from "@sagra/sagra-tooltip";

export class NavigationGroup {
    id: number;
    name: string;
    items: NavigationItem[];
}

export class NavigationItem {
    id: number;
    name: string;
    shortName?: string;
    disabled?: boolean;
    hidden?: boolean;
    iconClass?: string;
    tooltip?: SagraTooltipInterface;
}

export enum NavigationItemEnum {
    Unset = -1,
    None = 0,
    DatabaseInfo = 1,
    ActivitiesOperations = 2,
    Processes = 3,
    TWR = 4,
    Roles = 5,
    Sheets = 6,
    Districts = 7,
    Register = 8,
    Extracts = 9,
    WinPoint = 10,
    H5Tables = 11,
    Hierarchy = 12,
    Applications = 13,
    Targeting = 14
}
