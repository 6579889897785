import { Component, OnInit } from '@angular/core';
import { SagraPillOptions, SagraPillType } from '@sagra/sagra-pill';
import { SagraTranslatable, SagraTranslationService } from '@sagra/sagra-translation';
import { Job } from '../../../models/monitoring/job.model';
import { JobInfo } from '../../../models/monitoring/jobInfo.model';
import { MonitoringService } from '../../../services/monitoring/monitoring.service';
import { JobOperationType, JobState } from '../../../models/monitoring/jobStatus.model';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SagraLoaderOptionsInterface, SagraLoaderType } from '@sagra/sagra-loader';
import { JobTaskType } from '../../../models/biq/jobTaskType.model';
import { SagraGridColumnDefinitionInteface, SagraGridColumnType, SagraGridOptionsInterface } from '@sagra/sagra-grid';
import { StaviatorUserContext } from '../../../models/staviatorUserContext.model';
import { RunTask } from '../../../models/monitoring/staviatorContext.model';
import { MessageBusService } from '../../../services/messageBusService/messageBusService';
import moment from 'moment';

@Component({
    selector: 'app-jobView',
    templateUrl: './jobView.component.html',
    styleUrls: ['./jobView.component.scss']
})
export class JobViewComponent extends SagraTranslatable implements OnInit {
    private readonly _destroying$ = new Subject<void>();
    ngOnDestroy() {
        this._destroying$.next();
        this._destroying$.complete();
    }
    public showLoader: boolean = false;
    public sagraLoaderOptions: SagraLoaderOptionsInterface;

    public showModal: boolean = false;

    public gridOptions: SagraGridOptionsInterface;
    public list: any[] = [];

    jobId: string;
    jobInfo: JobInfo;

    userContext: StaviatorUserContext;

    constructor(private monitoringService: MonitoringService,
        private messageService: MessageBusService,
        private route: ActivatedRoute,
        translationService: SagraTranslationService) {
        super(translationService);

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }

        messageService.getMonitoringRefresh()
            .pipe(takeUntil(this._destroying$))
            .subscribe((jobInfo) => {
                this.getJobInfo();
            });

        const subjectRowClick: Subject<any> = new Subject<any>();
        subjectRowClick.subscribe(id => {
            //alert(id);
        });

        this.gridOptions = {
            columnsDefinition: [],
            rowIdColumn: "jobID",  // wymagane do działania 'click handlerów'
            stickyHeader: true,
            clickRowHandler: subjectRowClick,
            searchText: null,
            searchByColumns: ["timestamp"],
            selectionColumn: 'timestamp',
            sortingColumn: 'timestamp',
            sortingAsc: false,
            tableMinWidth: 800,
            rowClassColumn: 'rowClass',
            scroll: {
                virtualScroll: {
                    itemSize: 60,
                    minBufferPx: 50,
                    maxBufferPx: 200
                },
                scrollOptions: {
                    autoHeightDisabled: true,
                    track:'vertical'
                },

            }
        }

        this.setGridColumns();
    }

    setGridColumns() {
        let columsDef: SagraGridColumnDefinitionInteface[] = [];
        columsDef.push({
            firstLineOrValueColumn: "taskNo",
            description: this.L("TaskNo"),
            columnBold: false,
            sortable: false,
            include: false,
            textNoWrap: true,
            type: SagraGridColumnType.text,
            width: 50
        });
        columsDef.push({
            firstLineOrValueColumn: "taskId",
            description: this.L("TaskId"),
            columnBold: false,
            sortable: true,
            include: true,
            textNoWrap: true,
            type: SagraGridColumnType.text,
            width: 80
        });
        columsDef.push({
            firstLineOrValueColumn: "description",
            description: this.L('Description'),
            columnBold: true,
            sortable: true,
            include: true,
            textNoWrap: true,
            type: SagraGridColumnType.text,
            width: 300,
        });
        columsDef.push({
            firstLineOrValueColumn: "type",
            description: this.L('TaskType'),
            sortable: true,
            include: true,
            type: SagraGridColumnType.text,
            width: 100,
        });

        this.gridOptions.columnsDefinition = columsDef;
    }

    setDataToGrid() {
        this.list = [];
        let useI = false;
        if (this.jobInfo.job.tasks) {
            for (var i = 0; i < this.jobInfo.job.tasks.length; i++) {
                let task = this.jobInfo.job.tasks[i];

                if (i == 0 && task.no == 0) {
                    useI = true;
                }

                let row = {
                    taskNo: useI ? i + 1 : task.no,
                    taskId: task.id,
                    description: task.description,
                    type: task.type,
                    rowClass: 'rowW'
                };
                this.list.push(row);
            };
        }

        this.showLoader = false;

    }

    getJobStateName(jobState: JobState) {
        return this.monitoringService.getJobStateName(jobState);
    }

    getJobStatePillsClass(jobState: JobState) {
        return this.monitoringService.getJobStatePillsClass(jobState);
    }

    getJiraPillsClass(status: string) {
        switch (status) {
            case 'Closed': return 'mint';
            default: return 'Grey';
            //case JobState.Manual: return 'pacific';
            //case JobState.Retrying: return 'citrus';
            //case JobState.Running: return 'pacific';
            //case JobState.Stopped: return 'Grey';
            //case JobState.Stopping: return 'pacific';
            //case JobState.Suspended: return 'Grey';
            //$color-pacific-light4: #d5e8fa
        }
    }

    getJobTaskType(jobTaskType: JobTaskType) {
        return JobTaskType[jobTaskType].toString();
    }

    getAvatar(avatarUrls: Map<string, string>) {
        let av = avatarUrls['24x24'];
        return av;
    }

    getTaskNumber() {
        if (this.jobInfo.status.jobState == JobState.Completed) {
            return this.jobInfo.status.tasks;
        } else {
            return this.jobInfo.status.tasksCompleted + 1;
        }
    }

    getEnvPillClass(env: string) {
        switch (env) {
            case 'Production': return 'mint'
            default: return 'violet'
        }
    }

    getJobOperationTypeName(jot: JobOperationType) {
        return this.L(JobOperationType[jot])
    }

    openManualtTaskModal() {
        this.showModal = true;
    }

    showJob() {

    }
    recalculateJob() {
        this.showLoader = true;
        this.monitoringService.recalculateJob(this.jobInfo.job.id)
            .pipe(takeUntil(this._destroying$))
            .subscribe(() => this.showLoader = false);
    }
    stopJob() {

    }
    stopRetry() {

    }

    restartTask() {
        this.showLoader = true;
        let runNextTask: RunTask = {
            jobId: this.jobInfo.job.id,
            taskId: this.jobInfo.currentTask.id,
            runOnlyNextTask: false
        }
        this.monitoringService.restartCurrentTask(runNextTask)
            .pipe(takeUntil(this._destroying$))
            .subscribe(() => this.showLoader = false);
    }

    async ngOnInit(): Promise<void> {
        this.userContext = await this.monitoringService.getUserContext();
        this.getJobInfo()
    }

    getJobInfo() {
        this.showLoader = true;
        this.route.queryParams.pipe(takeUntil(this._destroying$)).subscribe({
            next: (params) => {
                this.jobId = params['jobId'];
                this.monitoringService.getInfo(this.jobId).pipe(takeUntil(this._destroying$)).subscribe({
                    next: (response) => {
                        this.jobInfo = response;
                        this.setDataToGrid();
                        this.showLoader = false;
                    },
                    error: (err) => {
                        console.error(err);
                        this.showLoader = false;
                    }
                })
            },
        });
    }
    getTimestampFormated() {
        return moment(this.jobInfo.status.timestamp).format('YYYY-MM-DD HH:mm:ss')
    }

    getCreateDateFormated() {
        return moment(this.jobInfo.status.createdDate).format('YYYY-MM-DD HH:mm:ss')
    }
}
