import { animate, state, style, transition, trigger } from "@angular/animations"

export function ShowPanel() {
    return trigger('showPanel', [
        transition(':enter', [
            style({ right: '-450px' }),
            animate(200)
        ]),
        transition(':leave', [
            animate(200, style({ right: '-450px' }))
        ]),
        state('*', style({ right: '*' }))
    ])
}

export function ResizeList() {
    return trigger('resizeList', [
        state('begin', style({ width: '100%' })),
        state('end', style({ width: '*' })),
        transition('begin <=> end', [animate('0.2s ease-in-out')])
    ])
}

export function DialogZoom(animationDuration: number) {
    return trigger('dialogZoom', [
        state('in', style({
            transformOrigin: 'center',
            transform: 'translate(-50%, -50%) scale(1)'
        })),
        state('out', style({
            transformOrigin: 'center',
            transform: 'translate(-50%, -50%) scale(0.8)'
        })),
        transition('in => out', animate(animationDuration + 'ms ease-in-out')),
        transition('* => in', [style({
            transformOrigin: 'center',
            transform: 'translate(-50%, -50%) scale(0.8)'
        }), animate(animationDuration + 'ms ease-in-out')])
    ])
}

export function DialogOpacity(animationDuration: number) {
    return trigger('dialogOpacity', [
        state('in', style({
            opacity: '*'
        })),
        state('out', style({
            opacity: '0',
        })),
        transition('in => out', animate(animationDuration + 'ms ease-in-out')),
        transition('* => in', [style({
            opacity: '0'
        }), animate(animationDuration + 'ms ease-in-out')])
    ])
}
