import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FunctionalityDetailsItem } from "../../models/functionalities/functionalityDetailsItem.model";
import { FunctionalityItem } from "../../models/functionalities/functionalityItem.model";

@Injectable()
export class FunctionalitiesService {
    private apiUrl: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this.apiUrl = baseUrl;
    }

    getActivitiesFullList(): Observable<any[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetActivitiesFullList', {}).pipe(map(data => {
            const items: Array<FunctionalityItem> = [];
            for (let item of data) {
                items.push({
                    id: item.idCzynnosciTypy,
                    name: item.czynnosc,
                    displayName: item.displayName,
                    baseType: item.idCzynnoscTypBazowy,
                    baseTypeDescription: item.czynnoscTypBazowyOpis,
                    shareCount: item.iloscUdostepien,
                    description: item.opis,
                    note: item.notatka,
                    idPrimaryType: item.idTypPierwotny,
                    guidPrimaryType: item.guidTypPierwotny
                });
            }
            return items;
        }));
    }

    getProcessesFullList(): Observable<any[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetProcessesFullList', {}).pipe(map(data => {
            const items: Array<FunctionalityItem> = [];
            for (let item of data) {
                items.push({
                    id: item.idKatalogProcesow,
                    name: item.nazwaProcesu,
                    shareCount: item.iloscUdostepien,
                    description: item.opis,
                    note: item.notatka
                });
            }
            return items;
        }));
    }

    getExtractsFullList(): Observable<any[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetExtractsFullList', {}).pipe(map(data => {
            const items: Array<FunctionalityItem> = [];
            for (let item of data) {
                items.push({
                    id: item.id,
                    name: item.name,
                    shareCount: item.shares
                });
            }
            return items;
        }));
    }

    getDataSheetsFullList(): Observable<any[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetDataSheetsFullList', {}).pipe(map(data => {
            const items: Array<FunctionalityItem> = [];
            for (let item of data) {
                items.push({
                    id: item.id,
                    guid: item.guid,
                    name: item.name,
                    baseType: item.type,
                    baseTypeDescription: item.sType,
                    shareCount: item.shares
                });
            }
            return items;
        }));
    }

    getTWRFullList(): Observable<any[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetTWRFullList', {}).pipe(map(data => {
            const items: Array<FunctionalityItem> = [];
            for (let item of data) {
                items.push({
                    guid: item.guid,
                    name: item.name,
                    shareCount: item.shares
                });
            }
            return items;
        }));
    }

    getH5TablesFullList(): Observable<any[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetH5TablesFullList', {}).pipe(map(data => {
            const items: Array<FunctionalityItem> = [];
            for (let item of data) {
                items.push({
                    guid: item.guid,
                    name: item.name,
                    shareCount: item.shares
                });
            }
            return items;
        }));
    }

    getApplicationsFullList(): Observable<any[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetApplicationsFullList', {}).pipe(map(data => {
            const items: Array<FunctionalityItem> = [];
            for (let item of data) {
                items.push({
                    guid: item.guid,
                    name: item.name,
                    shareCount: item.shares
                });
            }
            return items;
        }));
    }

    getActivitiesListDetails(idActivity: number): Observable<FunctionalityDetailsItem[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetActivityListTenantsDetails', {id: idActivity}).pipe(map(data => {
            const items: Array<FunctionalityDetailsItem> = [];
            for (let item of data) {
                items.push({
                    id: item.idCzynnosciTypy,
                    tenant: item.idTenant,
                    status: item.status,
                    statusDescription: item.statusInfo,
                    testBase: item.testBase
                });
            }
            return items;
        }));
    }

    getProcessesListDetails(idProcess: number): Observable<FunctionalityDetailsItem[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetProcessListTenantsDetails', { id: idProcess }).pipe(map(data => {
            const items: Array<FunctionalityDetailsItem> = [];
            for (let item of data) {
                items.push({
                    id: item.idKatalogProcesow,
                    tenant: item.idTenant,
                    status: item.status,
                    statusDescription: item.statusInfo,
                    testBase: item.testBase
                });
            }
            return items;
        }));
    }

    getExtractsListDetails(idExctract: number): Observable<FunctionalityDetailsItem[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetExtractsListTenantsDetails', { id: idExctract }).pipe(map(data => {
            const items: Array<FunctionalityDetailsItem> = [];
            for (let item of data) {
                items.push({
                    id: item.id,
                    tenant: item.idTenant,
                    status: item.status,
                    statusDescription: item.statusInfo,
                    testBase: item.testBase
                });
            }
            return items;
        }));
    }

    getDataSheetsListDetails(idDataSheet: number): Observable<FunctionalityDetailsItem[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetDataSheetsListTenantsDetails', { id: idDataSheet }).pipe(map(data => {
            const items: Array<FunctionalityDetailsItem> = [];
            for (let item of data) {
                items.push({
                    id: item.id,
                    tenant: item.idTenant,
                    status: item.status,
                    statusDescription: item.statusInfo,
                    testBase: item.testBase
                });
            }
            return items;
        }));
    }

    getTWRListDetails(guid: string): Observable<FunctionalityDetailsItem[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetTWRListTenantsDetails', { guid: guid }).pipe(map(data => {
            const items: Array<FunctionalityDetailsItem> = [];
            for (let item of data) {
                items.push({
                    guid: item.guid,
                    tenant: item.idTenant,
                    status: item.status,
                    statusDescription: item.statusInfo,
                    testBase: item.testBase
                });
            }
            return items;
        }));
    }

    getH5TablesListDetails(guid: string): Observable<FunctionalityDetailsItem[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetH5TablesListTenantsDetails', { guid: guid }).pipe(map(data => {
            const items: Array<FunctionalityDetailsItem> = [];
            for (let item of data) {
                items.push({
                    guid: item.guid,
                    tenant: item.idTenant,
                    status: item.status,
                    statusDescription: item.statusInfo,
                    testBase: item.testBase
                });
            }
            return items;
        }));
    }

    getApplicationsListDetails(guid: string): Observable<FunctionalityDetailsItem[]> {
        return this.http.post<any[]>(this.apiUrl + 'api/Functionalities/GetApplicationsListTenantsDetails', { guid: guid }).pipe(map(data => {
            const items: Array<FunctionalityDetailsItem> = [];
            for (let item of data) {
                items.push({
                    guid: item.guid,
                    tenant: item.idTenant,
                    status: item.status,
                    statusDescription: item.statusInfo,
                    testBase: item.testBase
                });
            }
            return items;
        }));
    }
}
