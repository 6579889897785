import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { TenantConfiguration } from '../../models/tenant/tenantConfiguration.model';
import { WinPointConfiguration } from '../../models/tenant/winPointConfiguration.model';
import { ApplicationAAD } from '../../models/tenant/applicationAAD.model';
import * as moment from "moment";
import { ApplicationActivationInfo } from '../../models/tenant/applicationActivationInfo.model';

@Injectable()
export class TenantService {
    private apiUrl: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.apiUrl = baseUrl;
    }

    uploadLogo(file: File, tenant: string): Observable<any> {
        const formData = new FormData();
        formData.append('dbName', tenant);
        formData.append('file', file, file.name);
        const observable = this.http.post<any>(this.apiUrl + 'api/Tenant/UploadLogo', formData, { reportProgress: true, observe: 'events' });
        return observable;
    }

    getPortalName(tenant: string): Observable<any> {
        const data = { DbName: tenant }
        const observable = this.http.post<any>(this.apiUrl + 'api/Tenant/GetPortalName', data, { observe: 'response' });
        return observable;
    }

    getTenantConfiguration(tenant: string): Observable<TenantConfiguration> {
        const data = { DbName: tenant }
        const observable = this.http.post<TenantConfiguration>(this.apiUrl + 'api/Tenant/GetTenantConfiguration', data, { observe: 'response' }).pipe(
            map(response => {
                return response.body as TenantConfiguration;
            }),
            share());
        return observable;
    }

    getWinpointConfiguration(tenant: string): Observable<WinPointConfiguration> {
        const data = { DbName: tenant }
        const observable = this.http.post<WinPointConfiguration>(this.apiUrl + 'api/Tenant/GetWinpointConfiguration', data, { observe: 'response' }).pipe(
            map(response => {
                return response.body as WinPointConfiguration;
            }),
            share());
        return observable;
    }

    saveWinpointConfiguration(tenant: string, dbAddress: string, winpointData: WinPointConfiguration): Observable<any> {
        const data = {
            DbName: tenant,
            DbAddress: dbAddress,
            WinpointConfiguration: winpointData
        }
        const observable = this.http.post(this.apiUrl + 'api/Tenant/SaveWinpointConfiguration', data, { observe: 'response' });
        return observable;
    }

    getApplicationsAAD(tenant: string, source: number): Observable<Array<ApplicationAAD>> {
        const data = { DbName: tenant, appType: source };
        const observable = this.http.post<Array<ApplicationAAD>>(this.apiUrl + 'api/Tenant/GetApplicationsAAD', data).pipe(map(items => {
            for (let item of items) {
                if (item.startDate != null) {
                  item.startDate = moment(item.startDate);
                }
                if (item.endDate != null) {
                    item.endDate = moment(item.endDate);
                }
                if (item.status == 0) {
                    //inprogress
                    item.sortStatus = 4;
                }
                else if (item.status == 1) {
                    //unshared
                    item.sortStatus = 1;
                }
                else if (item.status == 2) {
                    //shared
                    item.sortStatus = 3;
                }
                else {
                    //error
                    item.sortStatus = 2;
                }
            }
            return items;
        }));
        return observable;
    }

    activateAgreements(tenant: string, portal: string, agreements: Array<ApplicationAAD>): Observable<any> {
        const data = { DbName: tenant, PortalName: portal, AADAgreements: agreements };
        const observable = this.http.post(this.apiUrl + 'api/Tenant/ActivateAgreements', data);
        return observable;
    }
    activateNewWorld(tenant: string, portal: string): Observable<any> {
        const data = { DbName: tenant, PortalName: portal };
        const observable = this.http.post(this.apiUrl + 'api/Tenant/ActivateNewWorld', data);
        return observable;
    }

    startEngineH5(tenant: string, portal: string): Observable<any> {
        const data = { DbName: tenant, PortalName: portal };
        const observable = this.http.post(this.apiUrl + 'api/Tenant/StartEngineH5', data);
        return observable;
    }

    getEngineH5Status(tenant: string): Observable<any> {
        const data = { DbName: tenant };
        const observable = this.http.post(this.apiUrl + 'api/Tenant/GetEngineH5Status', data);
        return observable;
    }

    saveApplicationAccess(tenant: string, applications: Array<ApplicationAAD>, enable: boolean): Observable<any> {
        const DATE_FORMAT = 'YYYY-MM-DD';
        for (const app of applications) {
            if (app.startDate != null) {
                app.startDate.toJSON = function () { return moment(this).format(DATE_FORMAT); }
            }
            if (app.endDate != null) {
                app.endDate.toJSON = function () { return moment(this).format(DATE_FORMAT); }
            }
        }

        const data = { DbName: tenant, applications: applications, Enable: enable };
        const observable = this.http.post(this.apiUrl + 'api/Tenant/SaveAADApplication', data);
        return observable;
    }

    editApplicationAccess(tenant: string, applications: Array<ApplicationAAD>): Observable<any> {
        const DATE_FORMAT = 'YYYY-MM-DD';
        for (const app of applications) {
            if (app.startDate != null) {
                app.startDate.toJSON = function () { return moment(this).format(DATE_FORMAT); }
            }
            if (app.endDate != null) {
                app.endDate.toJSON = function () { return moment(this).format(DATE_FORMAT); }
            }
        }
        const data = { DbName: tenant, applications: applications };
        const observable = this.http.post(this.apiUrl + 'api/Tenant/EditAADApplication', data);
        return observable;
    }

    canApplicationBeActivated(tenant: string): Observable<ApplicationActivationInfo> {
        const data = { DbName: tenant };
        const observable = this.http.post<ApplicationActivationInfo>(this.apiUrl + 'api/Tenant/CanUserApplicationBeActivated', data);
        return observable;
    }
}
