<div class="db-list-filters">
  <div class="db-list-filters-header">
    <div class="db-list-filters-header-title">{{L('Filters')}}</div>
    <div class="db-list-filters-header-clear sgf-link-pacific" (click)="clearFilters()">{{L('Clear')}}</div>
  </div>
  <div *ngFor="let group of filters">
    <div class="db-list-filters-group-title">{{group.caption}}</div>
    <div class="db-list-filters-group-item" *ngFor="let item of group.items">
      <sagra-checkbox [state]="item.selected" [labelText]="item.caption" [checkboxsize]="checkboxSize" [labelPosition]="checkboxLabelPosition" (update)="onFilterChange($event, group, item)"></sagra-checkbox>
    </div>
  </div>
</div>
