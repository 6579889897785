<sagra-modal [options]="dialogOptions" [showModal]="showModal">
    <div class="dscv-header">
        <div class="dscv-header-title">{{L('Select_cache_version')}}</div>
        <button id="buttonDveClose" class="dscv-header-close sgf-btn-white icon icon-btn_close" (click)="onBtnCloseClick()"></button>
    </div>
    <div class="dscv-versions">
        <!--<ng-scrollbar class="sagra-scrollbar">-->
            <div class="dscv-version" [ngClass]="{'selected': selectedVersion != null && version.cacheSeverVersionId==selectedVersion.cacheSeverVersionId}" *ngFor="let version of versions" (click)="onItemClick(version)">
                <div class="dscv-version-name">{{version.cacheSeverVersionName}}</div>
            </div>
        <!--</ng-scrollbar>-->
    </div>
    <div class="dscv-footer">
        <button class="sgf-btn-light" (click)="onBtnCloseClick()">{{L('Cancel')}}</button>
        <button class="sgf-btn-primary" (click)="onAcceptClick()">{{L('Update')}}</button>
    </div>
</sagra-modal>
