<div class="register-component">
    <tabpage-header [title]="headerTitle"
                    [subTitle]="headerSubTitle"
                    [actionButtonTitle]="actionButtonText"
                    [actionButtonVisible]="actionButtonVisible"
                    [actionButtonDisabled]="actionButtonDisabled"
                    [searchEnabled]="true"
                    [showBreadcrumb]="true"
                    (searchTextChanged)="onSearchTextChanged($event)"
                    (actionButtonClicked)="onActionButtonClicked($event)">
    </tabpage-header>
    <div class="register-list">
        <div class="register-list-row" *ngFor="let register of registers | registerGroupSearchFilter:searchText">
            <div class="register-list-row-group">
                <div class="register-list-row-collapse-btn" (click)="onRegisterCollapse($event, register)" [ngClass]="{'collapsed': register.collapsed}">
                    <span class="icon icon-keyboard_arrow_down"></span>
                </div>
                <div class="register-list-row-checkbox">
                    <sagra-checkbox [state]="isRegisterSelected(register)" [disabled]="register.status==1 || register.status==2" [labelPosition]="checkboxLabelPosition" [clickable]="checkboxClickable" (update)="onRegisterSelected($event, register)"></sagra-checkbox>
                </div>
                <div class="register-list-row-name">{{register.name}}</div>
                <div class="register-list-row-status" *ngIf="register.status == 1">
                    <div class="register-list-column-loader">
                        <sagra-loader [options]="sagraLoaderOptions"></sagra-loader>
                    </div>
                    <div [ngClass]="statusClass(register)">
                        {{statusText(register)}}
                    </div>
                </div>
            </div>
            <div [@hidegroup]="register.collapsed ? 'closed' : 'open'" class="register-activity-rows">
                <div class="register-activity-row" *ngFor="let activity of register.activityTypes | registerActivitySearchFilter:searchText">
                    <div class="register-activity-row-checkbox">
                        <sagra-checkbox [state]="isActivityTypeSelected(activity)" [disabled]="activity.status==1 || (register.status!=2 && !isRegisterSelected(register))" [labelPosition]="checkboxLabelPosition" [clickable]="checkboxClickable" (update)="onActivitySelected($event, register, activity)"></sagra-checkbox>
                    </div>
                    <div class="register-activity-row-name" [ngClass]="{'disabled': (activity.status==1 || (register.status!=2 && !register.selected))}">{{activity.activityTypeName}}</div>
                    <div class="register-activity-row-status" *ngIf="activity.status == 1">
                        <div class="register-list-column-loader">
                            <sagra-loader [options]="sagraLoaderOptions"></sagra-loader>
                        </div>
                        <div [ngClass]="statusClass(activity)">
                            {{statusText(activity)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
</div>
