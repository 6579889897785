import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";
import { SagraDialogOptionsInterface, SagraDialogService, SagraDialogType } from "@sagra/sagra-dialog";
import { SagraInputIconPosition, SagraInputOptionsModelInterface } from "@sagra/sagra-input";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { SagraPlaceholderButtonType, SagraPlaceholderImage, SagraPlaceholderOptionsInterface } from "@sagra/sagra-placeholder";
import { SagraRadioComponentListValueInterface } from "@sagra/sagra-radio";
import { SagraTabInterface } from "@sagra/sagra-tabs";
import { SagraToastsService, SagraToastsStyle } from "@sagra/sagra-toasts";
import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { Subject, Subscription } from "rxjs";
import { TargetingGroup, UpdateTargetingGroupRequest } from "../../models/targeting/targetingGroup.model";
import { DialogsService } from "../../services/dialogsService/dialogs.service";
import { TargetingService } from "../../services/targetingService/targeting.service";
import { ManageTargetingModalComponent } from "./manage-targeting-modal/manage-targeting-modal.component";

@Component({
    selector: 'targeting-list',
    templateUrl: './targeting-list.component.html',
    styleUrls: ['./targeting-list.component.scss']
})

export class TargetingListComponent extends SagraTranslatable implements OnInit, OnDestroy {

    //#region Init

    @Input() tenant: string;
    public headerTitle = this.L('TargetingGrups');
    public selectedMenuItem: TargetingMenuItem = TargetingMenuItem.Locations;
    public showLoader: boolean = true;
    public targetingTypeDict: Map<TargetingMenuItem, number> = new Map<TargetingMenuItem, number>();
    private subscriptions = new Subscription();

    public searchText: string;
    public targetingDescriptionRadioValue: string;
    public targetingDescriptionRadioList: SagraRadioComponentListValueInterface[];
    public menuTabs: SagraTabInterface[];

    public locationsTargetingGroupList: TargetingGroup[] = [];
    public clientsTargetingGroupList: TargetingGroup[] = [];
    public selectedTargetingGroup: TargetingGroup = null;
    public areTargetingClientsActive: boolean = false;

    public manageTargetingModalVisible: boolean = false;
    public consoleVisible: boolean = true;

    public newTargetingGroupSubject = new Subject();
    public deleteTargetingGroupSubject = new Subject();
    private addTargetModalSubject = new Subject<string>();
    private editTargetModalSubject = new Subject<TargetingGroup>();
    private deleteTargetModalSubject = new Subject();


    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    public searchInputOptions: SagraInputOptionsModelInterface;
    public emptyListPlaceholderOptions: SagraPlaceholderOptionsInterface;

    @ViewChild('targetingModal') targetingModal: ManageTargetingModalComponent;

    constructor(public translationService: SagraTranslationService,
        private dialogService: SagraDialogService,
        private targetingService: TargetingService,
        private toastService: SagraToastsService,
        private dialogsService: DialogsService) {

        super(translationService);

        //ID typów grup targetowych (są zdefiniowane w bazie)
        this.targetingTypeDict.set(TargetingMenuItem.Locations, 1);
        this.targetingTypeDict.set(TargetingMenuItem.Clients, 4);

        this.subscriptions.add(this.newTargetingGroupSubject.asObservable().subscribe(() => this.showTargetingModal(null)));
        this.subscriptions.add(this.deleteTargetingGroupSubject.asObservable().subscribe(() => this.deleteTargetingGroup()));

        this.subscriptions.add(this.addTargetModalSubject.asObservable().subscribe({ next: data => { this.addTargetingGroup(data) } }));
        this.subscriptions.add(this.editTargetModalSubject.asObservable().subscribe({ next: data => { this.editTargetingGroup(data) } }));
        this.subscriptions.add(this.deleteTargetModalSubject.asObservable().subscribe({ next: data => { this.showDeleteTargetingGroupDialog() } }));


        this.searchInputOptions = {
            icon: {
                className: 'icon-btn_search',
                position: SagraInputIconPosition.left,
                margin: 10
            },
            placeholder: this.L("Search"),
        }

        this.emptyListPlaceholderOptions = {
            image: { type: SagraPlaceholderImage.file },
            title: this.L('NoLocationTargetingGroup'),
            button: {
                className: "sgf-link",
                name: `+ ${this.L('Add')}`,
                type: SagraPlaceholderButtonType.link,
                handler: this.newTargetingGroupSubject
            }
        }

        this.targetingDescriptionRadioList = [
            {
                name: this.L('RepresentativeName'),
                value: '0'
            },
            {
                name: this.L('RepresentativeCode'),
                value: '1'
            }
        ];

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }
    }

    ngOnInit() {
        //Pobranie grup targetowych
        this.subscriptions.add(this.targetingService.getTargetingGroups(this.tenant).subscribe(data => {
            this.locationsTargetingGroupList = data.filter(x => x.classId == this.targetingTypeDict.get(TargetingMenuItem.Locations));
            this.clientsTargetingGroupList = data.filter(x => x.classId == this.targetingTypeDict.get(TargetingMenuItem.Clients));
            this.showLoader = false;
        }));

        //Sprawdzenie czy tenant ma możliwość zakładania grup targetowych dla personelu medycznego
        this.subscriptions.add(this.targetingService.areTargetingClientsActive(this.tenant).subscribe(result => {
            this.areTargetingClientsActive = result;

            //Zakładki menu, których widoczność jest uzależniona od powyższego parametru
            this.menuTabs = [
                {
                    id: 0,
                    name: this.L('Locations')
                },
                {
                    id: 1,
                    name: this.L('Staff'),
                    hidden: !this.areTargetingClientsActive
                }
            ]
        }));

        //Pobranie sposobu w jaki opisywane są grupy targetowe dla danego tenanta
        this.subscriptions.add(this.targetingService.getTargetingGroupDescription(this.tenant).subscribe(targetingGroupDescription => {
            if (!targetingGroupDescription)
                return;

            this.targetingDescriptionRadioValue = targetingGroupDescription.descriptionId;
        }));
    }


    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    //#endregion

    public enableConsoles() {
        this.showLoader = true;
        this.subscriptions.add(this.targetingService.activateTargetConsole(this.tenant).subscribe(() => {
            this.toastService.pushToast({ moduleId: 1, message: this.L('TargetingOperationSuccessful'), style: SagraToastsStyle.success });
            this.consoleVisible = false;
            this.showLoader = false;
        }, (error) => {
            this.toastService.pushToast({ moduleId: 1, message: this.L('TargetingOperationFailed'), style: SagraToastsStyle.danger });
            this.showLoader = false;
        }));
    }

    public showDeleteTargetingGroupDialog(targetingGroup: TargetingGroup = null) {
        if (targetingGroup)
            this.selectedTargetingGroup = targetingGroup;

        let dialogOptions: SagraDialogOptionsInterface = {
            dialogType: SagraDialogType.confirm,
            title: this.L('Deleting'),
            message: this.L('DeletingElementWarning'),
            buttons: [
                { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                { name: this.L('Delete'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.custom, className: 'sgf-btn-cherry', handler: this.deleteTargetingGroupSubject }
            ]
        };

        this.dialogService.showAlert(dialogOptions);
    }

    updateTab = () => this.selectedMenuItem == 0 ? this.emptyListPlaceholderOptions.title = this.L('NoLocationTargetingGroup') : this.emptyListPlaceholderOptions.title = this.L('NoPersonnelTargetingGroup');

    //#region Obsługa grup targetowych

    public addTargetingGroup(name: string) {
        let request: UpdateTargetingGroupRequest = {
            id: null,
            dbName: this.tenant,
            classId: this.targetingTypeDict.get(this.selectedMenuItem),
            name: name,
            deleted: false
        }

        this.updateTargetingGroup(request, (targetingGroupId) => {
            let newTargetingGroup: TargetingGroup = {
                id: targetingGroupId,
                classId: request.classId,
                name: request.name,
                deleted: false
            }

            if (this.selectedMenuItem == TargetingMenuItem.Locations)
                this.locationsTargetingGroupList.push(newTargetingGroup);
            else if (this.selectedMenuItem == TargetingMenuItem.Clients)
                this.clientsTargetingGroupList.push(newTargetingGroup);
        });
    }

    public editTargetingGroup(targetingGroup: TargetingGroup) {
        let request: UpdateTargetingGroupRequest = {
            id: targetingGroup.id,
            dbName: this.tenant,
            classId: targetingGroup.classId,
            name: targetingGroup.name,
            deleted: targetingGroup.deleted
        }

        this.updateTargetingGroup(request, (targetingGroupId) => {
            this.selectedTargetingGroup.name = targetingGroup.name;
        });
    }

    public deleteTargetingGroup() {
        let request: UpdateTargetingGroupRequest = {
            id: this.selectedTargetingGroup.id,
            dbName: this.tenant,
            classId: this.selectedTargetingGroup.classId,
            name: this.selectedTargetingGroup.name,
            deleted: true
        }

        this.updateTargetingGroup(request, (targetingGroupId) => {

            if (this.selectedMenuItem == TargetingMenuItem.Locations)
                this.locationsTargetingGroupList = this.locationsTargetingGroupList.filter(x => x.id != this.selectedTargetingGroup.id);
            else if (this.selectedMenuItem == TargetingMenuItem.Clients)
                this.clientsTargetingGroupList = this.clientsTargetingGroupList.filter(x => x.id != this.selectedTargetingGroup.id);
        });
    }

    private updateTargetingGroup(request: UpdateTargetingGroupRequest, onSuccess: (id: number) => void) {
        this.hideTargetingModal();
        this.showLoader = true;
        this.subscriptions.add(this.targetingService.updateTargetingGroup(request).subscribe((targetingGroupId) => {
            onSuccess(targetingGroupId);
            this.toastService.pushToast({ moduleId: 1, message: this.L('TargetingOperationSuccessful'), style: SagraToastsStyle.success });
            this.showLoader = false;
        }, (error) => {
            this.toastService.pushToast({ moduleId: 1, message: this.L('TargetingOperationFailed'), style: SagraToastsStyle.danger });
            this.showLoader = false;
        }));
    }


    public targetingDescriptionChanged(newValue: string) {
        this.showLoader = true;
        this.subscriptions.add(this.targetingService.updateTargetingGroupDescription(this.tenant, newValue).subscribe(() => {
            this.showLoader = false;
        }))
    }

    //#endregion

    //#region Wyświetlanie modala

    public showTargetingModal(targetingGroup: TargetingGroup) {
        this.selectedTargetingGroup = targetingGroup;
        this.manageTargetingModalVisible = true;

        this.dialogsService.setTargetingModalState({
            state: true,
            targetingGroup: targetingGroup,
            groupForLocation: this.selectedMenuItem == 0,
            addTargetingGroupHandler: this.addTargetModalSubject,
            editTargetingGroupHandler: this.editTargetModalSubject,
            deleteTargetingGroupHandler: this.deleteTargetModalSubject
        });
    }

    public hideTargetingModal() {
        this.dialogsService.setTargetingModalState({
            state: false,
            targetingGroup: null,
            groupForLocation: false,
            addTargetingGroupHandler: null,
            editTargetingGroupHandler: null,
            deleteTargetingGroupHandler: null
        });
    }

    //#endregion
}

//Lista zakładek
export enum TargetingMenuItem {
    Locations = 0,
    Clients = 1
}
