<div class="cm-component">
    <div class="cm-filters">
        <database-list-filters [filters]="filters" (filterChanged)="onFilterChanged($event)"></database-list-filters>
    </div>
    <div class="cm-content">
        <tabpage-header [showBreadcrumb]="false"
                        [searchEnabled]="true"
                        [actionButtonVisible]="true"
                        [actionButtonTitle]="L('Update_cache_version')"
                        [actionButtonDisabled]="selectedItems.length == 0"
                        (searchTextChanged)="onSearchTextChanged($event)"
                        (actionButtonClicked)="onActionButtonClicked($event)">
        </tabpage-header>
        <div class="cm-list">
            <div class="cm-list-header">
                <div class="cm-list-header-column sort name" [ngClass]="{'sorting': sortingColumn=='clientName'}" (click)="sortColumn('clientName')">
                    {{L('Name')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <!--<div class="cm-list-header-column sort status" [ngClass]="{'sorting': sortingColumn=='status'}" (click)="sortColumn('status')">
                    {{L('Status')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>-->
                <div class="cm-list-header-column sort date" [ngClass]="{'sorting': sortingColumn=='timeStamp'}" (click)="sortColumn('timeStamp')">
                    {{L('Last_modification')}}
                    <span class="icon" [ngClass]="(sortingReverse) ? 'icon-sortarrow_down' : 'icon-sortarrow_up'"></span>
                </div>
                <div class="cm-list-header-column version">
                    {{L('VM_version')}}
                </div>
            </div>
                <div #list class="cm-list-rows"
                     [@blinkList]="blinkList ? 'hide' : 'show'" (@blinkList.done)="onEndBlinkAnimation()">
                    <!--<cdk-virtual-scroll-viewport #virtualScroll class="virtualScroll" [itemSize]="50" [minBufferPx]="350" [maxBufferPx]="500">-->
                    <div class="cm-list-row"
                         *ngFor="let item of filteredListItems"
                         [ngClass]="{ 'selected': selectedCacheDetails != null && item.dbName==selectedCacheDetails.dbName }"
                         sagraTooltip [sagraTooltipModel]="item.tooltip"
                         (click)="onItemClick(item)">
                        <div class="cm-list-column name">
                            <span class="center-vert">{{item.clientName}}</span>
                        </div>
                        <!--<div class="cm-list-column status">
                            <sagra-pill class="center-vert" [type]="item.status == 1 || item.status == 11 ? 2 : 1" [text]="statusTextForPills(item)" [pillClass]="statusClass(item)"></sagra-pill>
                        </div>-->
                        <div class="cm-list-column date">
                            <span class="center-vert">{{item.timeStampText}}</span>
                        </div>
                        <div class="cm-list-column version" sagraTooltip [sagraTooltipModel]="item.tooltipModel">
                            <span class="center-vert" *ngIf="item.inProgressInfo == null">{{item.versionsText}}</span>
                            <sagra-pill class="center-vert" [type]="2" [text]="statusTextForPills(item)" [pillClass]="statusClass(item)" *ngIf="item.inProgressInfo!=null"></sagra-pill>
                        </div>
                    </div>
                    <!--</cdk-virtual-scroll-viewport>-->
                </div>
        </div>
        <div class="container-for-placeholder" *ngIf="!firstDownloadingData && (filteredListItems == null || filteredListItems.length == 0)">
            <sagra-placeholder [title]="placeholderOptions.title"
                               [description]="placeholderOptions.description"
                               [button]="placeholderOptions.button"
                               [image]="placeholderOptions.image">
            </sagra-placeholder>
        </div>
        <div class="cm-details-panel" *ngIf="detailsPanelVisible" [@showPanel]="detailsPanelVisible ? 'end' : 'begin'">
            <div class="cm-details-container">
                <div class="cm-details-header">
                    <div class="cm-details-header-title">
                        <div class="main-title">{{selectedCacheDetails.dbName}}</div>
                    </div>
                    <!--<div class="cm-details-header-close icon icon-btn_close sgf-link-light" (click)="onClosePanelClick($event)"></div>-->
                    <button id="buttonSdpClose" class="cm-details-header-close sgf-btn-white" (click)="onClosePanelClick($event)" [title]="L('Close')"><span class="icon icon-btn_close"></span></button>
                </div>
                <div class="cm-details-content-title">{{L('Cache_versions')}}</div>
                <ng-scrollbar class="sagra-scrollbar" [autoHeightDisabled]="true">
                    <div class="cm-details-content">
                        <div class="cm-details-cache-item" *ngFor="let cache of selectedCacheDetails.cacheDetailsItems">
                            <cache-details [portal]="selectedCacheDetails.portalName"
                                           [tenant]="selectedCacheDetails.dbName"
                                           [cacheDetails]="cache"
                                           [packages]="packages"
                                           [versions]="cacheVersions"
                                           [licenseTypes]="cacheLicenseTypes"
                                           [allowDelete]="allowDeleteSelectedCache"
                                           (performingAction)="onCachePerformActionChange($event)"
                                           >
                            </cache-details>
                        </div>
                    </div>
                </ng-scrollbar>
            </div>
            <sagra-loader class="cm-details-loader"
                          *ngIf="showDetailsLoader"
                          [loaderType]="sagraLoaderOptions.loaderType"
                          [backgroundSetings]="sagraLoaderOptions.backgroundSetings"></sagra-loader>
        </div>
        <sagra-loader [loaderType]="sagraLoaderOptions.loaderType" [backgroundSetings]="sagraLoaderOptions.backgroundSetings" *ngIf="showLoader"></sagra-loader>
    </div>
</div>
