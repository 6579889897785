import { SagraTranslationService, SagraTranslatable } from "@sagra/sagra-translation";
import { SimpleChanges, Component, Input, OnInit, OnChanges } from "@angular/core";
import { DialogAgreementsOptions, DialogAgreementsOptionsInterface } from "../../logic/dialogAgreementsOptions.interface";
import { SagraCheckboxComponentSize, SagraCheckboxComponentLabelPosition } from "@sagra/sagra-checkbox";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { SagraToastsModelInterface, SagraToastsStyle, SagraToastsService } from "@sagra/sagra-toasts";
import { Subscription } from "rxjs";
import { TenantService } from "../../services/tenantService/tenant.service";
import { map } from "rxjs/operators";
import { ApplicationAAD } from "../../models/tenant/applicationAAD.model";
import { Clipboard } from '@angular/cdk/clipboard';
import { SagraTooltipOptionsInterface, SagraTooltipOrientation } from "@sagra/sagra-tooltip";
import { DialogOpacity, DialogZoom } from "../../animations/animations";
import { Utils } from "../../utils/utils";
import { DialogsService } from "../../services/dialogsService/dialogs.service";
import { AgreementsModalState } from "../../models/agreementsModalState.model";
import { SagraModalOptionsInterface } from "@sagra/sagra-modal";
import { Subject } from "rxjs";

const animationDuration: number = 200;

@Component({
    selector: 'dialog-agreements',
    templateUrl: './dialog-agreements.component.html',
    styleUrls: ['./dialog-agreements.component.scss'],
    animations: [ DialogZoom(animationDuration), DialogOpacity(animationDuration) ]
})

export class DialogAgreementsComponent extends SagraTranslatable implements OnInit, OnChanges {
    //@Input() options: DialogAgreementsOptionsInterface;

    public show: boolean = false;
    private modalState: AgreementsModalState;

    public visible: boolean;
    public agreementsToPublish: Array<any>;
    public agreements: Array<ApplicationAAD>;
    public tenant: string;
    private portal: string;
    public showLoader: boolean;
    public noLinksToCopy: boolean = true;

    public options: SagraModalOptionsInterface;
    public tooltipOptions: SagraTooltipOptionsInterface;
    public loaderOptions: SagraLoaderOptionsInterface;

    public checkboxSize: SagraCheckboxComponentSize = SagraCheckboxComponentSize.small;
    public checkboxLabelPosition: SagraCheckboxComponentLabelPosition = SagraCheckboxComponentLabelPosition.right;
    public sagraLoaderOptions: SagraLoaderOptionsInterface;
    private subscriptions = new Subscription();

    private cancelSubject: Subject<any> = new Subject<any>();

    constructor(public translationService: SagraTranslationService,
        public messageService: MessageBusService,
        private toastsService: SagraToastsService,
        public tenantService: TenantService,
        private dialogsService: DialogsService,
        private clipboard: Clipboard
    ) {
        super(translationService);

        this.options = {
            title: this.L('Agreements_aad'),
            maxHeight: '700px',
            minHeight: '700px',
            buttons: [],
            closeOnEscapeHandler: this.cancelSubject,
            maxWidth: '600px',
            minWidth: '600px',
            fixScrollPadding: false,
            zIndex: 900
        }


        this.subscriptions.add(this.dialogsService.getAgreementsModalState().subscribe({
            next: data => {
                this.modalState = data;
                this.show = data.state;
                if (this.show) {
                    this.agreementsToPublish = null;
                    this.visible = true;
                    this.tenant = data.tenant;
                    this.portal = data.portal;
                    this.getAADAgreements();
                }
            }
        }));

        this.subscriptions.add(this.cancelSubject.asObservable().subscribe(
            x => this.onCancelClick()
        ));
    }

    ngOnInit(): void {
        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            }
        }

        this.messageService.getRefreshAADAgreements().subscribe(data => {
            this.getAADAgreements();
        });

        this.tooltipOptions = {
            orientation: SagraTooltipOrientation.vertical
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    onCloseClick() {
        this.hideDialog();
    }

    onItemCheck(event: ApplicationAAD, agreement: ApplicationAAD) {
        let bAdd = true;
        if (this.agreementsToPublish == null) {
            this.agreementsToPublish = [];
        }
        for (let i = 0; i < this.agreementsToPublish.length; i++) {
            if (agreement.id == this.agreementsToPublish[i].id) {
                bAdd = false;
                this.agreementsToPublish.splice(i, 1);
                break;
            }
        }
        if (bAdd) {
            this.agreementsToPublish.push(agreement);
        }
    }

    onCancelClick() {
        this.hideDialog();

    }

    onPublishClick() {
        //this.hideDialog();

        this.activateAgreements();
    }

    onCopyLinksClick() {
        let links = "";
        for (let i = 0; i < this.agreements.length; i++) {
            if (this.agreements[i].status == 2) {
                if (links.length > 0) {
                    links = links + "\r\n";
                }
                links = links + this.agreements[i].name + " \t " + this.agreements[i].link;
            }
        }

        this.copyToClipboard(links);
        this.showToast();
    }

    onLinkClick(agreement: ApplicationAAD) {
        this.copyToClipboard(agreement.link);
        this.showToast();
    }

    hideDialog() {
        this.show = false;
    //    setTimeout(() => {
    //        this.visible = false;
    //    });
    }


    getAADAgreements() {
        this.showLoader = true;
        this.subscriptions.add(this.tenantService.getApplicationsAAD(this.tenant, 1)
            .pipe(map(items => {
                for (let i = 0; i < items.length; i++) {
                    items[i].link = Utils.getAADLink(items[i].id);
                    this.prepareTooltipOptions(items[i]);
                }
                return items;
            }))
            .subscribe(data => {
                this.agreements = data;
                this.noLinksToCopy = true;
                let result = this.agreements.filter(item => { return item.status == 2; });
                this.noLinksToCopy = (result == null || result.length == 0);
                this.showLoader = false;
            },
                error => {
                    this.showLoader = false;
                }));
    }

    isItemSelected(item: ApplicationAAD) {
        let bFound = false;

        if (this.agreementsToPublish != null) {
            for (let agreement of this.agreementsToPublish) {
                if (agreement.id == item.id) {
                    bFound = true;
                    break;
                }
            }
        }
        return (item.status == 2 && !bFound) || (item.status != 2 && bFound);
    }

    copyToClipboard(item: string): void {
        this.clipboard.copy(item);
    }

    showToast() {
        const toastModel: SagraToastsModelInterface = {
            moduleId: 1,
            message: this.L('Link_clipboard'),
            style: SagraToastsStyle.info,
            closeButton: true
        }

        this.toastsService.pushToast(toastModel);
    }


    prepareTooltipOptions(agreement: ApplicationAAD) {
        if (agreement != null) {
            agreement.tooltipModel = {
                tooltipItems: [{title: null, content: agreement.description}]
            }
        }
    }

    activateAgreements() {
        this.showLoader = true;
        this.subscriptions.add(this.tenantService.activateAgreements(this.tenant, this.portal, this.agreementsToPublish).subscribe({
            next: data => {
                this.showLoader = false;
                this.hideDialog();
            },
            error: error => {
                this.showLoader = false;
            }
        }));
    }
}

