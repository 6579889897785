import { SagraTranslatable, SagraTranslationService } from "@sagra/sagra-translation";
import { OnInit, OnDestroy, Input, Component } from "@angular/core";
import { SagraDialogService, SagraDialogOptionsInterface, SagraDialogType } from "@sagra/sagra-dialog";
import { Subscription, Subject } from "rxjs";
import { HierarchyService } from "../../services/hierarchyService/hierarchy.service";
import { HierarchyData } from "../../models/hierarchy/hierarchyData.model";
import { MessageBusService } from "../../services/messageBusService/messageBusService";
import { SagraLoaderOptionsInterface, SagraLoaderType } from "@sagra/sagra-loader";
import { SagraButtonActionType, SagraButtonStyle } from "@sagra/sagra-common";

@Component({
    selector: 'hierarchy-list',
    templateUrl: './hierarchy-list.component.html',
    styleUrls: ['./hierarchy-list.component.scss'],
})

export class HierarchyListComponent extends SagraTranslatable implements OnInit, OnDestroy {
    @Input() tenant: string;

    private subscriptions = new Subscription();
    public headerTitle = this.L('Hierarchy');
    public hierarchy: HierarchyData;
    public expandedAll: boolean;
    public expandAllText = this.L('Expand_all');
    public showLoader = false;

    private addLevelNameSubject = new Subject<string>();
    private saveHierarchySubject: Subject<any> = new Subject<any>();

    public sagraLoaderOptions: SagraLoaderOptionsInterface;

    constructor(public translationService: SagraTranslationService,
        public dialogService: SagraDialogService,
        public hierarchyService: HierarchyService,
        public messageService: MessageBusService) {
        super(translationService);

        this.subscriptions.add(this.addLevelNameSubject.asObservable().subscribe(
            x => this.showAddHierarchyDialog(x)
        ));

        this.subscriptions.add(this.saveHierarchySubject.asObservable().subscribe(
            x => {
                if (this.hierarchy == null) {
                    this.hierarchy = x.hierarchy;
                }
                else {
                    if (this.hierarchy.subHierarchies == null) {
                        this.hierarchy.subHierarchies = [];
                    }
                    this.hierarchyService.addHierarchy(this.hierarchy, x.hierarchy);
                }
            }
        ));

        this.sagraLoaderOptions = {
            loaderType: SagraLoaderType.spinerSolid,
            spinerSolidSetings: {
                size: 14,
                borderWidth: 3
            },
            backgroundSetings: {
                backgroundColor: '#FFFFFF',
                opacity: 0.8
            }
        }
    }

    ngOnInit(): void {
        this.getHierarchyList();
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onActivateCheckboxClick(item: any) {
    }

    onAddHierarchyClick() {
        let levelName = null;
        if (this.hierarchy != null) {
            levelName = this.hierarchyService.findLevelName(this.hierarchy, 1);
        }

        if (this.hierarchy == null || levelName == null) {
            let dialogOptions: SagraDialogOptionsInterface = {
                dialogType: SagraDialogType.prompt,
                title: this.L('Set_level_name'),
                message: this.L('Level_name_text'),
                buttons: [
                    { name: this.L('Cancel'), actionType: SagraButtonActionType.cancel, style: SagraButtonStyle.secondary },
                    { name: this.L('OK'), actionType: SagraButtonActionType.accept, style: SagraButtonStyle.primary, handler: this.addLevelNameSubject }
                ]
            };
            this.dialogService.showAlert(dialogOptions);
        }
        else {
            this.showAddHierarchyDialog(levelName);
        }
    }

    onExpandAllClick() {
        this.expandedAll = !this.expandedAll;
        this.messageService.expandAllHierarchies(this.expandedAll);
        if (this.expandedAll) {
            this.expandAllText = this.L('Collapse_all');
        }
        else {
            this.expandAllText = this.L('Expand_all');
        }
    }

    onHierarchyAdded(hierarchy: HierarchyData) {
        this.hierarchyService.addHierarchy(this.hierarchy, hierarchy);
    }

    showAddHierarchyDialog(levelName: string) {
        //if (levelName == null || levelName.length == 0) {
        //    return;
        //}
        let parentHierarchyCode: string;
        if (this.hierarchy != null) {
            parentHierarchyCode = this.hierarchy.hierarchyCode;
        }
        this.messageService.showDialogEditHierarchy({
            tenant: this.tenant,
            levelName: levelName,
            rootHierarchyItem: this.hierarchy,
            hierarchyItem: null,
            parentHierarchyCode: parentHierarchyCode,
            cancelHandler: null,
            saveHandler: this.saveHierarchySubject,
        });
    }

    getHierarchyList() {
        this.showLoader = true;
        this.subscriptions.add(this.hierarchyService.getHierarchyList(this.tenant).subscribe((data) => {
            if (data != null && data.length > 0) {
                this.hierarchy = data[0];
            }
            this.showLoader = false;
        },
            (error) => {
                console.log(error);
                this.showLoader = false;
            }
        ));
    }
}
