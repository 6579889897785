export class HierarchyData {
    hierarchyCode: string;
    name: string;
    companyCode: string;
    levelId: number;
    levelName: string;
    departmentId: number;
    endLevel: boolean;
    deleted: boolean;

    subHierarchies: Array<HierarchyData>;
    expanded: boolean;
}
