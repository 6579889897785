import { Component, Directive, ElementRef, HostListener, Input, OnInit, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[customTooltip]',

})

export class CustomTooltipDirective implements OnInit, AfterViewInit {
    @Input("customTooltipOptions") options: CustomTooltipOptions;

    public showTooltip: boolean;
    private displayDelay: number = 500; // opoznienie pokazania się tooltipa
    private offsetVert = 20; // przesunięcie w pionie
    public tooltipItems: Array<CustomTooltipItem> = [];
    private tooltipElement: any;
    private mousePositionX: number;
  private elementsNoTooltip;
    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit(): void {
        if (this.options != null) {
            this.tooltipItems = this.options.tooltipItems;
        }

        const container = document.body.querySelector(".tooltip-container");
        if (container == null) {
            const newElement = document.createElement("div");
            this.renderer.addClass(newElement, 'tooltip-container');
            this.renderer.appendChild(document.body, newElement);
            this.tooltipElement = newElement;
        }
        else {
            this.tooltipElement = container;
        }
    }

    ngAfterViewInit(): void {
    }

    @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
        if (this.options == null || this.options.tooltipItems == null) {
            return;
        }

        this.showTooltip = true;

        setTimeout(() => {
            if (this.showTooltip) {
                this.show(event);
            }
        }, this.displayDelay);
    this.assignElementsNoTooltip();
    }
    @HostListener('mouseover', ['$event']) onMouseOver(event: MouseEvent) {
        this.mousePositionX = event.clientX;
    }

  @HostListener('mouseleave') onMouseLeave() {
    this.removeElementsNoTooltip();

    this.makeVisible(false);
  }

  assignElementsNoTooltip() {
    this.elementsNoTooltip = this.el.nativeElement.querySelectorAll('.noTooltip');
    if (this.elementsNoTooltip != null) {
      this.elementsNoTooltip.forEach((el) => {
        el.addEventListener('mouseenter', this.mouseEnter.bind(this));
        el.addEventListener('mouseleave', this.mouseLeave.bind(this));
      });
    }
  }

  removeElementsNoTooltip() {
    if (this.elementsNoTooltip != null) {
      this.elementsNoTooltip.forEach((el) => {
        el.removeEventListener('mouseenter', this.mouseEnter);
        el.removeEventListener('mouseleave', this.mouseLeave);
      });
    }
  }

  mouseEnter(event) {
    this.makeVisible(false);
  }
  mouseLeave(event) {
    this.makeVisible(true);
  }



    show(event: MouseEvent) {
        if (!this.showTooltip) {
            return;
        }

        const parent = this.el.nativeElement;
        const parentGlobalPosition = parent.getBoundingClientRect();
        const elementWidth = this.tooltipElement.offsetWidth;
        const elementHeight = this.tooltipElement.offsetHeight;
        const pageWidth = window.innerWidth;
        const pageHeight = window.innerHeight;
        const tooltipCenterX = this.mousePositionX;

        while (this.tooltipElement.firstChild) {
            this.tooltipElement.firstChild.remove();
        }
        for (let i = 0; i < this.tooltipItems.length; i++) {
            if (this.tooltipItems[i].content != null && this.tooltipItems[i].content.length > 0) {
                const tooltipItem = document.createElement('div');
                this.renderer.addClass(tooltipItem, 'tooltip-item');
                const tooltipTitle = document.createElement('div');
                this.renderer.addClass(tooltipTitle, 'tooltip-title');
                tooltipTitle.innerHTML = this.tooltipItems[i].title;
                const tooltipContent = document.createElement('div');
                this.renderer.addClass(tooltipContent, 'tooltip-content');
                tooltipContent.innerHTML = this.tooltipItems[i].content;
                this.renderer.appendChild(tooltipItem, tooltipTitle);
                this.renderer.appendChild(tooltipItem, tooltipContent);
                this.renderer.appendChild(this.tooltipElement, tooltipItem);
            }
        }

        // wyznaczenie pozycji TOP
        let dispPointY = parentGlobalPosition.top + parent.offsetHeight + this.offsetVert;
        if (dispPointY + elementHeight > pageHeight) {
            dispPointY = parentGlobalPosition.top - elementHeight - this.offsetVert;
        }
        if (dispPointY < 0) {
            dispPointY = 0;
        }

        // wyznaczenie pozycji LEFT
        let dispPointX = tooltipCenterX - elementWidth / 2;
        if (dispPointX < 0) {
            dispPointX = 0;
        }
        else if (dispPointX + elementWidth > pageWidth) {
            dispPointX = pageWidth - elementWidth;
        }

        //ustawiamy pozycję tooltipa
        this.renderer.setStyle(this.tooltipElement, "left", dispPointX.toString() + "px");
        this.renderer.setStyle(this.tooltipElement, "top", dispPointY.toString() + "px");


        //pokazanie tooltipa
        this.makeVisible(true);
    }

    makeVisible(visible: boolean) {
        this.showTooltip = visible;
        if (visible) {
            this.renderer.addClass(this.tooltipElement, 'visible');
        }
        else {
            this.renderer.removeClass(this.tooltipElement, 'visible');
        }
    }

}


export class CustomTooltipOptions {
    tooltipItems: Array<CustomTooltipItem> = [];
}

export class CustomTooltipItem {
    title: string;
    content: string;
}
