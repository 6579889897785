<div class="dlg-edit-hierarchy" *ngIf="visible">
    <div [@dialogOpacity]="animation" class="dlg-backdrop" (click)="onBackdropClick($event)"></div>
    <div [@dialogZoom]="animation" [@dialogOpacity]="animation" class="dlg-content-container">
        <div class="dlg-content">
            <div>
                <div class="dlg-field-caption">{{L('Unit_name')}}</div>
                <sagra-input [model]="hierarchyNameModel"
                             [isRequired]="unitNameInputOptions.isRequired"
                             [isDisabled]="unitNameInputOptions.isDisabled"
                             [characterLimit]="unitNameInputOptions.characterLimit"
                             [returnInvalidValue]="unitNameInputOptions.returnInvalidValue"
                             (update)="onUnitNameModelChange($event)"></sagra-input>
            </div>
            <div>
                <div class="dlg-inline-elements">
                    <div class="dlg-field-caption">{{L('Parent_unit')}}</div>
                    <sagra-input class="dlg-input-half"
                                 [(model)]="parentUnitModel"
                                 [isDisabled]="parentUnitInputOptions.isDisabled"
                                 ></sagra-input>
                </div>
                <div class="dlg-inline-elements">
                    <div class="dlg-field-caption">{{L('Identifier')}}</div>
                    <sagra-input #inputID class="dlg-input-quater"
                                 [(model)]="identifierModel"
                                 [isRequired]="identifierInputOptions.isRequired"
                                 [isDisabled]="identifierInputOptions.isDisabled"
                                 [characterLimit]="identifierInputOptions.characterLimit"
                                 [validationRegex]="identifierInputOptions.validationRegex"
                                 [returnInvalidValue]="identifierInputOptions.returnInvalidValue"
                                 (update)="onIdentifierModelChange($event)"></sagra-input>
                </div>
            </div>
            <div>
                <div class="dlg-field-caption">{{L('ID_departament')}}</div>
                <sagra-input class="dlg-input-half"
                             [model]="departmentIdModel"
                             [isDisabled]="departmentIdInputOptions.isDisabled"
                             [type]="departmentIdInputOptions.type"
                             [maxValue]="departmentIdInputOptions.maxValue"
                             [minValue]="departmentIdInputOptions.minValue"
                             [returnInvalidValue]="departmentIdInputOptions.returnInvalidValue"
                             (update)="onDepartmentIdModelChange($event)"></sagra-input>
            </div>
            <div>
                <div class="dlg-field-caption">{{L('SF_code')}}</div>
                <sagra-input class="dlg-input-half"
                             [model]="companyCodeModel"
                             [isRequired]="codeSFInputOptions.isRequired"
                             [isDisabled]="codeSFInputOptions.isDisabled"
                             [characterLimit]="codeSFInputOptions.characterLimit"
                             [returnInvalidValue]="codeSFInputOptions.returnInvalidValue"
                             (update)="onCodeSFModelChange($event)"></sagra-input>
            </div>
            <div>
            <sagra-checkbox [state]="endLevelModel"
                            [labelText]="checkboxItem.labelText"
                            [labelPosition]="checkOptions.labelPosition"
                            [className]="checkboxEndLevel"
                            [checkboxsize]="checkOptions.checkboxsize"
                            [clickable]="checkOptions.clickable"
                            [singleLineLabel]="checkOptions.singleLineLabel"
                            [disabled]="checkOptions.disabled"
                            (update)="onEndLevelCheckboxClick($event)"></sagra-checkbox>
            </div>
        </div>
        <div class="dlg-footer">
            <button class="sgf-btn-primary dlg-btn-save" (click)="onSaveClick($event)" [disabled]="invalidForm" *ngIf="!readOnly">{{L('Save')}}</button>
            <button class="sgf-btn-secondary dlg-btn-cancel" (click)="onCancelClick($event)" *ngIf="!readOnly">{{L('Cancel')}}</button>
            <button class="sgf-btn-primary dlg-btn-ok" (click)="onCancelClick($event)" *ngIf="readOnly">{{L('OK')}}</button>
        </div>
        <sagra-loader *ngIf="showLoader"></sagra-loader>
    </div>
</div>
